/**
 * A plain date without time or time zone information.
 * Represented as YYYY-MM-DD. Used in date pickers and task due dates.
 * Should be superseded by Temporal.PlainDate when standardized.
 */
export class PlainDate {
  #date: Date;

  constructor(date: Date) {
    this.#date = date;
  }

  /**
   * Creates a PlainDate representing the current date.
   */
  static now() {
    return new PlainDate(new Date());
  }

  /**
   * Creates a PlainDate with a value from a 'YYYY-MM-DD' string.
   */
  static parse(value: string) {
    const [y, m, d] = value.split("-").map((n) => Number(n));
    const date = new Date(y, m - 1, d);
    return new PlainDate(date);
  }

  /**
   * Formats a JS Date object into a 'YYYY-MM-DD' PlainDate string.
   */
  static format(date: Date) {
    const y = date.getFullYear().toString();
    const m = (date.getMonth() + 1).toString().padStart(2, "0");
    const d = date.getDate().toString().padStart(2, "0");

    return `${y}-${m}-${d}`;
  }

  get year() {
    return this.#date.getFullYear();
  }

  get month() {
    return this.#date.getMonth() + 1;
  }

  get day() {
    return this.#date.getDate();
  }

  /**
   * Returns a JS Date object with this PlainDate's value.
   */
  toDate() {
    return new Date(this.#date);
  }

  /**
   * Returns a string representation of this PlainDate's value.
   */
  toString() {
    return PlainDate.format(this.#date);
  }
}
