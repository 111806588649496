import { html, cond, toState, type MaybeState, type State, type ViewContext } from "@manyducks.co/dolla";
import styles from "./IconButton.module.css";
import { pass, when } from "@helpers/patterns";
import { ButtonColor, ButtonStyle } from "@views/Button";

interface IconButtonProps {
  type?: "button" | "submit" | "reset";
  onClick?: (e: MouseEvent) => void;
  disabled?: MaybeState<boolean>;
  size?: "normal" | "small";
  active?: MaybeState<boolean>;
  tooltip?: MaybeState<string>;
  label?: MaybeState<string>;
  showLabel?: MaybeState<boolean>;
  numberBadge?: number | State<number> | State<number | undefined>;

  color?: ButtonColor;
  style?: ButtonStyle;
}

/**
 * Square button with an icon in the middle. Used for toolbars and UI controls.
 */
export function IconButton(props: IconButtonProps, ctx: ViewContext) {
  const $disabled = toState(props.disabled);
  const $tooltip = toState(props.tooltip);
  const $label = toState(props.label);
  const $showLabel = toState(props.showLabel);
  const $numberBadge = toState(props.numberBadge);
  const { type, size, active } = props;

  function onClick(e: MouseEvent) {
    if ($disabled.get() !== true && props.onClick) {
      props.onClick(e);
    }
  }

  const colorClasses = when(
    [
      [ButtonColor.Neutral, { [styles.colorNeutral]: true }],
      [ButtonColor.Danger, { [styles.colorDanger]: true }],
      [pass, { [styles.colorAccent]: true }],
    ],
    props.color,
  );
  const styleClasses = when(
    [
      [ButtonStyle.Hollow, { [styles.styleHollow]: true }],
      [pass, { [styles.styleSolid]: true }],
    ],
    props.style,
  );

  return html`
    <button
      onclick=${onClick}
      class=${[
        styles.button,
        size && styles[size],
        colorClasses,
        styleClasses,
        { [styles.withLabel]: $showLabel, [styles.active]: active },
      ]}
      type=${type ?? "button"}
      disabled=${$disabled}
      title=${$tooltip}
    >
      <div class=${styles.content}>
        ${ctx.outlet()} ${cond($showLabel, html`<span class=${styles.label}>${$label}</span>`)}
        ${cond($numberBadge, html`<span class=${styles.numberBadge}>${$numberBadge}</span>`)}
      </div>
    </button>
  `;

  // return (
  //   <button
  //     onClick={(e) => {
  //       if ($disabled.get() !== true && props.onClick) {
  //         props.onClick(e);
  //       }
  //     }}
  //     class={[
  //       styles.button,
  //       styles[variant ?? "transparent"],
  //       size && styles[size],
  //       { [styles.withLabel]: $showLabel, [styles.active]: active },
  //     ]}
  //     type={type ?? "button"}
  //     disabled={$disabled}
  //     title={$tooltip}
  //   >
  //     <div class={styles.icon}>{ctx.outlet()}</div>
  //     {cond($showLabel, <span class={styles.label}>{$label}</span>)}
  //     {cond($numberBadge, <div class={styles.numberBadge}>{$numberBadge}</div>)}
  //   </button>
  // );
}
