import Dolla from "@manyducks.co/dolla";

const debug = Dolla.createLogger("📦 stores/push");

const PUSH_PUBLIC_KEY =
  "BDzEsY6PkGh037b0cck71ZPXxtujkssIHVJMLZi9FUQXtFG3s6g4RIfibD6SpKaM-F3H7vNdRSGGosjAUWQJ91k";
const BANNER_DISMISSED_KEY = "quack-notification-banner-dismissed";

const [$subscription, setSubscription] = Dolla.createState<PushSubscription>();

const [$chatBannerDismissed, setChatBannerDismissed] = Dolla.createState(
  localStorage.getItem(BANNER_DISMISSED_KEY) === "true",
);

export const $isPushEnabled = Dolla.derive([$subscription], (s) => s != null);
export const $displayChatBanner = Dolla.derive(
  [$subscription, $chatBannerDismissed],
  (sub, dismissed) => "Notification" in window && sub == null && !dismissed,
);

export function dismissChatBanner() {
  localStorage.setItem(BANNER_DISMISSED_KEY, "true");
  setChatBannerDismissed(true);
}

export function resetChatBanner() {
  localStorage.removeItem(BANNER_DISMISSED_KEY);
  setChatBannerDismissed(false);
}

export async function disablePushNotifications() {
  const sub = $subscription.get();

  if (sub) {
    await Dolla.http.delete(`/api/push/web/registrations?endpoint=${sub.toJSON().endpoint}`);
    await sub.unsubscribe();

    setSubscription(undefined);
    dismissChatBanner();
  }
}

export async function enablePushNotifications() {
  return new Promise((resolve, reject) => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.ready.then((serviceWorkerRegistration) => {
        if (serviceWorkerRegistration.pushManager) {
          serviceWorkerRegistration.pushManager
            .subscribe({
              userVisibleOnly: true,
              applicationServerKey: PUSH_PUBLIC_KEY,
            })
            .then((subscription) => {
              setSubscription(subscription);
              resetChatBanner();

              // Keep your server in sync with the latest subscriptionId
              return _sendSubscriptionToServer(subscription);
            })
            .then(resolve);
        } else {
          reject(new Error(`Push notifications are not supported by your browser.`));
        }
      });
    } else {
      reject(new Error("Service worker is not present"));
    }
  });
}

async function _sendSubscriptionToServer(subscription: PushSubscription) {
  return Dolla.http
    .post("/api/push/web/registrations", {
      body: subscription.toJSON(),
    })
    .then(() => {
      debug.log("sent", subscription.toJSON());
    });
}

if ("serviceWorker" in navigator) {
  // We need the service worker registration to check for a subscription
  navigator.serviceWorker.ready.then((serviceWorkerRegistration) => {
    if (serviceWorkerRegistration.pushManager) {
      // Do we already have a push message subscription?
      serviceWorkerRegistration.pushManager.getSubscription().then((subscription) => {
        if (subscription) {
          setSubscription(subscription);

          // Keep server in sync
          _sendSubscriptionToServer(subscription);
        }
      });
    }
  });
}
