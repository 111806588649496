export default {
  "noteListItem": "NoteListItem_noteListItem_4d164",
  "itemDivider": "NoteListItem_itemDivider_4d164",
  "note": "NoteListItem_note_4d164",
  "highlight": "NoteListItem_highlight_4d164",
  "focused": "NoteListItem_focused_4d164",
  "selected": "NoteListItem_selected_4d164",
  "noteLayout": "NoteListItem_noteLayout_4d164",
  "noteText": "NoteListItem_noteText_4d164",
  "noteTitle": "NoteListItem_noteTitle_4d164",
  "untitled": "NoteListItem_untitled_4d164",
  "noteTagList": "NoteListItem_noteTagList_4d164",
  "noteTag": "NoteListItem_noteTag_4d164",
  "meta": "NoteListItem_meta_4d164",
  "metaIcon": "NoteListItem_metaIcon_4d164",
  "metaLabel": "NoteListItem_metaLabel_4d164"
};import "ni:sha-256;cUVu08I6-gbQPDrm61gQDTc9htzlngNJaX9WBx0bm9o";