export default {
  "layout": "PlaceLayout_layout_9748b",
  "header": "PlaceLayout_header_9748b",
  "titleArea": "PlaceLayout_titleArea_9748b",
  "homeButton": "PlaceLayout_homeButton_9748b",
  "title": "PlaceLayout_title_9748b",
  "mainText": "PlaceLayout_mainText_9748b",
  "withSubtitle": "PlaceLayout_withSubtitle_9748b",
  "subText": "PlaceLayout_subText_9748b",
  "deemphasized": "PlaceLayout_deemphasized_9748b",
  "titleControls": "PlaceLayout_titleControls_9748b",
  "main": "PlaceLayout_main_9748b",
  "userMenuIcon": "PlaceLayout_userMenuIcon_9748b",
  "sideTabs": "PlaceLayout_sideTabs_9748b",
  "active": "PlaceLayout_active_9748b",
  "tabIcon": "PlaceLayout_tabIcon_9748b",
  "tabLabel": "PlaceLayout_tabLabel_9748b",
  "tabBackdrop": "PlaceLayout_tabBackdrop_9748b",
  "hidden": "PlaceLayout_hidden_9748b",
  "bottomTabs": "PlaceLayout_bottomTabs_9748b",
  "content": "PlaceLayout_content_9748b"
};import "ni:sha-256;F8dJCNpRcSuIkb81rhugK7-FFIavvTs-C2jreIRD6O8";