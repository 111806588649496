export default {
  "container": "TextEditor_container_15a4c",
  "layout": "TextEditor_layout_15a4c",
  "toolbar": "TextEditor_toolbar_15a4c",
  "hidden": "TextEditor_hidden_15a4c",
  "toolbarContent": "TextEditor_toolbarContent_15a4c",
  "metaMenuOpen": "TextEditor_metaMenuOpen_15a4c",
  "toolbarButton": "TextEditor_toolbarButton_15a4c",
  "active": "TextEditor_active_15a4c",
  "toolbarDivider": "TextEditor_toolbarDivider_15a4c",
  "metaButtonGroup": "TextEditor_metaButtonGroup_15a4c",
  "editor": "TextEditor_editor_15a4c",
  "searchMenu": "TextEditor_searchMenu_15a4c",
  "content": "TextEditor_content_15a4c",
  "metaMenu": "TextEditor_metaMenu_15a4c",
  "metaMenuShade": "TextEditor_metaMenuShade_15a4c",
  "open": "TextEditor_open_15a4c",
  "metaContent": "TextEditor_metaContent_15a4c",
  "metaSwitchGroup": "TextEditor_metaSwitchGroup_15a4c",
  "metaDivider": "TextEditor_metaDivider_15a4c",
  "metaSectionContent": "TextEditor_metaSectionContent_15a4c",
  "metaSection": "TextEditor_metaSection_15a4c",
  "metaSubSection": "TextEditor_metaSubSection_15a4c",
  "metaAttachmentsList": "TextEditor_metaAttachmentsList_15a4c",
  "metaAttachmentUpload": "TextEditor_metaAttachmentUpload_15a4c",
  "metaAttachmentUploadInput": "TextEditor_metaAttachmentUploadInput_15a4c",
  "metaAttachmentUploadLabel": "TextEditor_metaAttachmentUploadLabel_15a4c",
  "outlineList": "TextEditor_outlineList_15a4c",
  "outlineItem": "TextEditor_outlineItem_15a4c",
  "timestampList": "TextEditor_timestampList_15a4c"
};import "ni:sha-256;yjjlEXB3WUV4Zo2SD9EYI6CunpEkz7zVOWVdFxtnays";