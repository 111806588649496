import {
  cond,
  derive,
  createState,
  toState,
  type MaybeState,
  type Renderable,
  type State,
  type ViewContext,
} from "@manyducks.co/dolla";
import styles from "./Switch.module.css";
import { Icon } from "MaterialSymbols";

interface SwitchProps {
  id?: string;
  $value: State<boolean>;
  disabled?: MaybeState<boolean>;
  offIcon?: MaybeState<Renderable>;
  onIcon?: MaybeState<Renderable>;
  onChange?: (value: boolean) => void;
}

export function Switch(props: SwitchProps, ctx: ViewContext) {
  const { $value } = props;

  const $disabled = toState(props.disabled ?? false);

  const [$pointerStart, setPointerStart] = createState(0);
  const [$isPressed, setIsPressed] = createState(false);
  const [$isDragging, setIsDragging] = createState(false);
  const [$dragStart, setDragStart] = createState(0);
  const [$dragOffset, setDragOffset] = createState(0);

  let justChangedByDrag = false; // Prevent input onchange from flipping value immediately if value was changed by dragging.

  const id = props.id ?? ctx.uid;

  function onPointerDown(e: PointerEvent) {
    setPointerStart(e.pageX);
    setIsPressed(true);

    window.addEventListener("pointermove", onPointerMove);
    window.addEventListener("pointerup", onPointerUp);
  }

  function onPointerMove(e: PointerEvent) {
    // Start handling as a drag if position has moved at least 5 pixels from start.
    if (!$isDragging.get() && Math.abs(e.pageX - $pointerStart.get()) > 5) {
      window.removeEventListener("pointermove", onPointerMove);
      window.removeEventListener("pointerup", onPointerUp);
      onDragStart(e);
    }
  }

  function onPointerUp(e: PointerEvent) {
    window.removeEventListener("pointermove", onPointerMove);
    window.removeEventListener("pointerup", onPointerUp);
    setIsPressed(false);
    justChangedByDrag = false;
  }

  function onDragStart(e: PointerEvent) {
    e.preventDefault();
    if ($disabled.get()) return;
    setDragStart(e.pageX);
    window.addEventListener("pointerup", onDragEnd);
    window.addEventListener("pointermove", onDragMove);
  }

  function onDragMove(e: PointerEvent) {
    e.preventDefault();
    if ($disabled.get()) return;
    setDragOffset(e.pageX - $dragStart.get());
  }

  function onDragEnd() {
    if ($disabled.get()) return;

    const pos = clamp($value.get() ? 22 : 0 + $dragOffset.get(), 0, 22);

    setDragOffset(0);

    window.removeEventListener("pointerup", onDragEnd);
    window.removeEventListener("pointermove", onDragMove);

    const checked = $value.get();
    if (!checked && pos >= 11) {
      props.onChange?.(true);
    } else if (checked && pos < 11) {
      props.onChange?.(false);
    }

    setIsPressed(false);
    justChangedByDrag = true;
  }

  return (
    <div
      class={{
        [styles.container]: true,
        [styles.pressed]: $isPressed,
        [styles.disabled]: $disabled,
        [styles.isOn]: $value,
      }}
      onPointerDown={onPointerDown}
    >
      <label class={styles.label} for={id} />
      <input
        id={id}
        class={styles.input}
        checked={$value}
        disabled={$disabled}
        onchange={() => {
          if (justChangedByDrag) {
            justChangedByDrag = false;
            return;
          }
          props.onChange?.(!$value.get());
        }}
        type="checkbox"
      />
      <div class={styles.track} />
      <span
        class={styles.switch}
        style={{
          transform: derive(
            [$value, $dragOffset],
            (v, d) => `translateX(${clamp((v ? 22 : 0) + d, 0, 22)}px)`,
          ),
        }}
      >
        {cond(
          derive([$value, $dragOffset], (v, d) => clamp((v ? 22 : 0) + d, 0, 22) >= 11),
          props.onIcon ?? <Icon name="Check" opticalSize={22} grade={80} />,
          cond(
            $disabled,
            <Icon name="Close" opticalSize={22} grade={80} />,
            props.offIcon ?? <Icon name="Arrow Right Alt" opticalSize={22} grade={80} />,
          ),
        )}
      </span>
    </div>
  );
}

function clamp(value: number, min: number, max: number) {
  return Math.max(min, Math.min(max, value));
}
