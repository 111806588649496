export default {
  "layout": "Home_layout_37d42",
  "header": "Home_header_37d42",
  "headerContent": "Home_headerContent_37d42",
  "userInfo": "Home_userInfo_37d42",
  "userAvatar": "Home_userAvatar_37d42",
  "userName": "Home_userName_37d42",
  "headerDayAndTime": "Home_headerDayAndTime_37d42",
  "headerControls": "Home_headerControls_37d42",
  "content": "Home_content_37d42",
  "contentWrap": "Home_contentWrap_37d42",
  "hidden": "Home_hidden_37d42",
  "section": "Home_section_37d42",
  "subsection": "Home_subsection_37d42",
  "linksList": "Home_linksList_37d42",
  "link": "Home_link_37d42",
  "linkIcon": "Home_linkIcon_37d42",
  "linkLabel": "Home_linkLabel_37d42",
  "linkIndicator": "Home_linkIndicator_37d42",
  "widgetGrid": "Home_widgetGrid_37d42",
  "widget": "Home_widget_37d42",
  "small": "Home_small_37d42",
  "medium": "Home_medium_37d42",
  "large": "Home_large_37d42",
  "taskList": "Home_taskList_37d42",
  "tileList": "Home_tileList_37d42",
  "taskCount": "Home_taskCount_37d42",
  "overflowButtonGroup": "Home_overflowButtonGroup_37d42",
  "projectLink": "Home_projectLink_37d42",
  "projectLinkContent": "Home_projectLinkContent_37d42",
  "projectTitleBlock": "Home_projectTitleBlock_37d42",
  "projectTitle": "Home_projectTitle_37d42",
  "projectRole": "Home_projectRole_37d42",
  "projectMeta": "Home_projectMeta_37d42",
  "empty": "Home_empty_37d42",
  "projectMetaIcon": "Home_projectMetaIcon_37d42",
  "projectInvite": "Home_projectInvite_37d42",
  "projectInviteActions": "Home_projectInviteActions_37d42",
  "projectInviteSender": "Home_projectInviteSender_37d42",
  "projectInviteSenderDot": "Home_projectInviteSenderDot_37d42",
  "hideButtonGroup": "Home_hideButtonGroup_37d42"
};import "ni:sha-256;3uKBsyudy29Xo9VGeJsM_1QGg8DTvUeY382IwUdVU8Q";