import Dolla, {
  t,
  derive,
  createState,
  type MaybeState,
  type ViewContext,
  createSettableState,
} from "@manyducks.co/dolla";
import { Button } from "@views/Button";
import { ColorInput } from "@views/ColorInput";
import { Dialog, DialogContent, DialogFooter, DialogHeader } from "@views/Dialog";
import { TextInput } from "@views/TextInput";
import styles from "./CreateProjectDialog.module.css";

import type { DialogProps } from "@stores/dialog";

interface CreateProjectInfo {
  color: string;
  name: string;
}

interface CreateProjectDialogProps extends DialogProps {
  accentColor: MaybeState<string>;

  /**
   * Called when confirmation is successful
   */
  onConfirm: (info: CreateProjectInfo) => Promise<void>;
}

/**
 * A dialog to confirm permanent deletion of some data.
 */
export function CreateProjectDialog(props: CreateProjectDialogProps, ctx: ViewContext) {
  const [$color, setColor] = createState("#ff0088");
  const $$name = createSettableState("");

  const [$isWaiting, setIsWaiting] = createState(false);

  return (
    <Dialog
      accentColor={derive(
        [props.accentColor, $color],
        (defaultColor, chosenColor) => chosenColor || defaultColor,
      )}
      onsubmit={async () => {
        setIsWaiting(true);
        props
          .onConfirm({
            color: $color.get(),
            name: $$name.get(),
          })
          .then(() => {
            props.dialog.close();
          })
          .finally(() => {
            setIsWaiting(false);
          });
      }}
      transitionIn={props.dialog.transitionIn}
      transitionOut={props.dialog.transitionOut}
    >
      <DialogHeader dialog={props.dialog} $title={t("workspace.nav.projects.createProjectDialog.title")} />

      <DialogContent>
        <div class={styles.content}>
          <div class={styles.formGroup}>
            <label for="project-name">{t("workspace.nav.projects.createProjectDialog.nameLabel")}</label>
            <TextInput
              id="project-name"
              $$value={$$name}
              // ref={$$textInput}
              placeholder={t("workspace.nav.projects.createProjectDialog.nameInputPlaceholder")}
            />
          </div>

          <div class={styles.formGroup}>
            <label for="project-color">{t("workspace.nav.projects.createProjectDialog.colorLabel")}</label>
            <ColorInput id="project-color" $value={$color} onChange={setColor} />
          </div>
        </div>
      </DialogContent>

      <DialogFooter>
        <Button type="submit" disabled={derive([$$name], (v) => v.trim().length === 0)}>
          {t("workspace.nav.projects.createProjectDialog.submitButtonText")}
        </Button>
      </DialogFooter>
    </Dialog>
  );
}
