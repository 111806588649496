import { TaskEditDialog } from "@dialogs/TaskEditDialog/TaskEditDialog";
import { relativePastTime } from "@helpers/relativePastTime";
import Dolla, { t, derive, type State, type ViewContext, cond } from "@manyducks.co/dolla";
import { Note, Task } from "schemas";
import type { Activity } from "../Activity";
import styles from "./ActivityListItem.module.css";

import { dialog, projects, theme } from "@stores";
import { TaskListItem } from "@views/TaskListItem";
import { NoteListItem } from "@views/NoteListItem";

type FavoriteType = "task" | "note";

type Recent = {
  path: string;
  timestamp: string;
};

interface ActivityListItemProps {
  $activity: State<Activity>;
  onClick?: (e: Event) => void;
}

export function ActivityListItem(props: ActivityListItemProps, ctx: ViewContext) {
  const $details = derive(
    [props.$activity, projects.$cache, Dolla.i18n.$locale],
    (activity, projectsCache, _) => {
      const project = projectsCache.get(activity.data.projectId);

      return {
        title: activity.data.title,
        color: project?.color ?? "#888",
        type: activity.type,
        data: activity.data,
      };
    },
  );

  // const $active = derive(
  //   [props.$activity, Dolla.router.$path],
  //   (a, path) => `/projects/${a.data.projectId}/${a.type}s/${a.data.id}` === path,
  // );

  // TODO: The wrapping <div> fixes a problem where repeat() can't maintain the correct order when a cond() is a direct child.

  return (
    <div class={styles.container}>
      {cond(
        derive([$details], (d) => d.type === "task"),
        <TaskListItem
          $task={derive([$details], (d) => d.data as Task)}
          meta={{ project: true, updatedAt: "relative" }}
        />,
        <NoteListItem
          $note={derive([$details], (d) => d.data as Note)}
          meta={{ project: true, updatedAt: "relative" }}
          onClick={props.onClick}
        />,
      )}
    </div>
  );
}
