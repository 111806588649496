import { Icon } from "MaterialSymbols";
import Dolla, {
  State,
  cond,
  toSettableState,
  createState,
  derive,
  type ViewContext,
} from "@manyducks.co/dolla";
import { Button } from "@views/Button";
import { ColorInput } from "@views/ColorInput";
import { TextInput } from "@views/TextInput";
import chroma from "chroma-js";
import { User } from "schemas";
import styles from "./Join.module.css";

import { auth, loader } from "@stores";
import { getTheme$ } from "@stores/theme";

interface JoinProps {}

export function Join(props: JoinProps, ctx: ViewContext) {
  const [$step, setStep] = createState(0);
  const [$email, setEmail] = createState<string>();
  const [$color, setColor] = createState<string>();
  const [$token, setToken] = createState<string>("");

  ctx.onMount(async () => {
    const query = Dolla.router.$query.get();
    const token = query.token as string;

    if (token) {
      // Confirm email and continue process
      // proceed based on which info is filled in on API userSignup response data (we will save with every step)
      // TODO: Token expired?
      // TODO: Pull current signup data
      try {
        await auth.logOut(); // Make sure we're logged out of any other account first.
        const res = await Dolla.http.get<{ step: number; email: string; color?: string }>("/api/join", {
          query: { token },
        });
        if (res.status === 200) {
          const { step, email, color } = res.body;
          setStep(step);
          setEmail(email);
          if (color) setColor(color);
          setToken(token);
        } else {
          // TODO: Handle error
          ctx.error(res);
        }
      } catch (err: any) {
        if (err.response) {
          if (err.response.status === 404) {
            // No signup record.
            ctx.warn("NO SIGNUP RECORD FOUND");
          }
        } else {
        }
      }
    } else {
      Dolla.router.go("/");
    }

    loader.hideAppLoader();
  });

  const $theme = getTheme$($color);

  return (
    <div class={styles.layout} style={$theme}>
      <div class={styles.content}>
        <h1>Welcome to Quack</h1>
        <ol class={styles.stepList}>
          <li>
            <ProgressDot step={0} $current={$step} />
            <div class={styles.stepListText}>
              <span>Confirm email</span>
              <span class={styles.secondary}>{$email}</span>
            </div>
          </li>
          <li>
            <ProgressDot step={1} $current={$step} />
            <div class={styles.stepListText}>
              <span>Add password</span>
            </div>
          </li>
          <li>
            <ProgressDot step={2} $current={$step} />
            <div class={styles.stepListText}>
              <span>Choose name and color</span>
            </div>
          </li>
          {/* <li>
            <ProgressDot step={3} $current={$$step} />
            Authenticator (optional)
          </li> */}
          {/* <li>(Later?) Choose/draw avatar</li>
          <li>(Later) Offer supporter subscription</li> */}
          <li>
            <ProgressDot step={3} $current={$step} />
            <div class={styles.stepListText}>
              <span>Done!</span>
            </div>
          </li>
        </ol>
        {derive([$step], (step) => {
          switch (step) {
            case 0:
              return <p>Verifying email address...</p>;
            case 1:
              return (
                <Step1_Password
                  $token={$token}
                  onNext={() => {
                    setStep((step) => step + 1);
                  }}
                />
              );
            case 2:
              return (
                <Step2_User
                  $token={$token}
                  onColorChange={setColor}
                  onResponse={({ user, token, expiresAt }) => {
                    auth.setAuth(token, new Date(expiresAt));
                  }}
                  onNext={() => {
                    setStep((step) => step + 1);
                  }}
                />
              );
            // case 3:
            //   return (
            //     <Step3_2FA
            //       $token={$token}
            //       onNext={() => {
            //         $$step.update((step) => step + 1);
            //       }}
            //     />
            //   );

            default:
              return <Done />;
          }
        })}
      </div>
    </div>
  );
}

interface ProgressDotProps {
  step: number;
  $current: State<number>;
}

function ProgressDot(props: ProgressDotProps) {
  const { step, $current } = props;

  const $isCurrent = derive([$current], (current) => current === step);
  const $isDone = derive([$current], (current) => current > step);

  return (
    <div
      class={[
        styles.progressDot,
        {
          [styles.current]: $isCurrent,
          [styles.done]: $isDone,
        },
      ]}
    >
      {cond($isCurrent, <Icon name="Arrow Right" />)}
      {cond($isDone, <Icon name="Check" />)}
    </div>
  );
}

interface StepProps {
  $token: State<string>;
  onNext: () => void;
}

function Step1_Password(props: StepProps, ctx: ViewContext) {
  let isSubmitting = false;
  const [$errorMessage, setErrorMessage] = createState<string>();

  const [$password, setPassword] = createState("");
  const [$verify, setVerify] = createState("");

  return (
    <form
      class={styles.form}
      onSubmit={(e) => {
        e.preventDefault();

        if (isSubmitting) return;
        isSubmitting = true;

        const token = props.$token.get();

        const form = e.currentTarget as HTMLFormElement;
        const password: string = form.password.value;
        const verify: string = form.verify.value;

        setErrorMessage(undefined);

        Dolla.http
          .post("/api/join/1", { body: { password, verify }, query: { token } })
          .then((res) => {
            props.onNext();
          })
          .catch((err) => {
            if (err.response) {
              const res = err.response;
              setErrorMessage(res.body.message);
            } else {
              ctx.error(err);
            }
          })
          .finally(() => {
            isSubmitting = false;
          });
      }}
    >
      <div class={styles.formGroup}>
        <p class={styles.formGroupDescription}>
          Password must be at least 16 characters. See{" "}
          <a href="https://xkcd.com/936/" target="_blank" rel="noreferrer noopener">
            relevant xkcd.
          </a>
        </p>
      </div>
      <div class={styles.formGroup}>
        <label for="password">Password</label>
        <TextInput
          id="password"
          type="password"
          name="password"
          $$value={toSettableState($password, setPassword)}
          minlength={16}
          placeholder="************"
          autocomplete={false}
        />
      </div>
      <div class={styles.formGroup}>
        <label for="verify">Password Again</label>
        <TextInput
          id="verify"
          type="password"
          name="verify"
          $$value={toSettableState($verify, setVerify)}
          minlength={16}
          placeholder="************"
          autocomplete={false}
        />
      </div>

      {cond($errorMessage, <p style={{ color: "red" }}>{$errorMessage}</p>)}

      <div class={styles.formControls}>
        <Button
          type="submit"
          disabled={derive([$password, $verify], (pass, verify) => {
            return pass !== verify || pass.length < 16;
          })}
        >
          Submit
        </Button>
      </div>
    </form>
  );
}

interface Step2Response {
  user: User;
  token: string;
  expiresAt: string;
}

interface Step2Props extends StepProps {
  onResponse: (data: Step2Response) => void;
  onColorChange: (color: string) => void;
}

function Step2_User(props: Step2Props, ctx: ViewContext) {
  const [$name, setName] = createState<string>("");
  const [$color, setColor] = createState<string>(chroma.random().hex());

  ctx.watch([$color], props.onColorChange);

  const [$errorMessage, setErrorMessage] = createState<string>();

  let isSubmitting = false;

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();

        if (isSubmitting) return;
        isSubmitting = true;

        const token = props.$token.get();

        const name = $name.get();
        const color = $color.get();

        Dolla.http
          .post<Step2Response>("/api/join/2", { body: { name, color }, query: { token } })
          .then((res) => {
            props.onResponse(res.body);
            props.onNext();
          })
          .catch((err) => {
            if (err.response) {
              const res = err.response;
              setErrorMessage(res.body.message);
            } else {
              ctx.error(err);
            }
          })
          .finally(() => {
            isSubmitting = false;
          });
      }}
    >
      <div class={styles.formGroup}>
        <label>Name</label>
        <TextInput $$value={toSettableState($name, setName)} name="name" />
      </div>
      <div class={styles.formGroup}>
        <label>Color</label>
        <ColorInput $value={$color} onChange={setColor} name="color" />
      </div>

      <div class={styles.formControls}>
        <Button type="submit">Submit</Button>
      </div>
    </form>
  );
}

function Step3_2FA(props: StepProps, ctx: ViewContext) {
  const [$uri, setURI] = createState<string>();
  const [$qrCode, setQRCode] = createState<string>();
  const [$burnCode, setBurnCode] = createState<string>();
  const [$errorMessage, setErrorMessage] = createState<string>();

  ctx.onMount(() => {
    const token = props.$token.get();
    Dolla.http.post<{ uri: string; qr: string }>("/api/join/3a", { query: { token } }).then((res) => {
      setURI(res.body.uri);
      setQRCode(res.body.qr);
    });
  });

  let isSubmitting = false;

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();

        if (isSubmitting) return;
        isSubmitting = true;

        const token = props.$token.get();

        const authCode: string = (e.currentTarget as HTMLFormElement).authCode.value;
        ctx.log(authCode);

        setErrorMessage(undefined);

        Dolla.http
          .post<{ burnCode: string }>("/api/join/3b", { body: { authCode }, query: { token } })
          .then((res) => {
            setBurnCode(res.body.burnCode);
          })
          .catch((err) => {
            if (err.response != null) {
              const res = err.response;
              setErrorMessage(res.body.message);
            } else {
              ctx.error(err);
            }
          })
          .finally(() => {
            isSubmitting = false;
          });
      }}
    >
      {cond(
        $burnCode,
        <div>
          <p>{$burnCode}</p>
          <Button
            onClick={() => {
              props.onNext();
            }}
          >
            I Saved It
          </Button>
        </div>,
        <div>
          {cond($qrCode, <img style={{ width: "250px" }} src={$qrCode as State<string>} alt="" />)}

          <label>Two Factor Code</label>
          <input type="text" name="authCode" />
          {cond($errorMessage, <p style={{ color: "red" }}>{$errorMessage}</p>)}

          <Button type="submit">Submit</Button>
        </div>,
      )}
    </form>
  );
}

function Done(props: {}, ctx: ViewContext) {
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        Dolla.router.go("/");
      }}
    >
      <div class={styles.formGroup}>
        <p>You're all set.</p>
      </div>

      <div class={styles.formButtons}>
        <Button type="submit">Get Quacking</Button>
      </div>
    </form>
  );
}
