import { TaskEditDialog } from "@dialogs/TaskEditDialog/TaskEditDialog";
import { adjustAccentColor } from "@helpers/makeTheme";
import Dolla, { createState, derive } from "@manyducks.co/dolla";
import chroma from "chroma-js";
import Quill from "quill";
import styles from "./QuackLinkBlot.module.css";

import { auth, chat, dialog, nav, notes, projects, tasks, theme } from "@stores";

const Embed = Quill.import("blots/embed");

export class QuackLinkBlot extends Embed {
  static blotName = "quack-permalink";
  static tagName = "A";
  static className = styles.linkBlot;

  debug = Dolla.createLogger("QuackLinkBlot");
  watcher = Dolla.createWatcher();

  stopCallbacks = [];
  isAttached = false;

  static create(data) {
    const node = super.create(null);
    const type = data.linkingId.split("_")[0];

    const icon = document.createElement("img");
    icon.classList.add(styles.linkBlotIcon);

    if (type === "doc" || type === "note") {
      icon.src = "/icons/notes.8.trans.png";
    } else if (type === "task") {
      icon.src = "/icons/tasks.8.trans.png";
    }

    const title = document.createElement("span");
    title.classList.add(styles.linkBlotTitle);
    title.textContent = data.title ?? "...";

    node.appendChild(icon);

    // if (type === "task") {
    //   const stage = document.createElement("span");
    //   stage.className = styles.linkBlotTaskStage;
    //   stage.textContent = "...";
    //   node.appendChild(stage);
    // }

    node.appendChild(title);

    node.setAttribute("href", buildPermalink(data.linkingId));
    node.dataset.linkingId = data.linkingId;
    node.dataset.title = data.title ?? "...";

    return node;
  }

  static value(domNode) {
    return domNode.dataset;
  }

  attach() {
    super.attach();
    this.isAttached = true;

    const node = this.domNode;

    const stageEl = node.querySelector("." + styles.linkBlotTaskStage);
    const titleEl = node.querySelector("." + styles.linkBlotTitle);

    const [$linkData, setLinkData] = createState();

    // Make HTTP call for permalink info and update nodes.
    Dolla.http.get(`/api/permalink/${node.dataset.linkingId}`).then((res) => {
      if (res.status === 200) {
        const link = res.body.data;
        setLinkData(link);

        titleEl.textContent = link.title;

        // Add event handler once link has been loaded. Now we know what we're working with.
        node.onclick = async (e) => {
          e.preventDefault();
          e.stopPropagation();

          if (link.type === "note") {
            Dolla.router.go(`/projects/${link.projectId}/notes/${link.id}`);
            if (window.innerWidth < 800) {
              nav.closeChat(); // Make sure user can see it after tapping on mobile
            }
          } else if (link.type === "task") {
            const task = await tasks.ensureTaskIsLoaded(link.id);
            const project = projects.$cache.get().get(link.projectId);
            dialog.show(TaskEditDialog, {
              task: task,
              // onToListClicked: () => {
              //   router.navigate(`/projects/${link.projectId}/tasks?task=${link.id}`);
              // },
            });
          }
        };
      } else {
        this.debug.warn(res);
      }
    });

    const $themeVars = theme.getTheme$(
      derive([$linkData, projects.$cache, auth.$me], (link, projects, me) => {
        if (!link) return me?.color ?? "#888";
        const project = projects.get(link.projectId);
        return project?.color ?? me?.color ?? "#888";
      }),
    );

    this.watcher.watch([$themeVars], (vars) => {
      for (const key of Object.keys(vars)) {
        node.style.setProperty(key, vars[key]);
      }
    });

    this.watcher.watch(
      [$linkData, tasks.$cache, notes.$cache, Dolla.i18n.$locale],
      (link, tasks, notes, _) => {
        if (!link) return;
        if (link.type === "task") {
          const task = tasks.get(link.id);
          if (titleEl) {
            titleEl.textContent = task?.title ?? link.title;
          }
          if (stageEl) {
            if (task?.completedAt != null) {
              stageEl.style.display = "unset";
              stageEl.textContent = translate("tasks.card.stages.done").get();
            } else {
              stageEl.style.display = "none";
            }
          }
        } else if (link.type === "note") {
          const note = notes.get(link.id);
          if (titleEl) {
            titleEl.textContent = note?.title ?? link.title;
          }
        }
      },
    );

    // This is a custom event that the parent view emits on unmount so we know when to clean up.
    this.scroll.emitter.on("parent-view-unmounted", () => {
      this.cleanup();
    });
  }

  cleanup() {
    this.watcher.stopAll();
  }

  detach() {
    this.cleanup();

    super.detach();
    this.isAttached = false;
  }
}

function buildPermalink(linkingId, location = window.location) {
  let link = location.protocol + "//" + location.hostname;

  if (location.hostname === "localhost") {
    link += ":" + location.port;
  }

  link += "/p/" + linkingId;

  return link;
}
