export default {
  "layout": "ServerStats_layout_49328",
  "content": "ServerStats_content_49328",
  "sectionContent": "ServerStats_sectionContent_49328",
  "sectionList": "ServerStats_sectionList_49328",
  "sectionHeader": "ServerStats_sectionHeader_49328",
  "sectionRow": "ServerStats_sectionRow_49328",
  "headerTitle": "ServerStats_headerTitle_49328",
  "sectionLabel": "ServerStats_sectionLabel_49328",
  "sectionValue": "ServerStats_sectionValue_49328",
  "meterList": "ServerStats_meterList_49328",
  "meterMeta": "ServerStats_meterMeta_49328",
  "meterTitle": "ServerStats_meterTitle_49328",
  "meterValue": "ServerStats_meterValue_49328",
  "meter": "ServerStats_meter_49328",
  "fill": "ServerStats_fill_49328",
  "cpuMeters": "ServerStats_cpuMeters_49328",
  "cpuMeterBox": "ServerStats_cpuMeterBox_49328",
  "oneQuarter": "ServerStats_oneQuarter_49328",
  "threeQuarters": "ServerStats_threeQuarters_49328",
  "cpuMeterGroup": "ServerStats_cpuMeterGroup_49328",
  "cpuMeterValue": "ServerStats_cpuMeterValue_49328",
  "cpuMeterLabel": "ServerStats_cpuMeterLabel_49328"
};import "ni:sha-256;e2fr1xSsCaMmPC_0HXxQloVXxigMMgNGmUuiRFI1YL8";