import { extractTitleFromDelta } from "@helpers/extractTitleFromDelta";
import { sortTasks } from "@helpers/sortTasks";
import Dolla, { derive, createState, type State } from "@manyducks.co/dolla";
import { differenceInMinutes } from "date-fns";
import { produce } from "immer";
import type { Op } from "quill-delta";
import type { Task, TaskAssignment, TaskComplete, TaskStatus } from "schemas";
import * as Y from "yjs";

import { auth } from ".";

export type UpdateTaskMetaData = {
  title: string;
  priority: number | null;
  dueDate: string | null;
  status: string | null;
  assignedUserId: number | null;
  tags: string[];
  attachments: string[];
  completed?: boolean;
};

export interface CreateTaskOptions {
  projectId: number;
  assignedUserId?: number;
  delta?: Op[];
  tags?: string[];
  dueDate?: string;
}

export interface FetchHistoryOptions {
  completedAtStart: Date;
  completedAtEnd: Date;
  assignedUserId?: number;
  projectId?: number;
}

export interface TaskActivity {
  type: "task";
  timestamp: string;
  data: Task;
}

const debug = Dolla.createLogger("📦 stores/tasks");

const [$cache, setCache] = createState(new Map<number, Task>());
export const $activity = derive([$cache], (tasks) => {
  return [...tasks.values()]
    .sort((a, b) => {
      if (a.updatedAt > b.updatedAt) {
        return -1;
      } else if (a.updatedAt < b.updatedAt) {
        return +1;
      } else {
        return 0;
      }
    })
    .slice(0, 10)
    .map((task) => ({
      type: "task",
      timestamp: task.updatedAt,
      data: task,
    }));
}) as State<TaskActivity[]>;

export const [$highlightTaskId, setHighlightedTaskId] = createState<number | null>(null);

export { $cache };

export const $myTasks = derive([$cache, auth.$me], (tasks, me) =>
  me == null ? [] : [...tasks.values()].filter((t) => t.assignedUserId === me.id).sort(sortTasks),
);

// Keys are project IDs
const indexCacheMap = new Map<number | null, { lastFetchedAt: Date; resultIds: number[] }>();

/**
 * Fetches the task index for a given project.
 */
export async function fetchIndexFor(projectId: number | null, fresh = false) {
  const now = new Date();
  const mapped = indexCacheMap.get(projectId);

  if (!fresh && mapped && differenceInMinutes(now, mapped.lastFetchedAt) < 5) {
    const cache = $cache.get();
    const results: Task[] = [];
    for (const id of mapped.resultIds) {
      results.push(cache.get(id)!);
    }
    return results;
  }

  return Promise.all([
    Dolla.http.get<Task[]>(projectId === null ? `/api/tasks/activity` : `/api/tasks?projectId=${projectId}`),
  ]).then(([tasksRes]) => {
    setCache(
      produce((tasks) => {
        for (const task of tasksRes.body) {
          tasks.set(task.id, task);
        }
      }),
    );

    indexCacheMap.set(projectId, {
      lastFetchedAt: now,
      resultIds: tasksRes.body.map((task) => task.id),
    });

    return tasksRes.body;
  });
}

/**
 * Fetch To Do list items for the current user.
 */
export async function fetchToDos() {
  const res = await Dolla.http.get<Task[]>("/api/tasks/todo");

  setCache(
    produce((tasks) => {
      for (const task of res.body) {
        tasks.set(task.id, task);
      }
    }),
  );
}

/**
 * Fetch tasks for project overview.
 */
export async function fetchOverview(projectId: number) {
  const res = await Dolla.http.get<Task[]>(`/api/tasks/overview/${projectId}`);

  setCache(
    produce((tasks) => {
      for (const task of res.body) {
        tasks.set(task.id, task);
      }
    }),
  );
}

/**
 * Fetch recently updated tasks for project overview.
 */
export async function fetchActivity(projectId: number) {
  const res = await Dolla.http.get<Task[]>(`/api/tasks/activity`, {
    query: {
      projectId,
    },
  });

  setCache(
    produce((tasks) => {
      for (const task of res.body) {
        tasks.set(task.id, task);
      }
    }),
  );
}

export async function fetchHistory(options: FetchHistoryOptions) {
  if (!options.assignedUserId && !options.projectId) {
    throw new Error(`Must pass either assignedUserId or projectId`);
  }

  if (options.completedAtStart > new Date()) {
    return;
  }

  const res = await Dolla.http.get<Task[]>("/api/tasks/history", { query: options });

  setCache(
    produce((tasks) => {
      for (const t of res.body) {
        const found = tasks.get(t.id);
        if (found) {
          Object.assign(found, t);
        } else {
          tasks.set(t.id, t);
        }
      }
    }),
  );
}

interface FetchCalendarOptions {
  projectId?: number;
  assignedUserId?: number;
  withAdjacent?: boolean;
}
export async function fetchCalendar(year: number, month: number, options: FetchCalendarOptions = {}) {
  const res = await Dolla.http.get<Task[]>(`/api/tasks/calendar/${year}/${month}`, { query: options });

  setCache(
    produce((tasks) => {
      for (const t of res.body) {
        const found = tasks.get(t.id);
        if (found) {
          Object.assign(found, t);
        } else {
          tasks.set(t.id, t);
        }
      }
    }),
  );
}

export async function ensureTaskIsLoaded(taskId: number): Promise<Task | null> {
  const found = $cache.get().get(taskId) ?? null;
  if (!found) {
    try {
      const res = await Dolla.http.get<Task>(`/api/tasks/${taskId}`);
      setCache(
        produce((tasks) => {
          tasks.set(res.body.id, res.body);
        }),
      );
      return res.body;
    } catch (err) {
      debug.error(err);
    }
  }
  return found;
}

export async function createTask(options: CreateTaskOptions) {
  return Dolla.http.post<Task>(`/api/tasks`, { body: options }).then(async (res) => {
    setCache(
      produce((tasks) => {
        tasks.set(res.body.id, res.body);
      }),
    );

    return res.body;
  });
}

/**
 * Update locally cached tasks. Actual changes to the task have already been
 * sent to the server over sockets by the time this has been called.
 */
export async function updateTask(taskId: number, ytext: Y.Text) {
  setCache(
    produce((tasks) => {
      const task = tasks.get(taskId);
      if (task) {
        task.title = extractTitleFromDelta(ytext.toDelta());
      }
    }),
  );

  debug.log("updated local cache for task", taskId);
}

export async function updateTaskMeta(taskId: number, fields: UpdateTaskMetaData) {
  setCache(
    produce((tasks) => {
      const task = tasks.get(taskId);
      if (task) {
        Object.assign(task, {
          ...fields,
          attachments: task.attachments.filter((f) => fields.attachments.includes(f.uuid)),
        });
      }
    }),
  );

  return Dolla.http
    .put(`/api/tasks/${taskId}`, {
      body: fields,
    })
    .then(async (res) => {
      debug.log(res);
    });
}

export async function deleteTask(taskId: number) {
  setCache(
    produce((tasks) => {
      tasks.delete(taskId);
    }),
  );

  return Dolla.http.delete(`/api/tasks/${taskId}`).then(async (res) => {
    debug.log(res);
  });
}

export async function assignTo(assignedUserId: number | null, taskId: number) {
  setCache(
    produce((tasks) => {
      const task = tasks.get(taskId);
      if (task) {
        if (assignedUserId == null) {
          task.assignedAt = null;
          task.assignedUserId = null;
        } else {
          task.assignedUserId = assignedUserId;
          task.assignedAt = new Date().toISOString();
        }
      }
    }),
  );

  if (assignedUserId == null) {
    return Dolla.http.delete(`/api/tasks/${taskId}/assignee`).then((res) => {
      debug.log(res);
    });
  } else {
    return Dolla.http.put(`/api/tasks/${taskId}/assignee`, { body: { assignedUserId } }).then((res) => {
      debug.log(res);
    });
  }
}

export async function setCompleted(taskId: number, completed: boolean) {
  setCache(
    produce((tasks) => {
      const found = tasks.get(taskId);

      if (found) {
        if (completed) {
          found.completedAt = new Date().toISOString();
        } else {
          found.completedAt = null;
        }
      }
    }),
  );

  if (completed) {
    return Dolla.http.put(`/api/tasks/${taskId}/complete`).then((res) => {
      debug.log(res);
    });
  } else {
    return Dolla.http.delete(`/api/tasks/${taskId}/complete`).then((res) => {
      debug.log(res);
    });
  }
}

export async function setPriority(taskId: number, priority: null | number) {
  setCache(
    produce((tasks) => {
      const found = tasks.get(taskId);
      if (found) {
        found.priority = priority;
      }
    }),
  );

  return Dolla.http.put(`/api/tasks/${taskId}/priority`, {
    body: {
      priority,
    },
  });
}

export async function updateStatus(taskId: number, status: string | null) {
  setCache(
    produce((tasks) => {
      const found = tasks.get(taskId);

      if (found) {
        found.status = status;
      }
    }),
  );

  if (status != null && status.trim() !== "") {
    return Dolla.http.put(`/api/tasks/${taskId}/status`, { body: { status } }).then((res) => {
      debug.log(res);
    });
  } else {
    return Dolla.http.delete(`/api/tasks/${taskId}/status`).then((res) => {
      debug.log(res);
    });
  }
}

export async function setTags(taskId: number, tags: string[]) {
  setCache(
    produce((tasks) => {
      const found = tasks.get(taskId);
      if (found) {
        found.tags = tags;
      }
    }),
  );

  await Dolla.http.put(`/api/tasks/${taskId}/tags`, {
    body: {
      tags,
    },
  });
}

/**
 * @param taskId - ID of the task to set the due date for.
 * @param dueDate - YYYY-MM-DD string representing the due date, or null to unset.
 */
export async function setDueDate(taskId: number, dueDate: string | null) {
  await Dolla.http.put(`/api/tasks/${taskId}/due`, {
    body: { dueDate },
  });
}

/*=============================*\
||       Socket Callbacks      ||
\*=============================*/

export function taskUpdateReceived(task: Task) {
  setCache(
    produce((cache) => {
      const current = cache.get(task.id);

      if (current) {
        Object.assign(current, task);
      } else {
        cache.set(task.id, task);
      }
    }),
  );
}

export function taskDeleteReceived(id: number) {
  const task = $cache.get().get(id);

  if (task) {
    setCache(
      produce((cache) => {
        cache.delete(id);
      }),
    );
  }
}

export function taskAssignReceived(taskAssignment: TaskAssignment) {
  setCache(
    produce((cache) => {
      const current = cache.get(taskAssignment.id);

      if (current) {
        Object.assign(current, taskAssignment);
      }
    }),
  );
}

export function taskCompleteReceived(taskComplete: TaskComplete) {
  setCache(
    produce((cache) => {
      const current = cache.get(taskComplete.id);

      if (current) {
        Object.assign(current, taskComplete);
      }
    }),
  );
}

export function taskStatusReceived(taskStatus: TaskStatus) {
  setCache(
    produce((cache) => {
      const current = cache.get(taskStatus.id);

      if (current) {
        Object.assign(current, taskStatus);
      }
    }),
  );
}

export function updateCache(cache: Array<number>) {
  if (cache.length > 0) {
    setCache(
      produce((tasks) => {
        for (const id of cache) {
          tasks.delete(id);
        }
      }),
    );
  }
}
