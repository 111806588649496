import {
  cond,
  type MaybeState,
  createRef,
  type SettableState,
  type State,
  type ViewContext,
  type Renderable,
  repeat,
  derive,
} from "@manyducks.co/dolla";
import { Icon } from "MaterialSymbols";
import { HoverMenu } from "@views/HoverMenu";
import { HoverMenuHorizontalAlignment, HoverMenuVerticalAlignment } from "@views/HoverMenu/HoverMenu";
import { MenuList, MenuListItem } from "@views/MenuList";
import styles from "./MoreMenu.module.css";

interface MoreMenuOption {
  label: MaybeState<string>;
  icon?: MaybeState<Renderable>;
  callback: () => void;
  variant?: "normal" | "destructive";
  hidden?: MaybeState<boolean>;
  disabled?: MaybeState<boolean>;
}

interface MoreMenuAttrs {
  $$open: SettableState<boolean>;
  options: MoreMenuOption[];
  icon?: MaybeState<Renderable>;
  title?: MaybeState<string>;
  size?: "normal" | "small";
  variant?: "normal" | "card";
  color?: string | State<string> | State<string | undefined>;
  preferVerticalAlignment?: MaybeState<HoverMenuVerticalAlignment>;
  preferHorizontalAlignment?: MaybeState<HoverMenuHorizontalAlignment>;
}

/**
 * Displays a "..." button that opens a menu with more options.
 */
export function MoreMenu({ $$open, options, size, color, ...props }: MoreMenuAttrs, ctx: ViewContext) {
  function onKeyDown(e: KeyboardEvent) {
    if (e.key === "Escape") {
      $$open.set(false);
    }
  }

  const anchorRef = createRef<HTMLElement>();

  ctx.watch([$$open], (open) => {
    if (open) {
      window.addEventListener("keydown", onKeyDown);
    } else {
      window.removeEventListener("keydown", onKeyDown);
    }
  });

  return (
    <div
      class={[
        styles.container,
        {
          [styles.small]: size === "small",
          [styles.card]: props.variant === "card",
        },
      ]}
    >
      <button
        type="button"
        class={{
          [styles.button]: true,
          [styles.open]: $$open,
        }}
        title={props.title}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();

          const open = $$open.get();
          if (!open) {
            const button = e.currentTarget as HTMLButtonElement;

            anchorRef.node = button;
            $$open.set(true);
          } else {
            $$open.set(false);
          }
        }}
      >
        {props.icon ?? <Icon name="More Horiz" size={24} />}
      </button>

      <HoverMenu
        $$open={$$open}
        anchorRef={anchorRef}
        color={color}
        preferHorizontalAlignment={props.preferHorizontalAlignment}
        preferVerticalAlignment={props.preferVerticalAlignment}
        distanceFromAnchor={size === "small" ? 8 : 12}
      >
        <MenuList>
          {repeat(
            options,
            (_, i) => i,
            ($option) => {
              return cond(
                derive([$option], (o) => o.hidden),
                null,
                <MenuListItem
                  icon={derive([$option], (o) => o.icon)}
                  label={derive([$option], (o) => o.label)}
                  variant={derive([$option], (o) => o.variant)}
                  disabled={derive([$option], (o) => o.disabled ?? false)}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    const o = $option.get();
                    o.callback();
                    $$open.set(false);
                  }}
                />,
              );
            },
          )}
        </MenuList>
      </HoverMenu>
    </div>
  );
}
