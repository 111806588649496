import Dolla, { cond, createState, derive, type ViewContext } from "@manyducks.co/dolla";
import { Quote } from "schemas";
import styles from "./QuoteWidget.module.css";

interface QuoteWidgetProps {}

export function QuoteWidget(props: QuoteWidgetProps, ctx: ViewContext) {
  // TODO: Get quote from API

  const [$quote, setQuote] = createState<Quote>({
    id: "abcd",
    text: "...",
    author: "...",
  });

  async function getQuote() {
    const res = await Dolla.http.get<Quote>(`/api/quote/random`);
    setQuote(res.body);
  }

  ctx.onMount(() => {
    getQuote();
  });

  // const $source = derive([$quote], (q) => q.source ?? "Example Source Here");
  const $source = derive([$quote], (q) => q.source);

  return (
    <div class={styles.layout}>
      <span class={styles.body}>{derive([$quote], (q) => q.text)}</span>
      <span class={styles.author}>{derive([$quote], (q) => q.author)}</span>
      {cond($source, <span class={styles.source}>{$source}</span>)}
    </div>
  );
}
