import { derive, type State, type MaybeState, type ViewContext, type Renderable } from "@manyducks.co/dolla";
import styles from "./CardDetailStack.module.css";

import { breakpoint } from "@stores";

interface CardDetailStackProps {
  $showDetail: State<boolean>;
  listContent: Renderable;
  detailContent: Renderable;

  /** Minimum container width to display contents in expanded mode. */
  expandedThreshold?: MaybeState<number>;

  expandedListWidth?: MaybeState<number>;
  expandedDetailWidth?: MaybeState<number>;
}

/**
 * Implements a navigation stack for a list view and a detail view with an animated transition.
 */
export function CardDetailStack(props: CardDetailStackProps, ctx: ViewContext) {
  const $expandedBreakpoint = derive([breakpoint.$width, props.expandedThreshold], (width, threshold) => {
    return threshold != null && width > threshold;
  });

  const $showDetail = derive([props.$showDetail, $expandedBreakpoint], (show, expanded) => show || expanded);

  return (
    <div
      class={[styles.layout, { [styles.expanded]: $expandedBreakpoint }]}
      style={derive([props.expandedListWidth, props.expandedDetailWidth], (listWidth, detailWidth) => {
        const styles: Record<string, string> = {};
        if (detailWidth != null) {
          styles["--expanded-detail-width"] = `${detailWidth}px`;
          styles["--expanded-list-width"] = `calc(100% - ${detailWidth}px)`;
        } else {
          styles["--expanded-detail-width"] = `calc(100% - ${listWidth ?? 400}px)`;
          styles["--expanded-list-width"] = `${listWidth ?? 400}px`;
        }
        return styles;
      })}
    >
      <div class={[styles.frame, styles.listFrame, { [styles.hidden]: props.$showDetail }]}>
        {props.listContent}
      </div>
      <div class={[styles.shade, { [styles.show]: props.$showDetail }]} />
      <div class={[styles.frame, styles.detailFrame, { [styles.show]: props.$showDetail }]}>
        {props.detailContent}
      </div>
    </div>
  );
}
