export default {
  "chat": "Chat_chat_c9779",
  "layout": "Chat_layout_c9779",
  "editor": "Chat_editor_c9779",
  "pushDisabledBanner": "Chat_pushDisabledBanner_c9779",
  "pushDisabledBannerControls": "Chat_pushDisabledBannerControls_c9779",
  "endOfMessages": "Chat_endOfMessages_c9779",
  "chatListSpacer": "Chat_chatListSpacer_c9779",
  "dividerLine": "Chat_dividerLine_c9779",
  "dividerLabel": "Chat_dividerLabel_c9779",
  "chatHeader": "Chat_chatHeader_c9779",
  "chatTitle": "Chat_chatTitle_c9779",
  "project": "Chat_project_c9779",
  "chatHeaderButtons": "Chat_chatHeaderButtons_c9779",
  "listContent": "Chat_listContent_c9779",
  "chatMessageList": "Chat_chatMessageList_c9779",
  "chatList": "Chat_chatList_c9779",
  "chatListItem": "Chat_chatListItem_c9779",
  "chatListItemIcon": "Chat_chatListItemIcon_c9779",
  "active": "Chat_active_c9779",
  "chatListItemLabel": "Chat_chatListItemLabel_c9779"
};import "ni:sha-256;2qmSWaQV1al2TwONHyI-QBN4_vinh2R5XSw1ue1EZNU";