import { pass, when } from "@helpers/patterns";
import { cond, type MaybeState, type Ref, toState, type ViewContext } from "@manyducks.co/dolla";
import { Icon } from "MaterialSymbols";
import styles from "./Button.module.css";

type ButtonVariant = "normal" | "destructive";
export type ButtonType = "button" | "submit" | "reset" | undefined;

export enum ButtonStyle {
  /**
   * The button is filled with the button color.
   */
  Solid,

  /**
   * The button is outlined with a transparent background.
   */
  Hollow,
}

export enum ButtonColor {
  /**
   * The button adopts the ambient accent color.
   */
  Accent = "accent",

  /**
   * The button uses a neutral-gray tone that goes with any color.
   */
  Neutral = "neutral",

  /**
   * The button uses a red color that indicates something permanent or dangerous will happen.
   */
  Danger = "danger",
}

export interface ButtonProps {
  /**
   * Button type. Note that type is 'button' by default and will not submit forms when clicked unless this is set to 'submit'.
   */
  type?: ButtonType;
  ref?: Ref<HTMLButtonElement>;
  onClick?: (event: MouseEvent) => void;
  disabled?: MaybeState<boolean>;
  variant?: ButtonVariant;
  tooltip?: MaybeState<string>;

  /**
   * Name of a Material Symbol to use as an icon.
   */
  icon?: string;

  color?: ButtonColor;
  style?: ButtonStyle;
}

/**
 * All purpose button. Does stuff when you click it.
 */
export function Button(props: ButtonProps, ctx: ViewContext) {
  const { type, variant, onClick } = props;

  const $disabled = toState(props.disabled ?? false);
  const $tooltip = toState(props.tooltip);

  const colorClasses = when(
    [
      [ButtonColor.Neutral, { [styles.colorNeutral]: true }],
      [ButtonColor.Danger, { [styles.colorDanger]: true }],
      [pass, { [styles.colorAccent]: true }],
    ],
    props.color,
  );
  const styleClasses = when(
    [
      [ButtonStyle.Hollow, { [styles.styleHollow]: true }],
      [pass, { [styles.styleSolid]: true }],
    ],
    props.style,
  );

  return (
    <button
      onClick={(e) => {
        if (!$disabled.get() && onClick) {
          onClick(e);
        }
      }}
      class={[styles.button, styles[variant ?? "normal"], colorClasses, styleClasses]}
      type={type}
      ref={props.ref}
      disabled={$disabled}
      title={$tooltip}
    >
      <div class={styles.content}>
        {cond(
          props.icon,
          <div class={styles.icon}>
            <Icon name={props.icon!} size={24} />
          </div>,
        )}
        <div class={styles.main}>{ctx.outlet()}</div>
      </div>
    </button>
  );
}
