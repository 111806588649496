import {
  createSettableState,
  createState,
  derive,
  toState,
  t,
  type MaybeState,
  type ViewContext,
} from "@manyducks.co/dolla";
import { Button } from "@views/Button";
import { ColorInput } from "@views/ColorInput";
import { Dialog, DialogContent, DialogFooter, DialogHeader } from "@views/Dialog";
import { TextInput } from "@views/TextInput";
import { Project } from "schemas";
import styles from "./EditProjectDialog.module.css";

import { DialogProps } from "@stores/dialog";

interface EditProjectInfo {
  color: string;
  name: string;
}

interface EditProjectDialogProps extends DialogProps {
  project: MaybeState<Project>;

  /**
   * Called when confirmation is successful
   */
  onConfirm: (info: EditProjectInfo) => Promise<void>;
}

export function EditProjectDialog(props: EditProjectDialogProps, ctx: ViewContext) {
  const $project = toState(props.project);

  const [$color, setColor] = createState($project.get().color);
  const $$name = createSettableState($project.get().name);

  const [$isWaiting, setIsWaiting] = createState(false);

  return (
    <Dialog
      accentColor={$color}
      onsubmit={async () => {
        setIsWaiting(true);
        props
          .onConfirm({
            color: $color.get(),
            name: $$name.get(),
          })
          .then(() => {
            props.dialog.close();
          })
          .finally(() => {
            setIsWaiting(false);
          });
      }}
      transitionIn={props.dialog.transitionIn}
      transitionOut={props.dialog.transitionOut}
    >
      <DialogHeader dialog={props.dialog} $title={t("workspace.nav.projects.editProjectDialog.title")} />

      <DialogContent>
        <div class={styles.content}>
          <div class={styles.formGroup}>
            <label for="project-name">{t("workspace.nav.projects.createProjectDialog.nameLabel")}</label>
            <TextInput
              id="project-name"
              $$value={$$name}
              // ref={$$textInput}
              placeholder={t("workspace.nav.projects.createProjectDialog.nameInputPlaceholder")}
            />
          </div>

          <div class={styles.formGroup}>
            <label for="project-color">{t("workspace.nav.projects.createProjectDialog.colorLabel")}</label>
            <ColorInput id="project-color" $value={$color} onChange={setColor} />
          </div>
        </div>
      </DialogContent>

      <DialogFooter>
        <Button type="submit" disabled={derive([$$name], (v) => v.trim().length === 0)}>
          {t("workspace.nav.projects.editProjectDialog.submitButtonText")}
        </Button>
      </DialogFooter>
    </Dialog>
  );
}
