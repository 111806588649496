import { Icon } from "MaterialSymbols";
import Dolla, {
  cond,
  createRef,
  createState,
  derive,
  repeat,
  toSettableState,
  type SettableState,
  type State,
  type ViewContext,
} from "@manyducks.co/dolla";
import { HoverMenu } from "@views/HoverMenu";
import { IconButton } from "@views/IconButton";
import {
  addDays,
  addWeeks,
  endOfWeek,
  getMonth,
  isAfter,
  isBefore,
  isSameDay,
  isSameMonth,
  isWeekend,
  startOfWeek,
  subWeeks,
} from "date-fns";
import styles from "./CalendarInput.module.css";

import { clock } from "@stores";

interface DayMeta {
  date: Date;
  label?: string;
}

interface CalendarInputProps {
  /**
   * Date value in 'YYYY-MM-DD' format.
   */
  $value: State<string>;

  onChange: (newValue: string) => void;
}

export function CalendarInput(props: CalendarInputProps, ctx: ViewContext) {
  const inputId = `input_${ctx.uid}`;

  const anchor = createRef<HTMLElement>();
  const [$open, setOpen] = createState(false);

  return (
    <div class={styles.container}>
      <button
        ref={anchor}
        class={styles.inputButton}
        onClick={(e) => {
          setOpen(true);
        }}
      >
        DATE
      </button>

      <HoverMenu
        $$open={toSettableState($open, setOpen)}
        anchorRef={anchor}
        preferHorizontalAlignment={"center"}
        preferVerticalAlignment={"below"}
      >
        {/* <CalendarBlockView /> */}
      </HoverMenu>
    </div>
  );
}

interface CalendarBlockViewProps {
  $$date: SettableState<Date | undefined>;
  $max?: State<Date> | State<Date | undefined>;
  $min?: State<Date> | State<Date | undefined>;
  $dayMeta?: State<DayMeta[]>;
}

/**
 * Displays a calendar one month at a time with weeks stacked vertically.
 */
export function CalendarBlockView(props: CalendarBlockViewProps, ctx: ViewContext) {
  const { $$date } = props;
  const [$startDate, setStartDate] = createState(startOfWeek($$date.get() ?? new Date()));

  const containerRef = createRef<HTMLDivElement>();
  const [$containerWidth, setContainerWidth] = createState(0);

  ctx.onMount(() => {
    const container = containerRef.get();

    const observer = new ResizeObserver((entries) => {
      for (const entry of entries) {
        setContainerWidth(entry.contentBoxSize[0].inlineSize);
      }
    });
    observer.observe(container!);

    ctx.onUnmount(() => {
      observer.disconnect();
    });
  });

  return (
    <div
      class={styles.calendarBlockView}
      style={{
        "--day-width": derive([$containerWidth], (width) => {
          return width / 7 + "px";
        }),
      }}
    >
      <div class={styles.calendarBlockControls}>
        <IconButton
          onClick={() => {
            setStartDate((current) => subWeeks(current, 4));
          }}
        >
          <Icon name="Chevron Left" />
        </IconButton>

        <span>
          {derive([$$date], (date) => {
            if (date == null) {
              return "---";
            }

            return Dolla.i18n.dateTime(date, {
              year: "numeric",
              month: "short",
              day: "numeric",
              weekday: "short",
            });
          })}
        </span>

        <IconButton
          onClick={() => {
            setStartDate((current) => addWeeks(current, 4));
          }}
        >
          <Icon name="Chevron Right" />
        </IconButton>
      </div>

      <div class={styles.calendarBlockContent} ref={containerRef}>
        <div class={styles.calendarBlockWeekView}>
          <WeekView
            $weekOf={$startDate}
            $$selectedDate={$$date}
            $max={props.$max}
            $min={props.$min}
            $dayMeta={props.$dayMeta}
          />
        </div>
        <div class={styles.calendarBlockWeekView}>
          <WeekView
            $weekOf={derive([$startDate], (date) => addWeeks(date, 1))}
            $$selectedDate={$$date}
            $max={props.$max}
            $min={props.$min}
            $dayMeta={props.$dayMeta}
          />
        </div>
        <div class={styles.calendarBlockWeekView}>
          <WeekView
            $weekOf={derive([$startDate], (date) => addWeeks(date, 2))}
            $$selectedDate={$$date}
            $max={props.$max}
            $min={props.$min}
            $dayMeta={props.$dayMeta}
          />
        </div>
        <div class={styles.calendarBlockWeekView}>
          <WeekView
            $weekOf={derive([$startDate], (date) => addWeeks(date, 3))}
            $$selectedDate={$$date}
            $max={props.$max}
            $min={props.$min}
            $dayMeta={props.$dayMeta}
          />
        </div>
      </div>
    </div>
  );
}

interface CalendarStripViewProps {
  $$date: SettableState<Date | undefined>;
  $max?: State<Date> | State<Date | undefined>;
  $min?: State<Date> | State<Date | undefined>;
  $dayMeta?: State<DayMeta[]>;
  onRangeUpdate?: (start: Date, end: Date) => void;
}

/**
 * Displays a calendar one week at a time.
 */
export function CalendarStripView(props: CalendarStripViewProps, ctx: ViewContext) {
  const { $$date } = props;

  const [$weekOf, setWeekOf] = createState(startOfWeek($$date.get() ?? new Date()));

  function onRangeUpdate() {
    if (props.onRangeUpdate) {
      const weekOf = $weekOf.get();
      const end = endOfWeek(weekOf);
      props.onRangeUpdate(weekOf, end);
    }
  }

  ctx.onMount(() => {
    onRangeUpdate();
  });

  return (
    <div class={styles.calendarStripView}>
      <div class={styles.calendarStripContent}>
        <div class={styles.calendarStripWeekView}>
          <WeekView
            $weekOf={$weekOf}
            $$selectedDate={$$date}
            $max={props.$max}
            $min={props.$min}
            $dayMeta={props.$dayMeta}
          />
        </div>

        <div class={styles.calendarStripControls}>
          <IconButton
            onClick={() => {
              setWeekOf((current) => subWeeks(current, 1));
              onRangeUpdate();
            }}
          >
            <Icon name="Chevron Left" />
          </IconButton>

          <span>
            {Dolla.i18n.dateTime($$date, {
              year: "numeric",
              month: "short",
              day: "numeric",
              weekday: "short",
            })}
          </span>

          <IconButton
            onClick={() => {
              setWeekOf((current) => addWeeks(current, 1));
              onRangeUpdate();
            }}
          >
            <Icon name="Chevron Right" />
          </IconButton>
        </div>
      </div>
    </div>
  );
}

interface WeekViewProps {
  $weekOf: State<Date>;
  $$selectedDate: SettableState<Date | undefined>;
  $max?: State<Date> | State<Date | undefined>;
  $min?: State<Date> | State<Date | undefined>;
  $dayMeta?: State<DayMeta[]>;
}

function WeekView(props: WeekViewProps, ctx: ViewContext) {
  const { $weekOf, $$selectedDate, $max, $min } = props;

  const $days = derive([$weekOf, $max, $min, props.$dayMeta], (date, max, min, dayMeta) => {
    let cursor = new Date(date);
    const end = endOfWeek(date);
    const days = [];

    while (cursor < end) {
      const meta = (dayMeta ?? []).find((m) => isSameDay(m.date, cursor));
      days.push({
        date: cursor,
        selectable: (!max || isAfter(max, cursor)) && (!min || isBefore(min, cursor)),
        label: meta?.label,
      });
      cursor = addDays(cursor, 1);
    }

    return days;
  });

  const $months = derive([$days], (days) => {
    const firstDate = days[0].date;
    const lastDate = days[days.length - 1].date;
    if (isSameMonth(firstDate, lastDate)) {
      return [{ date: firstDate }];
    } else {
      return [
        { date: firstDate, endsAtIndex: days.findIndex((d) => !isSameMonth(firstDate, d.date)) },
        { date: lastDate },
      ];
    }
  });

  return (
    <div class={styles.weekView}>
      <div class={styles.weekViewMonths}>
        {repeat(
          $months,
          (month) => getMonth(month.date),
          ($month, $index, ctx) => {
            const $style = derive([$month, $index], (month, index) => {
              if (month.endsAtIndex) {
                return { gridColumnStart: 1, gridColumnEnd: month.endsAtIndex + 1 };
              } else if (index > 0) {
                const startIndex = $months.get()[index - 1].endsAtIndex! + 1;
                return { gridColumnStart: startIndex, gridColumnEnd: 8 };
              } else {
                return { gridColumnStart: 1, gridColumnEnd: 8 };
              }
            });
            const $label = Dolla.i18n.dateTime(
              derive([$month], (m) => m.date),
              { month: "short" },
            );

            return (
              <div
                class={[
                  styles.weekViewMonth,
                  {
                    [styles.starts]: derive([$style], (s) => s.gridColumnStart > 1),
                    [styles.ends]: derive([$style], (s) => s.gridColumnEnd < 8),
                  },
                ]}
                style={$style}
              >
                <div class={styles.weekViewMonthLabelWrap}>
                  <span class={styles.weekViewMonthLabel}>{$label}</span>
                </div>
              </div>
            );
          },
        )}
      </div>
      <div class={styles.weekViewDays}>
        {repeat(
          $days,
          (day) => day.date.toISOString(),
          ($day) => {
            const $date = derive([$day], (day) => day.date);
            const $disabled = derive([$day], (day) => !day.selectable);
            const $label = derive([$day], (day) => day.label);
            const $selected = derive([$date, $$selectedDate], (date, selected) =>
              selected ? isSameDay(date, selected) : false,
            );
            const $today = derive([$date, clock.$hour], (date) => isSameDay(new Date(), date));

            return (
              <button
                class={[
                  styles.dayView,
                  {
                    [styles.weekend]: derive([$date], isWeekend),
                    [styles.selected]: $selected,
                    [styles.today]: $today,
                  },
                ]}
                disabled={$disabled}
                onClick={(e) => {
                  e.preventDefault();
                  $$selectedDate.set($date.get());
                }}
              >
                <div class={styles.dayLabel}>
                  <span class={styles.dayLabelWeekday}>
                    {Dolla.i18n.dateTime($date, { weekday: "short" })}
                  </span>
                  <span class={styles.dayLabelDate}>{derive([$date], (date) => date.getDate())}</span>
                </div>
                {cond(
                  props.$dayMeta,
                  <div class={styles.dayContent}>
                    {cond($label, <span class={styles.dayItemCount}>{$label}</span>)}
                  </div>,
                )}
              </button>
            );
          },
        )}
      </div>
    </div>
  );
}
