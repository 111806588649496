import { derive, repeat, t, type ViewContext } from "@manyducks.co/dolla";
import scrollStyles from "@styles/ScrollBar.module.css";
import { Dialog, DialogContent, DialogHeader } from "@views/Dialog";
import type { Note, Task } from "schemas";
import { NoteActivity } from "@stores/notes";
import { TaskActivity } from "@stores/tasks";
import styles from "./Activity.module.css";
import { ActivityListItem } from "./ActivityListItem/ActivityListItem";

import { auth, notes, tasks } from "@stores";
import type { DialogProps } from "@stores/dialog";

interface GenericSearchResult<Type, Schema> {
  rank: number;
  type: Type;
  data: Schema;
}

export type SearchResult = NoteSearchResult | TaskSearchResult;
export type NoteSearchResult = GenericSearchResult<"note", Note>;
export type TaskSearchResult = GenericSearchResult<"task", Task>;

export type Activity = TaskActivity | NoteActivity;

interface ActivityDialogProps extends DialogProps {}

export function ActivityDialog(props: ActivityDialogProps, ctx: ViewContext) {
  const $activity = derive([tasks.$activity, notes.$activity], (tasks, notes) =>
    [...tasks, ...notes].sort((a, b) => {
      if (a.timestamp > b.timestamp) {
        return -1;
      } else if (a.timestamp < b.timestamp) {
        return +1;
      } else {
        return 0;
      }
    }),
  );

  ctx.watch([$activity], (activity) => {
    ctx.log(activity);
  });

  ctx.onMount(() => {
    tasks.fetchIndexFor(null);
    notes.fetchIndexFor(null);
  });

  return (
    <Dialog
      transitionIn={props.dialog.transitionIn}
      transitionOut={props.dialog.transitionOut}
      onsubmit={() => {
        // props.dialog.$$open.set(false);
        // search();
      }}
      accentColor={derive([auth.$me], (m) => m?.color ?? "#888")}
    >
      <DialogHeader dialog={props.dialog} $title={t("workspace.activity.title")} />

      <DialogContent>
        {/* <div class={styles.switchBox}>
          <SegmentButton
            value="all"
            segments={[
              { value: "all", content: <span>All</span> },
              { value: "notes", content: <span>Notes</span> },
              { value: "tasks", content: <span>Tasks</span> },
            ]}
            onChange={(value, segment) => {}}
          />
        </div> */}

        <div class={[styles.content, scrollStyles.scrollable]}>
          <ul class={[styles.list]}>
            {repeat(
              $activity,
              (a) => `${a.type}/${a.data.id}`,
              ($entry) => {
                return (
                  <ActivityListItem
                    $activity={$entry}
                    onClick={() => {
                      // TODO: Close search
                      props.dialog.close();
                    }}
                  />
                );
              },
            )}
          </ul>
        </div>
      </DialogContent>
    </Dialog>
  );
}
