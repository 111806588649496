import {
  cond,
  createRef,
  createState,
  derive,
  State,
  t,
  toSettableState,
  type MaybeState,
  type ViewContext,
} from "@manyducks.co/dolla";
import { HoverMenu } from "@views/HoverMenu";
import { UserAvatar } from "@views/UserAvatar";
import { Icon } from "MaterialSymbols";
import { AssignMenu } from "./AssignMenu";
import styles from "./UserPicker.module.css";

import { auth, projects, theme } from "@stores";

interface UserPickerProps {
  $selectedId: State<number | undefined>;
  $projectId: State<number>;
  onchange: (newId: number | undefined) => void;
  $disabled?: State<boolean>;
  emptyLabel?: MaybeState<string>;
}

export function UserPicker({ $selectedId, onchange, ...props }: UserPickerProps, ctx: ViewContext) {
  // const $disabled = readable(props.$disabled ?? false);
  const $selected = derive([auth.$users, $selectedId], (users, id) => users?.find((u) => u.id === id));

  const $project = derive([props.$projectId, projects.$cache], (id, projects) => projects.get(id));

  const [$assignMenuIsOpen, setAssignMenuIsOpen] = createState(false);
  const assignMenuAnchor = createRef<HTMLElement>();

  return (
    <div
      class={[styles.container, { [styles.open]: $assignMenuIsOpen }]}
      style={theme.getTheme$(derive([$selected, $project], (user, project) => user?.color ?? project?.color))}
    >
      <button
        type="button"
        ref={assignMenuAnchor}
        class={styles.button}
        onClick={(e) => {
          setAssignMenuIsOpen(true);
        }}
      >
        {cond(
          $selected,
          <div class={styles.avatar}>
            <UserAvatar src={derive([$selected], (user) => user?.avatar ?? "")} />
          </div>,
        )}
        <div class={styles.label}>
          {derive([$selected], (user) => user?.name ?? t("views.userPicker.notAssignedLabel"))}
        </div>
        <div class={styles.indicator}>
          <Icon name="Arrow Drop Down" />
        </div>
      </button>

      <HoverMenu
        $$open={toSettableState($assignMenuIsOpen, setAssignMenuIsOpen)}
        anchorRef={assignMenuAnchor}
        color={derive([$project], (p) => p?.color)}
        distanceFromAnchor={14}
        preferHorizontalAlignment="center"
        preferVerticalAlignment="below"
      >
        <AssignMenu
          $projectId={props.$projectId}
          $value={derive([$selectedId], (id) => id ?? null)}
          onChange={(user) => {
            onchange(user?.id);
            setAssignMenuIsOpen(false);
          }}
        />
      </HoverMenu>

      {/* <Searchdown<number | null>
        size={props.size}
        color={computed($selected, (u) => u?.color ?? "#888")}
        $value={computed($selectedId, (id) => id ?? null)}
        suggestions={$suggestions}
        emptyLabel={props.emptyLabel}
        onInputChange={(value) => {
          ctx.log({ value });
        }}
        onSelect={(suggestion) => {
          ctx.log({ suggestion });
          onchange(suggestion.value as number);
        }}
      /> */}
    </div>
  );
}
