export default {
  "calendarBlockView": "CalendarInput_calendarBlockView_662b3",
  "calendarBlockWeekView": "CalendarInput_calendarBlockWeekView_662b3",
  "calendarBlockControls": "CalendarInput_calendarBlockControls_662b3",
  "calendarBlockContent": "CalendarInput_calendarBlockContent_662b3",
  "calendarStripView": "CalendarInput_calendarStripView_662b3",
  "calendarStripContent": "CalendarInput_calendarStripContent_662b3",
  "calendarStripControls": "CalendarInput_calendarStripControls_662b3",
  "buttonIcon": "CalendarInput_buttonIcon_662b3",
  "calendarStripButton": "CalendarInput_calendarStripButton_662b3",
  "calendarStripButtonIcon": "CalendarInput_calendarStripButtonIcon_662b3",
  "calendarStripWeekView": "CalendarInput_calendarStripWeekView_662b3",
  "weekView": "CalendarInput_weekView_662b3",
  "weekViewMonths": "CalendarInput_weekViewMonths_662b3",
  "weekViewMonth": "CalendarInput_weekViewMonth_662b3",
  "weekViewMonthLabelWrap": "CalendarInput_weekViewMonthLabelWrap_662b3",
  "weekViewMonthLabel": "CalendarInput_weekViewMonthLabel_662b3",
  "starts": "CalendarInput_starts_662b3",
  "ends": "CalendarInput_ends_662b3",
  "weekViewDays": "CalendarInput_weekViewDays_662b3",
  "dayView": "CalendarInput_dayView_662b3",
  "weekend": "CalendarInput_weekend_662b3",
  "selected": "CalendarInput_selected_662b3",
  "today": "CalendarInput_today_662b3",
  "dayLabel": "CalendarInput_dayLabel_662b3",
  "dayLabelWeekday": "CalendarInput_dayLabelWeekday_662b3",
  "dayLabelDate": "CalendarInput_dayLabelDate_662b3",
  "dayContent": "CalendarInput_dayContent_662b3",
  "dayItemCount": "CalendarInput_dayItemCount_662b3"
};import "ni:sha-256;GEDw5eauEP5JDANGiB6U_0ReYsxyms7z_JeW9aW8s20";