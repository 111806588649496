import { derive, t, ViewContext } from "@manyducks.co/dolla";
import { PlaceLayout } from "../PlaceLayout/PlaceLayout";

import { auth } from "@stores";

export function Admin(props: {}, ctx: ViewContext) {
  return (
    <PlaceLayout
      color={derive([auth.$me], (me) => me?.color)}
      title={t("workspace.nav.admin.label")}
      tabs={[
        {
          href: "/admin/server-stats",
          active: (pattern) => pattern.startsWith("/admin/server-stats"),
          text: t("workspace.admin.serverStats.tabName"),
          // icon: <ServerIcon />,
          icon: <img src="/icons/rooms.12.trans.png" alt="" />,
        },
        {
          href: "/admin/people",
          active: (pattern) => pattern.startsWith("/admin/people"),
          text: t("workspace.admin.people.tabName"),
          // icon: <UserIcon />,
          icon: <img src="/icons/people.12.trans.png" alt="" />,
        },
        {
          href: "/admin/projects",
          active: (pattern) => pattern.startsWith("/admin/projects"),
          text: t("workspace.admin.projects.tabName"),
          // icon: <FoldersIcon />,
          icon: <img src="/icons/projects.12.trans.png" alt="" />,
        },
      ]}
    >
      {ctx.outlet()}
    </PlaceLayout>
  );
}
