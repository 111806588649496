// TODO: Material icons plugin that requests icons from Google Fonts.

import { makeDebouncer } from "@helpers/makeDebouncer";
import type { Dolla, Logger, State, ViewContext } from "@manyducks.co/dolla";
import { valueOf, html } from "@manyducks.co/dolla";

export type MaterialSymbolsOptions = {
  style?: MaterialSymbolsStyle;

  settings?: Partial<MaterialSymbolIconSettings>;

  /**
   * Icons to request from Google Fonts. Add names of icons you know you're going to use here.
   */
  icons?: string[];

  /**
   * Font variation setting ranges to load. If you are using multiple variations aside from the default `settings`, add those ranges here to preload them.
   */
  variationRanges?: {
    /**
     * A two-item array representing the fill values to load.
     * Fill values are 0 (unfilled) and 1 (filled).
     */
    fill?: number[];

    /**
     * A two-item array representing the minimum and maximum weights to load.
     * Weight values range from 100 to 700.
     */
    weight?: number[];

    /**
     * A two-item array representing the minimum and maximum grades to load.
     * Grade values range from -25 to 200.
     */
    grade?: number[];

    /**
     * A two-item representing the minimum and maximum optical sizes to load.
     * Optical size values range from 20 to 48.
     */
    opticalSize?: number[];
  };
};

export enum MaterialSymbolsStyle {
  Outlined = "Outlined",
  Rounded = "Rounded",
  Sharp = "Sharp",
}

export interface MaterialSymbolIconSettings {
  /**
   * Fill gives you the ability to modify the default icon style. A single icon can render both unfilled and filled states.
   *
   * To convey a state transition, use the fill axis for animation or interaction. The values are 0 for default or 1 for completely filled. Along with the weight axis, the fill also impacts the look of the icon.
   */
  fill: boolean | State<boolean>;

  /**
   * Weight defines the symbol's stroke weight, with a range of weights between thin (100) and bold (700). Weight can also affect the overall size of the symbol.
   */
  weight: number;

  /**
   * Weight and grade affect a symbol's thickness. Adjustments to grade are more granular than adjustments to weight and have a small impact on the size of the symbol.
   *
   * Grade is also available in some text fonts. You can match grade levels between text and symbols for a harmonious visual effect. For example, if the text font has a -25 grade value, the symbols can match it with a suitable value, say -25.
   *
   * You can use grade for different needs: Low emphasis (e.g. -25 grade): To reduce glare for a light symbol on a dark background, use a low grade.
   *
   * High emphasis (e.g. 200 grade): To highlight a symbol, increase the positive grade.
   */
  grade: number;

  /**
   * Optical Sizes range from 20dp to 48dp.
   *
   * For the image to look the same at different sizes, the stroke weight (thickness) changes as the icon size scales. Optical Size offers a way to automatically adjust the stroke weight when you increase or decrease the symbol size.
   */
  opticalSize: number;
}

/**
 * Props passed to the icon view.
 */
export interface MaterialSymbolIconProps extends Partial<MaterialSymbolIconSettings> {
  name: string;

  /**
   * Size of the icon in pixels. Optical size will follow this value unless otherwise specified.
   */
  size?: number;
}

export class VariationRange {
  #min: number;
  #max: number;
  #values: number[] = [];

  constructor(min: number, max: number) {
    this.#min = min;
    this.#max = max;
  }

  /**
   * Clamp a value between this range's min and max.
   */
  clamp(value: number): number {
    return Math.min(this.#max, Math.max(this.#min, value));
  }

  /**
   * Adds a new value to the range. If it changed the value of the range the function will return true.
   */
  add(value: number): boolean {
    value = this.clamp(value);

    if (this.#values.length === 0) {
      this.#values.push(value);
      return true;
    } else if (this.#values.length === 1) {
      if (value < this.#values[0]) {
        this.#values.unshift(value);
        return true;
      } else if (value > this.#values[1]) {
        this.#values.push(value);
        return true;
      }
    } else {
      const [low, high] = this.#values;
      if (value < low) {
        this.#values[0] = value;
        this.#cleanup();
        return true;
      } else if (value > high) {
        this.#values[1] = value;
        this.#cleanup();
        return true;
      }
    }
    return false;
  }

  toJSON() {
    const [low, high] = this.#values;

    return {
      low: low ?? 0,
      high: high ?? low ?? 0,
    };
  }

  toString() {
    if (this.#values.length === 0) {
      return "0";
    } else if (this.#values.length === 1) {
      return this.#values[0].toString();
    } else {
      const [low, high] = this.#values;
      return `${low}..${high}`;
    }
  }

  #cleanup() {
    // If both values are the same, collapse to one.
    if (this.#values[0] === this.#values[1]) {
      this.#values = [this.#values[0]];
    }
  }
}

class MaterialSymbols {
  #dolla!: Dolla;
  #logger!: Logger;

  #icons = new Set<string>();
  #style = MaterialSymbolsStyle.Outlined;
  #settings: MaterialSymbolIconSettings = {
    fill: false,
    weight: 400,
    grade: 100,
    opticalSize: 24,
  };

  // Track which icons have been requested vs which have been loaded upfront.
  #requestedIcons = new Set<string>();
  #initialIcons = new Set<string>();

  #fillRange = new VariationRange(0, 1);
  #weightRange = new VariationRange(100, 700);
  #gradeRange = new VariationRange(-25, 200);
  #opticalSizeRange = new VariationRange(20, 48);

  #uid = Date.now().toString(16) + Math.floor(Math.random() * 9999).toString(16);
  #iconClassName = "material-symbols-icon-" + this.#uid;
  #linkId = "material-symbols-link-" + this.#uid;
  #styleId = "material-symbols-style-" + this.#uid;

  // Debounce stylesheet URL updates to batch request new icons together.
  #debouncer = makeDebouncer(100);

  setup(dolla: Dolla, options?: MaterialSymbolsOptions) {
    this.#dolla = dolla;
    this.#logger = dolla.createLogger("🔌 dolla-material-symbols");

    if (options?.icons) {
      options.icons.forEach((icon) => {
        this.#icons.add(this.#normalizeIconName(icon));

        this.#initialIcons.add(icon);
      });
    }

    if (options?.style) {
      this.#style = options.style;
    }

    if (options?.settings) {
      if (options.settings.fill) {
        this.#settings.fill = options.settings.fill;
      }
      if (options.settings.weight) {
        this.#settings.weight = this.#weightRange.clamp(options.settings.weight);
      }
      if (options.settings.grade) {
        this.#settings.grade = this.#gradeRange.clamp(options.settings.grade);
      }
      if (options.settings.opticalSize) {
        this.#settings.opticalSize = this.#opticalSizeRange.clamp(options.settings.opticalSize);
      }
    }

    this.#fillRange.add(Number(this.#settings.fill));
    this.#weightRange.add(this.#settings.weight);
    this.#gradeRange.add(this.#settings.grade);
    this.#opticalSizeRange.add(this.#settings.opticalSize);

    if (options?.variationRanges) {
      const { fill, weight, grade, opticalSize } = options.variationRanges;

      if (fill) {
        this.#assertRangeArray(fill, `Fill range must be an array with up to two numbers. Got: ${fill}`);
        fill.forEach((n) => this.#fillRange.add(n));
      }

      if (weight) {
        this.#assertRangeArray(
          weight,
          `Weight range must be an array with up to two numbers. Got: ${weight}`,
        );
        weight.forEach((n) => this.#weightRange.add(n));
      }

      if (grade) {
        this.#assertRangeArray(grade, `Grade range must be an array with up to two numbers. Got: ${grade}`);
        grade.forEach((n) => this.#gradeRange.add(n));
      }

      if (opticalSize) {
        this.#assertRangeArray(
          opticalSize,
          `Optical size range must be an array with up to two numbers. Got: ${opticalSize}`,
        );
        opticalSize.forEach((n) => this.#opticalSizeRange.add(n));
      }
    }

    this.#updateStyles();
  }

  Icon(props: MaterialSymbolIconProps, ctx: ViewContext) {
    let $settings: State<Record<string, string>> | undefined;

    // TODO: Handle when fill is passed as a State and the value changes after rendering.

    ctx.onMount(() => {
      this.#requestIcon(props.name, {
        fill: valueOf(props.fill),
        grade: props.grade,
        weight: props.weight,
        opticalSize: props.opticalSize,
      });
    });

    if (
      props.fill != null ||
      props.grade != null ||
      props.weight != null ||
      props.opticalSize != null ||
      props.size != null
    ) {
      $settings = this.#dolla.derive(
        [
          props.fill,
          props.grade,
          props.weight,
          props.opticalSize,
          this.#settings.fill,
          this.#settings.grade,
          this.#settings.weight,
          this.#settings.opticalSize,
        ],
        (fill, grade, weight, opticalSize, globalFill, globalGrade, globalWeight, globalOpticalSize) => {
          const sizeInPixels = (props.size ?? opticalSize ?? globalOpticalSize).toString() + "px";

          return {
            fontVariationSettings: this.#getSettingsStyles({
              fill: fill ?? globalFill,
              grade: grade ?? globalGrade,
              weight: weight ?? globalWeight,
              opticalSize: opticalSize ?? props.size ?? globalOpticalSize,
            }),
            fontSize: sizeInPixels,
          };
        },
      );
    }

    return html`
      <span
        class=${[`material-symbols-${this.#style.toLowerCase()}`, this.#iconClassName]}
        style=${$settings}
      >
        ${this.#normalizeIconName(props.name)}
      </span>
    `;
  }

  /**
   * Returns a list of icons requested from Google Fonts but not yet loaded on the page.
   */
  // getUnusedIcons() {
  //   const initial = [...this.#initialIcons].map((name) => ({
  //     name: name,
  //     normalized: this.#normalizeIconName(name),
  //   }));
  //   const requested = [...this.#requestedIcons].map(this.#normalizeIconName);

  //   return initial.filter((icon) => !requested.includes(icon.normalized)).map((icon) => icon.name);
  // }

  #assertRangeArray(value: unknown, errorMessage: string): value is number[] {
    const isRangeArray =
      Array.isArray(value) && value.length > 0 && value.every((n) => typeof n === "number");
    if (!isRangeArray) {
      throw new TypeError(errorMessage);
    }
    return isRangeArray;
  }

  /**
   * Adds an icon to the list requested from Google Fonts.
   */
  #requestIcon(name: string, options?: Partial<MaterialSymbolIconSettings>) {
    if (name.trim() === "") return;

    this.#requestedIcons.add(name);

    const normalized = this.#normalizeIconName(name);

    const hasIcon = this.#icons.has(normalized);
    let rangesUpdated = false;

    if (options) {
      if (options.fill) {
        rangesUpdated = this.#fillRange.add(Number(options.fill)) || rangesUpdated;
      }
      if (options.weight) {
        rangesUpdated = this.#weightRange.add(options.weight) || rangesUpdated;
      }
      if (options.grade) {
        rangesUpdated = this.#gradeRange.add(options.grade) || rangesUpdated;
      }
      if (options.opticalSize) {
        rangesUpdated = this.#opticalSizeRange.add(options.opticalSize) || rangesUpdated;
      }
    }

    if (!hasIcon || rangesUpdated) {
      if (!hasIcon) {
        this.#icons.add(normalized);

        this.#logger.warn(
          `Add '${name}' to the \`icons\` array when calling \`MaterialSymbols.setup()\` to preload this icon and reduce network requests.`,
        );
      }

      this.#debouncer.queue(() => {
        this.#updateStyles();
      });
    }
  }

  #normalizeIconName(name: string) {
    return name.toLowerCase().replace(/\s+/g, "_").trim();
  }

  #updateStyles() {
    if (this.#icons.size > 0) {
      const href = this.#getHref();
      const currentLink = document.getElementById(this.#linkId) as HTMLLinkElement;
      if (currentLink) {
        if (currentLink.href != href) {
          currentLink.href = href;
        }
      } else {
        const link = document.createElement("link");
        link.id = this.#linkId;
        link.rel = "stylesheet";
        link.href = href;
        document.head.appendChild(link);
      }
    }

    const css = `
      .${this.#iconClassName} {
        font-variation-settings: ${this.#getSettingsStyles()};
        font-weight: normal;
      }
    `;
    const currentStyle = document.getElementById(this.#styleId) as HTMLStyleElement;
    if (currentStyle) {
      // currentStyles.
      currentStyle.textContent = css;
    } else {
      const style = document.createElement("style");
      style.id = this.#styleId;
      style.textContent = css;
      document.head.appendChild(style);
    }
  }

  #getHref(): string {
    const family = `Material+Symbols+${this.#style}`;

    const fillRange = this.#fillRange.toString();
    const sizeRange = this.#opticalSizeRange.toString();
    const weightRange = this.#weightRange.toString();
    const gradeRange = this.#gradeRange.toString();

    const settings = `opsz,wght,FILL,GRAD@${sizeRange},${weightRange},${fillRange},${gradeRange}`;
    const iconNames = [...this.#icons].sort().join(",");

    return `https://fonts.googleapis.com/css2?family=${family}:${settings}&icon_names=${iconNames}`;
  }

  #getSettingsStyles(settings = this.#settings): string {
    return `'FILL' ${settings.fill ? "1" : "0"}, 'wght' ${settings.weight}, 'GRAD' ${settings.grade}, 'opsz' ${settings.opticalSize}`;
  }
}

const instance = new MaterialSymbols();

export const Icon = instance.Icon.bind(instance);

export default instance;
