import { t, type ViewContext } from "@manyducks.co/dolla";
import { Button } from "@views/Button";
import { Dialog, DialogContent, DialogFooter, DialogHeader } from "@views/Dialog";
import styles from "./DeclineProjectInviteDialog.module.css";

import { DialogProps } from "@stores/dialog";

interface DeclineProjectInviteDialog extends DialogProps {
  /**
   * Called when confirmation is successful
   */
  onConfirm: () => void;
}

export function DeclineProjectInviteDialog(props: DeclineProjectInviteDialog, ctx: ViewContext) {
  return (
    <Dialog
      onsubmit={() => {
        props.onConfirm();
        props.dialog.close();
      }}
      transitionIn={props.dialog.transitionIn}
      transitionOut={props.dialog.transitionOut}
      accentColor="#ff0000"
    >
      <DialogHeader dialog={props.dialog} $title={t("workspace.nav.projectInvites.declineDialogTitle")} />

      <DialogContent>
        <div class={styles.content}>
          <p class={styles.instructions}>{t("workspace.nav.projectInvites.declineDialogMessage")}</p>
        </div>
      </DialogContent>

      <DialogFooter>
        <Button type="submit">{t("workspace.nav.projectInvites.declineButtonText")}</Button>
      </DialogFooter>
    </Dialog>
  );
}
