import Dolla from "@manyducks.co/dolla";

const debug = Dolla.createLogger("📦 stores/clock");

const [$second, setSecond] = Dolla.createState(0);
const [$minute, setMinute] = Dolla.createState(0);
const [$hour, setHour] = Dolla.createState(0);

function tick() {
  const now = new Date();
  const untilNextTick = 1000 - now.getMilliseconds();

  setSecond(now.getSeconds());
  setMinute(now.getMinutes());
  setHour(now.getHours());

  // debug.info(
  //   `${now.getHours().toString().padStart(2, "0")}:${now.getMinutes().toString().padStart(2, "0")}:${now.getSeconds().toString().padStart(2, "0")}`,
  // );

  setTimeout(tick, untilNextTick);
}

Dolla.onMount(tick);

export { $second, $minute, $hour };
