import { promptForFiles } from "@helpers/promptForFiles";
import Dolla, {
  cond,
  createRef,
  createState,
  derive,
  repeat,
  toState,
  t,
  type ViewContext,
} from "@manyducks.co/dolla";
import type { DialogProps } from "@stores/dialog";
import { uploadFile } from "@stores/files";
import { UpdateTaskMetaData } from "@stores/tasks";
import scrollStyles from "@styles/ScrollBar.module.css";
import { Button, ButtonColor, ButtonStyle } from "@views/Button";
import { Checkbox } from "@views/Checkbox";
import { CollapsibleListSection } from "@views/CollapsibleListSection";
import { DatePicker } from "@views/DatePicker";
import { Dialog, DialogContent, DialogFooter, DialogHeader } from "@views/Dialog";
import { FileAttachment } from "@views/FileAttachment";
import { IconButton } from "@views/IconButton";
import { UserPicker } from "@views/UserPicker";
import { InputFile } from "Workspace/Chat/ChatEditor/ChatEditor";
import { startOfDay, subDays } from "date-fns";
import { produce } from "immer";
import { Task } from "schemas";
import styles from "./TaskEditDialog.module.css";

import { clock, dialog, nav, projects, tasks } from "@stores";
import { Icon } from "MaterialSymbols";
import { ConfirmDelete } from "@dialogs/ConfirmDelete/ConfirmDelete";

interface TaskEditDialogProps extends DialogProps {
  task: Task;
  onSubmitted?: () => void;
  onToListClicked?: () => void;
}

export function TaskEditDialog(props: TaskEditDialogProps, ctx: ViewContext) {
  const $task = derive([tasks.$cache], (tasks) => {
    if (props.task) {
      return tasks.get(props.task.id);
    }
  });
  const $taskExists = derive([$task], (t) => t != null);
  const $project = derive([$task, projects.$cache], (task, projects) => {
    if (!task) return null;
    return projects.get(task.projectId);
  });

  const $color = derive([$project], (project) => {
    return project?.color ?? "#888";
  });

  // Close dialog if task was deleted.
  ctx.watch([$taskExists], (exists) => {
    if (!exists) {
      props.dialog.close();
    }
  });

  ctx.onMount(() => {
    const task = $task.get();
    if (task) {
      nav.addRecent(`/projects/${task.projectId}/tasks/${task.id}`);
    }
  });

  const $taskIsFavorite = derive([$task, nav.$favorites], (task, favorites) => {
    if (task == null) return false;
    const { projectId, id } = task;
    return favorites.some((f) => f.path === `/projects/${projectId}/tasks/${id}`);
  });

  const [$submitting, setSubmitting] = createState(false);

  const [$completed, setCompleted] = createState(props.task?.completedAt != null);

  const [$title, setTitle] = createState(props.task?.title ?? "");
  const [$status, setStatus] = createState(props.task?.status ?? "");
  const [$assignedUserId, setAssignedUserId] = createState(props.task?.assignedUserId ?? null);
  const [$dueDate, setDueDate] = createState(props.task?.dueDate || null);

  const [$deletedAttachments, setDeletedAttachments] = createState<string[]>([]);
  const $attachments = derive(
    [$task, $deletedAttachments],
    (task, deletedUUIDs) => task?.attachments.filter((f) => !deletedUUIDs.includes(f.uuid)) ?? [],
  );

  const [$fileUploads, setFileUploads] = createState<InputFile[]>([]);

  const titleElement = createRef<HTMLSpanElement>();
  const descriptionElement = createRef<HTMLSpanElement>();

  ctx.onMount(() => {
    const titleNode = titleElement.node;
    if (titleNode) {
      titleNode.textContent = $title.get();
    }
    const descriptionNode = descriptionElement.node;
    if (descriptionNode) {
      descriptionNode.textContent = $status.get();
    }
  });

  function addFiles(files: FileList | File[]) {
    for (const file of Array.from(files)) {
      const events = uploadFile(file);
      let uploadId: string;

      events.on("started", (e) => {
        ctx.log("upload started", e);
        uploadId = e.data.uploadId;
        const inputFile: InputFile = {
          file,
          uploadId,
          progress: 0,
          complete: false,
          events,
        };
        setFileUploads(
          produce((current) => {
            current.push(inputFile);
          }),
        );
      });
      events.on("progress", (e) => {
        ctx.log("upload progress", e);
        setFileUploads(
          produce((current) => {
            const found = current.find((f) => f.uploadId === uploadId);
            if (found) {
              found.progress = e.data.percent;
            }
          }),
        );
      });
      events.on("error", (e) => {
        ctx.log("upload error", e);
        setFileUploads(
          produce((current) => {
            const found = current.find((f) => f.uploadId === uploadId);
            if (found) {
              found.error = e.data.error;
            }
          }),
        );
      });
      events.on("complete", (e) => {
        ctx.log("upload complete", e);

        const task = $task.get()!;
        Dolla.http
          .post<Task>(`/api/tasks/${task.id}/attachments`, { body: { uuids: [uploadId] } })
          .then((res) => {
            ctx.info("Attachment added", res);
            setFileUploads(
              produce((current) => {
                return current.filter((f) => f.uploadId !== uploadId);
              }),
            );
          })
          .catch((err) => {
            ctx.error(err);
          });
      });
    }
  }

  return (
    <Dialog
      onsubmit={async () => {
        if ($submitting.get()) return;

        setSubmitting(true);

        let title = $title.get();
        let status = $status.get();
        let assignedUserId = $assignedUserId.get();
        let dueDate = $dueDate.get();
        let attachments = $attachments.get();
        let completed = $completed.get();

        const data: UpdateTaskMetaData = {
          title: title.trim(),
          status: status.trim() || null,
          assignedUserId,
          priority: null,
          dueDate,
          tags: [],
          attachments: attachments.map((f) => f.uuid),
          completed,
        };

        if (props.task) {
          await tasks.updateTaskMeta(props.task.id, data);
        } else {
          // tasks.createTask(props.projectId, data);
        }

        setSubmitting(false);
        props.dialog.close();
      }}
      transitionIn={props.dialog.transitionIn}
      transitionOut={props.dialog.transitionOut}
      accentColor={$color}
    >
      <DialogHeader
        dialog={props.dialog}
        $title={t("views.taskListItem.editDialog.dialogTitle")}
        buttons={[
          cond(
            props.task,
            <IconButton
              onClick={() => {
                if ("clipboard" in window.navigator) {
                  const permalink = new URL(`/p/${props.task!.linkingId}`, window.location.origin);
                  window.navigator.clipboard.writeText(permalink.toString()).then(() => {
                    alert(`Wrote permalink to clipboard: ${permalink.toString()}`);
                  });
                }
              }}
            >
              <Icon name="Link" />
            </IconButton>,
          ),
          // cond(
          //   props.task,
          //   <button
          //     class={[styles.favoriteButton, { [styles.active]: $taskIsFavorite }]}
          //     onClick={(e) => {
          //       e.preventDefault();

          //       const { projectId, id } = props.task!;
          //       const path = `/projects/${projectId}/tasks/${id}`;

          //       if ($taskIsFavorite.get()) {
          //         const favorite = nav.$favorites.get().find((f) => f.path === path);
          //         if (favorite) {
          //           nav.removeFavorite(favorite.id);
          //         }
          //       } else {
          //         nav.addFavorite(path);
          //       }
          //     }}
          //   >
          //     <Star />
          //   </button>,
          // ),
        ]}
      />
      <DialogContent>
        <div class={[styles.content, scrollStyles.scrollable]}>
          <section class={styles.mainContent}>
            <div class={styles.checkbox}>
              <Checkbox
                $value={$completed}
                onChange={(value) => {
                  setCompleted(value);
                }}
              />
            </div>
            <div class={styles.titleAndDescription}>
              <div class={styles.titleContainer}>
                {cond(
                  derive([$title], (x) => x.trim().length === 0),
                  <span class={styles.titlePlaceholder}>
                    {t("views.taskListItem.editDialog.titlePlaceholder")}
                  </span>,
                )}
                <span
                  ref={titleElement}
                  class={styles.titleInput}
                  contentEditable
                  role="textbox"
                  onInput={(e) => {
                    const value = (e.currentTarget as HTMLElement).textContent ?? "";
                    setTitle(value);
                  }}
                ></span>
              </div>

              <div class={styles.descriptionContainer}>
                {cond(
                  derive([$status], (x) => x.trim().length === 0),
                  <span class={styles.descriptionPlaceholder}>
                    {t("views.taskListItem.editDialog.descriptionPlaceholder")}
                  </span>,
                )}
                <span
                  ref={descriptionElement}
                  class={styles.descriptionInput}
                  contentEditable
                  role="textbox"
                  onInput={(e) => {
                    const value = (e.currentTarget as HTMLElement).textContent ?? "";
                    setStatus(value);
                  }}
                ></span>
              </div>
            </div>
          </section>

          <section class={[styles.formGroup]}>
            <header>
              <h3>{t("views.taskListItem.editDialog.fields.assign.label")}</h3>
            </header>

            <div class={styles.formGroupContent}>
              <UserPicker
                $selectedId={derive([$assignedUserId], (id) => id ?? undefined)}
                $projectId={derive([$project], (p) => p?.id!)}
                onchange={(newUserId) => {
                  setAssignedUserId(newUserId ?? null);
                }}
              />
            </div>
          </section>

          <section class={[styles.formGroup]}>
            <header>
              <h3>{t("views.taskListItem.editDialog.fields.dueDate.label")}</h3>
            </header>
            <div class={styles.formGroupContent}>
              <DatePicker
                $value={$dueDate}
                $min={derive([clock.$hour], () => startOfDay(subDays(new Date(), 1)))}
                onChange={(value) => {
                  setDueDate(value);
                }}
                accentColor={$color}
                placeholder="---"
              />
            </div>
          </section>

          <section class={[styles.formGroup]}>
            <CollapsibleListSection
              $title={t("views.taskListItem.editDialog.fields.attachments.label")}
              controls={[
                {
                  id: "add",
                  icon: <Icon name="Add" />,
                  title: "ADD",

                  onClick: () => {
                    // Select files to upload.
                    promptForFiles({ multiple: true, accept: "*" }).then((files) => {
                      addFiles(files);
                    });
                  },
                },
              ]}
            >
              <div class={styles.formGroupContent}>
                {cond(
                  derive([$attachments], (f) => f.length > 0),
                  <ul class={styles.attachmentsList}>
                    {repeat(
                      $attachments,
                      (f) => f.id,
                      ($file) => (
                        <li>
                          <FileAttachment
                            $attachment={$file}
                            onDelete={() => {
                              const file = $file.get();
                              setDeletedAttachments((current) => [...current, file.uuid]);
                            }}
                          />
                        </li>
                      ),
                    )}
                  </ul>,
                  <p>No attachments.</p>,
                )}
              </div>
            </CollapsibleListSection>
          </section>
        </div>
      </DialogContent>
      <DialogFooter>
        {cond(
          props.onToListClicked,
          <Button
            type="button"
            disabled={$submitting}
            onClick={() => {
              props.dialog.close();
              props.onToListClicked?.();
            }}
          >
            {t("views.taskListItem.editDialog.toListButtonText")}
          </Button>,
        )}
        <IconButton
          color={ButtonColor.Danger}
          onClick={() => {
            const task = $task.get()!;

            dialog.show(ConfirmDelete, {
              itemName: task.title,
              message: t("views.taskListItem.deleteDialog.message"),
              onConfirm() {
                // TODO: Perform delete.
                tasks.deleteTask(task.id);
                props.dialog.close();
              },
            });
          }}
        >
          <Icon name="Delete Forever" />
        </IconButton>
        <div style={{ flex: "1 1 100%" }} />
        <Button type="submit" disabled={$submitting}>
          {t("views.taskListItem.editDialog.submitButtonText")}
        </Button>
      </DialogFooter>
    </Dialog>
  );
}
