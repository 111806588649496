import { ActivityDialog } from "@dialogs/Activity/Activity";
import { CreateProjectDialog } from "@dialogs/CreateProjectDialog/CreateProjectDialog";
import { CreateTaskDialog } from "@dialogs/CreateTaskDialog/CreateTaskDialog";
import { KeyboardShortcutsDialog } from "@dialogs/KeyboardShortcuts/KeyboardShortcuts";
import { SearchDialog } from "@dialogs/Search/Search";
import { SettingsDialog } from "@dialogs/Settings/Settings";
import { TaskEditDialog } from "@dialogs/TaskEditDialog/TaskEditDialog";
import { when } from "@helpers/patterns";
import { PlainDate } from "@helpers/PlainDate";
import Dolla, {
  cond,
  createState,
  derive,
  repeat,
  t,
  type State,
  type ViewContext,
} from "@manyducks.co/dolla";
import { auth, breakpoint, clock, dialog, projects, tasks, theme } from "@stores";
import { $invites } from "@stores/projects";
import scrollStyles from "@styles/ScrollBar.module.css";
import { Button, ButtonColor, ButtonStyle } from "@views/Button";
import { IconButton } from "@views/IconButton";
import { SquiggleDivider } from "@views/SquiggleDivider";
import { UserAvatar } from "@views/UserAvatar";
import { addWeeks, subDays } from "date-fns";
import { Icon } from "MaterialSymbols";
import { Note, Project, ProjectInvite, Task } from "schemas";
import { DeclineProjectInviteDialog } from "./DeclineProjectInviteDialog/DeclineProjectInviteDialog";
import styles from "./Home.module.css";
import { QuoteWidget } from "./QuoteWidget/QuoteWidget";
import { TaskListItem } from "@views/TaskListItem";
import { TimeWidget } from "./TimeWidget/TimeWidget";
import { TaskMetaOptions } from "@views/TaskListItem/TaskListItem";

interface GenericSearchResult<Type, Schema> {
  rank: number;
  type: Type;
  data: Schema;
}

export type SearchResult = NoteSearchResult | TaskSearchResult;
export type NoteSearchResult = GenericSearchResult<"note", Note>;
export type TaskSearchResult = GenericSearchResult<"task", Task>;

export function Home(props: {}, ctx: ViewContext) {
  const $myTasks = derive([auth.$me, tasks.$cache], (me, tasks) => {
    if (me == null) {
      return [];
    }
    return [...tasks.values()].filter(
      (t) => t.assignedUserId === me.id && (t.dueDate != null || t.completedAt != null),
    );
  });

  function sortTasksByDateAsc(a: Task, b: Task) {
    if (a.dueDate! < b.dueDate!) {
      return -1;
    } else if (a.dueDate! > b.dueDate!) {
      return +1;
    } else {
      if (a.createdAt < b.createdAt) {
        return -1;
      } else if (a.createdAt > b.createdAt) {
        return +1;
      } else {
        return 0;
      }
    }
  }

  const [$showArchived, setShowArchived] = createState(false);

  // Tasks that are due before today.
  const $overdueTasks = derive([$myTasks, clock.$hour], (tasks, _) => {
    const today = PlainDate.format(new Date());
    return tasks
      .filter((t) => t.completedAt == null && t.dueDate != null && t.dueDate < today)
      .sort(sortTasksByDateAsc);
  });

  // Tasks that are due today.
  const $todayTasks = derive([$myTasks, clock.$hour], (tasks, _) => {
    const today = PlainDate.format(new Date());
    return tasks.filter((t) => t.dueDate === today && t.completedAt == null).sort(sortTasksByDateAsc);
  });

  // Tasks that are due within one week from today.
  const $upcomingTasks = derive([$myTasks], (tasks) => {
    const today = PlainDate.format(new Date());
    const oneWeekFromNow = PlainDate.format(addWeeks(new Date(), 1));
    return tasks
      .filter(
        (t) => t.completedAt == null && t.dueDate != null && t.dueDate > today && t.dueDate <= oneWeekFromNow,
      )
      .sort(sortTasksByDateAsc);
  });

  // Tasks that have been finished in the last two days.
  const $recentlyFinishedTasks = derive([$myTasks], (tasks) => {
    const oldestRecentDate = PlainDate.format(subDays(new Date(), 2));
    return tasks
      .filter((t) => t.completedAt != null && t.completedAt >= oldestRecentDate)
      .sort((a, b) => {
        if (a.completedAt! < b.completedAt!) {
          return +1;
        } else if (a.completedAt! > b.completedAt!) {
          return -1;
        } else {
          return 0;
        }
      });
  });

  ctx.onMount(() => {
    tasks.fetchToDos();
  });

  const $sortedProjects = derive([projects.$cache, Dolla.i18n.$locale], (projects, _) => {
    const collator = Dolla.i18n.collator({ sensitivity: "base" });
    return [...projects.values()].sort((a, b) => collator.compare(a.name, b.name));
  });

  const $activeProjects = derive([$sortedProjects], (projects) => {
    return projects.filter((p) => p.archivedAt == null);
  });

  const $archivedProjects = derive([$sortedProjects], (projects) => {
    return projects.filter((p) => p.archivedAt != null);
  });

  return (
    <div class={styles.layout}>
      <div class={styles.header}>
        <div class={styles.headerContent}>
          <div class={styles.userInfo} style={theme.$userTheme}>
            <div class={styles.userAvatar}>
              <UserAvatar src={derive([auth.$me], (me) => me?.avatar ?? "")} />
            </div>
            <span class={styles.userName}>{derive([auth.$me], (me) => me?.name)}</span>
          </div>

          <ul class={styles.headerControls}>
            <li>
              <IconButton
                tooltip={t("workspace.search.title")}
                onClick={() => {
                  dialog.show(SearchDialog);
                }}
              >
                <Icon name="Search" />
              </IconButton>
            </li>

            <li>
              <IconButton
                tooltip={t("workspace.activity.title")}
                onClick={() => {
                  dialog.show(ActivityDialog);
                }}
              >
                <Icon name="Search Activity" />
              </IconButton>
            </li>

            <li>
              <IconButton
                tooltip={t("workspace.settings.title")}
                onClick={() => {
                  dialog.show(SettingsDialog);
                }}
              >
                <Icon name="Settings" />
              </IconButton>
            </li>

            {cond(
              breakpoint.min$(800),
              <li>
                <IconButton
                  tooltip={t("workspace.keyboardShortcuts.dialogTitle")}
                  onClick={() => {
                    dialog.show(KeyboardShortcutsDialog);
                  }}
                >
                  <Icon name="Keyboard" />
                </IconButton>
              </li>,
            )}
          </ul>
        </div>
      </div>

      <div class={[styles.content, scrollStyles.scrollable]}>
        <div class={styles.contentWrap}>
          {/* <section class={styles.section}> */}
          <ul class={styles.linksList}>
            <li>
              <a class={styles.link} href="/admin">
                <div class={styles.linkIcon}>
                  <img src="/icons/rooms.12.trans.png" alt="" />
                </div>
                <span class={styles.linkLabel}>{t("workspace.nav.admin.label")}</span>
                <div class={styles.linkIndicator}>
                  <Icon name="Chevron Right" size={32} />
                </div>
              </a>
            </li>
          </ul>
          {/* </section> */}

          <SquiggleDivider />

          <section class={styles.section}>
            <ul class={styles.widgetGrid}>
              <li class={[styles.widget, styles.small, styles.time]}>
                <TimeWidget />
              </li>
              <li class={[styles.widget, styles.small, styles.qotd]}>
                <QuoteWidget />
              </li>
            </ul>
          </section>

          <SquiggleDivider />

          <section class={styles.section}>
            <header>
              <h2>{t("workspace.nav.tasks.title")}</h2>

              <Button
                onClick={() => {
                  dialog.show(CreateTaskDialog, {
                    onConfirm: async (info, open) => {
                      const task = await tasks.createTask({
                        projectId: info.projectId,
                        dueDate: info.dueDate,
                        assignedUserId: auth.$me.get()!.id,
                        delta: [{ insert: info.title }, { insert: "\n" }],
                      });

                      if (open) {
                        dialog.show(TaskEditDialog, { task });
                      }
                    },
                  });
                }}
              >
                {t("workspace.nav.tasks.addTaskButtonText")}
              </Button>
            </header>

            {cond(
              derive([$myTasks], (t) => t.length > 0),
              <>
                {cond(
                  derive([$overdueTasks], (t) => t.length > 0),
                  <TasksSubsection
                    name="home.overdueTasks"
                    $title={t("workspace.nav.tasks.headings.overdue")}
                    $tasks={$overdueTasks}
                    taskMeta={{ project: true, assignedUser: false, updatedAt: false }}
                  />,
                )}

                {cond(
                  derive([$todayTasks], (t) => t.length > 0),
                  <TasksSubsection
                    name="home.todayTasks"
                    $title={t("workspace.nav.tasks.headings.today")}
                    $tasks={$todayTasks}
                    taskMeta={{ project: true, assignedUser: false, updatedAt: false }}
                  />,
                )}

                {cond(
                  derive([$upcomingTasks], (t) => t.length > 0),
                  <TasksSubsection
                    name="home.upcomingTasks"
                    $title={t("workspace.nav.tasks.headings.upcoming")}
                    $tasks={$upcomingTasks}
                    taskMeta={{ project: true, assignedUser: false, updatedAt: false }}
                  />,
                )}

                {cond(
                  derive([$recentlyFinishedTasks], (t) => t.length > 0),
                  <TasksSubsection
                    name="home.recentlyFinishedTasks"
                    $title={t("workspace.nav.tasks.headings.recentlyFinished")}
                    $tasks={$recentlyFinishedTasks}
                    taskMeta={{ project: true, assignedUser: false, updatedAt: false }}
                  />,
                )}
              </>,
              <p class={styles.noTasksLabel}>NO TASKS TO SHOW [TRANSLATE THIS]</p>,
            )}
          </section>

          <SquiggleDivider />

          <section class={styles.section}>
            <header>
              <h2>{t("workspace.nav.projects.title")}</h2>
              <Button
                onClick={() => {
                  dialog.show(CreateProjectDialog, {
                    onConfirm: async (info) => {
                      const project = await projects.createProject(info);
                      Dolla.router.go(`/projects/${project.id}`);
                    },
                    accentColor: "#888",
                  });
                }}
              >
                {t("workspace.nav.projects.addProjectButtonText")}
              </Button>
            </header>

            {cond(
              derive([projects.$invites], (i) => i.length > 0),
              <section class={styles.subsection}>
                <header>
                  <h3>{t("workspace.nav.projects.headings.invites")}</h3>
                </header>

                <ul class={styles.tileList}>
                  {repeat(
                    projects.$invites,
                    (i) => i.id,
                    ($invite) => {
                      return <ProjectInviteTile $invite={$invite} />;
                    },
                  )}
                </ul>
              </section>,
            )}

            <section class={styles.subsection}>
              {cond(
                derive([$invites], (i) => i.length > 0),
                <header>
                  <h3>{t("workspace.nav.projects.headings.current")}</h3>
                </header>,
              )}

              <ul class={styles.tileList}>
                {repeat(
                  $activeProjects,
                  (p) => p.id,
                  ($project) => {
                    return <ProjectTile $project={$project} />;
                  },
                )}
              </ul>
            </section>

            {cond(
              derive([$archivedProjects], (p) => p.length > 0),
              cond(
                $showArchived,
                <section class={styles.subsection}>
                  <header>
                    <h3>{t("workspace.nav.projects.headings.archived")}</h3>
                  </header>

                  <ul class={styles.tileList}>
                    {repeat(
                      $archivedProjects,
                      (p) => p.id,
                      ($project) => {
                        return <ProjectTile $project={$project} />;
                      },
                    )}
                  </ul>

                  <div class={styles.hideButtonGroup}>
                    <Button
                      color={ButtonColor.Neutral}
                      style={ButtonStyle.Hollow}
                      onClick={() => {
                        setShowArchived((current) => !current);
                      }}
                    >
                      {t("workspace.nav.projects.hideArchivedButtonText")}
                    </Button>
                  </div>
                </section>,
                <Button
                  color={ButtonColor.Neutral}
                  style={ButtonStyle.Hollow}
                  onClick={() => {
                    setShowArchived((current) => !current);
                  }}
                >
                  {t("workspace.nav.projects.showArchivedButtonText")}
                </Button>,
              ),
            )}
          </section>
        </div>
      </div>
    </div>
  );
}

interface TasksSubsectionProps {
  name: string;
  $title: State<string>;
  $tasks: State<Task[]>;
  taskLimit?: number;
  taskMeta?: TaskMetaOptions;
}

export function TasksSubsection(props: TasksSubsectionProps, ctx: ViewContext) {
  const { $title, $tasks } = props;
  const taskLimit = props.taskLimit || 5;

  const [$showAll, setShowAll] = createState(false);

  // Store showAll status for duration of session to make it stick between navigation.
  ctx.beforeMount(() => {
    setShowAll(sessionStorage.getItem("ui.expanded:" + props.name) === "true");
    ctx.watch([$showAll], (showAll) => {
      sessionStorage.setItem("ui.expanded:" + props.name, JSON.stringify(showAll));
    });
  });

  return (
    <section class={styles.subsection}>
      <header>
        <h3>
          {$title} <span class={styles.taskCount}>{derive([$tasks], (tasks) => tasks.length)}</span>
        </h3>
      </header>

      <ul class={styles.taskList}>
        {repeat(
          derive([$tasks, $showAll], (tasks, showAll) => (showAll ? tasks : tasks.slice(0, taskLimit))),
          (t) => t.id,
          ($task) => (
            <TaskListItem $task={$task} meta={props.taskMeta} />
          ),
        )}
      </ul>

      {cond(
        derive([$tasks], (tasks) => tasks.length > taskLimit),
        <div class={styles.overflowButtonGroup}>
          <Button
            color={ButtonColor.Neutral}
            style={ButtonStyle.Hollow}
            onClick={(e) => {
              setShowAll((current) => !current);
              if ($showAll.get() === false) {
                // Scroll button back into view so we're still at the bottom of the list after collapsing.
                (e.currentTarget as HTMLButtonElement).scrollIntoView({
                  behavior: "instant",
                  block: "center",
                });
              }
            }}
          >
            {cond(
              $showAll,
              t("common.showLess"),
              t("common.showMoreCount", {
                count: derive([$tasks], (tasks) => tasks.length - taskLimit),
              }),
            )}
          </Button>
        </div>,
      )}
    </section>
  );
}

interface ProjectTileProps {
  $project: State<Project>;
}

function ProjectTile(props: ProjectTileProps, ctx: ViewContext) {
  const { $project } = props;

  const $theme = derive([$project], (p) => theme.getTheme$(p?.color));

  const $role = derive([$project, auth.$me], (project, me) => {
    if (!me) return null;

    if (project.ownerId === me.id) {
      return "owner";
    }

    const user = project.users.find((u) => u.id === me.id);
    if (!user) return null;

    return user.role;
  });

  return (
    <li style={$theme}>
      <a class={styles.projectLink} href={derive([$project], (p) => `/projects/${p.id}`)}>
        <div class={styles.projectLinkContent}>
          <div class={styles.projectTitleBlock}>
            <span class={styles.projectTitle}>{derive([$project], (p) => p.name)}</span>

            <span class={styles.projectRole}>
              {derive(
                [$role],
                when([
                  ["owner", t("workspace.project.roles.owner.title")],
                  ["admin", t("workspace.project.roles.admin.title")],
                  ["collaborator", t("workspace.project.roles.collaborator.title")],
                  ["viewer", t("workspace.project.roles.viewer.title")],
                ]),
              )}
            </span>
          </div>

          <ul class={styles.projectMeta}>
            <li
              title={t("workspace.nav.projects.projectTile.meta.people", {
                count: derive([$project], (p) => p.users.length),
              })}
            >
              <div class={styles.projectMetaIcon}>
                <Icon name="Person" size={20} />
              </div>
              <span class={styles.projectMetaLabel}>{derive([$project], (p) => p.users.length)}</span>
            </li>
            <li
              title={t("workspace.nav.projects.projectTile.meta.tasks", {
                count: derive([$project], (p) => p.taskCount),
              })}
              class={{
                [styles.empty]: derive([$project], (p) => p.taskCount === 0),
              }}
            >
              <div class={styles.projectMetaIcon}>
                <Icon name="Event Available" size={20} />
              </div>
              <span class={styles.projectMetaLabel}>{derive([$project], (p) => p.taskCount)}</span>
            </li>
            <li
              title={t("workspace.nav.projects.projectTile.meta.notes", {
                count: derive([$project], (p) => p.noteCount),
              })}
              class={{
                [styles.empty]: derive([$project], (p) => p.noteCount === 0),
              }}
            >
              <div class={styles.projectMetaIcon}>
                <Icon name="Docs" size={20} />
              </div>
              <span class={styles.projectMetaLabel}>{derive([$project], (p) => p.noteCount)}</span>
            </li>

            <li
              title={t("workspace.nav.projects.projectTile.meta.files", {
                count: derive([$project], (p) => p.fileCount),
              })}
              class={{
                [styles.empty]: derive([$project], (p) => p.fileCount === 0),
              }}
            >
              <div class={styles.projectMetaIcon}>
                <Icon name="Attach File" size={20} />
              </div>
              <span class={styles.projectMetaLabel}>{derive([$project], (p) => p.fileCount)}</span>
            </li>

            {/* <li
              title={t("workspace.nav.projects.projectTile.meta.maps", {
                count: derive([$project], (p) => p.mapCount),
              })}
              class={{
                [styles.empty]: derive([$project], (p) => p.mapCount === 0),
              }}
            >
              <div class={styles.projectMetaIcon}>
                <Icon name="Polyline" size={20} />
              </div>
              <span class={styles.projectMetaLabel}>{derive([$project], (p) => p.mapCount)}</span>
            </li> */}
          </ul>
        </div>
      </a>
    </li>
  );
}

interface ProjectInviteTileProps {
  $invite: State<ProjectInvite>;
}

function ProjectInviteTile(props: ProjectInviteTileProps, ctx: ViewContext) {
  const { $invite } = props;

  const $theme = derive([$invite], (i) => theme.getTheme$(i.project.color));

  return (
    <li style={$theme}>
      <div class={[styles.projectInvite]}>
        <div class={styles.projectTitleBlock}>
          <span class={styles.projectTitle}>{derive([$invite], (i) => i.project.name)}</span>

          <span class={styles.projectRole}>
            {derive(
              [derive([$invite], (i) => i.role)],
              when([
                ["owner", t("workspace.project.roles.owner.title")],
                ["admin", t("workspace.project.roles.admin.title")],
                ["collaborator", t("workspace.project.roles.collaborator.title")],
                ["viewer", t("workspace.project.roles.viewer.title")],
              ]),
            )}
          </span>
        </div>

        <ul class={styles.projectMeta}>
          <li>
            <span
              class={styles.projectInviteSender}
              style={theme.getTheme$(derive([$invite], (i) => i.sender.color))}
            >
              <span class={styles.projectInviteSenderDot} />
              {derive([$invite], (i) => i.sender.name)}
            </span>
          </li>

          {/* <li
            title={t("workspace.nav.projects.projectTile.meta.people", {
              count: derive([$invite], (i) => i.project.users.length + 1),
            })}
          >
            <div class={styles.projectMetaIcon}>
              <Icon name="Group" size={20} />
            </div>
            <span class={styles.projectMetaLabel}>{derive([$invite], (i) => i.project.users.length)}</span>
          </li> */}
          <li
            title={t("workspace.nav.projects.projectTile.meta.tasks", {
              count: derive([$invite], (i) => i.project.taskCount),
            })}
            class={{
              [styles.empty]: derive([$invite], (i) => i.project.taskCount === 0),
            }}
          >
            <div class={styles.projectMetaIcon}>
              <Icon name="Event Available" size={20} />
            </div>
            <span class={styles.projectMetaLabel}>{derive([$invite], (i) => i.project.taskCount)}</span>
          </li>
          <li
            title={t("workspace.nav.projects.projectTile.meta.notes", {
              count: derive([$invite], (i) => i.project.noteCount),
            })}
            class={{
              [styles.empty]: derive([$invite], (i) => i.project.noteCount === 0),
            }}
          >
            <div class={styles.projectMetaIcon}>
              <Icon name="Docs" size={20} />
            </div>
            <span class={styles.projectMetaLabel}>{derive([$invite], (i) => i.project.noteCount)}</span>
          </li>

          <li
            title={t("workspace.nav.projects.projectTile.meta.files", {
              count: derive([$invite], (i) => i.project.fileCount),
            })}
            class={{
              [styles.empty]: derive([$invite], (i) => i.project.fileCount === 0),
            }}
          >
            <div class={styles.projectMetaIcon}>
              <Icon name="Attach File" size={20} />
            </div>
            <span class={styles.projectMetaLabel}>{derive([$invite], (i) => i.project.fileCount)}</span>
          </li>
        </ul>

        <div class={styles.projectInviteActions}>
          <Button
            // color={ButtonColor.Danger}
            style={ButtonStyle.Hollow}
            onClick={() => {
              dialog.show(DeclineProjectInviteDialog, {
                onConfirm: () => {
                  const invite = $invite.get();
                  projects.declineInvite(invite.id);
                },
              });
            }}
          >
            {t("workspace.nav.projectInvites.declineButtonText")}
          </Button>

          <Button
            onClick={() => {
              const invite = $invite.get();
              projects.acceptInvite(invite.id);
            }}
          >
            {t("workspace.nav.projectInvites.acceptButtonText")}
          </Button>
        </div>
      </div>
    </li>
  );
}
