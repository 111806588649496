import Dolla, { createState } from "@manyducks.co/dolla";

const debug = Dolla.createLogger("📦 stores/badge");

/**
 * Manages the app badge (unread count) for mobile home screen apps.
 */

const [$number, setNumber] = createState(0);

Dolla.watch([$number], (value) => {
  (navigator as any).setAppBadge?.(value);
  (navigator as any).setClientBadge?.(value);

  debug.info(`Set app badge number to ${value}`);
});

export function increment() {
  setNumber((n) => n + 1);
}

export function set(value: number) {
  setNumber(value);
}

export function clear() {
  setNumber(0);
}
