import Dolla, { derive, t, type State, type ViewContext } from "@manyducks.co/dolla";
import type { Project } from "schemas";
import { Icon } from "MaterialSymbols";
import styles from "./InviteListItem.module.css";

interface InviteListItemProps {
  $invite: State<Project["invites"][0]>;
}

export function InviteListItem(props: InviteListItemProps, ctx: ViewContext) {
  const { $invite } = props;

  return (
    <li class={styles.listItem}>
      <div class={styles.avatar} />
      <div class={styles.main}>
        <span class={styles.email}>{derive([$invite], (i) => i.email)}</span>
        <ul class={styles.meta}>
          <li>
            <span class={styles.metaLabel}>
              {derive([$invite], (i) => {
                switch (i.role) {
                  case "admin":
                    return t("workspace.project.roles.admin.title");
                  case "collaborator":
                    return t("workspace.project.roles.collaborator.title");
                  case "viewer":
                    return t("workspace.project.roles.viewer.title");
                }
              })}
            </span>
          </li>
          <li>
            <div class={styles.metaIcon}>
              <Icon name="Hourglass Empty" size={20} />
            </div>
            <span class={styles.metaLabel}>
              {Dolla.i18n.dateTime(
                derive([$invite], (i) => i.createdAt),
                { dateStyle: "short" },
              )}
            </span>
          </li>
        </ul>
      </div>
    </li>
  );
}
