import Dolla, { setDevDebug } from "@manyducks.co/dolla";

import "@styles/photoswipe.css";
import "./quill/quill.css";

import { enableMapSet } from "immer";

enableMapSet();

import Quill from "quill";
import QuillCursors from "quill-cursors";
import { QuackLinkBlot } from "quill/QuackLinkBlot";
import { BetterClipboard } from "quill/BetterClipboard";
import { ClickableLink } from "quill/ClickableLink";
import { MagicUrl } from "./quill/MagicUrl";

import MaterialSymbols, { MaterialSymbolsStyle } from "./MaterialSymbols";

import registerServiceWorker from "./registerServiceWorker";

import { dialog, loader, nav, tasks } from "./@stores";

import { Join } from "Join";
import { Login } from "Login";

import {
  Admin,
  AdminPeople,
  AdminProjects,
  AdminServerStats,
  Home,
  Project,
  ProjectCalendar,
  ProjectFiles,
  ProjectNotes,
  ProjectOverview,
  Settings,
  Workspace,
} from "Workspace";

import { TaskEditDialog } from "@dialogs/TaskEditDialog/TaskEditDialog";
import { PlainDate } from "@helpers/PlainDate";

Quill.register("modules/clipboard", BetterClipboard, true);
Quill.register("modules/magicUrl", MagicUrl);
Quill.register("modules/cursors", QuillCursors);
Quill.register(`blots/${QuackLinkBlot.blotName}`, QuackLinkBlot);
Quill.register("formats/link", ClickableLink, true);

MaterialSymbols.setup(Dolla, {
  style: MaterialSymbolsStyle.Sharp,
  settings: {
    fill: false,
    weight: 300,
    grade: 0,
    opticalSize: 24,
  },
  variationRanges: {
    fill: [0, 1],
    weight: [300],
    grade: [0, 100],
    opticalSize: [20, 32],
  },
  icons: [
    "Add",
    "Archive",
    "Arrow Back",
    "Arrow Downward Alt",
    "Arrow Drop Down",
    "Arrow Left Alt",
    "Arrow Left",
    "Arrow Right Alt",
    "Arrow Upward Alt",
    "Attach File Add",
    "Attach File",
    "Calendar Add On",
    "Check",
    "Chevron Left",
    "Chevron Right",
    "Close",
    "Code",
    "Collections Bookmark",
    "Colorize",
    "Dark Mode",
    "Delete Forever",
    "Delete",
    "Description",
    "Docs",
    "Download",
    "Edit Note",
    "Event Available",
    "Event",
    "Format Bold",
    "Format H1",
    "Format H2",
    "Format H3",
    "Format Indent Decrease",
    "Format Indent Increase",
    "Format Italic",
    "Format List Bulleted",
    "Format List Numbered",
    "Format Quote",
    "Format Strikethrough",
    "Forum",
    "Forward To Inbox",
    "Grid On",
    "History",
    "Home",
    "Hourglass Empty",
    "Info",
    "Keep",
    "Keyboard",
    "Light Mode",
    "Link",
    "Logout",
    "Mail",
    "More Horiz",
    "Note Add",
    "Person Add",
    "Person",
    "Right Panel Close",
    "Right Panel Open",
    "Schedule",
    "Search Activity",
    "Search",
    "Settings",
    "Sort",
    "Stylus Note",
    "Unarchive",
    "Update",
    "Upgrade",
    "Upload",
  ],
});

const IS_DEVELOPMENT = process.env.NODE_ENV === "development";

Dolla.setEnv(IS_DEVELOPMENT ? "development" : "production");
Dolla.setLogFilter(IS_DEVELOPMENT ? "*" : "-*");

// Print additional debugging info in dev mode.
setDevDebug(IS_DEVELOPMENT);

Dolla.i18n.setup({
  locale: localStorage.getItem("quack-current-language") ?? "auto",
  translations: [
    { locale: "en", path: "/locales/en.json" },
    { locale: "ja", path: "/locales/ja.json" },
    { locale: "tok", path: "/locales/tok.json" },
  ],
});

// Store the language setting in localStorage.
Dolla.watch([Dolla.i18n.$locale], (value) => {
  if (value != undefined) {
    localStorage.setItem("quack-current-language", value);
  }
});

Dolla.router.setup({
  routes: [
    {
      path: "/login",
      view: Login,
      beforeMatch: () => {
        loader.hideAppLoader();
      },
    },
    {
      path: "/join/*",
      view: Join,
      beforeMatch: () => {
        // Show app loader. Join view will hide it when data is loaded.
        loader.showAppLoader();
      },
    },
    {
      path: "/",
      view: Workspace,
      beforeMatch: () => {
        // Show app loader. Workspace view will hide it when data is loaded.
        loader.showAppLoader();
      },
      routes: [
        {
          path: "/",
          view: Home,
        },
        {
          path: "/settings",
          view: Settings,
        },
        {
          path: "/admin",
          view: Admin,
          routes: [
            { path: "/server-stats", view: AdminServerStats },
            { path: "/people", view: AdminPeople },
            { path: "/projects", view: AdminProjects },
            { path: "*", redirect: "./server-stats" },
          ],
        },
        {
          path: "/projects/{#projectId}",
          view: Project,
          routes: [
            {
              path: "/",
              view: ProjectOverview,
            },
            {
              path: "/notes",
              view: ProjectNotes,
              routes: [
                { path: "/", view: () => <h1>No Note</h1> },
                { path: "/{#noteId}", view: () => <h1>Note Details</h1> },
                { path: "*", redirect: "./" },
              ],
            },
            {
              path: "/calendar",
              view: ProjectCalendar,
              routes: [
                {
                  path: "/{#year}/{#month}",
                  routes: [
                    { path: "/{#day}", view: () => <h1>To Do</h1> },
                    { path: "/", view: () => <h1>To Do</h1> },
                    { path: "*", redirect: "./" },
                  ],
                },
                {
                  path: "*",
                  redirect: () => {
                    // Redirect any other path to the current month's calendar.
                    const now = PlainDate.now();
                    return `./${now.year}/${now.month}`;
                  },
                },
              ],
            },
            {
              path: "/files",
              view: ProjectFiles,
              routes: [
                { path: "/", view: () => <h1>To Do</h1> },
                { path: "*", redirect: "./" },
              ],
            },
            {
              path: "*",
              redirect: "./",
            },
          ],
        },
        {
          path: "/p/{linkingId}",
          view: (_, ctx) => {
            ctx.setName("permalink redirect");

            ctx.beforeMount(async () => {
              const params = Dolla.router.$params.get();
              const linkingId = params.linkingId as string;
              const data = await nav.getPermalinkData(linkingId);
              ctx.log(data);

              if (data) {
                switch (data.type) {
                  case "note":
                    Dolla.router.go(`/projects/${data.projectId}/notes/${data.id}`);
                    break;
                  case "task":
                    const task = await tasks.ensureTaskIsLoaded(data.id);
                    if (task) {
                      Dolla.router.go("/");
                      dialog.show(TaskEditDialog, { task });
                    } else {
                      // TODO: Open dialog.
                      alert("Task not found!");
                    }

                    break;
                  default:
                    Dolla.router.go("/");
                }
              }
            });

            return <h1>Linking...</h1>;
          },
        },
        { path: "*", redirect: "./" },
      ],
    },
    { path: "*", redirect: "/login" },
  ],
});

/*=========================*\
||   Configure & Connect   ||
\*=========================*/

registerServiceWorker();

Dolla.mount("#app");

// Prevent drag and drops on window redirecting the page to that file.
window.addEventListener(
  "dragover",
  (e) => {
    e.preventDefault();
  },
  false,
);
window.addEventListener(
  "drop",
  (e) => {
    e.preventDefault();
  },
  false,
);
