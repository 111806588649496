import { PlainDate } from "@helpers/PlainDate";
import { toSettableState, createState, derive, t, type ViewContext } from "@manyducks.co/dolla";
import { Button } from "@views/Button";
import { DatePicker } from "@views/DatePicker";
import { Dialog, DialogContent, DialogFooter, DialogHeader } from "@views/Dialog";
import { Select } from "@views/Select";
import { TextInput } from "@views/TextInput";
import { sortBy } from "lodash-es";
import { Project } from "schemas";
import styles from "./CreateTaskDialog.module.css";

import { projects } from "@stores";
import type { DialogProps } from "@stores/dialog";

interface CreateTaskInfo {
  title: string;
  projectId: number;
  dueDate: string;
}

interface CreateTaskDialogProps extends DialogProps {
  /**
   * Called when confirmation is successful
   */
  onConfirm: (info: CreateTaskInfo, openAfterSubmit: boolean) => Promise<void>;
}

/**
 * A dialog to create tasks from anywhere.
 */
export function CreateTaskDialog(props: CreateTaskDialogProps, ctx: ViewContext) {
  const [$project, setProject] = createState<Project>();
  const [$dueDate, setDueDate] = createState<string>(PlainDate.now().toString());
  const [$title, setTitle] = createState("");

  // Select the most recently selected project by default.
  const initialProjectId = Number(localStorage.getItem("addTask.lastProjectId") ?? "-1");
  if (initialProjectId > -1) {
    const project = projects.$cache.get().get(initialProjectId);
    if (project) {
      setProject(project);
    }
  }

  const $color = derive([$project], (p) => p?.color ?? "#888");

  let openAfterSubmit = false;

  const $canSubmit = derive([$project, $dueDate, $title], (project, dueDate, title) => {
    return project != null && dueDate != null && title.length > 0;
  });

  const [$isWaiting, setIsWaiting] = createState(false);

  return (
    <Dialog
      accentColor={$color}
      onsubmit={async () => {
        setIsWaiting(true);

        if (!$canSubmit.get()) {
          return;
        }

        props
          .onConfirm(
            {
              projectId: $project.get()!.id,
              dueDate: $dueDate.get()!,
              title: $title.get(),
            },
            openAfterSubmit,
          )
          .then(() => {
            props.dialog.close();
          })
          .finally(() => {
            setIsWaiting(false);
          });
      }}
      transitionIn={props.dialog.transitionIn}
      transitionOut={props.dialog.transitionOut}
    >
      <DialogHeader dialog={props.dialog} $title={t("workspace.nav.tasks.createTaskDialog.title")} />

      <DialogContent>
        <div class={styles.content}>
          <div class={styles.formGroup}>
            <label for="taskName">{t("workspace.nav.tasks.createTaskDialog.titleLabel")}</label>
            <TextInput
              id="taskName"
              $$value={toSettableState($title, setTitle)}
              placeholder={t("workspace.nav.tasks.createTaskDialog.titleInputPlaceholder")}
            />
          </div>

          <div class={styles.formGroup}>
            <label for="taskProject">{t("workspace.nav.tasks.createTaskDialog.projectLabel")}</label>
            <Select
              value={derive([$project], (p) => p?.id)}
              options={sortBy(
                [...projects.$cache.get().values()].map((p) => ({ label: p.name, value: p.id })),
                [(o) => o.label.toLowerCase()],
              )}
              onChange={(projectId) => {
                if (projectId) {
                  const project = projects.$cache.get().get(projectId);
                  setProject(project);
                  localStorage.setItem("addTask.lastProjectId", String(projectId));
                } else {
                  setProject(undefined);
                  localStorage.removeItem("addTask.lastProjectId");
                }
              }}
            />
          </div>

          <div class={styles.formGroup}>
            <label for="taskDueDate">{t("workspace.nav.tasks.createTaskDialog.dueDateLabel")}</label>
            <DatePicker
              $value={$dueDate}
              accentColor={$color}
              onChange={(value) => {
                if (value) {
                  setDueDate(value);
                }
              }}
            />
          </div>
        </div>
      </DialogContent>

      <DialogFooter>
        <Button
          type="submit"
          disabled={derive([$canSubmit], (x) => !x)}
          onClick={() => {
            openAfterSubmit = true;
          }}
        >
          {t("workspace.nav.tasks.createTaskDialog.submitAndOpenButtonLabel")}
        </Button>
        <Button type="submit" disabled={derive([$canSubmit], (x) => !x)}>
          {t("workspace.nav.tasks.createTaskDialog.submitButtonLabel")}
        </Button>
      </DialogFooter>
    </Dialog>
  );
}
