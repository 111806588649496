import { Synchronizer } from "@helpers/Synchronizer";
import Dolla, { cond, createState, derive, type State, type ViewContext } from "@manyducks.co/dolla";
import { DuckLoader, makeLoaderState } from "@views/DuckLoader";
import { produce } from "immer";
import { Note, Project } from "schemas";
import { auth, files, io, notes, projects } from "@stores";
import { InputFile } from "Workspace/Chat/ChatEditor/ChatEditor";
import { TextEditor } from "Workspace/Project/ProjectNotes/Details/TextEditor";
import { Awareness } from "y-protocols/awareness";
import * as Y from "yjs";
import styles from "./Details.module.css";

const { uploadFile } = files;

interface DetailsProps {
  $project: State<Project | undefined>;
  $note: State<Note | undefined>;
}

export function Details(props: DetailsProps, ctx: ViewContext) {
  ctx.setName("ProjectNotes/Details");

  const $projectId = derive([props.$project], (n) => n?.id);

  const [$fileUploads, setFileUploads] = createState<InputFile[]>([]);

  const $userRole = derive([$projectId, projects.$cache, auth.$me], (projectId, projectsCache, me) => {
    const project = projectsCache.get(projectId!);
    if (!project || !me || project.archivedAt != null) return "viewer";
    const user = project.users.find((u) => u.id === me.id);
    return user?.role ?? "viewer";
  });

  const $isViewer = derive([$userRole], (role) => role === "viewer");

  function addFiles(files: FileList | File[]) {
    for (const file of Array.from(files)) {
      const events = uploadFile(file);
      let uploadId: string;

      events.on("started", (e) => {
        ctx.log("upload started", e);
        uploadId = e.data.uploadId;
        const inputFile: InputFile = {
          file,
          uploadId,
          progress: 0,
          complete: false,
          events,
        };
        setFileUploads(
          produce((current) => {
            current.push(inputFile);
          }),
        );
      });
      events.on("progress", (e) => {
        ctx.log("upload progress", e);
        setFileUploads(
          produce((current) => {
            const found = current.find((f) => f.uploadId === uploadId);
            if (found) {
              found.progress = e.data.percent;
            }
          }),
        );
      });
      events.on("error", (e) => {
        ctx.log("upload error", e);
        setFileUploads(
          produce((current) => {
            const found = current.find((f) => f.uploadId === uploadId);
            if (found) {
              found.error = e.data.error;
            }
          }),
        );
      });
      events.on("complete", (e) => {
        ctx.log("upload complete", e);

        const note = props.$note.get()!;
        Dolla.http
          .post(`/api/notes/${note.id}/attachments`, { body: { uuids: [uploadId] } })
          .then((res) => {
            ctx.info("Attachment added", res);
            setFileUploads(
              produce((current) => {
                return current.filter((f) => f.uploadId !== uploadId);
              }),
            );
          })
          .catch((err) => {
            ctx.error(err);
          });
      });
    }
  }

  const loader = makeLoaderState(false);

  // Stores the current connection info for the selected doc.
  const [$noteConnectionDetails, setNoteConnectionDetails] = createState<{
    id: number;
    ydoc: Y.Doc;
    awareness: Awareness;
    sync: Synchronizer;
  } | null>(null, { equals: (a, b) => a?.id === b?.id });

  const $noteId = derive([props.$note], (n) => n?.id);

  ctx.watch([$noteId, $noteConnectionDetails], (noteId, connection) => {
    if (noteId == null && connection != null) {
      // Clean up existing connection before opening a new one.
      connection.ydoc.off("update", updateLocalData);
      connection.sync.disconnect();

      setNoteConnectionDetails(null);
    }
  });

  // Keep synchronizer edit mode setting up to date with card state
  ctx.watch([$noteConnectionDetails, $userRole], (details, role) => {
    if (details) {
      details.sync.setEditMode(role === "viewer" ? false : true);
    }
  });

  // Keep awareness user up to date with user settings
  ctx.watch([$noteConnectionDetails, auth.$me], (details, me) => {
    if (details && me) {
      details.awareness.setLocalStateField("user", {
        name: me.name,
        color: me.color,
      });
    }
  });

  // Update local doc metadata when the document changes.
  function updateLocalData() {
    const details = $noteConnectionDetails.get();
    if (details) {
      notes.updatePage(details.id, details.ydoc.getText("content"));
    }
  }

  // Configure the document and socket connection before opening details view.
  async function prepareNote(id: number) {
    ctx.info("preparing note " + id);

    const { exists } = await notes.ensureNoteIsLoaded(id);

    if (!exists) {
      ctx.error("Note not found");
      return Dolla.router.go(`/projects/${$projectId.get()}/notes`);
    }

    const currentDetails = $noteConnectionDetails.get();

    if (currentDetails) {
      if (currentDetails.id === id) {
        return; // We are already connected
      } else {
        // Clean up existing connection before opening a new one.
        currentDetails.ydoc.off("update", updateLocalData);
        currentDetails.sync.disconnect();
      }
    }

    const ydoc = new Y.Doc();
    const awareness = new Awareness(ydoc);
    const socket = io.socket(`/notes/${id}`);
    const sync = new Synchronizer(socket, ydoc, awareness);

    await sync.connect();

    ydoc.on("update", updateLocalData);

    setNoteConnectionDetails({ id, ydoc, awareness, sync });
  }

  ctx.watch([$noteId, $noteConnectionDetails], async (noteId, details) => {
    if (noteId != null && noteId != details?.id) {
      ctx.info("NOTE MISMATCH; PREPARING", noteId);
      await loader.show();
      await prepareNote(noteId);
      await loader.hide();
    }
  });

  return cond(
    props.$note,
    <div class={styles.container}>
      <div class={styles.content}>
        {derive([$noteConnectionDetails], (details) => {
          ctx.info("text editor re-rendered", details);

          if (details) {
            return (
              <TextEditor
                $note={props.$note as State<Note>}
                addFiles={addFiles}
                content={details.ydoc.getText("content")}
                accentColor={derive([props.$project], (p) => p?.color ?? "#888")}
                awareness={details.awareness}
                readOnly={$isViewer}
                scrollable
              />
            );
          }
        })}
      </div>

      <DuckLoader state={loader} />
    </div>,
    <div class={styles.emptyPlaceholder}>Select a note.</div>,
  );
}
