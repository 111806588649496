import Dolla, { createState } from "@manyducks.co/dolla";
import { produce } from "immer";
import type { Favorite, File, PermalinkResponse } from "schemas";

interface Recent {
  path: string;
  timestamp: string;
}

type PermalinkData = Exclude<PermalinkResponse["data"], undefined>;

const debug = Dolla.createLogger("📦 stores/nav");

const permalinkCache = new Map<string, PermalinkData>();

/*=================================*\
|*            Permalink            *|
\*=================================*/

function _extractLinkingId(url: string) {
  const parsed = URL.parse(url);
  if (parsed == null) {
    throw new Error(`URL could not be parsed: ${url}`);
  }
  const matches = parsed.pathname.match(/\/p\/(.+)/);
  if (matches == null) {
    throw new Error(`Linking ID could not be found in URL: ${url}`);
  }
  debug.log(matches);
  return matches[1];
}

export async function getPermalinkData(urlOrLinkingId: string) {
  let linkingId: string;

  if (urlOrLinkingId.startsWith("task_") || urlOrLinkingId.startsWith("note_")) {
    linkingId = urlOrLinkingId;
  } else {
    linkingId = _extractLinkingId(urlOrLinkingId);
  }

  if (!permalinkCache.has(linkingId)) {
    const res = await Dolla.http.get<PermalinkResponse>(`/api/permalink/${linkingId}`);
    if (res.status == 200) {
      permalinkCache.set(linkingId, res.body.data!);
    } else {
      // TODO: Handle error
    }
  }

  return permalinkCache.get(linkingId)!;
}

// async function navigateToPermalink(url: string) {
//   const data = await getPermalinkData(url);
//   switch (data.type) {
//     case "task":
//       await tasks.ensureTaskIsLoaded(data.id);
//       const task = tasks.$cache.get().get(data.id)!;
//       dialog.open(TaskEditDialog, {
//         projectId: data.projectId!,
//         task,
//       });
//       break;
//     case "note":
//       router.navigate(`/projects/${data.projectId!}/notes/${data.id}`);
//       break;
//   }
// }

/*=================================*\
|*             Recents             *|
\*=================================*/

const [$recents, setRecents] = createState<Recent[]>([]);

export { $recents };

Dolla.onMount(() => {
  _loadRecents();
});

export function addRecent(path: string) {
  debug.log(path);

  setRecents(
    produce((recents) => {
      const matchIndex = recents.findIndex((r) => r.path === path);
      if (matchIndex >= 0) {
        recents.splice(matchIndex, 1);
      }
      recents.unshift({ path, timestamp: new Date().toISOString() });

      // Keep only the 10 most recent items.
      recents.splice(10, Infinity);
    }),
  );

  _saveRecents();
}

function _loadRecents() {
  const recents = JSON.parse(localStorage.getItem("nav.recentItems") ?? "[]");
  setRecents(recents);
}

function _saveRecents() {
  const json = JSON.stringify($recents.get());
  localStorage.setItem("nav.recentItems", json);
}

/*=================================*\
|*            Favorites            *|
\*=================================*/

const [$favorites, setFavorites] = createState<Favorite[]>([]);

export { $favorites };

export async function fetchFavorites() {
  const res = await Dolla.http.get<Favorite[]>("/api/favorites");
  setFavorites(res.body);
}

export async function addFavorite(path: string) {
  let highestOrder = 0;
  for (const favorite of $favorites.get()) {
    if (favorite.order > highestOrder) {
      highestOrder = favorite.order;
    }
  }
  const res = await Dolla.http.post<Favorite>("/api/favorites", { body: { path } });
  setFavorites((current) => [...current, res.body]);
}

export async function removeFavorite(id: number) {
  setFavorites((current) => current.filter((f) => f.id !== id));

  await Dolla.http.delete(`/api/favorites/${id}`);
}

export async function reorderFavorites(favorites: Favorite[]) {
  // TODO: Implement reordering
  localStorage.setItem("favorites", JSON.stringify($favorites.get()));
}

/*=================================*\
|*           File Viewer           *|
\*=================================*/

const [$fileViewerIsOpen, setFileViewerIsOpen] = createState(false);
const [$fileViewerContent, setFileViewerContent] = createState<File>();

export { $fileViewerContent, $fileViewerIsOpen };

export function openFileViewer(file: File) {
  setFileViewerContent(file);
  setFileViewerIsOpen(true);
}

export function closeFileViewer() {
  setFileViewerIsOpen(false);
}

/*=================================*\
|*               Chat              *|
\*=================================*/

const [$chatIsOpen, setChatIsOpen] = createState(localStorage.getItem("chat.isOpen") === "true");

export { $chatIsOpen };

export function openChat() {
  setChatIsOpen(true);
}

export function closeChat() {
  setChatIsOpen(false);
}

export function toggleChat() {
  setChatIsOpen((current) => !current);
}

Dolla.watch([$chatIsOpen], (value) => {
  localStorage.setItem("chat.isOpen", JSON.stringify(value));
});
