import { Icon } from "MaterialSymbols";
import {
  t,
  cond,
  createRef,
  createSettableState,
  derive,
  repeat,
  type SettableState,
  type State,
  type ViewContext,
} from "@manyducks.co/dolla";
import { HoverMenu } from "@views/HoverMenu";
import { TextInput } from "@views/TextInput";
import styles from "./TagFilter.module.css";

interface TagFilterProps {
  $$tags: SettableState<string[]>;
  $color: State<string>;
  $suggestions: State<Record<string, number>>;
}

export function TagFilter(props: TagFilterProps, ctx: ViewContext) {
  const $$tags = props.$$tags;
  const $$inputValue = createSettableState("");

  const $$menuIsOpen = createSettableState(false);

  const menuAnchor = createRef<HTMLElement>();
  const textInput = createRef<HTMLInputElement>();

  ctx.watch([$$menuIsOpen], (menuIsOpen) => {
    if (menuIsOpen) {
      textInput.node?.focus();
    }
  });

  function addTag(tag: string) {
    $$tags.set((tags) => [...tags, tag].sort(alphabeticalCaseInsensitive((t) => t)));
  }

  function removeTag(tag: string) {
    $$tags.set((tags) => tags.filter((t) => t !== tag));
  }

  return (
    <div
      class={[styles.container, { [styles.open]: $$menuIsOpen }]}
      onClick={(e) => {
        menuAnchor.node = e.currentTarget as HTMLElement;
        $$menuIsOpen.set(true);
      }}
    >
      <ul class={styles.tagList}>
        {cond(
          derive([$$tags], (tags) => tags.length === 0),
          <span class={styles.emptyListPlaceholder}>{t("views.tagFilter.emptyListPlaceholder")}</span>,
        )}

        {repeat(
          $$tags,
          (t) => t,
          ($tag) => {
            return (
              <li class={styles.tag}>
                <span class={styles.tagName}>{$tag}</span>
                <button
                  class={styles.tagRemoveButton}
                  onclick={(e) => {
                    e.stopPropagation();
                    removeTag($tag.get());
                  }}
                >
                  <Icon name="Close" size={20} />
                </button>
              </li>
            );
          },
        )}
      </ul>

      <div class={styles.indicatorIcon}>
        <Icon name="Arrow Drop Down" />
      </div>

      <HoverMenu
        $$open={$$menuIsOpen}
        anchorRef={menuAnchor}
        color={props.$color}
        preferHorizontalAlignment="center"
        preferVerticalAlignment="below"
        distanceFromAnchor={16}
      >
        <div class={styles.menuLayout}>
          <div class={styles.menuInput}>
            <TextInput
              $$value={$$inputValue}
              placeholder={t("views.tagFilter.tagSearchPlaceholder")}
              ref={textInput}
            />
          </div>

          <ul class={styles.menuTagList}>
            {repeat(
              derive([$$tags, props.$suggestions, $$inputValue], (tags, suggestions, term) =>
                Object.entries(suggestions)
                  .map((entry) => ({ tag: entry[0], count: entry[1] }))
                  .filter((obj) => !tags.includes(obj.tag) && obj.tag.startsWith(term.toLocaleLowerCase()))
                  .sort(alphabeticalCaseInsensitive((obj) => obj.tag)),
              ),
              (s) => s.tag,
              ($suggestion) => {
                return (
                  <li>
                    <button
                      class={styles.menuTagButton}
                      onClick={() => {
                        addTag($suggestion.get().tag);
                      }}
                    >
                      <span class={styles.menuTagLabel}>{derive([$suggestion], (s) => s.tag)}</span>
                      <span class={styles.menuTagCount}>{derive([$suggestion], (s) => s.count)}</span>
                    </button>
                  </li>
                );
              },
            )}
          </ul>
        </div>
      </HoverMenu>
    </div>
  );
}

function alphabeticalCaseInsensitive<T>(map: (value: T) => string) {
  return (a: T, b: T) => {
    const aChar = map(a)[0].toLocaleLowerCase();
    const bChar = map(b)[0].toLocaleLowerCase();

    if (aChar < bChar) {
      return -1;
    } else if (aChar > bChar) {
      return 1;
    } else {
      return 0;
    }
  };
}
