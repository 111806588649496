import { derive, t, type ViewContext } from "@manyducks.co/dolla";
import scrollStyles from "@styles/ScrollBar.module.css";
import { Dialog, DialogContent, DialogHeader } from "@views/Dialog";
import { Settings } from "Workspace/Settings/Settings";
import styles from "./Settings.module.css";

import { auth } from "@stores";
import type { DialogProps } from "@stores/dialog";

interface SettingsDialogProps extends DialogProps {}

export function SettingsDialog(props: SettingsDialogProps, ctx: ViewContext) {
  return (
    <Dialog
      transitionIn={props.dialog.transitionIn}
      transitionOut={props.dialog.transitionOut}
      onsubmit={() => {
        // props.dialog.$$open.set(false);
      }}
      accentColor={derive([auth.$me], (m) => m?.color ?? "#888")}
    >
      <DialogHeader dialog={props.dialog} $title={t("workspace.settings.title")} />

      <DialogContent>
        <div class={[styles.content, scrollStyles.scrollable]}>
          <Settings />
        </div>
      </DialogContent>
    </Dialog>
  );
}
