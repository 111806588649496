import { PlainDate } from "@helpers/PlainDate";
import { toSettableState, createState, derive, toState, t, type ViewContext } from "@manyducks.co/dolla";
import { Button } from "@views/Button";
import { DatePicker } from "@views/DatePicker";
import { Dialog, DialogContent, DialogFooter, DialogHeader } from "@views/Dialog";
import { TextInput } from "@views/TextInput";
import { UserPicker } from "@views/UserPicker";
import styles from "./NewTaskDialog.module.css";

import { auth, projects } from "@stores";
import { DialogProps } from "@stores/dialog";

interface NewTaskInfo {
  title: string;
  assignedUserId: number;
  dueDate: string;
}

interface NewTaskDialogProps extends DialogProps {
  projectId: number;

  /**
   * Called when confirmation is successful
   */
  onConfirm: (info: NewTaskInfo, openAfterSubmit: boolean) => Promise<void>;
}

/**
 * A dialog to create tasks from anywhere.
 */
export function NewTaskDialog(props: NewTaskDialogProps, ctx: ViewContext) {
  const $project = derive([projects.$cache, props.projectId], (projects, id) => projects.get(id)!);
  const [$assignedUserId, setAssignedUserId] = createState<number>(auth.$me.get()!.id);
  const [$dueDate, setDueDate] = createState<string>(PlainDate.now().toString());
  const [$title, setTitle] = createState("");

  const $color = derive([$project], (p) => p?.color ?? "#888");

  let openAfterSubmit = false;

  const $canSubmit = derive([$project, $dueDate, $title], (project, dueDate, title) => {
    return project != null && dueDate != null && title.length > 0;
  });

  const [$isWaiting, setIsWaiting] = createState(false);

  return (
    <Dialog
      accentColor={$color}
      onsubmit={async () => {
        setIsWaiting(true);

        if (!$canSubmit.get()) {
          return;
        }

        props
          .onConfirm(
            {
              assignedUserId: $assignedUserId.get(),
              dueDate: $dueDate.get()!,
              title: $title.get(),
            },
            openAfterSubmit,
          )
          .then(() => {
            props.dialog.close();
          })
          .finally(() => {
            setIsWaiting(false);
          });
      }}
      transitionIn={props.dialog.transitionIn}
      transitionOut={props.dialog.transitionOut}
    >
      <DialogHeader
        dialog={props.dialog}
        $title={t("workspace.project.overview.tasks.createTaskDialog.title")}
      />

      <DialogContent>
        <div class={styles.content}>
          <div class={styles.formGroup}>
            <label for="taskName">{t("workspace.project.overview.tasks.createTaskDialog.titleLabel")}</label>
            <TextInput
              id="taskName"
              $$value={toSettableState($title, setTitle)}
              placeholder={t("workspace.nav.tasks.createTaskDialog.titleInputPlaceholder")}
            />
          </div>

          <div class={styles.formGroup}>
            <label for="taskAssignedUserId">
              {t("workspace.project.overview.tasks.createTaskDialog.assignedToLabel")}
            </label>
            <UserPicker
              $selectedId={derive([$assignedUserId], (id) => id ?? undefined)}
              $projectId={toState(props.projectId)}
              onchange={(newUserId) => {
                if (newUserId) {
                  setAssignedUserId(newUserId);
                }
              }}
            />
          </div>

          <div class={styles.formGroup}>
            <label for="taskDueDate">
              {t("workspace.project.overview.tasks.createTaskDialog.dueDateLabel")}
            </label>
            <DatePicker
              $value={$dueDate}
              accentColor={$color}
              onChange={(value) => {
                if (value) {
                  setDueDate(value);
                }
              }}
            />
          </div>
        </div>
      </DialogContent>

      <DialogFooter>
        <Button
          type="submit"
          disabled={derive([$canSubmit], (x) => !x)}
          onClick={() => {
            openAfterSubmit = true;
          }}
        >
          {t("workspace.project.overview.tasks.createTaskDialog.submitAndOpenButtonLabel")}
        </Button>
        <Button type="submit" disabled={derive([$canSubmit], (x) => !x)}>
          {t("workspace.project.overview.tasks.createTaskDialog.submitButtonLabel")}
        </Button>
      </DialogFooter>
    </Dialog>
  );
}
