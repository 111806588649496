import { EventEmitter } from "@borf/bedrock";
import Dolla from "@manyducks.co/dolla";
import type { FileUpload } from "schemas";

import * as auth from "./auth";

const debug = Dolla.createLogger("📦 stores/clock");

export type FileData = {
  id: number;
  type: string; // Probably an enum or set of known values
  description: string;
  name: string;
  uuid: string;
  bytes: number;
  createdAt: string;
  filePath: string;
  thumbPath: string;
};

type FileUploadResponse = {
  uuid: string;
};

export type UploadEvents = {
  started: { uploadId: string };
  progress: { percent: number };
  complete: { uploaded: FileUpload };
  error: { error: Error };

  /**
   * Return event that can be emitted to halt upload and clean up on the server.
   */
  "abort!": null;
};

const [$uploading, setUploading] = Dolla.createState(false);
const [$uploadProgress, setUploadProgress] = Dolla.createState(0);

export { $uploading, setUploading, $uploadProgress, setUploadProgress };

/**
 * Uploads a file, returning the new file ID.
 *
 * @param file - File object to upload
 * @param description - Description text
 */
export function uploadFile(file: File) {
  const events = new EventEmitter<UploadEvents>();

  const authToken = auth.$token.get();
  const mimeType = getMimeType(file);

  async function doUpload() {
    const uploadRes = await Dolla.http.post<FileUploadResponse>("/api/files/uploads");
    const minChunkSize = 1024 * 128; // 128KB
    const maxChunkSize = 1024 * 1024 * 50; // 50MB

    let uploadedChunks: { id: number; startBytes: number; endBytes: number }[] = [];
    let chunkSize = 1024 * 128; // 128KB starting size
    let aborted = false;

    function getNextId() {
      const lastChunk = uploadedChunks[uploadedChunks.length - 1];

      if (!lastChunk) {
        return 1;
      }

      return lastChunk.id + 1;
    }

    function getStartBytes() {
      const lastChunk = uploadedChunks[uploadedChunks.length - 1];

      if (!lastChunk) {
        return 0;
      }

      return lastChunk.endBytes;
    }

    async function sendChunk(id: number, startBytes: number, endBytes: number): Promise<void> {
      endBytes = Math.min(endBytes, file.size);
      const buffer = file.slice(startBytes, endBytes);

      const abortController = new AbortController();
      const abortTimeout = setTimeout(() => {
        abortController.abort();
      }, 20000);

      const off = events.on("abort!", () => {
        abortController.abort();
        clearTimeout(abortTimeout);
      });

      try {
        await fetch(`/api/files/uploads/${uploadRes.body.uuid}/${id}`, {
          signal: abortController.signal,
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Range": `${startBytes}-${endBytes}/${file.size}`,
            "Content-Type": "application/octet-stream",
          },
          body: buffer,
        });

        clearTimeout(abortTimeout);
        off();
      } catch (err) {
        off();
        if (aborted) return;

        debug.error(err);
        debug.log("retrying chunk upload", { id, startBytes, endBytes });
        // Call self again to retry.
        return sendChunk(id, startBytes, endBytes);
      }
    }

    events.emit("started", { uploadId: uploadRes.body.uuid });

    events.on("abort!", () => {
      aborted = true;
    });

    while (getStartBytes() < file.size) {
      if (aborted) break;

      const id = getNextId();
      const startBytes = getStartBytes();
      const endBytes = Math.min(file.size, startBytes + chunkSize);

      let start = Date.now();

      await sendChunk(id, startBytes, endBytes);
      uploadedChunks.push({ id, startBytes, endBytes });

      // Time, then adjust chunkSize to get an average of 2 seconds per request.
      const duration = Date.now() - start;
      const bytes = endBytes - startBytes;
      const bytesPer2Seconds = (2000 / duration) * bytes;
      const nextChunkSize = (chunkSize + bytesPer2Seconds) / 2;

      // New chunk size pinned between min and max, based on timing of previous chunk.
      chunkSize = Math.min(Math.max(nextChunkSize, minChunkSize), maxChunkSize);

      events.emit("progress", { percent: endBytes / file.size });
    }

    if (!aborted) {
      const fileRes = await Dolla.http.post<FileUpload>(`/api/files/uploads/${uploadRes.body.uuid}`, {
        body: {
          fileName: file.name,
          mimeType,
        },
      });

      return fileRes.body;
    }

    return null;
  }

  doUpload()
    .then((uploaded) => {
      if (uploaded == null) {
        // Upload was aborted.
      } else {
        events.emit("complete", { uploaded });
      }
    })
    .catch((error) => {
      events.emit("error", { error });
    });

  return events;
}

// async function deleteFile(fileId: number) {
//   return http.delete(`/api/files/${fileId}`)
// }

function getMimeType(file: File) {
  if (file.type && file.type !== "") {
    return file.type;
  }

  // Attempt to guess mime type from file extension if File API doesn't supply it.
  const ext = getFileExtension(file);
  if (ext) {
    switch (ext.toLowerCase()) {
      case ".mkv":
        return "video/x-matroska";
      default:
        return "application/octet-stream";
    }
  }

  return null;
}

function getFileExtension(file: File) {
  const match = file.name.match(/(\..+)?$/);
  if (match && match[1]) {
    return match[1];
  }

  return null;
}
