import Dolla from "@manyducks.co/dolla";
import { Manager } from "socket.io-client";

import { $me, $token } from "./auth";

const debug = Dolla.createLogger("📦 stores/io");

const uri = window.location.origin;
const manager = new Manager(uri, {
  autoConnect: false,
  transports: ["websocket"],
});

export function socket(namespace = "/", options = {}) {
  const auth = {
    userId: $me.get()?.id,
    token: $token.get(),
  };

  const _socket = manager.socket(namespace, {
    ...options,
    auth,
  });

  _socket.connect();

  return _socket;
}
