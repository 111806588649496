import Quill from "quill";
import Dolla from "@manyducks.co/dolla";

import { nav, tasks, dialog, breakpoint, chat } from "@stores";
import { TaskEditDialog } from "@dialogs/TaskEditDialog/TaskEditDialog";

const Link = Quill.import("formats/link");
const debug = Dolla.createLogger("ClickableLink");

const permalinkPathRegexp = /\/p\/(note|task)_.+/;

export class ClickableLink extends Link {
  static create(value) {
    const node = super.create(value);

    node.setAttribute("href", Link.sanitize(value));
    node.setAttribute("target", "_blank");
    // node.setAttribute("contenteditable", "false");

    node.addEventListener("click", async (e) => {
      if (isContentEditable(node)) {
        // Let Quill handle the link if we're editable.
        return;
      }

      const href = node.getAttribute("href");
      if (href.startsWith(window.location.origin)) {
        const url = URL.parse(href);
        if (permalinkPathRegexp.test(url.pathname)) {
          e.preventDefault();

          const data = await nav.getPermalinkData(href);

          if (data.type === "note") {
            // Navigate to note.
            Dolla.router.go(`/projects/${data.projectId}/notes/${data.id}`);

            // Also close chat so you can see the note on mobile.
            if (breakpoint.$width() < 800) {
              nav.closeChat();
            }
          } else if (data.type === "task") {
            // Open task dialog.
            const task = await tasks.ensureTaskIsLoaded(data.id);
            if (task) {
              dialog.show(TaskEditDialog, {
                projectId: task.projectId,
                task,
              });
            }
          }
        }
      } else {
        // Handle as a normal link.
      }
    });

    return node;
  }
}

function isContentEditable(element) {
  let target = element;
  while (true) {
    if (target.getAttribute("contenteditable") === "true") {
      return true;
    }
    if (target.getAttribute("contenteditable") === "false") {
      return false;
    }
    if (target.parentNode == null || target.parentNode === document.body) {
      return false;
    }
    target = target.parentNode;
  }
}
