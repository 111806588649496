import { ConfirmDialog } from "@dialogs/Confirm/Confirm";
import { TaskEditDialog } from "@dialogs/TaskEditDialog/TaskEditDialog";
import { PlainDate } from "@helpers/PlainDate";
import Dolla, { cond, createSettableState, derive, repeat, State, t, ViewContext } from "@manyducks.co/dolla";
import { auth, clock, dialog, notes, projects, tasks, theme } from "@stores";
import { Button, ButtonColor, ButtonStyle } from "@views/Button";
import { CardContent } from "@views/Card";
import { MoreMenu } from "@views/MoreMenu";
import { NoteListItem } from "@views/NoteListItem";
import { SquiggleDivider } from "@views/SquiggleDivider";
import { TaskListItem } from "@views/TaskListItem";
import { UserAvatar } from "@views/UserAvatar";
import { addWeeks, subDays, subWeeks } from "date-fns";
import deepEqual from "fast-deep-equal";
import { Icon } from "MaterialSymbols";
import { Project, Task, User } from "schemas";
import { TasksSubsection } from "Workspace/Home/Home";
import { EditProjectDialog } from "./EditProjectDialog/EditProjectDialog";
import { InviteDialog } from "./InviteDialog/InviteDialog";
import { InviteListItem } from "./InviteListItem/InviteListItem";
import { NewTaskDialog } from "./NewTaskDialog/NewTaskDialog";
import styles from "./ProjectOverview.module.css";

interface ProjectUser extends User {
  role: Project["users"][0]["role"];
  joinedAt: string;
}

export function ProjectOverview(props: {}, ctx: ViewContext) {
  const $project = derive(
    [Dolla.router.$params, projects.$cache],
    (params, projects) => {
      const id = Number(params.projectId);
      return projects.get(id);
    },
    { equals: deepEqual },
  );
  const $invites = derive([$project], (p) => p?.invites ?? []);

  const $people: State<ProjectUser[]> = derive([auth.$users, $project], (users, project) => {
    return (
      project?.users.map((pu) => {
        return {
          ...users.find((u) => u.id === pu.id)!,
          role: pu.role,
          joinedAt: pu.joinedAt,
        };
      }) ?? []
    );
  });

  const $role = derive([$project, auth.$me], (project, me) => {
    if (project == null || me == null) return "viewer";

    if (project.ownerId === me.id) {
      return "owner";
    }

    const user = project.users.find((p) => p.id === me.id);
    if (user) {
      return user.role;
    }

    return "viewer";
  });

  const $projectTasks = derive(
    [$project, tasks.$cache],
    (project, tasks) => {
      if (project == null) {
        return [];
      }
      return [...tasks.values()].filter(
        (t) => t.projectId === project.id && (t.dueDate != null || t.completedAt != null),
      );
    },
    { equals: deepEqual },
  );

  function sortTasksByDateAsc(a: Task, b: Task) {
    if (a.dueDate! < b.dueDate!) {
      return -1;
    } else if (a.dueDate! > b.dueDate!) {
      return +1;
    } else {
      if (a.createdAt < b.createdAt) {
        return -1;
      } else if (a.createdAt > b.createdAt) {
        return +1;
      } else {
        return 0;
      }
    }
  }

  // Tasks that are due before today.
  const $overdueTasks = derive([$projectTasks, clock.$hour], (tasks, _) => {
    const today = PlainDate.format(new Date());
    return tasks
      .filter((t) => t.completedAt == null && t.dueDate != null && t.dueDate < today)
      .sort(sortTasksByDateAsc);
  });

  // Tasks that are due today.
  const $todayTasks = derive([$projectTasks, clock.$hour], (tasks, _) => {
    const today = PlainDate.format(new Date());
    return tasks.filter((t) => t.dueDate === today && t.completedAt == null).sort(sortTasksByDateAsc);
  });

  // Tasks that are due within one week from today.
  const $upcomingTasks = derive([$projectTasks], (tasks) => {
    const today = PlainDate.format(new Date());
    const oneWeekFromNow = PlainDate.format(addWeeks(new Date(), 1));
    return tasks
      .filter(
        (t) => t.completedAt == null && t.dueDate != null && t.dueDate > today && t.dueDate <= oneWeekFromNow,
      )
      .sort(sortTasksByDateAsc);
  });

  // Tasks that have been finished in the last two days.
  const $recentlyFinishedTasks = derive([$projectTasks], (tasks) => {
    const oldestRecentDate = PlainDate.format(subDays(new Date(), 2));
    ctx.log(
      oldestRecentDate,
      tasks.filter((t) => t.completedAt != null),
    );
    return tasks
      .filter((t) => t.completedAt != null && t.completedAt >= oldestRecentDate)
      .sort((a, b) => {
        if (a.completedAt! < b.completedAt!) {
          return +1;
        } else if (a.completedAt! > b.completedAt!) {
          return -1;
        } else {
          return 0;
        }
      });
  });

  const $recentlyUpdatedTasks = derive([$projectTasks], (tasks) => {
    const twoWeeksAgo = subWeeks(new Date(), 2).toISOString();
    return tasks
      .filter((task) => task.updatedAt >= twoWeeksAgo)
      .sort((a, b) => {
        if (a.updatedAt > b.updatedAt) {
          return -1;
        } else if (a.updatedAt < b.updatedAt) {
          return +1;
        } else {
          return 0;
        }
      })
      .slice(0, 10);
  });

  const $recentlyUpdatedNotes = derive([notes.$cache, $project], (notes, project) => {
    if (project == null) {
      return [];
    }
    const twoWeeksAgo = subWeeks(new Date(), 2).toISOString();
    return [...notes.values()]
      .filter((note) => note.projectId === project.id && note.updatedAt >= twoWeeksAgo)
      .sort((a, b) => {
        if (a.updatedAt > b.updatedAt) {
          return -1;
        } else if (a.updatedAt < b.updatedAt) {
          return +1;
        } else {
          return 0;
        }
      })
      .slice(0, 10);
  });

  ctx.watch([derive([$project], (p) => p?.id)], (projectId) => {
    if (projectId) {
      tasks.fetchOverview(projectId);
      tasks.fetchActivity(projectId);
      notes.fetchActivity(projectId);
    }
  });

  const $$moreMenuIsOpen = createSettableState(false);

  return (
    <div class={styles.layout}>
      <CardContent>
        <div class={styles.content}>
          <section class={[styles.quickActionsSection]}>
            <ul class={styles.quickActions}>
              <li>
                <Button
                  icon="Calendar Add On"
                  color={ButtonColor.Neutral}
                  style={ButtonStyle.Hollow}
                  disabled={derive([$role], (r) => r === "viewer")}
                  onClick={() => {
                    const project = $project.get()!;

                    dialog.show(NewTaskDialog, {
                      projectId: project.id,
                      onConfirm: async (info, open) => {
                        ctx.log(info, open);
                        const task = await tasks.createTask({
                          projectId: project.id,
                          dueDate: info.dueDate,
                          assignedUserId: info.assignedUserId,
                          delta: [{ insert: info.title }, { insert: "\n" }],
                        });
                        if (open) {
                          dialog.show(TaskEditDialog, {
                            task,
                            onSubmitted: () => {},
                            onToListClicked: () => {},
                          });
                        }
                      },
                    });
                  }}
                >
                  {t("workspace.project.overview.quickActions.newTaskButtonText")}
                </Button>
              </li>

              <li>
                <Button
                  icon="Note Add"
                  color={ButtonColor.Neutral}
                  style={ButtonStyle.Hollow}
                  disabled={derive([$role], (r) => r === "viewer")}
                  onClick={() => {
                    const project = $project.get()!;

                    notes.createNote({ projectId: project.id }).then((note) => {
                      Dolla.router.go(`/projects/${project.id}/notes/${note.id}`);
                    });
                  }}
                >
                  {t("workspace.project.overview.quickActions.newNoteButtonText")}
                </Button>
              </li>

              {cond(
                derive([$role], (r) => ["owner", "admin"].includes(r)),
                <li>
                  <Button
                    icon="Edit Note"
                    color={ButtonColor.Neutral}
                    style={ButtonStyle.Hollow}
                    onClick={() => {
                      const project = $project.get()!;

                      dialog.show(EditProjectDialog, {
                        project,
                        onConfirm: async (info) => {
                          return projects.updateProject(project.id, info);
                        },
                      });
                    }}
                  >
                    {t("workspace.project.overview.quickActions.editProjectButtonText")}
                  </Button>
                </li>,
              )}
            </ul>

            <MoreMenu
              $$open={$$moreMenuIsOpen}
              color={derive([$project], (p) => p?.color)}
              options={[
                {
                  label: t("workspace.project.overview.actions.leaveProject.label"),
                  icon: <Icon name="Logout" size={24} />,
                  disabled: derive([$role], (r) => r === "owner"),
                  callback: () => {
                    // TODO: Confirm, then leave. The owner is unable to leave the project.
                    // This reminds me, we need the ability to transfer ownership.

                    const project = $project.get()!;

                    dialog.show(ConfirmDialog, {
                      color: project.color,
                      title: t("workspace.project.overview.actions.leaveProject.dialogTitle"),
                      message: t("workspace.project.overview.actions.leaveProject.dialogMessage", {
                        project: project.name,
                      }),
                      confirmButtonText: t(
                        "workspace.project.overview.actions.leaveProject.confirmButtonLabel",
                      ),
                      onConfirm: () => {
                        Dolla.router.go("/");
                        projects.leaveProject(project.id);
                      },
                    });
                  },
                },
                {
                  label: t("workspace.project.overview.actions.archiveProject.label"),
                  icon: <Icon name="Archive" size={24} />,
                  hidden: derive(
                    [$project, $role],
                    (p, r) => p?.archivedAt != null || !["owner", "admin"].includes(r),
                  ),
                  callback: () => {
                    // TODO: Confirm, then archive.

                    const project = $project.get()!;

                    dialog.show(ConfirmDialog, {
                      color: project.color,
                      title: t("workspace.project.overview.actions.archiveProject.dialogTitle"),
                      message: t("workspace.project.overview.actions.archiveProject.dialogMessage", {
                        project: project.name,
                      }),
                      confirmButtonText: t(
                        "workspace.project.overview.actions.archiveProject.confirmButtonLabel",
                      ),
                      onConfirm: () => {
                        Dolla.router.go("/");
                        projects.archiveProject(project.id);
                      },
                    });
                  },
                },
                {
                  label: "UNARCHIVE",
                  icon: <Icon name="Unarchive" size={24} />,
                  hidden: derive(
                    [$project, $role],
                    (p, r) => p?.archivedAt == null || !["owner", "admin"].includes(r),
                  ),
                  callback: () => {
                    // TODO: Confirm, then unarchive.
                  },
                },
              ]}
            />
          </section>

          <div class={styles.divider}>
            <SquiggleDivider />
          </div>

          <section class={styles.section}>
            <header>
              <h2>{t("workspace.project.overview.people.title")}</h2>

              {cond(
                derive([$role], (role) => ["owner", "admin"].includes(role)),

                <Button
                  onClick={() => {
                    const project = $project.get()!;

                    dialog.show(InviteDialog, {
                      project: project,
                      onSubmit: async (data) => {
                        return projects.sendInvite(project.id, data);
                      },
                      onCancel: () => {},
                    });
                  }}
                >
                  {t("workspace.project.overview.people.addPersonButtonText")}
                </Button>,
              )}
            </header>

            <section class={styles.subsection}>
              <UsersList $project={$project} $users={$people} />
            </section>

            {cond(
              derive([$invites], (i) => i.length > 0),
              <section class={styles.subsection}>
                <header>
                  <h3>Invited</h3>
                </header>

                <ul class={styles.inviteList}>
                  {repeat(
                    $invites,
                    (i) => i.email,
                    ($invite) => (
                      <InviteListItem $invite={$invite} />
                    ),
                  )}
                </ul>
              </section>,
            )}
          </section>

          <div class={styles.divider}>
            <SquiggleDivider />
          </div>

          <section class={styles.section}>
            <header>
              <h2>{t("workspace.project.overview.tasks.title")}</h2>

              {cond(
                derive([$role], (role) => ["owner", "admin", "collaborator"].includes(role)),
                <Button
                  onClick={() => {
                    const project = $project.get()!;

                    dialog.show(NewTaskDialog, {
                      projectId: project.id,
                      onConfirm: async (info, open) => {
                        ctx.log(info, open);
                        const task = await tasks.createTask({
                          projectId: project.id,
                          dueDate: info.dueDate,
                          assignedUserId: info.assignedUserId,
                          delta: [{ insert: info.title }, { insert: "\n" }],
                        });
                        if (open) {
                          dialog.show(TaskEditDialog, {
                            task,
                            onSubmitted: () => {},
                            onToListClicked: () => {},
                          });
                        }
                      },
                    });
                  }}
                >
                  {t("workspace.project.overview.tasks.addTaskButtonText")}
                </Button>,
              )}
            </header>

            {cond(
              derive(
                [$overdueTasks, $todayTasks, $upcomingTasks, $recentlyFinishedTasks],
                (overdue, today, upcoming, finished) =>
                  overdue.length == 0 && today.length == 0 && upcoming.length == 0 && finished.length == 0,
              ),
              <p class={styles.emptyListMessage}>No tasks yet.</p>,
              <>
                {cond(
                  derive([$overdueTasks], (t) => t.length > 0),
                  <TasksSubsection
                    name="overview.overdueTasks"
                    $title={t("workspace.project.overview.tasks.headings.overdue")}
                    $tasks={$overdueTasks}
                    // taskMeta={{ assignedUser: true }}
                  />,
                )}

                {cond(
                  derive([$todayTasks], (t) => t.length > 0),
                  <TasksSubsection
                    name="overview.todayTasks"
                    $title={t("workspace.project.overview.tasks.headings.today")}
                    $tasks={$todayTasks}
                    // taskMeta={{ assignedUser: true }}
                  />,
                )}

                {cond(
                  derive([$upcomingTasks], (t) => t.length > 0),
                  <TasksSubsection
                    name="overview.overdueTasks"
                    $title={t("workspace.project.overview.tasks.headings.upcoming")}
                    $tasks={$upcomingTasks}
                    // taskMeta={{ assignedUser: true }}
                  />,
                )}

                {cond(
                  derive([$recentlyFinishedTasks], (t) => t.length > 0),
                  <TasksSubsection
                    name="overview.recentlyFinishedTasks"
                    $title={t("workspace.project.overview.tasks.headings.recentlyFinished")}
                    $tasks={$recentlyFinishedTasks}
                    // taskMeta={{ assignedUser: true }}
                  />,
                )}
              </>,
            )}
          </section>

          <div class={styles.divider}>
            <SquiggleDivider />
          </div>

          <section class={styles.section}>
            <header>
              <h2>{t("workspace.project.overview.recentActivity.heading")}</h2>
            </header>

            <section class={styles.subsection}>
              <header>
                <h3>{t("workspace.project.overview.recentActivity.tasksHeading")}</h3>
              </header>

              {cond(
                derive([$recentlyUpdatedTasks], (t) => t.length > 0),
                <ul class={styles.taskList}>
                  {repeat(
                    $recentlyUpdatedTasks,
                    (t) => t.id,
                    ($task) => {
                      return (
                        <TaskListItem
                          $task={$task}
                          meta={{
                            assignedUser: true,
                            updatedAt: "relative",
                          }}
                        />
                      );
                    },
                  )}
                </ul>,
                <p class={styles.emptyListMessage}>No recent updates.</p>,
              )}
            </section>

            <section class={styles.subsection}>
              <header>
                <h3>{t("workspace.project.overview.recentActivity.notesHeading")}</h3>
              </header>

              {cond(
                derive([$recentlyUpdatedNotes], (n) => n.length > 0),
                <ul class={styles.taskList}>
                  {repeat(
                    $recentlyUpdatedNotes,
                    (n) => n.id,
                    ($note) => {
                      return <NoteListItem $note={$note} meta={{ updatedAt: "relative" }} />;
                    },
                  )}
                </ul>,
                <p class={styles.emptyListMessage}>No recent updates.</p>,
              )}
            </section>
          </section>
        </div>
      </CardContent>
    </div>
  );
}

interface UsersListProps {
  $project: State<Project | undefined>;
  $users: State<ProjectUser[]>;
}

function UsersList(props: UsersListProps, ctx: ViewContext) {
  return cond(
    derive([props.$users], (x) => x.length === 0),
    <p class={styles.emptyUserListMessage}>No users.</p>,
    <ul class={styles.userList}>
      {repeat(
        props.$users,
        (u) => u.id,
        ($user, $index, ctx) => {
          const $name = derive([$user], (x) => x.name);
          const $color = derive([$user], (x) => x.color);
          const $themeVars = theme.getTheme$($color);

          return (
            <li class={styles.userListItem} style={$themeVars}>
              <div class={styles.userLayout}>
                <div class={styles.userInfo}>
                  <div class={styles.userIcon}>
                    <UserAvatar src={derive([$user], (u) => u.avatar ?? "")} />
                  </div>

                  <div class={styles.userText}>
                    <span class={styles.userName}>
                      <span class={styles.userNameLabel}>{$name}</span>
                    </span>

                    <ul class={styles.userMeta}>
                      <li>
                        {derive([$user, props.$project], (u, p) => {
                          if (p?.ownerId === u.id) {
                            return t("workspace.project.roles.owner.title");
                          }

                          switch (u.role) {
                            case "admin":
                              return t("workspace.project.roles.admin.title");
                            case "collaborator":
                              return t("workspace.project.roles.collaborator.title");
                            case "viewer":
                              return t("workspace.project.roles.viewer.title");
                          }
                        })}
                      </li>
                      <li>
                        <Icon name="Mail" opticalSize={20} />
                        <span class={styles.userMetaLabel}>{derive([$user], (u) => u.email)}</span>
                      </li>
                      <li>
                        <Icon name="Person Add" opticalSize={20} />
                        <span class={styles.userMetaLabel}>
                          {Dolla.i18n.dateTime(
                            derive([$user], (u) => u.joinedAt),
                            { dateStyle: "short" },
                          )}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>

                {/* {cond(
            $($userRole, (role) => role === "admin"), // Includes owner
            <div class={styles.userActions}>
              <MoreMenu
                $$open={$$menuIsOpen}
                size="small"
                options={[
                  // {
                  //   label: translate("common.edit"),
                  //   icon: <EditIcon />,
                  //   callback: () => {
                  //     $$editIsOpen.set(true);
                  //   },
                  // },
                  {
                    label: translate("common.delete"),
                    icon: <Trash />,
                    variant: "destructive",
                    callback: async () => {
                      // await projects.deleteProject($project.get().id);
                    },
                  },
                ]}
              />
            </div>,
          )} */}
              </div>

              <div class={styles.projectDivider} />
            </li>
          );
        },
      )}
    </ul>,
  );
}
