import { adjustAccentColor } from "@helpers/makeTheme";
import { relativePastTime } from "@helpers/relativePastTime";
import Dolla, { cond, createRef, derive, type State, t, type ViewContext } from "@manyducks.co/dolla";
import { isBefore, isSameDay, isSameMinute } from "date-fns";
import { Icon } from "MaterialSymbols";
import prettyBytes from "pretty-bytes";
import type { Note } from "schemas";
import styles from "./NoteListItem.module.css";

import { clock, projects, theme } from "@stores";

interface NoteListItemProps {
  $note: State<Note>;

  onClick?: (e: MouseEvent) => void;

  meta?: NoteMetaOptions;
}

export interface NoteMetaOptions {
  /**
   * Show the project name.
   */
  project?: boolean;

  /**
   * Show a "pinned" label if the note is pinned.
   */
  pinned?: boolean;

  /**
   * Display the last updated timestamp.
   */
  updatedAt?: "relative" | "absolute" | false;
}

export function NoteListItem(props: NoteListItemProps, ctx: ViewContext) {
  const { $note } = props;

  const listItemElement = createRef<HTMLLIElement>();

  const $defaultTitle = t("views.noteListItem.unnamedFallbackTitle");
  const $title = derive([$note, $defaultTitle], (note, defaultTitle) => note.title || defaultTitle);
  const $isTitled = derive([$note], (note) => !!note.title);
  const $isUntitled = derive([$title, $defaultTitle], (title, defaultTitle) => title === defaultTitle);

  // const $highlighted = derive([$note, notes.$highlightNoteId], (t, id) => t.id === id);
  // ctx.watch([$highlighted], (highlighted) => {
  //   if (highlighted) {
  //     const button = listItemElement.node!.querySelector("button");

  //     if (button) {
  //       button.addEventListener(
  //         "animationend",
  //         () => {
  //           notes.setHighlightedTaskId(null);
  //         },
  //         { once: true },
  //       );
  //     }
  //   }
  // });

  const $project = derive([$note, projects.$cache], (note, projects) => {
    return projects.get(note.projectId);
  });

  const $projectColor = derive([$project, theme.$isDark], (project, dark) => {
    return adjustAccentColor(project!.color, dark);
  });

  return (
    <li
      class={styles.noteListItem}
      style={theme.getTheme$($projectColor)}
      ref={listItemElement}
      data-note-id={derive([$note], (t) => t.id)}
    >
      <a
        href={derive([$note], (n) => `/projects/${n.projectId}/notes/${n.id}`)}
        class={[styles.note]}
        onDragStart={(e) => {
          ctx.log("dragstart", e);

          e.dataTransfer!.setData("data/note-id", String($note.get().id));
          e.dataTransfer!.effectAllowed = "move";
        }}
        draggable={true}
        onClick={props.onClick}
      >
        <div class={styles.layout}>
          <div class={styles.noteLine} />

          <div class={styles.content} draggable={false}>
            <div class={styles.mainContent}>
              <span class={[styles.title, { [styles.untitled]: $isUntitled }]}>{$title}</span>
            </div>

            <div class={styles.secondaryContent}>
              <ul class={[styles.meta]}>
                {cond(
                  props.meta?.project,
                  <li>
                    <div class={[styles.metaIcon, styles.projectIcon]}>
                      <Icon name="Collections Bookmark" size={20} />
                    </div>
                    <span class={[styles.metaLabel, styles.projectLabel]}>
                      {derive([$project], (p) => p?.name)}
                    </span>
                  </li>,
                )}

                {/* {cond(
                  derive([$task], (t) => t.status != null && t.status.length > 0),
                  <li>
                    <div class={styles.metaIcon}>
                      <FileText />
                    </div>
                  </li>,
                )} */}

                <li>
                  <div class={styles.metaIcon}>
                    <Icon name="Stylus Note" size={20} />
                  </div>
                  <span class={styles.metaLabel}>
                    {derive([$note], (note) => {
                      return t("views.noteListItem.metaWordCountLabel", { count: note.wordCount });
                    })}
                  </span>
                </li>

                {cond(
                  derive([$note], (n) => n.attachments.length > 0),
                  <li>
                    <div class={styles.metaIcon}>
                      <Icon name="Attach File" size={20} />
                    </div>
                    <span class={styles.metaLabel}>
                      {derive([$note], (note) => {
                        const count = note.attachments.length;
                        const size = prettyBytes(note.attachments.reduce((sum, f) => sum + f.sizeInBytes, 0));
                        return t("views.taskListItem.meta.attachmentsLabel", { count, size });
                      })}
                    </span>
                  </li>,
                )}
                {cond(
                  derive([props.meta?.updatedAt], (style) => Boolean(style)),
                  <li>
                    <div class={styles.metaIcon}>
                      <Icon name="Search Activity" size={20} />
                    </div>
                    <span class={styles.metaLabel}>
                      {derive(
                        [$note, props.meta?.updatedAt, Dolla.i18n.$locale, clock.$minute],
                        (note, style, lang) => {
                          if (style === "absolute") {
                            return t("absoluteTime.updatedAtTimestamp", {
                              timestamp: Dolla.i18n
                                .dateTime(note.updatedAt, { dateStyle: "short", timeStyle: "short" })
                                .get(),
                            });
                          } else {
                            if (isSameMinute(note.updatedAt, new Date())) {
                              return t("relativeTime.updatedNow");
                            } else {
                              return t("relativeTime.updatedAtTimestamp", {
                                timestamp: relativePastTime(lang!, note.updatedAt)!,
                              });
                            }
                          }
                        },
                      )}
                    </span>
                  </li>,
                )}
              </ul>
            </div>
          </div>
        </div>
      </a>
    </li>
  );
}
