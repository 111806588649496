import { adjustAccentColor } from "@helpers/makeTheme";
import {
  createState,
  derive,
  repeat,
  type State,
  toState,
  t,
  toSettableState,
  type ViewContext,
} from "@manyducks.co/dolla";
import { TextInput } from "@views/TextInput";
import { UserAvatar } from "@views/UserAvatar";
import { type User } from "schemas";
import styles from "./AssignMenu.module.css";

import { auth, projects, theme } from "@stores";

interface AssignMenuProps {
  $projectId: State<number>;
  $value?: State<number | null>;
  onChange: (user: User | null) => void;
}

export function AssignMenu(props: AssignMenuProps, ctx: ViewContext) {
  const $project = derive([projects.$cache, props.$projectId], (projects, id) => projects.get(id));
  const $value = toState(props.$value ?? null);

  const [$inputValue, setInputValue] = createState("");

  const $users = derive([auth.$users, auth.$me, $project, $inputValue], (users, me, project, inputValue) => {
    const results = [
      me!,
      ...users
        .filter(
          (u) => u.id !== me!.id && !!project?.users.find((pu) => pu.id === u.id && pu.role !== "viewer"),
        )
        .sort((a, b) => {
          const aName = a.name.toLowerCase();
          const bName = b.name.toLowerCase();
          if (aName < bName) {
            return -1;
          } else if (aName > bName) {
            return +1;
          } else {
            return 0;
          }
        }),
    ];
    const term = inputValue.toLowerCase();
    return results.filter((user) => {
      return user.name.toLowerCase().startsWith(term);
    });
  });

  return (
    <div class={styles.container}>
      <div class={styles.input}>
        <TextInput
          $$value={toSettableState($inputValue, setInputValue)}
          onChange={(e) => {
            setInputValue((e.target as HTMLInputElement).value);
          }}
          placeholder={t("views.taskListItem.assignMenu.searchPlaceholder")}
        />
      </div>

      <ul class={styles.results}>
        {/* {cond(
          derive([$value, $inputValue], (v, i) => v != null && i.trim().length === 0),
          <li class={styles.resultItem}>
            <button
              class={styles.resultItemButton}
              onClick={() => {
                props.onChange(null);
              }}
            >
              <div class={styles.resultItemIcon}>
                <div class={styles.unassignedIcon}>
                  <UserIcon />
                </div>
              </div>
              <span
                class={styles.resultItemLabel}
                style={{
                  color: "var(--color-text-secondary)",
                }}
              >
                {translate("views.taskListItem.assignMenu.unassignLabel")}
              </span>
            </button>
          </li>,
        )} */}

        {repeat(
          derive([$users, $value], (users, selectedId) => users.filter((u) => u.id !== selectedId)),
          (u) => u.id,
          ($user) => {
            return <ResultItem $user={$user} onClick={props.onChange} />;
          },
        )}
      </ul>
    </div>
  );
}

interface ResultItemProps {
  $user: State<User>;
  onClick: (user: User) => void;
}

function ResultItem(props: ResultItemProps, ctx: ViewContext) {
  return (
    <li class={styles.resultItem}>
      <button
        class={styles.resultItemButton}
        onClick={() => {
          props.onClick(props.$user.get());
        }}
      >
        <div class={styles.resultItemIcon}>
          <UserAvatar src={derive([props.$user], (u) => u.avatar ?? "")} />
        </div>
        <span
          class={styles.resultItemLabel}
          style={{
            color: derive([props.$user, theme.$isDark], (user, dark) => {
              return adjustAccentColor(user.color, dark);
            }),
          }}
        >
          {derive([props.$user], (u) => u.name)}
        </span>
      </button>
    </li>
  );
}
