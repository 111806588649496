import { adjustAccentColor, Theme, type ThemeCSSVariables } from "@helpers/makeTheme";
import Dolla, { createState, derive, type MaybeState, type State } from "@manyducks.co/dolla";
import { argbFromHex, Blend, hexFromArgb } from "@material/material-color-utilities";
import { Project, User } from "schemas";

import { auth, projects } from ".";

export enum ColorSchemes {
  System = "system",
  Light = "light",
  Dark = "dark",
}

type ThemeSet = {
  light: ThemeCSSVariables;
  dark: ThemeCSSVariables;
};

// Store exactly one copy of each generated theme.
const themeCache = new Map<string, ThemeSet>();

function getThemeSet(hex: string): ThemeSet {
  const cached = themeCache.get(hex);

  if (cached) {
    return cached;
  } else {
    const light = new Theme(hex, false);
    const dark = new Theme(hex, true);
    const theme = {
      light: light.toCSS(),
      dark: dark.toCSS(),
    };
    themeCache.set(hex, theme);
    return theme;
  }
}

const [$systemScheme, setSystemScheme] = createState<ColorSchemes>(ColorSchemes.Light);
const [$selectedScheme, setSelectedScheme] = createState<ColorSchemes>(ColorSchemes.System);

export { $selectedScheme, $systemScheme, setSelectedScheme };

const $defaultColor = Dolla.toState("#2D79E9");

export const $placeColor = derive(
  [Dolla.router.$params, projects.$cache, auth.$me],
  (params, projects, me) => {
    if (params.projectId) {
      const project = projects.get(params.projectId as number);
      if (project) {
        return project.color;
      }
    }
    return me?.color ?? "#888";
  },
);

export const $currentScheme = derive([$systemScheme, $selectedScheme], (system, selected) => {
  if (selected === ColorSchemes.Dark || (selected === ColorSchemes.System && system === ColorSchemes.Dark)) {
    return ColorSchemes.Dark;
  } else {
    return ColorSchemes.Light;
  }
});
export const $isDark = derive([$currentScheme], (scheme) => scheme === ColorSchemes.Dark);

export const $cssVars = derive([$currentScheme, $defaultColor], (scheme, defaultColor) => {
  const theme = getThemeSet(defaultColor);
  if (scheme === ColorSchemes.Dark) {
    return theme.dark;
  } else {
    return theme.light;
  }
});

const $userThemeSet = derive([auth.$me, $defaultColor], (me, defaultColor) => {
  return getThemeSet(me?.color ?? defaultColor);
});
export const $userTheme = derive([$userThemeSet, $currentScheme], (theme, scheme) => {
  if (scheme === ColorSchemes.Dark) {
    return theme.dark;
  } else {
    return theme.light;
  }
});

/**
 * Returns a theme build around `color` as a set of CSS variables. Automatically adapts for light and dark mode.
 */
export function getTheme$(color?: string | State<string | undefined>) {
  return derive([color, $defaultColor, $currentScheme], (c, defaultColor, scheme) => {
    const theme = getThemeSet(c ?? defaultColor);
    if (scheme === ColorSchemes.Dark) {
      return theme.dark;
    } else {
      return theme.light;
    }
  });
}

export function getAdjustedColor$(color?: MaybeState<string | undefined>) {
  return derive([color, $defaultColor, $currentScheme], (c, defaultColor, scheme) => {
    return adjustAccentColor(c ?? defaultColor, scheme === ColorSchemes.Dark);
  });
}

export function getBlendedColor$(
  color?: MaybeState<string | undefined>,
  sourceColor?: MaybeState<string | undefined>,
): State<string> {
  return derive([color, sourceColor], (c, s) => {
    const argbColor = argbFromHex(c ?? "#888");
    const argbSourceColor = argbFromHex(s ?? "#888");
    const blendedColor = Blend.harmonize(argbColor, argbSourceColor);
    return hexFromArgb(blendedColor);
  });
}

export function getBlendedUserColor$(
  user?: MaybeState<User | undefined>,
  project?: MaybeState<Project | undefined>,
): State<string> {
  return derive([user, project], (u, p) => {
    const userColor = argbFromHex(u?.color ?? "#888");
    const projectColor = argbFromHex(p?.color ?? "#888");
    const blendedColor = Blend.harmonize(userColor, projectColor);
    return hexFromArgb(blendedColor);
  });
}

// Adds CSS vars to document body when they change.
Dolla.watch([$placeColor, $currentScheme], (placeColor, scheme) => {
  let metaThemeColor: string;

  const theme = getThemeSet(placeColor);
  const vars = scheme === ColorSchemes.Light ? theme.light : theme.dark;

  Object.keys(vars).forEach((key) => {
    document.body.style.setProperty(key, (vars as any)[key]);
  });

  metaThemeColor = vars["--theme-primary"];

  // Update iOS status bar color.
  document.querySelector('meta[name="theme-color"]')?.setAttribute("content", metaThemeColor);

  const lightThemeAccent = document.querySelector("#light-theme-accent") as HTMLMetaElement;
  if (lightThemeAccent) lightThemeAccent.content = metaThemeColor;

  const darkThemeAccent = document.querySelector("#dark-theme-accent") as HTMLMetaElement;
  if (darkThemeAccent) lightThemeAccent.content = metaThemeColor;
});

Dolla.watch([$currentScheme], (scheme) => {
  if (scheme === ColorSchemes.Dark) {
    document.body.classList.remove("color-scheme-light");
    document.body.classList.add("color-scheme-dark");
  } else {
    document.body.classList.add("color-scheme-light");
    document.body.classList.remove("color-scheme-dark");
  }
});

Dolla.onMount(async () => {
  const stored = localStorage.getItem("quack-color-scheme") as ColorSchemes;

  if (stored && Object.values(ColorSchemes).includes(stored)) {
    setSelectedScheme(stored);
  } else {
    setSelectedScheme(ColorSchemes.System);
  }

  // Persist the selected scheme to local storage.
  Dolla.watch([$selectedScheme], (scheme) => {
    localStorage.setItem("quack-color-scheme", scheme);
  });

  // Watch the system color scheme for changes.
  const darkModeQuery = window.matchMedia("(prefers-color-scheme: dark)");

  // Set system scheme to match the current OS setting.
  setSystemScheme(darkModeQuery.matches ? ColorSchemes.Dark : ColorSchemes.Light);

  // Update the system scheme when it changes.
  darkModeQuery.addEventListener("change", (e) => {
    setSystemScheme(e.matches ? ColorSchemes.Dark : ColorSchemes.Light);
  });
});
