import { ViewContext } from "@manyducks.co/dolla";

import { push } from "@stores";

/**
 * Returns a function that toggles push notifications on or off.
 *
 * @param ctx - Component context object.
 */
export function makeTogglePush(ctx: ViewContext) {
  return async function togglePush() {
    if (push.$isPushEnabled.get()) {
      push.disablePushNotifications().then(() => {
        ctx.log("push disabled");
      });
    } else {
      push
        .enablePushNotifications()
        .then(() => {
          ctx.log("push enabled");
        })
        .catch((err) => {
          alert(err.message);
        });
    }
  };
}
