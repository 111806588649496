export default {
  "calendar": "CalendarView_calendar_4646a",
  "header": "CalendarView_header_4646a",
  "days": "CalendarView_days_4646a",
  "day": "CalendarView_day_4646a",
  "notInMonth": "CalendarView_notInMonth_4646a",
  "isToday": "CalendarView_isToday_4646a",
  "isSelected": "CalendarView_isSelected_4646a",
  "dayButton": "CalendarView_dayButton_4646a",
  "dayHeader": "CalendarView_dayHeader_4646a",
  "dayMonthLabel": "CalendarView_dayMonthLabel_4646a",
  "dayItems": "CalendarView_dayItems_4646a",
  "dayItem": "CalendarView_dayItem_4646a",
  "completed": "CalendarView_completed_4646a",
  "hiddenMessage": "CalendarView_hiddenMessage_4646a",
  "daySection": "CalendarView_daySection_4646a",
  "empty": "CalendarView_empty_4646a",
  "title": "CalendarView_title_4646a"
};import "ni:sha-256;mKP63bK7zJEaTy-tdKoJiSVCLG9HVVaL3t6UYlwNNEo";