import Quill from "quill";
import Delta from "quill-delta";

const Clipboard = Quill.import("modules/clipboard");
const CodeBlock = Quill.import("formats/code-block");

/**
 * A Quill Clipboard module that runs text matchers when plain text is pasted.
 * This is a regression in Quill 2; MagicUrl relies on the v1 behavior.
 */
export class BetterClipboard extends Clipboard {
  convert({ html, text }, formats = {}) {
    if (formats[CodeBlock.blotName]) {
      return new Delta().insert(text || "", {
        [CodeBlock.blotName]: formats[CodeBlock.blotName],
      });
    }
    if (!html) {
      return this.convertText(text || "", formats);
    }
    const delta = this.convertHTML(html);
    // Remove trailing newline
    if (deltaEndsWith(delta, "\n") && (delta.ops[delta.ops.length - 1].attributes == null || formats.table)) {
      return delta.compose(new Delta().retain(delta.length() - 1).delete(1));
    }
    return delta;
  }

  convertText(text, formats = {}) {
    const node = new Text(text);
    const textMatchers = [];

    for (const [selector, matcher] of this.matchers) {
      if (selector === Node.TEXT_NODE) {
        textMatchers.push(matcher);
      }
    }

    return textMatchers.reduce((delta, matcher) => {
      return matcher(node, delta, this.quill.scroll);
    }, new Delta());
  }
}

function deltaEndsWith(delta, text) {
  let endText = "";
  for (let i = delta.ops.length - 1; i >= 0 && endText.length < text.length; --i) {
    const op = delta.ops[i];
    if (typeof op.insert !== "string") break;
    endText = op.insert + endText;
  }
  return endText.slice(-1 * text.length) === text;
}
