import {
  createState,
  toState,
  type Markup,
  type MaybeState,
  type State,
  type ViewContext,
} from "@manyducks.co/dolla";
import { IconButton } from "@views/IconButton";
import styles from "./Dialog.module.css";
import { Icon } from "MaterialSymbols";

import { theme } from "@stores";
import type { DialogProps as StoreDialogProps } from "@stores/dialog";

class OpenPromise<T> {
  resolve!: (value: T) => void;
  reject!: (reason?: any) => void;
  promise: Promise<T>;

  constructor() {
    const promise = new Promise<T>((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });

    this.promise = promise;
  }
}

/* ----- Dialog ----- */

interface DialogProps {
  onsubmit?: () => void;

  /**
   * Called when the user clicks anywhere but the dialog panel.
   */
  onclickoutside?: () => void;

  accentColor?: MaybeState<string>;

  /**
   * From `dialog` store; callback is called immediately after the dialog is connected.
   */
  transitionIn: (callback: () => Promise<void>) => void;

  /**
   * From `dialog` store; dialog is disconnected once callback promise resolves.
   */
  transitionOut: (callback: () => Promise<void>) => void;
}

export function Dialog({ onsubmit, onclickoutside, ...props }: DialogProps, ctx: ViewContext) {
  const $color = toState(props.accentColor ?? "#888");
  const $themeVars = theme.getTheme$($color);

  const [$isOpen, setIsOpen] = createState(false);

  let transition: OpenPromise<void>;

  props.transitionIn(async () => {
    setTimeout(() => {
      setIsOpen(true);
    }, 0);
    transition = new OpenPromise<void>();
    await transition.promise;
  });
  props.transitionOut(async () => {
    setIsOpen(false);
    transition = new OpenPromise<void>();
    await transition.promise;
  });

  return (
    <div
      class={[styles.container, { [styles.open]: $isOpen }]}
      onClick={(e) => {
        // TODO: Find out why we were stopping events here. It was causing clicks on links inside a dialog to not be handled by the router.
        // e.preventDefault();
        // e.stopPropagation();
      }}
      style={$themeVars}
    >
      <div class={styles.backdrop} />
      <form
        class={styles.form}
        onTransitionEnd={() => {
          transition.resolve();
        }}
        onsubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onsubmit?.();
        }}
        onClick={(e) => {
          // e.stopPropagation();
        }}
      >
        <div class={styles.modal}>{ctx.outlet()}</div>
      </form>
    </div>
  );
}

/* ----- Header ----- */

interface DialogHeaderProps {
  dialog: StoreDialogProps["dialog"];
  $title?: State<string>;
  buttons?: Markup[];
}

export function DialogHeader({ dialog, $title, buttons, ...props }: DialogHeaderProps, ctx: ViewContext) {
  return (
    <header class={styles.header}>
      <div class={styles.headerText}>
        <h1 class={styles.title}>{$title}</h1>
      </div>

      <div class={styles.buttons}>
        {buttons && buttons.length > 0 ? buttons : []}

        <IconButton
          // variant="card"
          // variant="transparent"
          onClick={(e) => {
            e.preventDefault();
            dialog.close();
          }}
        >
          <Icon name="Close" />
        </IconButton>
      </div>
    </header>
  );
}

/* ----- Content ----- */

export function DialogContent(_: {}, ctx: ViewContext) {
  return <div class={styles.content}>{ctx.outlet()}</div>;
}

/* ----- Footer ----- */

export function DialogFooter(_: {}, ctx: ViewContext) {
  return <div class={styles.controls}>{ctx.outlet()}</div>;
}

/* ----- Button ----- */

interface DialogButtonAttrs {
  submit?: boolean;
  onclick: () => void;
}

export function DialogButton({ submit, onclick }: DialogButtonAttrs, ctx: ViewContext) {
  return (
    <button
      class={styles.modalButton}
      onClick={(e: any) => {
        if (!submit) {
          e.preventDefault();
        }
        onclick();
      }}
    >
      {ctx.outlet()}
    </button>
  );
}
