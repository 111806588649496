import { EventEmitter } from "@borf/bedrock";
import { promptForFiles } from "@helpers/promptForFiles";
import {
  cond,
  createRef,
  createState,
  derive,
  repeat,
  t,
  type State,
  type ViewContext,
} from "@manyducks.co/dolla";
import { type UploadEvents } from "@stores/files";
import markdownStyles from "@styles/Markdown.module.css";
import { IconButton } from "@views/IconButton";
import { produce } from "immer";
import prettyBytes from "pretty-bytes";
import Quill from "quill";
import type { Op } from "quill-delta";
import { QuillBinding } from "y-quill";
import * as Y from "yjs";
import styles from "./ChatEditor.module.css";
import { Icon } from "MaterialSymbols";

import { theme } from "@stores";
import { uploadFile } from "@stores/files";
import { QuackLinkBlot } from "quill/QuackLinkBlot";
import { ButtonColor } from "@views/Button";

export interface InputFile {
  uploadId: string;
  file: File;
  progress: number;
  complete: boolean;
  events: EventEmitter<UploadEvents>;
  error?: Error;
}

interface ChatEditorProps {
  onSubmit: (delta: Op[], attachments: string[]) => void;

  $color: State<string>;
}

/**
 * Provides a UI for creating chat messages, including file uploads and message formatting.
 */
export function ChatEditor(props: ChatEditorProps, ctx: ViewContext) {
  const $placeholder = t("workspace.chat.inputBox.inputPlaceholder");
  const editorElement = createRef<HTMLDivElement>();

  const ydoc = new Y.Doc();
  const inputValue = ydoc.getText();

  const [$showPlaceholder, setShowPlaceholder] = createState(true);
  const [$inputHasContent, setInputHasContent] = createState(inputValue.length > 0);
  const [$inputFiles, setInputFiles] = createState<InputFile[]>([]);
  const [$isFocused, setIsFocused] = createState(false);

  ydoc.on("update", () => {
    setInputHasContent(inputValue._length > 0);
    setShowPlaceholder(inputValue._length === 0);
  });

  const $canSend = derive([$inputFiles, $inputHasContent], (files, hasContent) => {
    if (files.length === 0) {
      // Don't allow sending an empty message with no files.
      if (!hasContent) {
        return false;
      }
    } else {
      // Don't allow sending when some files are still uploading.
      if (files.find((f) => !f.complete) || files.find((f) => f.error != null)) {
        return false;
      }
    }
    return true;
  });

  ctx.onMount(() => {
    const editor = new Quill(editorElement.node!, {
      formats: [
        "bold",
        "italic",
        "link",
        "list",
        "blockquote",
        "image",
        "indent",
        "code-block",
        QuackLinkBlot.blotName,
      ],
      modules: {
        magicUrl: true,
        keyboard: {
          bindings: {
            shift_enter: {
              key: [13, "Enter"],
              shiftKey: true,
              handler: (range: any, ctx: any) => {
                editor.insertText(range.index, "\n");
              },
            },
            enter: {
              key: [13, "Enter"],
              handler: (range: any, ctx: any) => {
                if ($canSend.get()) {
                  onSend();
                }
              }, // submit form
            },
          },
        },
      },
    });

    editor.on("selection-change", (range) => {
      // Cursor is in editor if range has a value
      setIsFocused(range != null);
    });

    const binding = new QuillBinding(inputValue, editor);

    ctx.onUnmount(() => {
      binding.destroy();
      editor.scroll.emitter.emit("parent-view-unmounted");
    });
  });

  function onSend() {
    if ($canSend.get()) {
      const attachments = $inputFiles.get().map((f) => f.uploadId);
      const delta = inputValue.toDelta();

      props.onSubmit(delta, attachments);

      inputValue.delete(0, inputValue.length);
      setInputFiles([]);
    }
  }

  function addFiles(files: FileList | File[]) {
    for (const file of Array.from(files)) {
      const events = uploadFile(file);
      let uploadId: string;

      events.on("started", (e) => {
        ctx.log("upload started", e);
        uploadId = e.data.uploadId;
        const inputFile: InputFile = {
          file,
          uploadId,
          progress: 0,
          complete: false,
          events,
        };
        setInputFiles(
          produce((current) => {
            current.push(inputFile);
          }),
        );
      });
      events.on("progress", (e) => {
        ctx.log("upload progress", e);
        setInputFiles(
          produce((current) => {
            const found = current.find((f) => f.uploadId === uploadId);
            if (found) {
              found.progress = e.data.percent;
            }
          }),
        );
      });
      events.on("error", (e) => {
        ctx.log("upload error", e);
        setInputFiles(
          produce((current) => {
            const found = current.find((f) => f.uploadId === uploadId);
            if (found) {
              found.error = e.data.error;
            }
          }),
        );
      });
      events.on("complete", (e) => {
        ctx.log("upload complete", e);
        setInputFiles(
          produce((current) => {
            const found = current.find((f) => f.uploadId === uploadId);
            if (found) {
              found.complete = true;
            }
          }),
        );
      });
    }
  }

  return (
    <div
      class={styles.container}
      style={theme.getTheme$(props.$color)}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <ul class={styles.attachments}>
        {repeat(
          $inputFiles,
          (f) => f.uploadId,
          ($file) => (
            <InputAttachment
              $file={$file}
              onRemove={(file) => {
                // Abort upload and rely on nightly cleanup to remove abandoned chunks and records.
                file.events.emit("abort!", null);

                setInputFiles((files) => {
                  return files.filter((f) => f.uploadId !== file.uploadId);
                });
              }}
            />
          ),
        )}
      </ul>

      <div class={styles.form} style={{ "--color-input-border-focused": "var(--color-user-accent)" }}>
        <IconButton
          onClick={() => {
            promptForFiles({ multiple: true, accept: "*" }).then(addFiles);
          }}
        >
          {/* <Paperclip /> */}
          <Icon name="Attach File Add" />
        </IconButton>

        <div class={[styles.editor, { [styles.focused]: $isFocused }]}>
          <div class={[markdownStyles.markdown]} ref={editorElement} />
          {cond($showPlaceholder, <span class={styles.placeholder}>{$placeholder}</span>)}
        </div>

        <IconButton
          type="button"
          tooltip={t("workspace.chat.inputBox.buttonToolTip")}
          disabled={derive([$canSend], (x) => !x)}
          onClick={onSend}
        >
          <Icon name="Forward To Inbox" fill={false} />
          {/* <MailSend /> */}
          {/* <Send /> */}
        </IconButton>
      </div>
    </div>
  );
}

interface InputAttachmentProps {
  $file: State<InputFile>;
  onRemove: (file: InputFile) => void;
}

// Items have a set height, but variable width based on their aspect ratio.
// Attachments are horizontally aligned in one row that can scroll when there are enough items.
export function InputAttachment(props: InputAttachmentProps, ctx: ViewContext) {
  // TODO: Support returning plain DOM nodes from views and passing them as children.
  // This invalidates the need for refs a lot of the time and makes more sense if you're familiar with browser APIs.

  // const img = document.createElement("img");

  const imgElement = createRef<HTMLImageElement>();

  const [$type, setType] = createState<string>("");
  const [$bytes, setBytes] = createState<string>("");
  const [$progress, setProgress] = createState<number>(0);

  let lastFileId: string;

  ctx.watch([props.$file], ({ uploadId, file, progress }) => {
    if (uploadId !== lastFileId) {
      const reader = new FileReader();
      setBytes(prettyBytes(file.size));

      if (file.type !== "") {
        const type = file.type.split("/")[0].toLowerCase();
        setType(type[0].toUpperCase() + type.slice(1));
      } else {
        setType("File");
      }

      reader.onload = function (e) {
        imgElement.node!.src = e.target?.result as string;
      };
      reader.readAsDataURL(file);

      lastFileId = uploadId;
    }

    ctx.log(progress);
    setProgress(progress);
  });

  return (
    <li class={styles.inputFormAttachment}>
      <div class={styles.attachmentToolbar}>
        <div class={styles.attachmentMeta}>
          <span class={styles.attachmentTypeLabel}>{$type}</span>
          <span class={styles.attachmentSizeLabel}>{$bytes}</span>
        </div>

        <IconButton
          color={ButtonColor.Danger}
          size="small"
          onClick={(e) => {
            e.preventDefault();
            props.onRemove(props.$file.get());
          }}
        >
          <Icon name="Close" />
        </IconButton>
      </div>
      <img ref={imgElement} src="" alt="" />
      <progress class={styles.attachmentProgressBar} value={$progress} />
    </li>
  );
}
