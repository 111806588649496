import Dolla, {
  t,
  cond,
  createRef,
  createState,
  derive,
  repeat,
  State,
  toState,
  type MaybeState,
  type ViewContext,
} from "@manyducks.co/dolla";
import markdownStyles from "@styles/Markdown.module.css";
import scrollStyles from "@styles/ScrollBar.module.css";
import { CollapsibleListSection } from "@views/CollapsibleListSection";
import { FileAttachment } from "@views/FileAttachment";
import { IconButton } from "@views/IconButton";
import { Switch } from "@views/Switch";
import { TagInput } from "@views/TagInput";
import Quill from "quill";
import type { Note } from "schemas";
import { nav, notes } from "@stores";
import { type Awareness } from "y-protocols/awareness";
import { QuillBinding } from "y-quill";
import * as Y from "yjs";
import styles from "./TextEditor.module.css";
import { Icon } from "MaterialSymbols";
import { QuackLinkBlot } from "quill/QuackLinkBlot";

interface OutlineHeading {
  title: string;
  anchor: string;
  level: number;
  parent?: OutlineHeading;
  children: OutlineHeading[];
}

// NOTE: Quill modules are registered in browser/app.ts

interface TextEditorProps {
  $note: State<Note>;

  addFiles: (files: File[]) => void;

  autoFocus?: boolean;

  accentColor?: MaybeState<string>;

  onFocus?: () => void;
  onBlur?: () => void;

  // Content is provided through a Y.Text field.
  content: Y.Text;
  awareness?: Awareness;

  readOnly?: MaybeState<boolean>;

  scrollable?: MaybeState<boolean>;
  // scrollContainerRef: Writable<any>;
}

const toolbarIconSize = 22;
const toolbarIconWeight = 300;

export function TextEditor(props: TextEditorProps, ctx: ViewContext) {
  const { content, awareness, addFiles } = props;
  const editorElement = createRef<HTMLDivElement>();
  const $readOnly = toState(props.readOnly);
  const $scrollable = toState(props.scrollable);

  const toolbarId = ctx.uid + "_toolbar";

  let editor: Quill;
  let binding: QuillBinding;

  const [$toolbarState, setToolbarState] = createState<{
    bold: boolean;
    italic: boolean;
    strike: boolean;
    blockquote: boolean;
    indent: number;
    "code-block": boolean;
    list: "ordered" | "bullet" | null;
    header: number | null;
  }>({
    bold: false,
    italic: false,
    strike: false,
    blockquote: false,
    indent: 0,
    "code-block": false,
    list: null,
    header: null,
  });

  const [$metaMenuOpen, setMetaMenuOpen] = createState(
    sessionStorage.getItem("ui.notes.meta.metaMenuOpen") === "true",
  );
  ctx.watch([$metaMenuOpen], (open) => {
    sessionStorage.setItem("ui.notes.meta.metaMenuOpen", JSON.stringify(open));
  });

  const $favorite = derive([nav.$favorites, props.$note], (favorites, note) => {
    return favorites.find((f) => f.path === `/projects/${note.projectId}/notes/${note.id}`);
  });

  function updateToolbarState() {
    if (!editor.hasFocus()) return; // Fix: Steals focus from active element when getFormat is called.

    const format = editor.getFormat() as Record<string, any>;

    setToolbarState({
      bold: format.bold ?? false,
      italic: format.italic ?? false,
      strike: format.strike ?? false,
      blockquote: format.blockquote ?? false,
      indent: format.indent ?? 0,
      "code-block": format["code-block"] ?? false,
      list: format.list ?? null,
      header: format.header ?? null,
    });
  }

  ctx.onMount(() => {
    editor = new Quill(editorElement.node!, {
      readOnly: $readOnly.get(),
      // theme: "snow",
      formats: [
        "bold",
        "header",
        "italic",
        "strike",
        "link",
        "list",
        "blockquote",
        "indent",
        "image",
        "indent",
        "code-block",
        QuackLinkBlot.blotName,
      ],
      modules: {
        cursors: true,
        magicUrl: true,
        history: {
          userOnly: true, // Don't undo changes from remote users.
        },
      },
      // scrollingContainer: props.scrollContainerRef.get(),
    });

    binding = new QuillBinding(content, editor, awareness);

    // Set link blot context object so it can display live link data and open cards.
    // (editor.scroll as any).emitter.on("quack-link-attached", (blot: QuackLinkBlot) => {
    //   blot.setContext(ctx, props.cardId);
    // });

    if (props.onFocus) {
      editor.root.addEventListener("focus", () => {
        props.onFocus!();
      });
    }

    if (props.onBlur) {
      editor.root.addEventListener("blur", () => {
        props.onBlur!();
      });
    }

    editor.on("selection-change", updateToolbarState);
    editor.on("text-change", updateToolbarState);

    updateHeadings();
    editor.on("text-change", updateHeadings);

    if (props.autoFocus) {
      editor.setSelection(editor.getLength(), 0, "silent");
    }
  });

  ctx.onUnmount(() => {
    binding?.destroy();
    editor.scroll.emitter.emit("parent-view-unmounted");
  });

  ctx.watch([$readOnly], (readOnly) => {
    if (readOnly) {
      editor.disable();
    } else {
      editor.enable();
    }
  });

  const $tags = derive([props.$note], (n) => (n ? [...n.tags].sort() : []));
  const $tagCount = derive([$tags], (t) => t.length);
  const $attachments = derive([props.$note], (n) => n?.attachments ?? []);
  const $attachmentCount = derive([$attachments], (a) => a.length);

  const [$headings, setHeadings] = createState<OutlineHeading[]>([]);

  // TODO: Parse note into OutlineHeading array
  function updateHeadings() {
    const headings: OutlineHeading[] = [];

    let buffer: string = "";
    let level: number = 1;

    const delta = props.content.toDelta();

    for (const op of delta) {
      if (typeof op.insert === "string") {
        if (op.insert === "\n" && typeof op.attributes?.header == "number") {
          if (op.attributes.header > level) {
            // Starting subheading
            level = op.attributes.header;
          } else if (op.attributes.header <= level) {
            // Higher level heading
            level = op.attributes.header;
          }

          // Heading terminator. Load buffer as heading.
          if (buffer.length > 0) {
            headings.push({
              title: buffer,
              anchor: "",
              level,
              children: [],
            });
          }
          buffer = "";
        } else {
          buffer = op.insert.split("\n").at(-1);
        }
      }
    }

    setHeadings(headings);

    return headings;
  }

  return (
    <div
      class={{
        [styles.container]: true,
        [styles.scrollable]: $scrollable,
      }}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div class={styles.layout}>
        <div id={toolbarId} class={[styles.toolbar, { [styles.hidden]: $readOnly }]}>
          <div class={[styles.toolbarContent, { [styles.metaMenuOpen]: $metaMenuOpen }]}>
            <button
              class={[styles.toolbarButton, { [styles.active]: derive([$toolbarState], (s) => s.bold) }]}
              type="button"
              onClick={(e) => {
                e.preventDefault();
                const format = editor.getFormat();
                if (format.bold) {
                  editor.format("bold", false, "user");
                } else {
                  editor.format("bold", true, "user");
                }
              }}
            >
              <Icon name="Format Bold" opticalSize={toolbarIconSize} weight={toolbarIconWeight} />
            </button>
            <button
              type="button"
              class={[styles.toolbarButton, { [styles.active]: derive([$toolbarState], (s) => s.italic) }]}
              onClick={(e) => {
                e.preventDefault();
                const format = editor.getFormat();
                if (format.italic) {
                  editor.format("italic", false, "user");
                } else {
                  editor.format("italic", true, "user");
                }
              }}
            >
              <Icon name="Format Italic" opticalSize={toolbarIconSize} weight={toolbarIconWeight} />
            </button>
            <button
              type="button"
              class={[styles.toolbarButton, { [styles.active]: derive([$toolbarState], (s) => s.strike) }]}
              onClick={(e) => {
                e.preventDefault();
                const format = editor.getFormat();
                if (format.strike) {
                  editor.format("strike", false, "user");
                } else {
                  editor.format("strike", true, "user");
                }
              }}
            >
              <Icon name="Format Strikethrough" opticalSize={toolbarIconSize} weight={toolbarIconWeight} />
              {/* <StrikeIcon /> */}
            </button>

            <div class={styles.toolbarDivider} />

            <button
              type="button"
              class={[
                styles.toolbarButton,
                { [styles.active]: derive([$toolbarState], (s) => s.header === 1) },
              ]}
              onClick={(e) => {
                e.preventDefault();
                const format = editor.getFormat();
                if (format.header === 1) {
                  editor.format("header", null, "user");
                } else {
                  editor.format("header", 1, "user");
                }
              }}
            >
              <Icon name="Format H1" opticalSize={toolbarIconSize} weight={toolbarIconWeight} />
            </button>
            <button
              type="button"
              class={[
                styles.toolbarButton,
                { [styles.active]: derive([$toolbarState], (s) => s.header === 2) },
              ]}
              onClick={(e) => {
                e.preventDefault();
                const format = editor.getFormat();
                if (format.header === 2) {
                  editor.format("header", null, "user");
                } else {
                  editor.format("header", 2, "user");
                }
              }}
            >
              <Icon name="Format H2" opticalSize={toolbarIconSize} weight={toolbarIconWeight} />
            </button>
            <button
              type="button"
              class={[
                styles.toolbarButton,
                { [styles.active]: derive([$toolbarState], (s) => s.header === 3) },
              ]}
              onClick={(e) => {
                e.preventDefault();
                const format = editor.getFormat();
                if (format.header === 3) {
                  editor.format("header", null, "user");
                } else {
                  editor.format("header", 3, "user");
                }
              }}
            >
              <Icon name="Format H3" opticalSize={toolbarIconSize} weight={toolbarIconWeight} />
            </button>

            <div class={styles.toolbarDivider} />

            <button
              type="button"
              class={[
                styles.toolbarButton,
                { [styles.active]: derive([$toolbarState], (s) => s.list === "bullet") },
              ]}
              onClick={(e) => {
                e.preventDefault();
                const format = editor.getFormat();
                if (format.list === "bullet") {
                  editor.format("list", null, "user");
                } else {
                  editor.format("list", "bullet", "user");
                }
              }}
            >
              <Icon name="Format List Bulleted" opticalSize={toolbarIconSize} weight={toolbarIconWeight} />
            </button>
            <button
              type="button"
              class={[
                styles.toolbarButton,
                { [styles.active]: derive([$toolbarState], (s) => s.list === "ordered") },
              ]}
              onClick={(e) => {
                e.preventDefault();
                const format = editor.getFormat();
                if (format.list === "ordered") {
                  editor.format("list", null, "user");
                } else {
                  editor.format("list", "ordered", "user");
                }
              }}
            >
              <Icon name="Format List Numbered" opticalSize={toolbarIconSize} weight={toolbarIconWeight} />
            </button>
            <button
              type="button"
              class={[
                styles.toolbarButton,
                { [styles.active]: derive([$toolbarState], (s) => s.blockquote) },
              ]}
              onClick={(e) => {
                e.preventDefault();
                const format = editor.getFormat();
                if (format.blockquote) {
                  editor.format("blockquote", null, "user");
                } else {
                  editor.format("blockquote", true, "user");
                }
              }}
            >
              <Icon
                name="Format Quote"
                opticalSize={toolbarIconSize}
                weight={toolbarIconWeight}
                fill={true}
              />
            </button>

            <div class={styles.toolbarDivider} />

            <button
              type="button"
              class={styles.toolbarButton}
              disabled={derive([$toolbarState], (s) => s.indent === 0)}
              onClick={(e) => {
                e.preventDefault();
                const format = editor.getFormat();
                ctx.log(format);
                editor.format("indent", ($toolbarState.get().indent - 1) % 3, "user");
              }}
            >
              <Icon name="Format Indent Decrease" opticalSize={toolbarIconSize} weight={toolbarIconWeight} />
            </button>

            <button
              type="button"
              class={styles.toolbarButton}
              disabled={derive([$toolbarState], (s) => s.indent >= 2)}
              onClick={(e) => {
                e.preventDefault();
                editor.format("indent", ($toolbarState.get().indent + 1) % 3, "user");
              }}
            >
              <Icon name="Format Indent Increase" opticalSize={toolbarIconSize} weight={toolbarIconWeight} />
            </button>

            <div class={styles.toolbarDivider} />

            <button
              type="button"
              class={[
                styles.toolbarButton,
                { [styles.active]: derive([$toolbarState], (s) => s["code-block"]) },
              ]}
              onClick={(e) => {
                e.preventDefault();
                const format = editor.getFormat();
                if (format["code-block"]) {
                  editor.format("code-block", null, "user");
                } else {
                  editor.format("code-block", true, "user");
                }
              }}
            >
              <Icon name="Code" opticalSize={toolbarIconSize} weight={toolbarIconWeight} />
            </button>
          </div>

          <div class={styles.metaButtonGroup}>
            <IconButton
              onClick={() => {
                if ("clipboard" in window.navigator) {
                  const note = props.$note.get()!;
                  const permalink = new URL(`/p/${note.linkingId}`, window.location.origin);
                  window.navigator.clipboard.writeText(permalink.toString()).then(() => {
                    alert(`Wrote permalink to clipboard: ${permalink.toString()}`);
                  });
                }
              }}
            >
              <Icon name="Link" />
            </IconButton>

            <IconButton
              onClick={() => {
                setMetaMenuOpen((current) => !current);
              }}
            >
              {cond($metaMenuOpen, <Icon name="Right Panel Close" />, <Icon name="Right Panel Open" />)}
            </IconButton>
          </div>
        </div>

        <div class={styles.content}>
          <div
            class={[styles.editor, markdownStyles.markdown, { [styles.metaMenuOpen]: $metaMenuOpen }]}
            ref={editorElement}
          ></div>

          <aside class={[styles.metaMenu, { [styles.open]: $metaMenuOpen }]}>
            <button
              class={styles.metaMenuShade}
              onClick={() => {
                setMetaMenuOpen(false);
              }}
            />

            <div class={[styles.metaContent, scrollStyles.scrollable]}>
              <div class={styles.metaSwitchGroup}>
                <label for="note-pinned-switch">
                  {t("workspace.project.notes.details.meta.pinnedLabel")}
                </label>
                <Switch
                  id="note-pinned-switch"
                  $value={derive([props.$note], (n) => n.isPinned)}
                  onChange={() => {
                    const note = props.$note.get();
                    notes.setPinned(note.id, !note.isPinned);
                  }}
                />
              </div>

              <div class={styles.metaSwitchGroup}>
                <label for="note-favorite-switch">
                  {t("workspace.project.notes.details.meta.favoriteLabel")}
                </label>
                <Switch
                  id="note-favorite-switch"
                  $value={derive([$favorite], (f) => f != null)}
                  onChange={() => {
                    const favorite = $favorite.get();
                    if (favorite) {
                      nav.removeFavorite(favorite.id);
                    } else {
                      const note = props.$note.get();
                      nav.addFavorite(`/projects/${note.projectId}/notes/${note.id}`);
                    }
                  }}
                />
              </div>

              <CollapsibleListSection $title={t("workspace.project.notes.details.meta.outlineTitle")}>
                <div class={styles.metaSectionContent}>
                  {cond(
                    derive([$headings], (h) => h.length > 0),
                    <ul class={styles.outlineList}>
                      {repeat(
                        $headings,
                        (h, i) => i,
                        ($heading) => {
                          return (
                            <li class={styles.outlineItem} data-level={derive([$heading], (h) => h.level)}>
                              <a href={derive([$heading], (h) => h.anchor)}>
                                {derive([$heading], (h) => h.title)}
                              </a>
                            </li>
                          );
                        },
                      )}
                    </ul>,
                    <p class={styles.emptyMessage}>No headings.</p>,
                  )}
                </div>
              </CollapsibleListSection>

              <CollapsibleListSection
                $title={t("workspace.project.notes.details.meta.attachmentsTitle")}
                $itemCount={$attachmentCount}
              >
                <div class={styles.metaSectionContent}>
                  <ul class={styles.metaAttachmentsList}>
                    {repeat(
                      $attachments,
                      (a) => a.id,
                      ($a) => (
                        <li>
                          <FileAttachment
                            $attachment={$a}
                            onDelete={(file) => {
                              const note = props.$note.get()!;
                              Dolla.http.delete(`/api/notes/${note.id}/attachments/${file.id}`);
                            }}
                          />
                        </li>
                      ),
                    )}
                  </ul>
                  <div class={styles.metaAttachmentUpload}>
                    <input
                      id="attachmentUploadInput"
                      class={styles.metaAttachmentUploadInput}
                      type="file"
                      multiple
                      onChange={(e) => {
                        const target = e.currentTarget as HTMLInputElement;
                        const files = Array.from(target.files ?? []);

                        addFiles(files);
                      }}
                    />
                    <label for="attachmentUploadInput" class={styles.metaAttachmentUploadLabel}>
                      {t("workspace.project.notes.details.meta.attachmentsUploadLabel")}
                    </label>
                  </div>
                </div>
              </CollapsibleListSection>

              <CollapsibleListSection
                $title={t("workspace.project.notes.details.meta.tagsTitle")}
                $itemCount={derive([$tags], (t) => t.length)}
              >
                <div class={styles.metaSectionContent}>
                  <TagInput
                    $value={$tags}
                    onChange={({ tags }) => {
                      const note = props.$note.get()!;
                      notes.setTags(note.id, tags);
                    }}
                  />
                </div>
              </CollapsibleListSection>

              <CollapsibleListSection $title={t("workspace.project.notes.details.meta.timestampsTitle")}>
                <div class={styles.metaSectionContent}>
                  <ul class={styles.timestampList}>
                    <li>
                      {t("absoluteTime.createdAtTimestamp", {
                        timestamp: Dolla.i18n.dateTime(
                          derive([props.$note], (n) => n.createdAt),
                          { dateStyle: "medium", timeStyle: "short" },
                        ),
                      })}
                    </li>

                    <li>
                      {t("absoluteTime.updatedAtTimestamp", {
                        timestamp: Dolla.i18n.dateTime(
                          derive([props.$note], (n) => n.updatedAt),
                          { dateStyle: "medium", timeStyle: "short" },
                        ),
                      })}
                    </li>
                  </ul>
                </div>
              </CollapsibleListSection>
            </div>
          </aside>
        </div>
      </div>
    </div>
  );
}
