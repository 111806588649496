import Dolla from "@manyducks.co/dolla";

// Each breakpoint begins at its width in pixels
const Breakpoints = {
  S: 480,
  M: 767,
  L: 1023,
  XL: 1279,
};

const debug = Dolla.createLogger("📦 stores/breakpoint");

const [$width, setWidth] = Dolla.createState(0);

export { $width };

Dolla.onMount(() => {
  window.addEventListener(
    "resize",
    () => {
      setWidth(window.innerWidth);
    },
    { passive: true },
  );

  setWidth(window.innerWidth);
});

/**
 * Returns a new state that is true when page width is at least `width`.
 */
export function min$(width: number) {
  return Dolla.derive([$width], (current) => current >= width);
}

/**
 * Returns a new state that is true when page width is less than `width`.
 */
export function max$(width: number) {
  return Dolla.derive([$width], (current) => current < width);
}

// Breakpoints are true when screen width is at least as wide as their value in pixels
export const $sm = min$(Breakpoints.S);
export const $md = min$(Breakpoints.M);
export const $lg = min$(Breakpoints.L);
export const $xl = min$(Breakpoints.XL);
