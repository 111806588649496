import { PlainDate } from "@helpers/PlainDate";
import { sticky } from "@helpers/sticky";
import Dolla, {
  cond,
  createSettableState,
  derive,
  repeat,
  t,
  type State,
  type ViewContext,
} from "@manyducks.co/dolla";
import { Button } from "@views/Button";
import { CardContent, CardToolbar } from "@views/Card";
import { CollapsibleListSection } from "@views/CollapsibleListSection";
import { TaskListItem } from "@views/TaskListItem";
import { TextInput } from "@views/TextInput";
import { ToolBar } from "@views/ToolBar";
import { getHours, isBefore, isSameDay, startOfDay } from "date-fns";
import { Task } from "schemas";
import { parseDueDate } from "../parseDueDate";
import styles from "./DayDetails.module.css";

import { auth, tasks } from "@stores";
import { Icon } from "MaterialSymbols";

interface DayDetailsProps {
  $projectId: State<number>;
  $selectedDate: State<Date | undefined>;
  $canInteract: State<boolean>;
  onBackButtonClicked: () => void;
}

export function DayDetails(props: DayDetailsProps, ctx: ViewContext) {
  const $latestDate = derive([props.$selectedDate], sticky());

  const $$inputText = createSettableState("");

  const $tasks = derive([props.$projectId, tasks.$cache, props.$selectedDate], (projectId, tasks, date) => {
    let matches: Task[] = [];

    if (date != null) {
      for (const task of tasks.values()) {
        if (task.projectId !== projectId) continue;

        if (task.completedAt == null && isSameDay(parseDueDate(task.dueDate), date)) {
          matches.push(task);
        } else if (task.completedAt != null && isSameDay(task.completedAt, date)) {
          matches.push(task);
        }
      }
    }

    return matches;
  });

  const $activeTasks = derive([$tasks], (tasks) => tasks.filter((t) => t.completedAt == null));
  const $completedTasks = derive([$tasks], (tasks) => tasks.filter((t) => t.completedAt != null));

  const $completedGroups = derive([$completedTasks], (tasks) => groupByHour(tasks));

  const $dateIsInPast = derive(
    [props.$selectedDate],
    (date) => !date || isBefore(startOfDay(date), startOfDay(new Date())),
  );

  return (
    <div class={styles.layout}>
      <CardContent>
        <div class={styles.content}>
          <h1>
            {Dolla.i18n.dateTime($latestDate, {
              year: "numeric",
              month: "short",
              day: "numeric",
              weekday: "short",
            })}
          </h1>

          <CollapsibleListSection
            $title={t("workspace.project.tasks.calendar.dayView.due.title")}
            $itemCount={derive([$activeTasks], (x) => x.length)}
          >
            {cond(
              derive([$activeTasks], (x) => x.length === 0),
              <p class={styles.emptyListMessage}>
                {t("workspace.project.tasks.calendar.dayView.due.emptyListMessage")}
              </p>,
              <ul class={styles.taskList}>
                {repeat(
                  $activeTasks,
                  (t) => t.id,
                  ($task) => (
                    <TaskListItem $task={$task} meta={{ dueDate: false }} />
                  ),
                )}
              </ul>,
            )}
          </CollapsibleListSection>

          <CollapsibleListSection
            $title={t("workspace.project.tasks.calendar.dayView.done.title")}
            $itemCount={derive([$completedTasks], (x) => x.length)}
          >
            {cond(
              derive([$completedTasks], (x) => x.length === 0),
              <p class={styles.emptyListMessage}>
                {t("workspace.project.tasks.calendar.dayView.done.emptyListMessage")}
              </p>,
              <ul class={styles.taskList}>
                {repeat(
                  $completedGroups,
                  (g) => g.hour,
                  ($group, $index) => {
                    return (
                      <li class={styles.taskListHour}>
                        <section
                          class={[
                            styles.hourGroup,
                            {
                              [styles.first]: derive([$index], (i) => i === 0),
                              [styles.last]: derive([$completedGroups, $index], (g, i) => g.length - 1 === i),
                            },
                          ]}
                        >
                          <header>
                            <div class={styles.hourGroupIcon}>
                              <Icon name="Schedule" size={20} />
                            </div>
                            <h4>{derive([$group], (g) => g.label)}</h4>
                          </header>
                          <ul class={styles.taskList}>
                            {repeat(
                              derive([$group], (g) => g.items),
                              (t) => t.id,
                              ($task) => {
                                return (
                                  <TaskListItem
                                    $task={$task}
                                    meta={{
                                      // We already know it's completed today.
                                      completedAt: "time",
                                    }}
                                  />
                                );
                              },
                            )}
                          </ul>
                        </section>
                      </li>
                    );
                  },
                )}
              </ul>,
            )}
          </CollapsibleListSection>
        </div>
      </CardContent>

      <CardToolbar>
        <ToolBar>
          <form
            class={styles.searchOrCreate}
            onSubmit={(e) => {
              e.preventDefault();

              if (!props.$canInteract.get()) {
                ctx.info("Bailing. $canInteract is false.");
                return;
              }

              ctx.info("Can interact. Submitting.");

              const projectId = props.$projectId.get();
              const date = props.$selectedDate.get();

              tasks
                .createTask({
                  projectId: projectId,
                  delta: [
                    { insert: $$inputText.get() },
                    { insert: "\n", attributes: { header: 1 } },
                    { insert: "\n" },
                  ],
                  dueDate: date ? PlainDate.format(date) : undefined,
                  assignedUserId: auth.$me.get()!.id,
                  tags: [],
                })
                .then((task) => {
                  $$inputText.set("");
                  setTimeout(() => {
                    // scrollToTask(task.id);
                    tasks.setHighlightedTaskId(task.id);
                  }, 20);
                });
            }}
          >
            <TextInput
              $$value={$$inputText}
              name="taskTitle"
              disabled={derive(
                [props.$canInteract, $dateIsInPast],
                (canInteract, inPast) => !canInteract || inPast,
              )}
              placeholder={t("workspace.project.tasks.searchOrCreate.inputPlaceholder")}
            />
            <Button
              type="submit"
              disabled={derive(
                [$$inputText, props.$selectedDate, props.$canInteract],
                (text, date, canInteract) => {
                  ctx.log({
                    text,
                    date,
                    canInteract,
                    beforeCheck: isBefore(startOfDay(date!), startOfDay(new Date())),
                  });

                  return (
                    !canInteract ||
                    text.trim().length === 0 ||
                    date == null ||
                    isBefore(startOfDay(date), startOfDay(new Date()))
                  );
                },
              )}
            >
              {t("workspace.project.tasks.searchOrCreate.buttonText")}
            </Button>
          </form>
        </ToolBar>
      </CardToolbar>
    </div>
  );
}

interface HourGroup<T> {
  hour: number;
  label: string;
  items: T[];
}

function groupByHour(items: Task[]): HourGroup<Task>[] {
  const groups = new Map<string, HourGroup<Task>>();

  const sortedItems = items.sort((a, b) => {
    if (a.completedAt! < b.completedAt!) {
      return -1;
    } else if (a.completedAt! > b.completedAt!) {
      return 1;
    } else {
      return 0;
    }
  });

  for (const item of sortedItems) {
    const date = new Date(item.completedAt!);
    const hourLabel = Dolla.i18n.dateTime(date, { hour: "numeric" }).get();

    if (groups.has(hourLabel)) {
      const items = groups.get(hourLabel)!.items;
      items.push(item);
    } else {
      groups.set(hourLabel, {
        hour: getHours(date),
        label: hourLabel,
        items: [item],
      });
    }
  }

  return [...groups.values()];
}
