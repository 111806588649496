export default {
  "layout": "ProjectOverview_layout_e9f1e",
  "content": "ProjectOverview_content_e9f1e",
  "section": "ProjectOverview_section_e9f1e",
  "subsection": "ProjectOverview_subsection_e9f1e",
  "emptyListMessage": "ProjectOverview_emptyListMessage_e9f1e",
  "quickActionsSection": "ProjectOverview_quickActionsSection_e9f1e",
  "quickActions": "ProjectOverview_quickActions_e9f1e",
  "userList": "ProjectOverview_userList_e9f1e",
  "emptyUserListMessage": "ProjectOverview_emptyUserListMessage_e9f1e",
  "userListItem": "ProjectOverview_userListItem_e9f1e",
  "userLayout": "ProjectOverview_userLayout_e9f1e",
  "userInfo": "ProjectOverview_userInfo_e9f1e",
  "userIcon": "ProjectOverview_userIcon_e9f1e",
  "roleBadge": "ProjectOverview_roleBadge_e9f1e",
  "userText": "ProjectOverview_userText_e9f1e",
  "userName": "ProjectOverview_userName_e9f1e",
  "userNameLabel": "ProjectOverview_userNameLabel_e9f1e",
  "userStatus": "ProjectOverview_userStatus_e9f1e",
  "userActions": "ProjectOverview_userActions_e9f1e",
  "userMeta": "ProjectOverview_userMeta_e9f1e",
  "userMetaLabel": "ProjectOverview_userMetaLabel_e9f1e",
  "cardLauncherIcon": "ProjectOverview_cardLauncherIcon_e9f1e",
  "menuPanelIcon": "ProjectOverview_menuPanelIcon_e9f1e",
  "addUserSection": "ProjectOverview_addUserSection_e9f1e",
  "justifyEnd": "ProjectOverview_justifyEnd_e9f1e",
  "toolButtonIcon": "ProjectOverview_toolButtonIcon_e9f1e",
  "editFormGroup": "ProjectOverview_editFormGroup_e9f1e",
  "inviteListItem": "ProjectOverview_inviteListItem_e9f1e",
  "inviteListItemName": "ProjectOverview_inviteListItemName_e9f1e"
};import "ni:sha-256;jp_l3k--6nkeuj5GuegPqB5DjJAF-l_2sB2JunfkUC0";