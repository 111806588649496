import { sticky } from "@helpers/sticky";
import Dolla, {
  cond,
  createRef,
  createState,
  derive,
  repeat,
  t,
  type State,
  type ViewContext,
} from "@manyducks.co/dolla";
import { Button } from "@views/Button";
// import { makeLoaderState } from "@views/CardLoader";
import scrollStyles from "@styles/ScrollBar.module.css";
import { CollapsibleListSection } from "@views/CollapsibleListSection";
import { IconButton } from "@views/IconButton";
import styles from "./Chat.module.css";
import { ChatDetailStack } from "./ChatDetailStack/ChatDetailStack";
import { ChatEditor } from "./ChatEditor/ChatEditor";
import { ChatInfoDialog } from "./ChatInfoDialog/ChatInfoDialog";
import { ChatMessageList } from "./ChatMessageList/ChatMessageList";
import { Icon } from "MaterialSymbols";

import { auth, chat, dialog, nav, projects, push, theme } from "@stores";

interface ChatProps {
  $chatColor: State<string>;
  onShowSettings?: () => void;
}

export function Chat(props: ChatProps, ctx: ViewContext) {
  ctx.setName("Chat");

  // NOTE: Add SELECT with projects in list
  // move projectId into store and export with setProjectId
  // see Settings.tsx; search "$currentLanguage"

  const $project = derive([chat.$projectId, projects.$cache], (id, projects) =>
    id ? projects.get(id) : null,
  );
  const $latestProject = derive([$project], sticky());

  const $projectsWithChat = derive([projects.$cache], (projects) =>
    [...projects.values()]
      .filter((p) => p.archivedAt == null && p.users.length > 1)
      .sort((a, b) => a.name.localeCompare(b.name)),
  );

  // const $$projectId = $$<number>();
  // const loader = makeLoaderState();
  // loader.hideLoader();

  const $messages = derive([chat.$projectId, chat.$messages], (projectId, messages) =>
    messages
      .filter((m) => m.projectId === projectId)
      .sort((a, b) => {
        if (a.createdAt < b.createdAt) {
          return -1;
        } else if (a.createdAt > b.createdAt) {
          return 1;
        } else {
          return 0;
        }
      }),
  );

  const scrollContainer = createRef<HTMLElement>();

  const [$initialScroll, setInitialScroll] = createState(true);
  const [$scrollOffsetBottom, setScrollOffsetBottom] = createState(
    Number(localStorage.getItem("ui.chatScrollOffset") ?? 0),
  );
  // TODO: Store scroll offset from bottom. Whenever page size changes, make sure offset from bottom is restored. Update offset when user scrolls.
  // Or... use last read timestamp and scrollIntoView the most recently read message.

  // Reset scroll offset to bottom when project changes.
  ctx.watch([$latestProject], (_) => {
    setInitialScroll(true);
    setScrollOffsetBottom(0);
  });

  function _getScrollOffset(element: HTMLElement) {
    return element.scrollHeight - (element.scrollTop + element.clientHeight);
  }

  function _scrollToOffset(targetOffset: number = $scrollOffsetBottom.get()) {
    const container = scrollContainer.node!;
    const initialScroll = $initialScroll.get();

    setTimeout(() => {
      const currentOffset = _getScrollOffset(container);
      container.scrollBy({
        top: currentOffset - targetOffset,
        behavior: initialScroll ? "instant" : "smooth",
      });
    }, 10);

    setInitialScroll(false);
  }

  function _onScroll(e: Event) {
    ctx.log("SCROLL");

    const container = scrollContainer.node!;
    const currentOffset = _getScrollOffset(container);

    localStorage.setItem("ui.chatScrollOffset", String(currentOffset));
  }

  ctx.onMount(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        // ctx.log("resized", entry);

        _scrollToOffset();
      }
    });

    resizeObserver.observe(scrollContainer.node!);

    _scrollToOffset();

    ctx.onUnmount(() => {
      resizeObserver.disconnect();
    });
  });

  // function scrollToBottom(animate = false) {
  //   const el = $$scrollContainer.get()!;

  //   // Reset unread message count.
  //   badge.clear();

  //   el.scrollTo({
  //     top: el.scrollHeight,
  //     behavior: animate ? "smooth" : "instant",
  //   });
  // }

  // const $blendedUserColor = derive([auth.$me, props.$chatColor], (me, chatColor) => {
  //   return theme.getBlendedColor$(me?.color ?? "#888", chatColor);
  // });

  const $userColor = derive([auth.$me], (me) => me?.color ?? "#888");

  return (
    <div class={styles.chat} style={theme.getTheme$(props.$chatColor)}>
      <ChatDetailStack
        $showDetail={derive([chat.$projectId], (projectId) => projectId != null)}
        listContent={
          <div class={styles.layout}>
            <div
              class={styles.chatHeader}
              // style={{ backgroundImage: "url(/textures/inspiration-geometry.png)" }}
            >
              <span class={styles.chatTitle}>{t("workspace.project.overview.features.chatLabel")}</span>
            </div>

            <div class={styles.listContent}>
              <CollapsibleListSection
                $title={t("workspace.nav.projects.title")}
                $itemCount={derive([$projectsWithChat], (projects) => projects.length)}
              >
                <ul class={styles.chatList}>
                  {repeat(
                    $projectsWithChat,
                    (p) => p.id,
                    ($project) => (
                      <li style={theme.getTheme$(derive([$project], (p) => p.color))}>
                        <button
                          class={[
                            styles.chatListItem,
                            {
                              [styles.active]: derive(
                                [$project, chat.$projectId],
                                (project, id) => project?.id === id,
                              ),
                            },
                          ]}
                          onClick={() => {
                            chat.setProjectId($project.get().id);
                          }}
                          title={derive([$project], (p) => p.name)}
                        >
                          <div class={styles.chatListItemIcon}>
                            <img
                              width="16"
                              height="16"
                              style="image-rendering: pixelated"
                              src="/icons/projects.8.trans.png"
                              alt=""
                            />
                          </div>
                          <span class={styles.chatListItemLabel}>{derive([$project], (p) => p.name)}</span>
                        </button>
                      </li>
                    ),
                  )}
                </ul>
              </CollapsibleListSection>
            </div>
          </div>
        }
        detailContent={
          <div
            class={styles.layout}
            // style={theme.getTheme$(props.$chatColor)}
          >
            <div
              class={styles.chatHeader}
              // style={{ backgroundImage: "url(/textures/inspiration-geometry.png)" }}
            >
              <IconButton onClick={() => chat.setProjectId(undefined)}>
                {/* <ArrowLeft /> */}
                <Icon name="Arrow Back" />
              </IconButton>

              <span class={[styles.chatTitle, styles.project]}>{derive([$project], (p) => p?.name)}</span>

              <div class={styles.chatHeaderButtons}>
                <IconButton
                  onClick={() => {
                    dialog.show(ChatInfoDialog, {
                      color: $project.get()?.color ?? "#888",
                    });
                  }}
                >
                  {/* <Information /> */}
                  <Icon name="Info" fill={false} />
                </IconButton>

                <IconButton onClick={() => nav.closeChat()}>
                  {/* <Close /> */}
                  <Icon name="Close" />
                </IconButton>
              </div>
            </div>

            {cond(
              push.$displayChatBanner,

              <div class={styles.pushDisabledBanner}>
                <p>{t("workspace.chat.pushNotificationsOffBanner.message")}</p>
                <div class={styles.pushDisabledBannerControls}>
                  <Button onClick={push.dismissChatBanner}>
                    {t("workspace.chat.pushNotificationsOffBanner.dismissButtonText")}
                  </Button>
                  <Button
                    onClick={() => {
                      Dolla.router.go("/settings");
                      props.onShowSettings?.();
                    }}
                  >
                    {t("workspace.chat.pushNotificationsOffBanner.showSettingsButtonText")}
                  </Button>
                </div>
              </div>,
            )}

            <div class={[styles.chatMessageList, scrollStyles.scrollable]} ref={scrollContainer}>
              <div class={styles.chatListSpacer} />

              <div class={styles.endOfMessages}>
                <span class={styles.dividerLine} />
                <span class={styles.dividerLabel}>{t("workspace.chat.endOfMessages")}</span>
                <span class={styles.dividerLine} />
              </div>

              <ChatMessageList
                $messages={$messages}
                onMessageAdded={() => {
                  _scrollToOffset();
                }}
              />
            </div>

            <div class={styles.editor}>
              <ChatEditor
                $color={$userColor}
                onSubmit={(delta, attachments) => {
                  const projectId = chat.$projectId.get()!; // Is defined if $canSend is true
                  chat.sendMessage(projectId, delta, attachments);
                }}
              />
            </div>
          </div>
        }
      />
    </div>
  );
}
