import { Icon } from "MaterialSymbols";
import Dolla, { t, cond, createState, derive, type ViewContext } from "@manyducks.co/dolla";
import { FileViewer } from "@views/FileViewer";
import { auth, breakpoint, chat, dialog, io, loader, nav, notes, projects, tasks, theme } from "@stores";
import { Chat } from "./Chat";
import styles from "./Workspace.module.css";
import Mousetrap from "mousetrap";
import { KeyboardShortcutsDialog } from "@dialogs/KeyboardShortcuts/KeyboardShortcuts";
import { SettingsDialog } from "@dialogs/Settings/Settings";
import { ActivityDialog } from "@dialogs/Activity/Activity";
import { SearchDialog } from "@dialogs/Search/Search";
import { ColorSchemes } from "@stores/theme";
import { NewTaskDialog } from "./Project/ProjectOverview/NewTaskDialog/NewTaskDialog";
import { CreateTaskDialog } from "@dialogs/CreateTaskDialog/CreateTaskDialog";
import { CreateProjectDialog } from "@dialogs/CreateProjectDialog/CreateProjectDialog";
import { TaskEditDialog } from "@dialogs/TaskEditDialog/TaskEditDialog";

/**
 * Top level layout for the authenticated portions of the app.
 */
export function Workspace(_: {}, ctx: ViewContext) {
  // const [$menuWidth, setMenuWidth] = createState(Number(localStorage.getItem("ui.menuWidth") ?? 300));
  // const [$chatWidth, setChatWidth] = createState(Number(localStorage.getItem("ui.chatWidth") ?? 300));

  // const [$resizeTarget, setResizeTarget] = createState<"menu" | "chat" | null>(null);

  // ctx.watch([$menuWidth], (menuWidth) => {
  //   localStorage.setItem("ui.menuWidth", String(menuWidth));
  // });

  // ctx.watch([$chatWidth], (chatWidth) => {
  //   localStorage.setItem("ui.chatWidth", String(chatWidth));
  // });
  //

  ctx.set("test", "If you're reading this, context variables are working.");

  const $chatProject = derive([chat.$projectId, projects.$cache], (projectId, projects) => {
    if (!projectId) return null;
    return projects.get(projectId);
  });

  const $chatColor = derive(
    [$chatProject, theme.$placeColor],
    (project, placeColor) => project?.color ?? placeColor,
  );
  const [$chatIsAnimating, setChatIsAnimating] = createState(false);

  function onServiceWorkerMessage(this: ServiceWorkerContainer, event: MessageEvent<any>): any {
    ctx.info("received from worker", event.data);

    if (event.data.type === "chatMessage") {
      const projectId = event.data.project.id as number;
      chat.setProjectId(projectId);
      nav.openChat();
    }
  }

  const [$socketConnected, setSocketConnected] = createState(false);
  const [$isLoaded, setIsLoaded] = createState(false);

  ctx.onMount(async () => {
    if (!auth.$isLoggedIn.get()) {
      Dolla.router.go("/login");
      loader.hideAppLoader();
      return;
    }

    ctx.watch([Dolla.router.$path], (path) => {
      localStorage.setItem("nav.lastVisitedPath", path);
    });

    // Once user becomes logged out, drop them back at the landing page.
    ctx.watch([auth.$isLoggedIn], (isLoggedIn) => {
      if (!isLoggedIn) {
        Dolla.router.go("/login");
      }
    });

    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.addEventListener("message", onServiceWorkerMessage);
    }

    await auth.getUsers();

    const socket = io.socket();

    socket.on("connect", () => {
      auth.getUpdateStatus();
      setSocketConnected(true);
    });

    socket.on("disconnect", () => {
      setSocketConnected(false);
    });

    socket.on("task:update", tasks.taskUpdateReceived);
    socket.on("task:delete", tasks.taskDeleteReceived);

    socket.on("note:update", notes.noteUpdateReceived);
    socket.on("note:delete", notes.noteDeleteReceived);

    // socket.on("settings:update", auth.settingsUpdateReceived);

    socket.on("project:invited", projects.projectInviteReceived); // When a project invite is received
    socket.on("project:added", (projectId) => {}); // When user joins a project
    socket.on("project:update", projects.projectUpdateReceived);
    socket.on("project:delete", (projectId) => {
      projects.projectDeleteReceived(projectId);
      socket.emit("projects:refresh", () => {
        ctx.log("Projects refreshed");
      });
    });

    socket.on("user:update", auth.userUpdateReceived);

    socket.on("chat:message", chat.chatMessageReceived);

    await Promise.all([projects.refreshList(), chat.fetchMessages(), nav.fetchFavorites()]).then(() => {
      loader.hideAppLoader();
      setIsLoaded(true);
    });

    ctx.onUnmount(() => {
      if ("serviceWorker" in navigator) {
        navigator.serviceWorker.removeEventListener("message", onServiceWorkerMessage);
      }
      socket.close();
    });
  });

  ctx.watch([$socketConnected], async (socketConnected) => {
    if (!socketConnected || !$isLoaded.get()) return;

    const promises: Promise<any>[] = [
      auth.getUsers(),
      projects.refreshList(),
      chat.fetchMessages(),
      nav.fetchFavorites(),
    ];

    // remove deleted resources
    const caches: {
      users: number[];
      projects: number[];
      notes: number[];
      tasks: number[];
    } = {
      users: auth.$users.get().map((x) => x.id),
      projects: [...projects.$cache.get().keys()],
      notes: [],
      tasks: [],
    };

    const pattern = Dolla.router.$pattern.get();
    const params = Dolla.router.$params.get();

    const inNotes = pattern?.startsWith("/projects/{#projectId}/notes");
    const inTasks = pattern?.startsWith("/projects/{#projectId}/tasks");

    if (inNotes) {
      caches.notes = [...notes.$cache.get().keys()];
    } else if (inTasks) {
      caches.tasks = [...tasks.$cache.get().keys()];
    }

    const deletedCaches = await auth.validateCache(caches);

    auth.updateCache(deletedCaches.users);
    projects.updateCache(deletedCaches.projects);
    notes.updateCache(deletedCaches.notes);
    tasks.updateCache(deletedCaches.tasks);

    // load latest resources
    if (inNotes) {
      promises.push(notes.fetchIndexFor(Number(params.projectId), true));
    } else if (inTasks) {
      promises.push(tasks.fetchIndexFor(Number(params.projectId), true));
    } else if (pattern === "/") {
      promises.push(tasks.fetchToDos());
    }

    await Promise.all(promises);

    ctx.log("Socket reconnected: data refreshed");
  });

  // check for updates
  const onVisibilityChange = async () => {
    if (document.visibilityState === "visible") {
      // clear notifications
      navigator.serviceWorker.ready.then((reg) => {
        reg.getNotifications().then((notifications) => {
          for (let i = 0; i < notifications.length; i += 1) {
            notifications[i].close();
          }
        });
      });

      // check for update
      const updateAvailable = await auth.getUpdateStatus();
      ctx.info(`UPDATE AVAILABLE: ${updateAvailable}`);
    }
  };

  ctx.onMount(() => {
    window.addEventListener("visibilitychange", onVisibilityChange);
  });

  ctx.onUnmount(() => {
    window.removeEventListener("visibilitychange", onVisibilityChange);
  });

  ctx.onMount(() => {
    Mousetrap.bind("?", () => {
      dialog.show(KeyboardShortcutsDialog);
    });

    Mousetrap.bind("c c", (e) => {
      e.preventDefault();
      nav.toggleChat();
    });
    // Mousetrap.bind("c a", () => {
    //   if (nav.$chatIsOpen.get()) {
    //     alert("This would work if it was set up.");
    //     // Toggle attach menu
    //   }
    // });

    Mousetrap.bind("h", (e) => {
      e.preventDefault();
      if (Dolla.router.$path.get() !== "/") {
        Dolla.router.go("/");
      }
    });

    Mousetrap.bind("f", (e) => {
      e.preventDefault();
      dialog.show(SearchDialog);
    });
    Mousetrap.bind("a", (e) => {
      e.preventDefault();
      dialog.show(ActivityDialog);
    });
    Mousetrap.bind("s", (e) => {
      e.preventDefault();
      dialog.show(SettingsDialog);
    });

    Mousetrap.bind("t up", (e) => {
      e.preventDefault();
      theme.setSelectedScheme(ColorSchemes.Light);
    });
    Mousetrap.bind("t down", (e) => {
      e.preventDefault();
      theme.setSelectedScheme(ColorSchemes.Dark);
    });
    Mousetrap.bind(["t left", "t right"], (e) => {
      e.preventDefault();
      theme.setSelectedScheme(ColorSchemes.System);
    });

    Mousetrap.bind("n n", (e) => {
      e.preventDefault();
      alert("New note not yet implemented.");
      return;
    });
    Mousetrap.bind("n t", (e) => {
      e.preventDefault();

      // TODO: Simplify this logic; maybe combine these dialogs.

      const { projectId } = Dolla.router.$params.get();
      if (projectId) {
        const id = Number(projectId)!;
        dialog.show(NewTaskDialog, {
          projectId: id,
          async onConfirm(info, openAfterSubmit) {
            const task = await tasks.createTask({
              projectId: id,
              dueDate: info.dueDate,
              assignedUserId: auth.$me.get()!.id,
              delta: [{ insert: info.title }, { insert: "\n" }],
            });

            if (openAfterSubmit) {
              dialog.show(TaskEditDialog, {
                task: task,
              });
            }
          },
        });
      } else {
        dialog.show(CreateTaskDialog, {
          async onConfirm(info, openAfterSubmit) {
            const task = await tasks.createTask({
              projectId: info.projectId,
              dueDate: info.dueDate,
              assignedUserId: auth.$me.get()!.id,
              delta: [{ insert: info.title }, { insert: "\n" }],
            });

            if (openAfterSubmit) {
              dialog.show(TaskEditDialog, {
                task: task,
              });
            }
          },
        });
      }
    });
    Mousetrap.bind("n p", (e) => {
      e.preventDefault();

      dialog.show(CreateProjectDialog, {
        accentColor: "#888",
        async onConfirm(info) {
          const project = await projects.createProject(info);
          Dolla.router.go(`/projects/${project.id}`);
        },
      });
    });
  });

  ctx.onUnmount(() => {
    Mousetrap.reset();
  });

  return cond(
    $isLoaded,
    <div
      class={styles.layout}
      style={derive([breakpoint.min$(800), nav.$chatIsOpen], (bp, open) => ({
        "--horizontal-padding-for-chat": (function () {
          if (bp) {
            if (open) {
              return "400px";
            } else {
              return "38px";
            }
          }

          return "0px";
        })(),
      }))}
    >
      <div class={styles.main}>
        {cond(
          auth.$updateAvailable,
          <button
            class={styles.updateBanner}
            onClick={() => {
              // Reload the page.
              window.location.href = window.location.href;
            }}
          >
            <div class={styles.updateBannerIcon}>
              <Icon name="Upgrade" />
            </div>
            <span class={styles.updateBannerText}>{t("workspace.nav.applyUpdateButtonText")}</span>
          </button>,
        )}

        {ctx.outlet()}
      </div>

      <div
        class={[styles.chat, { [styles.open]: nav.$chatIsOpen, [styles.animating]: $chatIsAnimating }]}
        style={theme.getTheme$($chatColor)}
        onTransitionEnd={() => {
          if (!nav.$chatIsOpen.get()) {
            setChatIsAnimating(false);
          }
        }}
      >
        <div class={styles.chatContent}>
          <Chat $chatColor={$chatColor} />
        </div>

        <button
          class={styles.chatTab}
          onClick={() => {
            // Set to current project before opening if there is no current chat project selected.
            if (!nav.$chatIsOpen.get() && chat.$projectId.get() == null) {
              const { projectId } = Dolla.router.$params.get();
              if (projectId != null) {
                chat.setProjectId(Number(projectId));
              }
            }

            setChatIsAnimating(true);

            nav.toggleChat();
          }}
        >
          <div class={styles.chatTabContent}>
            <span class={styles.chatTabIcon}>
              <Icon name="Forum" size={24} />
            </span>
            <span class={styles.chatTabLabel}>
              {cond(nav.$chatIsOpen, t("workspace.chat.closeChatLabel"), t("workspace.chat.openChatLabel"))}
            </span>
            {cond(
              $chatProject,
              <ul class={styles.chatTabMeta}>
                <li class={styles.chatTabMetaUnread}>{t("workspace.chat.unreadCountLabel", { count: 3 })}</li>
                <li class={styles.chatTabMetaName}>{derive([$chatProject], (p) => p?.name)}</li>
              </ul>,
            )}
          </div>
        </button>
      </div>

      {cond(nav.$fileViewerIsOpen, <FileViewer />)}
    </div>,
  );
}
