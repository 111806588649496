import { TaskEditDialog } from "@dialogs/TaskEditDialog/TaskEditDialog";
import Dolla, { cond, derive, t, toState, type State, type ViewContext } from "@manyducks.co/dolla";
import { Note, Task } from "schemas";
import styles from "./RecentListItem.module.css";

import { auth, dialog, notes, projects, tasks, theme } from "@stores";
import { TaskListItem } from "@views/TaskListItem";
import { NoteListItem } from "@views/NoteListItem";

type FavoriteType = "task" | "note";

type Recent = {
  path: string;
  timestamp: string;
};

interface RecentListItemProps {
  $recent: State<Recent>;
  onClick?: (e: Event) => void;
}

export function RecentListItem(props: RecentListItemProps, ctx: ViewContext) {
  const $project = derive([props.$recent, projects.$cache], (favorite, projects) => {
    const projectId = parseInt(favorite.path.split("/")[2]);
    return projects.get(projectId);
  });

  const $details = derive(
    [props.$recent, auth.$me, projects.$cache, notes.$cache, tasks.$cache, Dolla.i18n.$locale],
    (recent, me, projectsCache, notesCache, tasksCache, _) => {
      let title = recent.path;
      let color = "#888";
      let type: FavoriteType = "note";
      let _task: Task | undefined;
      let _note: Note | undefined;

      if (recent.path.startsWith("/projects/")) {
        const projectId = parseInt(recent.path.split("/")[2]);
        const project = projectsCache.get(projectId);
        if (!project) {
          color = "#f00";
          title = "MISSING PROJECT";
        } else {
          color = project.color;

          if (recent.path.includes("/tasks")) {
            const taskId = parseInt(recent.path.split("/")[4]);
            if (!isNaN(taskId)) {
              // SIDE EFFECT
              tasks.ensureTaskIsLoaded(taskId);

              type = "task";

              const task = tasksCache.get(taskId);
              if (task) {
                title = task.title;
                _task = task;
              } else {
                title = "MISSING TASK";
                color = "#f00";
              }
            } else {
              title = t("workspace.nav.favorites.labels.tasksList_projectName", {
                projectName: project.name,
              }).get();
            }
          } else if (recent.path.includes("/notes")) {
            const noteId = parseInt(recent.path.split("/")[4]);
            if (!isNaN(noteId)) {
              // SIDE EFFECT
              notes.ensureNoteIsLoaded(noteId);

              type = "note";

              const note = notesCache.get(noteId);
              if (note) {
                title = note.title;
                _note = note;
              } else {
                title = "MISSING NOTE";
                color = "#f00";
              }
            } else {
              title = t("workspace.nav.favorites.labels.notesList_projectName", {
                projectName: project.name,
              }).get();
            }
          } else {
            // Overview
            title = t("workspace.nav.favorites.labels.overview_projectName", {
              projectName: project.name,
            }).get();
          }
        }
      }

      return { title, color, type, _task, _note };
    },
  );

  const $active = derive([props.$recent, Dolla.router.$path], (recent, path) => recent.path === path);

  return derive([$details], (details) => {
    if (details.type === "task" && details._task != null) {
      return <TaskListItem $task={toState(details._task)} meta={{ project: true }} />;
    }

    if (details.type === "note" && details._note != null) {
      return <NoteListItem $note={toState(details._note)} meta={{ project: true }} />;
    }
  });
}
