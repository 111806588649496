import { extractTitleFromDelta } from "@helpers/extractTitleFromDelta";
import Dolla, { derive, createState, State } from "@manyducks.co/dolla";
import { differenceInMinutes } from "date-fns";
import { produce } from "immer";
import type { Op } from "quill-delta";
import type { Note } from "schemas";
import * as Y from "yjs";

export interface ProjectTagMap {
  [projectId: number]: {
    lastFetchedAt: Date;
    tags: {
      [tag: string]: number;
    };
  };
}

interface CreateNoteOptions {
  projectId: number;
  delta?: Op[];
  tags?: string[];
}

export interface NoteActivity {
  type: "note";
  timestamp: string;
  data: Note;
}

const debug = Dolla.createLogger("📦 stores/notes");

const [$cache, setCache] = createState(new Map<number, Note>());
const $activity = derive([$cache], (notes) => {
  return [...notes.values()]
    .sort((a, b) => {
      if (a.updatedAt > b.updatedAt) {
        return -1;
      } else if (a.updatedAt < b.updatedAt) {
        return +1;
      } else {
        return 0;
      }
    })
    .slice(0, 10)
    .map((note) => ({
      type: "note",
      timestamp: note.updatedAt,
      data: note,
    }));
}) as State<NoteActivity[]>;

export { $cache, $activity };

// Keys are project IDs
const indexCacheMap = new Map<number | null, { lastFetchedAt: Date; resultIds: number[] }>();

/**
 * Returns the wiki index for a given project.
 */
export async function fetchIndexFor(projectId: number | null, fresh = false) {
  const now = new Date();
  const mapped = indexCacheMap.get(projectId);

  if (!fresh && mapped && differenceInMinutes(now, mapped.lastFetchedAt) < 5) {
    const cache = $cache.get();
    const results: Note[] = [];
    for (const id of mapped.resultIds) {
      results.push(cache.get(id)!);
    }
    return results;
  }

  return Dolla.http
    .get<Note[]>(projectId == null ? `/api/notes/activity` : `/api/notes?projectId=${projectId}`)
    .then(async (res) => {
      debug.info(`Fetched ${res.body.length} note(s) for index { projectId: ${projectId}, fresh: ${fresh} }`);

      setCache(
        produce((notes) => {
          for (const note of res.body) {
            const current = notes.get(note.id);
            if (current) {
              Object.assign(current, note);
            } else {
              notes.set(note.id, note);
            }
          }
        }),
      );

      indexCacheMap.set(projectId, {
        lastFetchedAt: now,
        resultIds: res.body.map((note) => note.id),
      });

      return res.body;
    });
}

/**
 * Fetch recently updated notes for project overview.
 */
export async function fetchActivity(projectId: number) {
  const res = await Dolla.http.get<Note[]>(`/api/notes/activity`, {
    query: {
      projectId,
    },
  });

  setCache(
    produce((notes) => {
      for (const note of res.body) {
        notes.set(note.id, note);
      }
    }),
  );
}

export async function createNote(options: CreateNoteOptions) {
  return Dolla.http.post<Note>(`/api/notes`, { body: options }).then(async (res) => {
    debug.log(res);

    // Received from socket with note:update event

    // $$cache.update(
    //   produce((pages) => {
    //     pages.push(res.body);
    //   })
    // );

    return res.body;
  });
}

export async function ensureNoteIsLoaded(noteId: number) {
  const found = $cache.get().get(noteId);
  if (!found) {
    try {
      const res = await Dolla.http.get<Note>(`/api/notes/${noteId}`);
      setCache(
        produce((notes) => {
          notes.set(res.body.id, res.body);
        }),
      );
      return { exists: true };
    } catch (err) {
      debug.error(err);
    }
  }
  return { exists: found != null };
}

/**
 * Update locally cached pages. Actual changes to the page have already been
 * sent to the server over sockets by the time this has been called.
 */
export async function updatePage(pageId: number, ytext: Y.Text) {
  setCache(
    produce((pages) => {
      const page = pages.get(pageId);
      if (page) {
        page.title = extractTitleFromDelta(ytext.toDelta());
        // page.wordCount = ytext.toString().split(/[\s\t\n]+/g).length;
      }
    }),
  );

  debug.info("updated local cache for page", pageId);
}

export async function deletePage(pageId: number) {
  setCache(
    produce((pages) => {
      pages.delete(pageId);
    }),
  );

  return Dolla.http.delete(`/api/notes/${pageId}`).then(async (res) => {
    debug.log(res);
  });
}

export async function setPinned(noteId: number, pinned: boolean) {
  setCache(
    produce((notes) => {
      const note = notes.get(noteId);
      if (note) {
        note.isPinned = pinned;
      }
    }),
  );

  if (pinned) {
    await Dolla.http.put(`/api/notes/${noteId}/pin`);
  } else {
    await Dolla.http.delete(`/api/notes/${noteId}/pin`);
  }
}

export async function setTags(noteId: number, tags: string[]) {
  setCache(
    produce((notes) => {
      const found = notes.get(noteId);
      if (found) {
        found.tags = tags;
      }
    }),
  );

  await Dolla.http.put(`/api/notes/${noteId}/tags`, {
    body: {
      tags,
    },
  });
}

export function updateCache(cache: Array<number>) {
  if (cache.length > 0) {
    setCache(
      produce((notes) => {
        for (const id of cache) {
          notes.delete(id);
        }
      }),
    );
  }
}

export function noteUpdateReceived(note: Note) {
  setCache(
    produce((cache) => {
      const current = cache.get(note.id);

      if (current) {
        Object.assign(current, note);
      } else {
        cache.set(note.id, note);
      }
    }),
  );
}

export function noteDeleteReceived(id: number) {
  setCache(
    produce((cache) => {
      cache.delete(id);
    }),
  );
}
