export default {
  "container": "TagFilter_container_852b3",
  "open": "TagFilter_open_852b3",
  "tagList": "TagFilter_tagList_852b3",
  "emptyListPlaceholder": "TagFilter_emptyListPlaceholder_852b3",
  "tag": "TagFilter_tag_852b3",
  "tagName": "TagFilter_tagName_852b3",
  "tagRemoveButton": "TagFilter_tagRemoveButton_852b3",
  "input": "TagFilter_input_852b3",
  "indicatorIcon": "TagFilter_indicatorIcon_852b3",
  "menuLayout": "TagFilter_menuLayout_852b3",
  "menuInput": "TagFilter_menuInput_852b3",
  "menuTagList": "TagFilter_menuTagList_852b3",
  "menuTagButton": "TagFilter_menuTagButton_852b3"
};import "ni:sha-256;EqUqueE2QZu9-X9rQ9o4fylUBSu11mSXzPrUhRiK2VE";