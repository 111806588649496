import Dolla, { derive, ViewContext } from "@manyducks.co/dolla";
import SunCalc from "suncalc";
import styles from "./TimeWidget.module.css";

import { clock } from "@stores";

interface TimeWidgetProps {}

export function TimeWidget(props: TimeWidgetProps, ctx: ViewContext) {
  function test() {
    // Lat and long for Spokane
    const coords = [47.659, -117.425018];
    const sunTimes = SunCalc.getTimes(new Date(), coords[0], coords[1]);
    const moonTimes = SunCalc.getMoonTimes(new Date(), coords[0], coords[1]);

    ctx.log({ sunTimes, moonTimes });
  }

  ctx.onMount(() => {
    test();
  });

  return (
    <div class={styles.layout}>
      <div class={styles.text}>
        <span class={styles.time}>
          {derive([clock.$minute], () => {
            return Dolla.i18n.dateTime(new Date(), { timeStyle: "short" });
          })}
        </span>

        <div class={styles.dateAndWeekday}>
          <span class={styles.date}>
            {derive([clock.$hour], () => {
              return Dolla.i18n.dateTime(new Date(), {
                month: "long",
                year: "numeric",
                day: "numeric",
              });
            })}
          </span>
          <span class={styles.weekday}>
            {derive([clock.$hour], () => {
              return Dolla.i18n.dateTime(new Date(), { weekday: "long" });
            })}
          </span>
        </div>
      </div>

      {/* <Button onClick={test}>TEST</Button> */}
    </div>
  );
}
