export default {
  "layout": "Settings_layout_b7096",
  "content": "Settings_content_b7096",
  "formToolBar": "Settings_formToolBar_b7096",
  "formGroup": "Settings_formGroup_b7096",
  "userName": "Settings_userName_b7096",
  "nameForm": "Settings_nameForm_b7096",
  "nameFormButtons": "Settings_nameFormButtons_b7096",
  "userStatus": "Settings_userStatus_b7096",
  "userAvatar": "Settings_userAvatar_b7096",
  "userColorInput": "Settings_userColorInput_b7096",
  "userColorButtons": "Settings_userColorButtons_b7096",
  "editor": "Settings_editor_b7096",
  "editorButtons": "Settings_editorButtons_b7096",
  "formLabel": "Settings_formLabel_b7096",
  "sectionHeader": "Settings_sectionHeader_b7096",
  "headerTitle": "Settings_headerTitle_b7096",
  "sectionList": "Settings_sectionList_b7096",
  "settingsButton": "Settings_settingsButton_b7096",
  "flowVertical": "Settings_flowVertical_b7096",
  "statusLabel": "Settings_statusLabel_b7096",
  "toggleGroup": "Settings_toggleGroup_b7096",
  "colorSchemeOption": "Settings_colorSchemeOption_b7096",
  "colorSchemeLabel": "Settings_colorSchemeLabel_b7096"
};import "ni:sha-256;hdAuvdhDMNRtvD_dl6LCOSEwgBxJHuVQbLqK7OWm7EY";