import { sticky } from "@helpers/sticky";
import Dolla, { createState, derive, type ViewContext } from "@manyducks.co/dolla";
import { CardDetailStack } from "@views/CardDetailStack";
import { nav, notes, projects } from "@stores";
import { Details } from "./Details/Details";
import { List } from "./List/List";
import styles from "./ProjectNotes.module.css";

interface ProjectNotesProps {}

export function ProjectNotes(props: ProjectNotesProps, ctx: ViewContext) {
  ctx.setName("ProjectNotes");

  const $projectId = derive([Dolla.router.$params], (p) => (p.projectId ? Number(p.projectId) : null));
  const $currentProject = derive([$projectId, projects.$cache], (id, projects) =>
    id ? projects.get(id) : undefined,
  );
  const $latestProject = derive([$currentProject], sticky());

  const $currentNote = derive([Dolla.router.$params, notes.$cache], (params, notes) =>
    params.noteId ? notes.get(Number(params.noteId)) : undefined,
  );
  const [$latestNote, setLatestNote] = createState($currentNote.get());

  ctx.watch([$currentNote, $projectId, $latestProject], (note, projectId, latestProject) => {
    if (note && note.projectId === projectId /* && $$latestNote.get()?.id !== note.id */) {
      setLatestNote(note);
    } else if (projectId != latestProject?.id) {
      setLatestNote(undefined);
    }
  });

  ctx.watch([$latestNote], (note) => {
    if (note) {
      nav.addRecent(`/projects/${note.projectId}/notes/${note.id}`);
    }
  });

  ctx.watch([$projectId], (projectId) => {
    if (projectId != null) {
      notes.fetchIndexFor(projectId);
    }
  });

  return (
    <div class={styles.layout}>
      <CardDetailStack
        $showDetail={derive([$currentNote], (note) => note != null)}
        listContent={<List />}
        detailContent={<Details $project={$latestProject} $note={$latestNote} />}
        expandedThreshold={700}
      />
    </div>
  );
}
