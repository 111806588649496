import { derive, type State, type ViewContext } from "@manyducks.co/dolla";
import { nanoid } from "nanoid";
import styles from "./ColorInput.module.css";

interface ColorInputProps {
  /**
   * Input 'id' for use in forms.
   */
  id?: string;

  /**
   * Input 'name' for use in forms.
   */
  name?: string;

  /**
   * Color value.
   */
  $value: State<string>;

  onChange: (value: string) => void;
}

/**
 * Provides a color picker to select a custom color.
 */
export function ColorInput(props: ColorInputProps, ctx: ViewContext) {
  const id = props.id ?? nanoid();

  return (
    <div class={styles.container}>
      <input
        id={id}
        class={styles.input}
        type="color"
        value={props.$value}
        onChange={(e) => {
          const newValue = (e.currentTarget as HTMLInputElement).value;
          props.onChange(newValue);
        }}
        name={props.name}
      />
      <label for={id} class={styles.label} style={{ "--input-color": props.$value }}>
        <span class={styles.fill} />
        <span class={styles.code}>{derive([props.$value], (hex) => hex.toUpperCase())}</span>
      </label>
    </div>
  );
}
