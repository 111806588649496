import { type MaybeState, type State, type ViewContext, valueOf } from "@manyducks.co/dolla";
import { Icon } from "MaterialSymbols";
import styles from "./Checkbox.module.css";

interface CheckboxProps {
  id?: string;
  $value: State<boolean>;
  disabled?: MaybeState<boolean>;
  onChange?: (value: boolean) => void;
}

export function Checkbox(props: CheckboxProps, ctx: ViewContext) {
  const { $value } = props;

  const id = props.id ?? ctx.uid;

  return (
    <div class={{ [styles.container]: true, [styles.checked]: $value, [styles.disabled]: props.disabled }}>
      <label class={styles.label} for={id} />
      <input
        id={id}
        class={styles.input}
        checked={$value}
        disabled={props.disabled}
        onchange={() => {
          if (!valueOf(props.disabled ?? false)) {
            props.onChange?.(!$value.get());
          }
        }}
        type="checkbox"
      />
      <div class={styles.checkmark}>
        <Icon name="Check" size={20} />
      </div>
    </div>
  );
}
