export default {
  "noteListItem": "NoteListItem_noteListItem_7453d",
  "note": "NoteListItem_note_7453d",
  "highlighted": "NoteListItem_highlighted_7453d",
  "highlightNote": "NoteListItem_highlightNote_7453d",
  "layout": "NoteListItem_layout_7453d",
  "noteLine": "NoteListItem_noteLine_7453d",
  "content": "NoteListItem_content_7453d",
  "title": "NoteListItem_title_7453d",
  "untitled": "NoteListItem_untitled_7453d",
  "meta": "NoteListItem_meta_7453d",
  "metaIcon": "NoteListItem_metaIcon_7453d",
  "metaLabel": "NoteListItem_metaLabel_7453d",
  "projectIcon": "NoteListItem_projectIcon_7453d",
  "projectLabel": "NoteListItem_projectLabel_7453d"
};import "ni:sha-256;_rSkEWe1wNNZL7kkJ_SRbCE2bfMgSyIjN-kwiIJYSPM";