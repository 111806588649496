export default {
  "messageList": "ChatMessageList_messageList_f9608",
  "message": "ChatMessageList_message_f9608",
  "mine": "ChatMessageList_mine_f9608",
  "noTextSpacer": "ChatMessageList_noTextSpacer_f9608",
  "authorAvatar": "ChatMessageList_authorAvatar_f9608",
  "authorAvatarSpacer": "ChatMessageList_authorAvatarSpacer_f9608",
  "messageBubble": "ChatMessageList_messageBubble_f9608",
  "messageContent": "ChatMessageList_messageContent_f9608",
  "messageInfo": "ChatMessageList_messageInfo_f9608",
  "authorName": "ChatMessageList_authorName_f9608",
  "messageTimestamp": "ChatMessageList_messageTimestamp_f9608"
};import "ni:sha-256;Ab0JJUYbwdNmL-iRTf5qO2HI8v9C4kS16AgMF14R0JA";