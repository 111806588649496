import { adjustAccentColor } from "@helpers/makeTheme";
import {
  cond,
  createState,
  derive,
  repeat,
  toState,
  type MaybeState,
  type Renderable,
  type State,
  type ViewContext,
} from "@manyducks.co/dolla";
import chroma from "chroma-js";
import styles from "./CollapsibleListSection.module.css";

import { theme } from "@stores";

interface CollapsibleListControl {
  id: string | number;
  icon: Renderable;
  title: MaybeState<string>;
  disabled?: MaybeState<boolean>;
  hidden?: MaybeState<boolean>;
  onClick: (e: Event) => void;
}

interface CollapsibleListSectionProps {
  $open?: State<boolean>;
  onClose?: () => void;
  $title: State<string>;
  $itemCount?: State<number>;
  $headerColor?: State<string>;
  controls?: CollapsibleListControl[];
}

export function CollapsibleListSection(props: CollapsibleListSectionProps, ctx: ViewContext) {
  const [$open, setOpen] = createState(true);

  // Follow value of outside State.
  if (props.$open) {
    ctx.watch([$open], (open) => {
      setOpen(open);
    });
  }

  const $styles = derive([props.$headerColor, theme.$isDark], (color, dark) => {
    if (color) {
      const accent = adjustAccentColor(color, dark);
      return {
        "--color-header-background": dark
          ? chroma(accent).luminance(0.2).hex()
          : chroma(accent).luminance(0.8).hex(),
        "--color-header-background-hover": dark
          ? chroma(accent).luminance(0.24).hex()
          : chroma(accent).luminance(0.76).hex(),
        "--color-header-text": dark ? "#fff" : accent,
      };
    }
  });

  const $controls = toState(props.controls ?? []);

  return (
    <section class={styles.collapsibleListSection}>
      <header style={$styles}>
        <button
          class={[
            styles.listSectionHeaderBackground,
            {
              [styles.open]: $open,
              [styles.withControls]: derive([$controls], (c) => c != null && c.length > 0),
            },
          ]}
          type="button"
          onClick={() => {
            setOpen(false);
            props.onClose?.();
          }}
        >
          <span class={styles.listSectionTitle}>
            <span class={[styles.listSectionOpenIndicator, { [styles.open]: $open }]} />
            {props.$title}
          </span>
          {cond(
            derive([props.$itemCount], (ic) => ic != null),
            <span class={styles.listSectionCount}>{props.$itemCount}</span>,
          )}
        </button>
        {cond(
          $controls,
          <ul class={styles.controls}>
            {repeat(
              $controls,
              (control) => control.id,
              ($control) => {
                const $title = derive([$control], (c) => c.title);
                const $icon = derive([$control], (c) => c.icon);
                const $disabled = derive([$control], (c) => c.hidden ?? false);
                const $hidden = derive([$control], (c) => c.hidden ?? false);

                return (
                  <>
                    {cond(
                      $hidden,
                      null,
                      <li>
                        <button
                          class={[styles.controlButton]}
                          disabled={$disabled}
                          title={$title}
                          type="button"
                          onClick={(e) => {
                            const { onClick } = $control.get();
                            onClick(e);
                          }}
                        >
                          {$icon}
                        </button>
                      </li>,
                    )}
                  </>
                );
              },
            )}
          </ul>,
        )}
      </header>
      <div class={[styles.listSectionContent, { [styles.show]: $open }]}>{ctx.outlet()}</div>
    </section>
  );
}
