import { TaskEditDialog } from "@dialogs/TaskEditDialog/TaskEditDialog";
import { adjustAccentColor } from "@helpers/makeTheme";
import { relativePastTime } from "@helpers/relativePastTime";
import Dolla, { cond, createRef, derive, type State, t, type ViewContext } from "@manyducks.co/dolla";
import { addDays, isBefore, isSameDay, isSameMinute } from "date-fns";
import { Icon } from "MaterialSymbols";
import prettyBytes from "pretty-bytes";
import { type Task } from "schemas";
import styles from "./TaskListItem.module.css";

import { auth, clock, dialog, projects, tasks, theme } from "@stores";

interface TaskListItemProps {
  $task: State<Task>;

  // interactive?: MaybeState<boolean>;

  onClick?: (e: MouseEvent) => void;

  // scrollToTask?: (taskId: number) => void;

  meta?: TaskMetaOptions;
}

export interface TaskMetaOptions {
  /**
   * Show the project name.
   */
  project?: boolean;

  /**
   * Show the assigned user.
   */
  assignedUser?: boolean;

  /**
   * Show the due date (unless completed).
   */
  dueDate?: boolean;

  /**
   * Show the last updated timestamp.
   */
  updatedAt?: "relative" | "absolute" | false;

  /**
   * Show the completed timestamp (if completed).
   */
  completedAt?: "relative" | "absolute" | "time" | false;
}

export function TaskListItem(props: TaskListItemProps, ctx: ViewContext) {
  const { $task } = props;

  const $isSelected = derive([Dolla.router.$params, $task], (params, task) => params.taskId == task.id);

  const listItemElement = createRef<HTMLLIElement>();

  // const $interactive = toState(props.interactive ?? true);

  const $defaultTitle = t("views.taskListItem.unnamedFallbackTitle");
  const $title = derive([$task, $defaultTitle], (task, defaultTitle) => task.title || defaultTitle);
  const $isTitled = derive([$task], (task) => !!task.title);
  const $isUntitled = derive([$title, $defaultTitle], (title, defaultTitle) => title === defaultTitle);

  const $assignedUser = derive([$task, auth.$users], (task, users) =>
    users.find((u) => u.id === task.assignedUserId),
  );
  const $assignedUserTheme = theme.getTheme$(derive([$assignedUser], (u) => u?.color));

  const $highlighted = derive([$task, tasks.$highlightTaskId], (t, id) => t.id === id);
  ctx.watch([$highlighted], (highlighted) => {
    if (highlighted) {
      const button = listItemElement.node!.querySelector("button");

      if (button) {
        button.addEventListener(
          "animationend",
          () => {
            tasks.setHighlightedTaskId(null);
          },
          { once: true },
        );
      }
    }
  });

  const $project = derive([$task, projects.$cache], (task, projects) => {
    return projects.get(task.projectId);
  });

  const $projectColor = derive([$project, theme.$isDark], (project, dark) => {
    return adjustAccentColor(project!.color, dark);
  });

  return (
    <li
      class={styles.taskListItem}
      style={theme.getTheme$($projectColor)}
      ref={listItemElement}
      data-task-id={derive([$task], (t) => t.id)}
    >
      <button
        class={[
          styles.task,
          {
            [styles.completed]: derive([$task], (t) => t.completedAt != null),
            [styles.highlighted]: $highlighted,
            [styles.selected]: $isSelected,
          },
        ]}
        onClick={(e) => {
          props.onClick?.(e);

          if (!e.defaultPrevented) {
            e.preventDefault();

            // const interactive = $interactive.get();
            // if (!interactive) {
            //   return;
            // }

            // TODO: Open read-only task dialog if user doesn't have permission to edit this task.

            const task = $task.get();
            dialog.show(TaskEditDialog, { task });
          }
        }}
        onDragStart={(e) => {
          ctx.log("dragstart", e);

          e.dataTransfer!.setData("data/task-id", String($task.get().id));
          e.dataTransfer!.effectAllowed = "move";
        }}
        draggable={true}
      >
        <div class={styles.taskLayout}>
          <div class={[styles.taskDot, { [styles.filled]: derive([$task], (t) => t.completedAt != null) }]} />

          <div class={styles.taskContent} draggable={false}>
            <div class={styles.mainContent}>
              <span class={styles.taskTitle}>
                <span class={{ [styles.untitled]: $isUntitled, [styles.taskTitle]: $isTitled }}>
                  {$title}
                </span>
              </span>
            </div>

            <div class={styles.secondaryContent}>
              <ul class={[styles.meta]}>
                {cond(
                  props.meta?.project,
                  <li>
                    <div class={[styles.metaIcon, styles.projectIcon]}>
                      <Icon name="Collections Bookmark" size={20} />
                    </div>
                    <span class={[styles.metaLabel, styles.projectLabel]}>
                      {derive([$project], (p) => p?.name)}
                    </span>
                  </li>,
                )}

                {cond(
                  derive(
                    [$assignedUser, props.meta?.assignedUser],
                    (user, show) => show !== false && user != null,
                  ),
                  <li>
                    <div class={styles.assignedUser} style={$assignedUserTheme}>
                      <div class={styles.userDot} />
                      <span>{derive([$assignedUser], (u) => u?.name)}</span>
                    </div>
                  </li>,
                )}

                {cond(
                  derive(
                    [$task, props.meta?.completedAt],
                    (task, show) => task.completedAt != null && show !== false,
                  ),
                  <li>
                    <div class={styles.metaIcon}>
                      <Icon name="Check" size={20} />
                    </div>
                    <span class={styles.metaLabel}>
                      {derive([$task, Dolla.i18n.$locale, props.meta?.completedAt], (task, lang, style) => {
                        if (style === "time") {
                          return t("absoluteTime.completedAtTimestamp", {
                            timestamp: Dolla.i18n.dateTime(task.completedAt!, { timeStyle: "short" }),
                          });
                        } else if (style === "absolute") {
                          return t("absoluteTime.completedAtTimestamp", {
                            timestamp: Dolla.i18n.dateTime(task.completedAt!, { dateStyle: "short" }),
                          });
                        } else {
                          const timestamp = relativePastTime(lang!, task.completedAt!);
                          if (timestamp) {
                            return t("relativeTime.completedAtTimestamp", { timestamp }).get();
                          } else {
                            return t("relativeTime.completedNow").get();
                          }
                        }
                      })}
                    </span>
                  </li>,
                  cond(
                    derive(
                      [$task, clock.$hour, props.meta?.dueDate],
                      (t, _, show) => t.dueDate != null && t.completedAt == null && show !== false,
                    ),
                    <li
                      class={[
                        styles.taskDueDate,
                        { [styles.inPast]: derive([$task], (t) => isDateInPast(t.dueDate!)) },
                      ]}
                    >
                      <div class={styles.metaIcon}>
                        <Icon name="Event Available" size={20} />
                      </div>
                      <span class={styles.metaLabel}>
                        {derive([$task, clock.$hour], (task, _) => {
                          const [y, m, d] = task.dueDate!.split("-").map((n) => Number(n));
                          const date = new Date(y, m - 1, d);
                          const now = new Date();

                          if (isSameDay(date, now)) {
                            return t("views.taskListItem.meta.dueDateLabel_today");
                          } else if (isSameDay(date, addDays(now, 1))) {
                            return t("views.taskListItem.meta.dueDateLabel_tomorrow");
                          } else {
                            return t("views.taskListItem.meta.dueDateLabel_value", {
                              value: Dolla.i18n.dateTime(date, { month: "short", day: "numeric" }),
                            });
                          }
                        })}
                      </span>
                    </li>,
                  ),
                )}

                {cond(
                  derive([$task], (t) => t.status != null && t.status.length > 0),
                  <li>
                    <div class={styles.metaIcon}>
                      <Icon name="Description" size={20} />
                    </div>
                  </li>,
                )}

                {cond(
                  derive([$task], (t) => t.attachments.length > 0),
                  <li>
                    <div class={styles.metaIcon}>
                      <Icon name="Attach File" size={20} />
                    </div>
                    <span class={styles.metaLabel}>
                      {derive([$task], (task) => {
                        const count = task.attachments.length;
                        const size = prettyBytes(task.attachments.reduce((sum, f) => sum + f.sizeInBytes, 0));
                        return t("views.taskListItem.meta.attachmentsLabel", { count, size });
                      })}
                    </span>
                  </li>,
                )}

                {cond(
                  derive([props.meta?.updatedAt], (style) => Boolean(style)),
                  <li>
                    <div class={styles.metaIcon}>
                      <Icon name="Search Activity" size={20} />
                    </div>
                    <span class={styles.metaLabel}>
                      {derive(
                        [$task, Dolla.i18n.$locale, props.meta?.updatedAt, clock.$minute],
                        (task, lang, style) => {
                          if (style === "absolute") {
                            return t("absoluteTime.updatedAtTimestamp", {
                              timestamp: Dolla.i18n.dateTime(task.updatedAt, {
                                dateStyle: "short",
                                timeStyle: "short",
                              }),
                            });
                          } else {
                            if (isSameMinute(task.updatedAt, new Date())) {
                              return t("relativeTime.updatedNow");
                            } else {
                              return t("relativeTime.updatedAtTimestamp", {
                                timestamp: relativePastTime(lang!, task.updatedAt)!,
                              });
                            }
                          }
                        },
                      )}
                    </span>
                  </li>,
                )}
              </ul>
            </div>
          </div>
        </div>
      </button>
    </li>
  );
}

function isDateInPast(dateString: string) {
  const [y, m, d] = dateString.split("-").map((n) => Number(n));
  const date = new Date(y, m - 1, d);
  const now = new Date();

  return !isSameDay(now, date) && isBefore(date, now);
}
