import {
  createRef,
  createState,
  derive,
  toState,
  t,
  type MaybeState,
  type ViewContext,
} from "@manyducks.co/dolla";
import { Button, ButtonColor, ButtonStyle } from "@views/Button";
import { Dialog, DialogContent, DialogFooter, DialogHeader } from "@views/Dialog";
import { NumericInput } from "@views/NumericInput";
import styles from "./Confirm.module.css";

import { DialogProps } from "@stores/dialog";

interface ConfirmProps extends DialogProps {
  /**
   * Dialog title
   */
  title?: MaybeState<string>;

  color?: MaybeState<string>;

  /**
   * Message to customize the dialog to the resource being deleted.
   */
  message: MaybeState<string>;

  /**
   * Text to display on the confirm button.
   */
  confirmButtonText?: MaybeState<string>;

  /**
   * Called if the user confirms the action in the dialog.
   */
  onConfirm: () => void;

  /**
   * Called if the user cancels or closes the dialog without confirming.
   */
  onCancel?: () => void;
}

/**
 * A dialog to confirm permanent deletion of some data.
 */
export function ConfirmDialog(props: ConfirmProps, ctx: ViewContext) {
  const $message = toState(props.message);
  const $title = toState(props.title ?? t("views.dialog.confirm.defaultTitle"));
  const $confirmButtonText = toState(props.confirmButtonText ?? t("common.ok"));

  return (
    <Dialog
      onsubmit={() => {
        props.onConfirm();
        props.dialog.close();
      }}
      transitionIn={props.dialog.transitionIn}
      transitionOut={props.dialog.transitionOut}
      accentColor={props.color}
    >
      <DialogHeader dialog={props.dialog} $title={$title} />

      <DialogContent>
        <div class={styles.content}>
          <p class={styles.instructions}>{$message}</p>
        </div>
      </DialogContent>

      <DialogFooter>
        <Button
          type="button"
          style={ButtonStyle.Hollow}
          color={ButtonColor.Accent}
          onClick={() => {
            props.onCancel?.();
            props.dialog.close();
          }}
        >
          {t("common.cancel")}
        </Button>

        <Button type="submit" style={ButtonStyle.Solid} color={ButtonColor.Accent}>
          {$confirmButtonText}
        </Button>
      </DialogFooter>
    </Dialog>
  );
}
