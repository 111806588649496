export default {
  "listItem": "ResultListItem_listItem_15c2a",
  "active": "ResultListItem_active_15c2a",
  "link": "ResultListItem_link_15c2a",
  "main": "ResultListItem_main_15c2a",
  "icon": "ResultListItem_icon_15c2a",
  "name": "ResultListItem_name_15c2a",
  "meta": "ResultListItem_meta_15c2a",
  "projectName": "ResultListItem_projectName_15c2a",
  "removeButton": "ResultListItem_removeButton_15c2a"
};import "ni:sha-256;YZkXEB6R6BO17HmBja6HdxG2WCzZoUuNavOt4R_rGog";