import { sticky } from "@helpers/sticky";
import { Icon } from "MaterialSymbols";
import Dolla, { t, type State, ViewContext, derive } from "@manyducks.co/dolla";
import { Button } from "@views/Button";
import { CardDetailStack } from "@views/CardDetailStack";
import { IconButton } from "@views/IconButton";
import { Select } from "@views/Select";
import { ToolBar, ToolBarGroup } from "@views/ToolBar";
import { CalendarView } from "./CalendarView/CalendarView";
import { DayDetails } from "./DayDetails/DayDetails";
import styles from "./ProjectCalendar.module.css";

import { auth, projects, tasks } from "@stores";

interface ProjectCalendarProps {}

export function ProjectCalendar(props: ProjectCalendarProps, ctx: ViewContext) {
  const $projectId = derive([Dolla.router.$params], (params) =>
    params.projectId ? Number(params.projectId) : 0,
  );

  const $year = derive([Dolla.router.$params], (p) =>
    p.year != null ? Number(p.year) : new Date().getFullYear(),
  );
  const $month = derive([Dolla.router.$params], (p) =>
    p.month != null ? Number(p.month) : new Date().getMonth() + 1,
  );
  const $day = derive([Dolla.router.$params], (p) => (p.day != null ? Number(p.day) : undefined));

  const $selectedDate = derive([$year, $month, $day], (y, m, d) => {
    if (d == null) {
      return undefined;
    } else {
      return new Date(y, m - 1, d);
    }
  });

  let lastProjectId: number | undefined;

  const $currentProject = derive([projects.$cache, $projectId], (projects, id) => projects.get(id)!);
  const $project = derive([$currentProject], sticky());
  const $selectableYears = derive([$project], (p) => {
    const years = [];
    let cursor = new Date(p.createdAt).getFullYear();
    let max = new Date().getFullYear() + 2;
    // From the year the project was created to 2 years in the future.
    while (cursor <= max) {
      years.push(cursor);
      cursor += 1;
    }
    return years;
  });

  const $userRole = derive([auth.$me, $project], (user, project) => {
    if (!user || !project || project.archivedAt != null) {
      return "viewer";
    }
    return project.users.find((u) => u.id === user.id)?.role ?? "viewer";
  });

  ctx.watch([$month, $year, $projectId], (month, year, projectId) => {
    tasks.fetchCalendar(year, month, {
      projectId,
      withAdjacent: lastProjectId != projectId,
    });
    lastProjectId = projectId;
  });

  return (
    <div class={styles.layout}>
      <CardDetailStack
        $showDetail={derive([$selectedDate], (x) => x != null)}
        // expandedThreshold={1000}
        expandedDetailWidth={400}
        listContent={
          <div class={styles.listContent}>
            <div class={styles.topControls}>
              <ToolBar>
                <ToolBarGroup>
                  <Select
                    value={$month}
                    options={[
                      { label: getMonthName$(1), value: 1 },
                      { label: getMonthName$(2), value: 2 },
                      { label: getMonthName$(3), value: 3 },
                      { label: getMonthName$(4), value: 4 },
                      { label: getMonthName$(5), value: 5 },
                      { label: getMonthName$(6), value: 6 },
                      { label: getMonthName$(7), value: 7 },
                      { label: getMonthName$(8), value: 8 },
                      { label: getMonthName$(9), value: 9 },
                      { label: getMonthName$(10), value: 10 },
                      { label: getMonthName$(11), value: 11 },
                      { label: getMonthName$(12), value: 12 },
                    ]}
                    onChange={(value) => {
                      const projectId = $projectId.get();
                      const year = $year.get();
                      Dolla.router.go(`/projects/${projectId}/calendar/${year}/${value}`);
                    }}
                  />
                  {derive([$selectableYears], (years) => {
                    return (
                      <Select
                        value={$year}
                        options={years.map((y) => ({ label: y.toString(), value: y }))}
                        onChange={(value) => {
                          const projectId = $projectId.get();
                          const month = $month.get();
                          Dolla.router.go(`/projects/${projectId}/calendar/${value}/${month}`);
                        }}
                      />
                    );
                  })}
                </ToolBarGroup>

                <ToolBarGroup>
                  <IconButton
                    onClick={() => {
                      const projectId = $projectId.get();
                      const year = $year.get();
                      const month = $month.get();

                      if (month == 1) {
                        Dolla.router.go(`/projects/${projectId}/calendar/${year - 1}/12`);
                      } else {
                        Dolla.router.go(`/projects/${projectId}/calendar/${year}/${month - 1}`);
                      }
                    }}
                  >
                    <Icon name="Chevron Left" />
                  </IconButton>
                  <Button
                    onClick={() => {
                      const now = new Date();
                      const projectId = $projectId.get();
                      const year = now.getFullYear();
                      const month = now.getMonth() + 1;

                      Dolla.router.go(`/projects/${projectId}/calendar/${year}/${month}`);
                    }}
                  >
                    {t("workspace.project.tasks.calendar.todayButtonText")}
                  </Button>
                  <IconButton
                    onClick={() => {
                      const projectId = $projectId.get();
                      const year = $year.get();
                      const month = $month.get();

                      if (month == 12) {
                        Dolla.router.go(`/projects/${projectId}/calendar/${year + 1}/1`);
                      } else {
                        Dolla.router.go(`/projects/${projectId}/calendar/${year}/${month + 1}`);
                      }
                    }}
                  >
                    <Icon name="Chevron Right" />
                  </IconButton>
                </ToolBarGroup>
              </ToolBar>
            </div>

            <div class={styles.calendar}>
              <CalendarView
                $month={$month}
                $year={$year}
                $selectedDate={$selectedDate}
                $project={$project}
                onDaySelected={(date) => {
                  const projectId = $projectId.get();
                  const year = date.getFullYear();
                  const month = date.getMonth() + 1;
                  const day = date.getDate();

                  Dolla.router.go(`/projects/${projectId}/calendar/${year}/${month}/${day}`);
                }}
              />
            </div>
          </div>
        }
        detailContent={
          <DayDetails
            $projectId={$projectId}
            $selectedDate={$selectedDate}
            $canInteract={derive([$userRole], (role) => role != "viewer")}
            onBackButtonClicked={() => {
              const projectId = $projectId.get();
              const year = $year.get();
              const month = $month.get();

              Dolla.router.go(`/projects/${projectId}/calendar/${year}/${month}`);
            }}
          />
        }
      />
    </div>
  );
}

function getMonthName$(month: number): State<string> {
  return Dolla.i18n.dateTime(new Date(2024, month - 1, 1), { month: "short" });
}
