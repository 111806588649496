export default {
  "layout": "DayDetails_layout_d089f",
  "content": "DayDetails_content_d089f",
  "taskList": "DayDetails_taskList_d089f",
  "emptyListMessage": "DayDetails_emptyListMessage_d089f",
  "searchOrCreate": "DayDetails_searchOrCreate_d089f",
  "hourGroup": "DayDetails_hourGroup_d089f",
  "first": "DayDetails_first_d089f",
  "last": "DayDetails_last_d089f",
  "hourGroupIcon": "DayDetails_hourGroupIcon_d089f"
};import "ni:sha-256;GT8LvQYT3N6vjxUII6BeB_nStTVw14-SPnHLa4Zr8fk";