import Dolla, { derive, t, toState, type State, type ViewContext } from "@manyducks.co/dolla";
import { Favorite, Note, Task } from "schemas";
import { NoteListItem } from "@views/NoteListItem";
import { TaskListItem } from "@views/TaskListItem";
import { notes, projects, tasks } from "@stores";

type FavoriteType = "task" | "note";

interface FavoriteListItemProps {
  $favorite: State<Favorite>;
  onClick?: (e: Event) => void;
}

export function FavoriteListItem(props: FavoriteListItemProps, ctx: ViewContext) {
  // const $project = derive([props.$favorite, projects.$cache], (favorite, projects) => {
  //   const projectId = parseInt(favorite.path.split("/")[2]);
  //   return projects.get(projectId);
  // });

  const $details = derive(
    [props.$favorite, projects.$cache, notes.$cache, tasks.$cache, Dolla.i18n.$locale],
    (favorite, projectsCache, notesCache, tasksCache, _) => {
      let title = favorite.path;
      let color = "#888";
      let type: FavoriteType = "note";
      let _task: Task | undefined;
      let _note: Note | undefined;

      if (favorite.path.startsWith("/projects/")) {
        const projectId = parseInt(favorite.path.split("/")[2]);
        const project = projectsCache.get(projectId);
        if (!project) {
          color = "#f00";
          title = "MISSING PROJECT";
        } else {
          color = project.color;

          if (favorite.path.includes("/tasks")) {
            const taskId = parseInt(favorite.path.split("/")[4]);
            if (!isNaN(taskId)) {
              // SIDE EFFECT
              tasks.ensureTaskIsLoaded(taskId);

              type = "task";

              const task = tasksCache.get(taskId);
              if (task) {
                title = task.title;
                _task = task;
              } else {
                title = "MISSING TASK";
                color = "#f00";
              }
            } else {
              title = t("workspace.nav.favorites.labels.tasksList_projectName", {
                projectName: project.name,
              }).get();
            }
          } else if (favorite.path.includes("/notes")) {
            const noteId = parseInt(favorite.path.split("/")[4]);
            if (!isNaN(noteId)) {
              // SIDE EFFECT
              notes.ensureNoteIsLoaded(noteId);

              type = "note";

              const note = notesCache.get(noteId);
              if (note) {
                title = note.title;
                _note = note;
              } else {
                title = "MISSING NOTE";
                color = "#f00";
              }
            } else {
              title = t("workspace.nav.favorites.labels.notesList_projectName", {
                projectName: project.name,
              }).get();
            }
          } else {
            // Overview
            title = t("workspace.nav.favorites.labels.overview_projectName", {
              projectName: project.name,
            }).get();
          }
        }
      }

      return { title, color, type, _task, _note };
    },
  );

  const $active = derive([props.$favorite, Dolla.router.$path], (favorite, path) => favorite.path === path);

  return derive([$details], (details) => {
    if (details.type === "task" && details._task != null) {
      return <TaskListItem $task={toState(details._task)} meta={{ project: true }} />;
    }

    if (details.type === "note" && details._note != null) {
      return <NoteListItem $note={toState(details._note)} meta={{ project: true }} onClick={props.onClick} />;
    }
  });
}
