export default {
  "content": "TaskEditDialog_content_93414",
  "mainContent": "TaskEditDialog_mainContent_93414",
  "checkbox": "TaskEditDialog_checkbox_93414",
  "titleAndDescription": "TaskEditDialog_titleAndDescription_93414",
  "titleContainer": "TaskEditDialog_titleContainer_93414",
  "titleInput": "TaskEditDialog_titleInput_93414",
  "titlePlaceholder": "TaskEditDialog_titlePlaceholder_93414",
  "descriptionContainer": "TaskEditDialog_descriptionContainer_93414",
  "descriptionInput": "TaskEditDialog_descriptionInput_93414",
  "descriptionPlaceholder": "TaskEditDialog_descriptionPlaceholder_93414",
  "formGroup": "TaskEditDialog_formGroup_93414",
  "formGroupContent": "TaskEditDialog_formGroupContent_93414",
  "attachmentsList": "TaskEditDialog_attachmentsList_93414",
  "clearButton": "TaskEditDialog_clearButton_93414",
  "metaPriorityItem": "TaskEditDialog_metaPriorityItem_93414",
  "metaButtons": "TaskEditDialog_metaButtons_93414",
  "metaButton": "TaskEditDialog_metaButton_93414",
  "hidden": "TaskEditDialog_hidden_93414",
  "metaButtonIcon": "TaskEditDialog_metaButtonIcon_93414",
  "favoriteButton": "TaskEditDialog_favoriteButton_93414",
  "active": "TaskEditDialog_active_93414"
};import "ni:sha-256;Uv_EdHoYH6iBuUxTNlXSn1ygNAUQYwY02HC4KWE33v4";