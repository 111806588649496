import { ActivityDialog } from "@dialogs/Activity/Activity";
import { SearchDialog } from "@dialogs/Search/Search";
import { SettingsDialog } from "@dialogs/Settings/Settings";
import { sticky } from "@helpers/sticky";
import Dolla, {
  cond,
  createRef,
  createState,
  derive,
  type MaybeState,
  type Renderable,
  repeat,
  type State,
  t,
  valueOf,
  type ViewContext,
} from "@manyducks.co/dolla";
import { IconButton } from "@views/IconButton";
import { Icon } from "MaterialSymbols";
import styles from "./PlaceLayout.module.css";

import { KeyboardShortcutsDialog } from "@dialogs/KeyboardShortcuts/KeyboardShortcuts";
import { auth, breakpoint, dialog, nav, notes, projects, tasks } from "@stores";

export interface Tab {
  text: MaybeState<string>;
  href: MaybeState<string>;
  icon: Renderable;
  active: (pattern: string) => boolean;
  hidden?: MaybeState<boolean>;
}

interface PlaceLayoutProps {
  color?: string | State<string> | State<string | undefined>;
  title: MaybeState<string>;
  tabs?: MaybeState<Tab[]>;
}

export function PlaceLayout(props: PlaceLayoutProps, ctx: ViewContext) {
  const contentElement = createRef<HTMLDivElement>();
  const layoutElement = createRef<HTMLElement>();

  const [$contentWidth, setContentWidth] = createState(0);

  ctx.onMount(() => {
    const element = contentElement.node;

    const observer = new ResizeObserver((entries) => {
      for (const entry of entries) {
        setContentWidth(entry.contentRect.width);
      }
    });

    observer.observe(element!);

    ctx.onUnmount(() => {
      observer.disconnect();
    });
  });

  const $subtitle = derive(
    [Dolla.router.$pattern, Dolla.router.$params, tasks.$cache, notes.$cache],
    (pattern, params, tasks, notes) => {
      if (pattern != null) {
        if (pattern.startsWith("/projects/{#projectId}/tasks/{#taskId}")) {
          const task = tasks.get(params.taskId as number);
          if (task) {
            return (
              <span>
                <span class={styles.deemphasized}>{t("workspace.project.tasks.tabName")}/</span>
                {task.title}
              </span>
            );
          }
        }
        if (pattern.startsWith("/projects/{#projectId}/notes/{#noteId}")) {
          const note = notes.get(params.noteId as number);
          if (note) {
            return (
              <span>
                <span class={styles.deemphasized}>{t("workspace.project.notes.tabName")}/</span>
                {note.title}
              </span>
            );
          }
        }
        if (pattern.startsWith("/projects/{#projectId}/calendar/{#year}/{#month}/{#day}")) {
          const { year, month, day } = params;
          if (year != null && month != null && day != null) {
            return (
              <span>
                <span class={styles.deemphasized}>{t("workspace.project.calendar.tabName")}/</span>
                {Dolla.i18n.dateTime(new Date(Number(year), Number(month) - 1, Number(day)), {
                  dateStyle: "medium",
                })}
              </span>
            );
          }
        }
      }
    },
  );
  const $latestSubtitle = derive([$subtitle], sticky());
  const $backLocation = derive(
    [Dolla.router.$pattern, Dolla.router.$params, $contentWidth],
    (pattern, params, width) => {
      if (width <= 1200 && pattern != null) {
        if (pattern.startsWith("/projects/{#projectId}/tasks/{#taskId}")) {
          const { projectId } = params;
          return `/projects/${projectId}/tasks`;
        } else if (pattern.startsWith("/projects/{#projectId}/notes/{#noteId}")) {
          const { projectId } = params;
          return `/projects/${projectId}/notes`;
        } else if (pattern.startsWith("/projects/{#projectId}/calendar/{#year}/{#month}/{#day}")) {
          const { projectId, year, month } = params;
          return `/projects/${projectId}/calendar/${year}/${month}`;
        }
      }

      return "/";
    },
  );

  function goBack() {
    return Dolla.router.go($backLocation.get());
  }

  const $menuBadgeNumber = derive([auth.$updateAvailable, projects.$invites], (hasUpdate, invites) => {
    let count = invites.length;
    if (hasUpdate) {
      count += 1;
    }
    return count;
  });

  const $currentPageIsFavorite = derive([Dolla.router.$path, nav.$favorites], (path, favorites) =>
    favorites.some((f) => f.path === path),
  );

  return (
    <section
      class={[styles.layout, { [styles.isLargeScreen]: derive([$contentWidth], (x) => x > 1200) }]}
      ref={layoutElement}
    >
      <header class={[styles.header]}>
        <div class={styles.homeButton}>
          <IconButton
            onClick={() => {
              goBack();
            }}
          >
            {cond(
              derive([$backLocation], (path) => path === "/"),
              <Icon name="Home" />,
              // <Home />,
              <Icon name="Arrow Back" />,
            )}
          </IconButton>
        </div>

        <div class={styles.titleArea}>
          <div
            class={[
              styles.title,
              {
                [styles.withSubtitle]: derive([$subtitle, $contentWidth], (s, cw) => cw < 1200 && s != null),
              },
            ]}
          >
            <span class={styles.mainText}>{props.title}</span>
            <span class={styles.subText}>{$latestSubtitle}</span>
          </div>

          <ul class={styles.titleControls}>
            <li>
              <IconButton
                tooltip={t("workspace.search.title")}
                onClick={() => {
                  dialog.show(SearchDialog);
                }}
              >
                <Icon name="Search" />
              </IconButton>
            </li>

            <li>
              <IconButton
                tooltip={t("workspace.activity.title")}
                onClick={() => {
                  dialog.show(ActivityDialog);
                }}
              >
                <Icon name="Search Activity" />
              </IconButton>
            </li>

            <li>
              <IconButton
                tooltip={t("workspace.settings.title")}
                onClick={() => {
                  dialog.show(SettingsDialog);
                }}
              >
                <Icon name="Settings" />
              </IconButton>
            </li>

            {cond(
              breakpoint.min$(800),
              <li>
                <IconButton
                  tooltip={t("workspace.keyboardShortcuts.dialogTitle")}
                  onClick={() => {
                    dialog.show(KeyboardShortcutsDialog);
                  }}
                >
                  <Icon name="Keyboard" />
                </IconButton>
              </li>,
            )}
          </ul>
        </div>
      </header>

      <div class={styles.main}>
        {props.tabs &&
          cond(
            breakpoint.min$(800),
            <nav class={styles.sideTabs}>
              <ul>
                {repeat(
                  props.tabs!,
                  (tab) => valueOf(tab.href),
                  ($tab) => (
                    <li class={{ [styles.hidden]: derive([$tab], (t) => t.hidden) }}>
                      <a
                        href={derive([$tab], (t) => t.href)}
                        class={{
                          [styles.active]: derive([Dolla.router.$pattern, $tab], (pattern, tab) =>
                            pattern ? tab.active(pattern) : false,
                          ),
                        }}
                      >
                        <div class={styles.tabBackdrop} />
                        <div class={styles.tabIcon}>{derive([$tab], (t) => t.icon)}</div>
                        {/* {derive([$tab], (t) => t.icon)} */}
                        <span class={styles.tabLabel}>{derive([$tab], (t) => t.text)}</span>
                      </a>
                    </li>
                  ),
                )}
              </ul>
            </nav>,
            <nav
              class={styles.bottomTabs}
              style={{ "--tab-count": derive([props.tabs], (tabs) => tabs.length) }}
            >
              <ul>
                {repeat(
                  props.tabs!,
                  (tab) => valueOf(tab.href),
                  ($tab) => (
                    <li class={{ [styles.hidden]: derive([$tab], (t) => t.hidden) }}>
                      <a
                        href={derive([$tab], (t) => t.href)}
                        class={{
                          [styles.active]: derive([Dolla.router.$pattern, $tab], (pattern, tab) =>
                            pattern ? tab.active(pattern) : false,
                          ),
                        }}
                      >
                        <div class={styles.tabBackdrop} />
                        <div class={styles.tabIcon}>{derive([$tab], (t) => t.icon)}</div>
                        <span class={styles.tabLabel}>{derive([$tab], (t) => t.text)}</span>
                      </a>
                    </li>
                  ),
                )}
              </ul>
            </nav>,
          )}

        <div class={styles.content} ref={contentElement}>
          {ctx.outlet()}
        </div>
      </div>
    </section>
  );
}
