import Dolla, { derive, createState } from "@manyducks.co/dolla";
import { produce } from "immer";
import { type User } from "../schemas";

import * as push from "./push";

const debug = Dolla.createLogger("📦 stores/auth");

const AUTH_LOCAL_STORAGE_KEY = "quack-auth";

type AuthToken = {
  token: string;
  expiresAt: Date;
};

type MeUpdateOptions = {
  name?: string;
  avatar?: string;
  status?: string;
  color?: string;
  language?: string;
};

const [$auth, _setAuth] = createState<AuthToken>();
const [$userId, setUserId] = createState<number>();
const [$users, setUsers] = createState<User[]>([]);
const [$updateAvailable, setUpdateAvailable] = createState(false);

const $me = derive([$userId, $users], (id, users) => users?.find((u) => u.id === id));

const $token = derive([$auth], (a) => a?.token);
const $isLoggedIn = derive([$auth], (a) => (a && a.token != null && a.expiresAt > new Date()) || false);

export { $me, $users, $updateAvailable, $token, $isLoggedIn };

let version: string | null = null;

const stored = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);

if (stored) {
  const parsed = JSON.parse(stored);
  parsed.expiresAt = new Date(parsed.expiresAt);

  _setAuth(parsed);
}

// Update UI to match user's preferred language.
Dolla.watch([$me], (user) => {
  if (user && user.language && user.language !== Dolla.i18n.$locale.get()) {
    Dolla.i18n.setLocale(user.language);
  }
});

Dolla.onMount(() => {
  // Add auth to API calls made through the @http service
  // and redirect to login page on 401 or 403 responses.
  Dolla.http.use(async (req, next) => {
    const loggedIn = $isLoggedIn.get();

    if (loggedIn && req.uri.startsWith("/api/")) {
      const token = $token.get();
      if (token) {
        req.headers.set("authorization", "Bearer " + token);
      }
    }

    const response = await next();

    if (response.status === 401 || response.status === 403) {
      _clearLocalStorage();
      _setAuth(undefined);
      Dolla.router.go("/login");
    }
  });

  if ($isLoggedIn.get()) {
    getUsers();
  }
});

export async function getUsers() {
  const res = await Dolla.http.get<{ userId: number; users: User[] }>("/api/users");

  setUserId(res.body.userId);
  setUsers(res.body.users);
}

export async function getUpdateStatus() {
  return Dolla.http.get<string>("/api/version").then((res) => {
    if (!version) {
      version = res.body;
    }

    setUpdateAvailable(res.body !== version);
    return res.body !== version;
  });
}

export function setAuth(token: string, expiresAt: Date) {
  _setAuth({
    token,
    expiresAt,
  });

  localStorage.setItem(
    AUTH_LOCAL_STORAGE_KEY,
    JSON.stringify({
      token,
      expiresAt,
    }),
  );
}

export async function logIn(email: string, password: string) {
  return Dolla.http
    .post<{ token: string; expiresAt: string }>("/api/auth/login", {
      body: { email: email.trim().toLowerCase(), password },
    })
    .then(async (res) => {
      const token = res.body.token;
      const expiresAt = new Date(res.body.expiresAt);

      setAuth(token, expiresAt);

      await getUsers();

      return res;
    });
}

export async function signUp(email: string, promoCode?: string) {
  return Dolla.http.post("/api/auth/signup", {
    body: { email: email.trim().toLowerCase(), promoCode },
  });
}

/**
 * Removes user-specific local storage options to avoid leaking info between logins.
 */
function _clearLocalStorage() {
  localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY);
  localStorage.removeItem("quack-current-cards");
  localStorage.removeItem("quack-focused-card");
  localStorage.removeItem("quack-quick-add-content");
  localStorage.removeItem("quack-current-language");
  localStorage.removeItem("quack-color-scheme");
}

export async function logOut() {
  if ($isLoggedIn.get()) {
    await push.disablePushNotifications();
    await Dolla.http.delete("/api/auth/login");
  }

  _clearLocalStorage();

  _setAuth(undefined);
  setUserId(undefined);
}

export async function changePassword(newPassword: string) {
  return Dolla.http
    .post("/api/auth/password-reset", {
      body: { password: newPassword },
    })
    .then(() => {
      logOut(); // Log user out so they can log in with new credentials
    });
}

export async function updateMe(options: MeUpdateOptions) {
  return Dolla.http.put<User, MeUpdateOptions>("/api/users/me", { body: options }).then((res) => {
    setUsers(
      produce((users) => {
        const me = users.find((x) => x.id === res.body.id);
        if (me) {
          Object.assign(me, res.body);
        }
      }),
    );
  });
}

interface caches {
  users: Array<number>;
  projects: Array<number>;
  notes: Array<number>;
  tasks: Array<number>;
}

export async function validateCache(caches: any) {
  // return http
  //   .post<Array<number>>("/api/users/validate-cache", { body: { cache: $$users.get().map((x) => x.id) } })
  //   .then((res) => {
  //     $$users.update((users) => users.filter((user) => !res.body.includes(user.id)));
  //   });
  return Dolla.http.post<caches>("/api/users/validate-cache", { body: caches }).then((res) => {
    return res.body;
  });
}

export function updateCache(cache: Array<number>) {
  if (cache.length > 0) {
    setUsers((users) => users.filter((user) => !cache.includes(user.id)));
  }
}

export function userUpdateReceived(user: User) {
  debug.log({ user });
  setUsers(
    produce((users) => {
      const found = users.find((x) => x.id === user.id);

      if (found) {
        Object.assign(found, user);
      } else {
        users.push(user);
      }
    }),
  );
}
