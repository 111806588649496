export default {
  "container": "Dialog_container_a88c1",
  "open": "Dialog_open_a88c1",
  "backdrop": "Dialog_backdrop_a88c1",
  "form": "Dialog_form_a88c1",
  "modal": "Dialog_modal_a88c1",
  "header": "Dialog_header_a88c1",
  "headerIcon": "Dialog_headerIcon_a88c1",
  "headerText": "Dialog_headerText_a88c1",
  "title": "Dialog_title_a88c1",
  "subtitle": "Dialog_subtitle_a88c1",
  "withSubtitle": "Dialog_withSubtitle_a88c1",
  "content": "Dialog_content_a88c1",
  "controls": "Dialog_controls_a88c1",
  "buttons": "Dialog_buttons_a88c1"
};import "ni:sha-256;fosq3Y5wNw5OSJVu-Jt_PgZTMc-qpqldMGKxgOcCyNs";