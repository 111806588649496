import { downloadFile } from "@helpers/downloadFile";
import { Icon } from "MaterialSymbols";
import { derive, portal, type ViewContext } from "@manyducks.co/dolla";
import { IconButton } from "@views/IconButton";
import { ToolBar, ToolBarGroup } from "@views/ToolBar";
import styles from "./FileViewer.module.css";

import { nav, theme } from "@stores";

export function FileViewer(props: {}, ctx: ViewContext) {
  return portal(
    document.body,
    <div class={[styles.container]} style={theme.$userTheme}>
      <div
        class={[styles.toolbar, styles.top]}
        style={{ backgroundImage: "url(/textures/inspiration-geometry.png)" }}
      >
        <ToolBar>
          <ToolBarGroup>
            <IconButton
              onClick={() => {
                // nav.closeFileViewer();
                const file = nav.$fileViewerContent.get();
                if (file) {
                  downloadFile({ path: `/static/files/${file.fileName}`, name: file.fileDisplayName });
                }
              }}
            >
              <Icon name="Download" />
            </IconButton>
          </ToolBarGroup>
          <ToolBarGroup>
            <IconButton
              onClick={() => {
                nav.closeFileViewer();
              }}
            >
              <Icon name="Close" />
            </IconButton>
          </ToolBarGroup>
        </ToolBar>
      </div>
      <div class={styles.content}>
        {derive([nav.$fileViewerContent], (file) => {
          ctx.log(file);
          if (file?.mimeType.startsWith("image")) {
            return <img src={`/static/files/${file.fileName}`} alt="" class={styles.contentImage} />;
          } else {
            return "UNSUPPORTED MIME TYPE: " + file?.mimeType;
          }
        })}
      </div>
      {/* <div
        class={[styles.toolbar, styles.bottom]}
        style={{ backgroundImage: "url(/textures/inspiration-geometry.png)" }}
      >
        <ToolBar>
          <ToolBarGroup></ToolBarGroup>
          <ToolBarGroup>
            <IconButton
              onClick={() => {
                // nav.closeFileViewer();
              }}
            >
              <Download />
            </IconButton>
          </ToolBarGroup>
        </ToolBar>
      </div> */}
    </div>,
  );
}
