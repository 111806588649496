export default {
  "layout": "Workspace_layout_a6a81",
  "updateBanner": "Workspace_updateBanner_a6a81",
  "updateBannerIcon": "Workspace_updateBannerIcon_a6a81",
  "main": "Workspace_main_a6a81",
  "chat": "Workspace_chat_a6a81",
  "open": "Workspace_open_a6a81",
  "chatContent": "Workspace_chatContent_a6a81",
  "chatTab": "Workspace_chatTab_a6a81",
  "chatTabContent": "Workspace_chatTabContent_a6a81",
  "chatTabIcon": "Workspace_chatTabIcon_a6a81",
  "chatTabLabel": "Workspace_chatTabLabel_a6a81",
  "chatTabMeta": "Workspace_chatTabMeta_a6a81",
  "chatTabMetaName": "Workspace_chatTabMetaName_a6a81",
  "animating": "Workspace_animating_a6a81"
};import "ni:sha-256;BFueQHu9_dajpxI-8-tRbDIp71xVuG89fNo-omeluxs";