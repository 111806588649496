import {
  derive,
  toState,
  type Ref,
  type InputType,
  type MaybeState,
  type ViewContext,
} from "@manyducks.co/dolla";
import styles from "./NumericInput.module.css";

interface NumericInputProps {
  ref?: Ref<HTMLInputElement>;
  value?: MaybeState<number>;
  class?: MaybeState<any>;
  autofocus?: boolean;
  id?: string;
  type?: InputType;
  name?: string;
  placeholder?: MaybeState<string>;
  onChange?: (value: number, e: Event) => void;
  onKeyDown?: (value: KeyboardEvent) => void;
}

export function NumericInput(props: NumericInputProps, ctx: ViewContext) {
  const $value = toState(props.value ?? undefined);
  const $class = toState(props.class);
  const $placeholder = toState(props.placeholder);

  const { autofocus, onChange, onKeyDown, id, type, name } = props;

  return (
    <input
      ref={props.ref}
      class={[styles.input, $class]}
      id={id}
      type="number"
      pattern="\d*"
      name={name}
      placeholder={$placeholder}
      autofocus={autofocus}
      value={derive([$value], (n) => String(n))}
      onkeydown={(e) => {
        if (onKeyDown) {
          onKeyDown(e);
        }
      }}
      oninput={(e) => {
        if (onChange) {
          const value = Number((e.currentTarget as HTMLInputElement).value);
          onChange(value, e);
        }
      }}
    />
  );
}
