export default {
  "container": "ChatEditor_container_cd321",
  "editor": "ChatEditor_editor_cd321",
  "focused": "ChatEditor_focused_cd321",
  "placeholder": "ChatEditor_placeholder_cd321",
  "searchMenu": "ChatEditor_searchMenu_cd321",
  "attachments": "ChatEditor_attachments_cd321",
  "inputFormAttachment": "ChatEditor_inputFormAttachment_cd321",
  "attachmentToolbar": "ChatEditor_attachmentToolbar_cd321",
  "attachmentMeta": "ChatEditor_attachmentMeta_cd321",
  "attachmentSizeLabel": "ChatEditor_attachmentSizeLabel_cd321",
  "attachmentProgressBar": "ChatEditor_attachmentProgressBar_cd321",
  "form": "ChatEditor_form_cd321"
};import "ni:sha-256;hzF4_dc0ZVTyLj7BI0c2nzyoUj-EDow4YjBGtlc43RA";