import Dolla, {
  cond,
  createRef,
  createState,
  derive,
  repeat,
  t,
  toSettableState,
  toState,
  type ViewContext,
} from "@manyducks.co/dolla";
import scrollStyles from "@styles/ScrollBar.module.css";
import { Button } from "@views/Button";
import { CollapsibleListSection } from "@views/CollapsibleListSection";
import { Dialog, DialogContent, DialogHeader } from "@views/Dialog";
import { TextInput } from "@views/TextInput";
import type { SearchResult } from "schemas";
import { FavoriteListItem } from "./FavoriteListItem/FavoriteListItem";
import { RecentListItem } from "./RecentListItem/RecentListItem";
import { ResultListItem } from "./ResultListItem/ResultListItem";
import styles from "./Search.module.css";

import { auth, nav, notes, tasks } from "@stores";
import type { DialogProps } from "@stores/dialog";

interface SearchDialogProps extends DialogProps {}

export function SearchDialog(props: SearchDialogProps, ctx: ViewContext) {
  const [$inputValue, setInputValue] = createState("");
  const [$activeSearchTerm, setActiveSearchTerm] = createState<string>();
  const [$searchResults, setSearchResults] = createState<SearchResult[]>([]);

  function search() {
    const inputValue = $inputValue.get().trim();
    const activeSearchTerm = $activeSearchTerm.get();

    if (inputValue.length > 0 && inputValue !== activeSearchTerm) {
      setActiveSearchTerm(inputValue);

      Dolla.http
        .get<SearchResult[]>(`/api/search?search=${encodeURIComponent(inputValue)}&type=notes,tasks`)
        .then((res) => {
          ctx.log("received search response", res.body);
          setSearchResults(res.body);
        });
    }
  }

  const $filteredFavorites = derive(
    [$activeSearchTerm, nav.$favorites, tasks.$cache, notes.$cache],
    (term, favorites, tasks, notes) => {
      if (term) {
        term = term.toLowerCase();
        return favorites.filter((f) => {
          if (f.path.includes("/tasks/")) {
            const matched = f.path.match(/tasks\/(\d+)/);
            if (matched) {
              const id = Number(matched[1]);
              const task = tasks.get(id);
              return task?.title.toLowerCase().includes(term!);
            }
          } else if (f.path.includes("/notes/")) {
            const matched = f.path.match(/notes\/(\d+)/);
            if (matched) {
              const id = Number(matched[1]);
              const note = notes.get(id);
              return note?.title.toLowerCase().includes(term!);
            }
          }
          return false;
        });
      } else {
        return favorites;
      }
    },
  );

  const inputElement = createRef<HTMLInputElement>();

  ctx.onMount(() => {
    inputElement.node!.select();
    nav.fetchFavorites();
  });

  return (
    <Dialog
      transitionIn={props.dialog.transitionIn}
      transitionOut={props.dialog.transitionOut}
      onsubmit={() => {
        // props.dialog.$$open.set(false);
        search();
      }}
      accentColor={derive([auth.$me], (m) => m?.color ?? "#888")}
    >
      <DialogHeader dialog={props.dialog} $title={t("workspace.search.title")} />

      <DialogContent>
        <div class={styles.searchBox}>
          <TextInput
            ref={inputElement}
            $$value={toSettableState($inputValue, setInputValue)}
            placeholder={t("workspace.search.inputPlaceholder")}
          />
          <Button
            type="submit"
            disabled={derive(
              [$inputValue, $activeSearchTerm],
              (value, active) => value.trim().length === 0 || value === active,
            )}
          >
            {t("workspace.search.inputButtonText")}
          </Button>
        </div>

        <div class={[styles.content, scrollStyles.scrollable]}>
          {cond(
            derive([$filteredFavorites], (f) => f.length > 0),
            <CollapsibleListSection
              $title={toState("Favorites")}
              $itemCount={derive([$filteredFavorites], (f) => f.length)}
            >
              <ul class={[styles.list, styles.favoritesList]}>
                {repeat(
                  $filteredFavorites,
                  (f) => f.id,
                  ($favorite) => {
                    return (
                      <li>
                        <FavoriteListItem
                          $favorite={$favorite}
                          onClick={() => {
                            // TODO: Close search
                            props.dialog.close();
                          }}
                        />
                      </li>
                    );
                  },
                )}
              </ul>
            </CollapsibleListSection>,
          )}

          {cond(
            $activeSearchTerm,

            // Results if search is active
            <CollapsibleListSection
              $title={toState("Results")}
              $itemCount={derive([$searchResults], (r) => r.length)}
            >
              <ul class={[styles.list, styles.resultsList]}>
                {repeat(
                  $searchResults,
                  ({ task, note }) => {
                    if (task) {
                      return `tasks/${task.id}`;
                    }
                    if (note) {
                      return `notes/${note.id}`;
                    }
                    return "unknown type";
                  },
                  ($result) => {
                    return (
                      <li>
                        <ResultListItem
                          $result={$result}
                          onClick={() => {
                            // TODO: Close search
                            props.dialog.close();
                          }}
                        />
                      </li>
                    );
                  },
                )}
              </ul>
            </CollapsibleListSection>,

            // Recents if not
            <CollapsibleListSection
              $title={toState("Recents")}
              $itemCount={derive([nav.$recents], (r) => r.length)}
            >
              <ul class={[styles.list, styles.recentsList]}>
                {repeat(
                  nav.$recents,
                  (r) => r.path,
                  ($recent) => {
                    return (
                      <RecentListItem
                        $recent={$recent}
                        onClick={() => {
                          // TODO: Close search
                          props.dialog.close();
                        }}
                      />
                    );
                  },
                )}
              </ul>
            </CollapsibleListSection>,
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
}
