import { TaskEditDialog } from "@dialogs/TaskEditDialog/TaskEditDialog";
import Dolla, { cond, derive, toState, type State, type ViewContext } from "@manyducks.co/dolla";
import styles from "./ResultListItem.module.css";
import { dialog, projects, tasks, theme } from "@stores";
import { TaskListItem } from "@views/TaskListItem";
import type { Task, SearchResult } from "schemas";

interface ResultListItemProps {
  $result: State<SearchResult>;
  onClick?: (e: Event) => void;
}

export function ResultListItem(props: ResultListItemProps, ctx: ViewContext) {
  const $project = derive([props.$result, projects.$cache], (result, projects) => {
    const projectId = result.task?.projectId || result.note?.projectId!;
    return projects.get(projectId);
  });

  // TODO: Make sure search results return complete tasks and notes objects.

  return cond(
    derive([props.$result], (r) => r.type === "task"),
    <TaskListItem $task={derive([props.$result], (r) => r.task!)} meta={{ project: true }} />,
    <li class={[styles.listItem]} style={theme.getTheme$(derive([$project], (x) => x?.color))}>
      <a
        class={styles.link}
        href={derive([props.$result], (r) => `/projects/${r.note!.projectId}/${r.type}s/${r.note!.id}`)}
        title={derive([props.$result], (r) => r.note!.title)}
        onClick={async (e) => {
          const result = props.$result.get();
          if (result.type === "task") {
            // Open task dialog if it's a task.
            e.preventDefault();
            const task = await tasks.ensureTaskIsLoaded(result.note!.id);
            dialog.show(TaskEditDialog, {
              task: task!,
              onToListClicked: () => {
                Dolla.router.go(`/projects/${result.note!.projectId}/tasks?task=${result.note!.id}`);
                props.onClick?.(e);
              },
            });
          } else if (result.type === "note") {
            e.preventDefault();
            const path = `/projects/${result.note!.projectId}/notes/${result.note!.id}`;
            Dolla.router.go(path);
            props.onClick?.(e);
          }
        }}
      >
        <div class={styles.main}>
          <span class={styles.name}>{derive([props.$result], (x) => x.note!.title)}</span>
          {/* <div class={styles.controls}>
        <button
          onClick={(e) => {
            e.preventDefault();
            nav.removeFavorite(props.$recent.get().id);
          }}
          class={styles.removeButton}
        >
          <Close />
        </button>
      </div> */}
        </div>

        <ul class={styles.meta}>
          {derive([props.$result], ({ type }) => {
            switch (type) {
              case "note":
                return (
                  <li>
                    <span>Note</span>
                  </li>
                );
              case "task":
                return (
                  <li>
                    <span>Task</span>
                  </li>
                );
            }
          })}
          <li class={styles.projectName}>
            <span>{derive([$project], (p) => p?.name)}</span>
          </li>
        </ul>
      </a>
    </li>,
  );
}
