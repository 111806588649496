import Dolla from "@manyducks.co/dolla";

const debug = Dolla.createLogger("📦 stores/loader");

const labels = ["LOADING", "tawa", "読み込み中"];

/**
 * Manipulates the loading screen included in `index.html`.
 * That screen is displayed until scripts are active and content is ready to show.
 * This store lets the app control when it's shown or hidden.
 *
 * The CSS classes used here are defined in the `<style>` tag in the header of `index.html`.
 */

const loader = document.getElementById("loader")!;

if (!loader) {
  debug.crash(new Error(`Loader element was not found on the page.`));
}

const label = loader.querySelector("#loaderLabel")!;

if (!label) {
  debug.crash(new Error(`Loader label element was not found on the page.`));
}

export async function hideAppLoader() {
  return new Promise<void>((resolve) => {
    if (loader.classList.contains("hidden")) {
      return resolve();
    }

    // Add 'animateOut' to trigger transition, then add 'hidden' when done.
    function done() {
      Dolla.render.write(() => {
        loader.classList.add("hidden");
        loader.classList.remove("animateOut");
        resolve();
      });
      loader.removeEventListener("transitionend", done);
    }

    loader.addEventListener("transitionend", done);
    Dolla.render.write(() => {
      loader.classList.remove("hidden");
      loader.classList.add("animateOut");
    });
  });
}

export async function showAppLoader() {
  return new Promise<void>((resolve) => {
    if (!loader.classList.contains("hidden")) {
      return resolve();
    }

    // Add 'animateIn' to trigger transition.
    function done() {
      Dolla.render.write(() => {
        loader.classList.remove("animateIn");
        resolve();
      });
      loader.removeEventListener("transitionend", done);
    }

    const text = labels[Math.round(Math.random() * (labels.length - 1))];
    label.textContent = text;

    loader.addEventListener("transitionend", done);
    Dolla.render.write(() => {
      loader.classList.remove("hidden");
      loader.classList.add("animateIn");
    });
  });
}
