export default {
  "container": "MoreMenu_container_b96fc",
  "small": "MoreMenu_small_b96fc",
  "button": "MoreMenu_button_b96fc",
  "card": "MoreMenu_card_b96fc",
  "open": "MoreMenu_open_b96fc",
  "icon": "MoreMenu_icon_b96fc",
  "menu": "MoreMenu_menu_b96fc",
  "listButton": "MoreMenu_listButton_b96fc",
  "destructive": "MoreMenu_destructive_b96fc"
};import "ni:sha-256;BRX-FuOyb4EZCYAPybIYHTix6xetKCj83t6DPb3YHtc";