import Dolla, { type State, type ViewNode, type StopFunction, type ViewFunction } from "@manyducks.co/dolla";

const debug = Dolla.createLogger("dolla/modal");

export interface DialogProps {
  dialog: {
    /**
     * Whether the modal is currently open.
     */
    $isOpen: State<boolean>;

    /**
     * Call to close the modal from within.
     */
    close: () => void;

    /**
     * Calls `callback` immediately after dialog has been connected.
     */
    transitionIn: (callback: () => Promise<void>) => void;

    /**
     * Calls `callback` and awaits its Promise before disconnecting the dialog.
     */
    transitionOut: (callback: () => Promise<void>) => void;
  };
}

export interface OpenDialog {
  instance: ViewNode;
  transitionInCallback?: () => Promise<void>;
  transitionOutCallback?: () => Promise<void>;
}

const appElement = document.querySelector("#app")!;

const container = document.createElement("div");
container.style.position = "fixed";
container.style.top = "0";
container.style.right = "0";
container.style.bottom = "0";
container.style.left = "0";
container.style.zIndex = "99999";

/**
 * A first-in-last-out queue of dialogs. The last one appears on top.
 * This way if a dialog opens another dialog the new dialog stacks.
 */
const [$dialogs, setDialogs] = Dolla.createState<OpenDialog[]>([]);

let activeDialogs: OpenDialog[] = [];

function dialogChangedCallback() {
  // Container is only connected to the DOM when there is at least one dialog to display.
  if (activeDialogs.length > 0) {
    if (!container.parentNode) {
      appElement.appendChild(container);
    }
  } else {
    if (container.parentNode) {
      appElement.removeChild(container);
    }
  }
}

let stopCallback: StopFunction | undefined;

Dolla.onMount(() => {
  // Diff dialogs when value is updated, adding and removing dialogs as necessary.
  stopCallback = Dolla.watch([$dialogs], (dialogs) => {
    Dolla.render.write(() => {
      let removed: OpenDialog[] = [];
      let added: OpenDialog[] = [];

      for (const dialog of activeDialogs) {
        if (!dialogs.includes(dialog)) {
          removed.push(dialog);
        }
      }

      for (const dialog of dialogs) {
        if (!activeDialogs.includes(dialog)) {
          added.push(dialog);
        }
      }

      for (const dialog of removed) {
        if (dialog.transitionOutCallback) {
          dialog.transitionOutCallback().then(() => {
            dialog.instance.unmount();
            activeDialogs.splice(activeDialogs.indexOf(dialog), 1);
            dialogChangedCallback();
          });
        } else {
          dialog.instance.unmount();
          activeDialogs.splice(activeDialogs.indexOf(dialog), 1);
        }
      }

      for (const dialog of added) {
        dialog.instance.mount(container);

        if (dialog.transitionInCallback) {
          dialog.transitionInCallback();
        }

        activeDialogs.push(dialog);
      }

      dialogChangedCallback();
    });
  });
});

Dolla.onUnmount(() => {
  if (stopCallback) {
    stopCallback();
    stopCallback = undefined;
  }

  if (container.parentNode) {
    document.body.removeChild(container);
  }
});

export function show<P extends DialogProps>(view: ViewFunction<P>, props?: Omit<P, keyof DialogProps>) {
  const [$isOpen, setIsOpen] = Dolla.createState(true);

  let dialog: OpenDialog | undefined;

  let transitionInCallback: (() => Promise<void>) | undefined;
  let transitionOutCallback: (() => Promise<void>) | undefined;

  let instance = Dolla.constructView(view, {
    ...props,
    dialog: {
      $isOpen,
      close: () => {
        setIsOpen(false);
      },
      transitionIn: (callback) => {
        transitionInCallback = callback;
      },
      transitionOut: (callback) => {
        transitionOutCallback = callback;
      },
    },
  } as P);

  dialog = {
    instance,

    // These must be getters because the fns passed to props aren't called until before connect.
    get transitionInCallback() {
      return transitionInCallback;
    },
    get transitionOutCallback() {
      return transitionOutCallback;
    },
  };

  setDialogs((current) => {
    return [...current, dialog!];
  });

  const stopObserver = $isOpen.watch((value) => {
    if (!value) {
      closeDialog();
    }
  });

  const escapeListener = (e: KeyboardEvent) => {
    if (e.key === "Escape" && document.activeElement?.tagName !== "INPUT") {
      closeDialog();
    }
  };
  window.addEventListener("keydown", escapeListener);

  function closeDialog() {
    setDialogs((current) => {
      return current.filter((x) => x !== dialog);
    });
    dialog = undefined;

    stopObserver();
    window.removeEventListener("keydown", escapeListener);
  }

  return closeDialog;
}
