import Dolla from "@manyducks.co/dolla";
import { produce } from "immer";
import type { Op } from "quill-delta";
import type { ChatMessage } from "schemas";

import * as io from "./io.js";
import * as projects from "./projects.js";

const debug = Dolla.createLogger("📦 stores/chat");

function loadProjectId() {
  const stored = localStorage.getItem("chat.projectId");
  return stored ? Number(stored) : undefined;
}

const [$messages, setMessages] = Dolla.createState<ChatMessage[]>([]);
const [$projectId, setProjectId] = Dolla.createState(loadProjectId());

Dolla.watch([$projectId], (projectId) => {
  if (projectId == null) {
    localStorage.removeItem("chat.projectId");
  } else {
    localStorage.setItem("chat.projectId", String(projectId));
  }
});

// Deselect project if chat is not enabled.
Dolla.watch([projects.$cache, $projectId], (projects, id) => {
  if (id == null) return;
  const project = projects.get(id);
  if (project == null) return;
  if (project.archivedAt != null || project.users.length < 2) {
    setProjectId(undefined);
  }
});

export async function fetchMessages() {
  const res = await Dolla.http.get<ChatMessage[]>(`/api/chat`);
  setMessages(res.body);
}

/**
 * @param delta - Quill delta of message content
 * @param attachments - UUIDs of uploaded files to attach
 */
export async function sendMessage(projectId: number, delta: Op[], attachments: string[]) {
  const socket = io.socket();
  socket.emit("chat:message", { projectId, delta, attachments });
}

export function chatMessageReceived(message: ChatMessage) {
  debug.log("received chat message", message);

  setMessages(
    produce((messages) => {
      const found = messages.find((m) => m.id === message.id);
      if (found) {
        Object.assign(found, message);
      } else {
        messages.push(message);
      }
    }),
  );
}

export { $messages, $projectId, setProjectId };
