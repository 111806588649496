import Dolla, {
  t,
  createSettableState,
  createState,
  derive,
  repeat,
  type ViewContext,
} from "@manyducks.co/dolla";
import { CardContent } from "@views/Card";
import { CollapsibleListSection } from "@views/CollapsibleListSection";
import { MoreMenu } from "@views/MoreMenu";
import { UserAvatar } from "@views/UserAvatar";
import styles from "./People.module.css";

import { theme } from "@stores";
import { Icon } from "MaterialSymbols";

export function People(props: {}, ctx: ViewContext) {
  const [$users, setUsers] = createState<any[]>([]);

  ctx.onMount(async () => {
    const res = await Dolla.http.get<any[]>("/api/admin/users");
    setUsers(res.body);

    ctx.log({ users: res.body });
  });

  // TODO: Show server admin status and date created.

  return (
    <CardContent>
      <div class={styles.container}>
        <CollapsibleListSection
          $title={t("workspace.admin.people.headings.active")}
          $itemCount={derive([$users], (x) => x.length)}
        >
          <ul class={styles.usersList}>
            {repeat(
              $users,
              (u) => u.id,
              ($user) => {
                const $$menuIsOpen = createSettableState(false);

                return (
                  <li class={styles.listItem} style={theme.getTheme$(derive([$user], (u) => u.color))}>
                    <div class={styles.listItemContent}>
                      <UserAvatar src={derive([$user], (u) => u.avatar)} />
                      <span class={styles.userName}>{derive([$user], (u) => u.name)}</span>
                      <span class={styles.userColor}>{derive([$user], (u) => u.color.toLowerCase())}</span>
                    </div>
                    <MoreMenu
                      $$open={$$menuIsOpen}
                      preferHorizontalAlignment="left"
                      preferVerticalAlignment="below"
                      size="small"
                      color={derive([$user], (x) => x.color)}
                      options={[
                        {
                          label: t("workspace.admin.people.menu.deactivate"),
                          icon: <Icon name="Close" />,
                          disabled: true,
                          callback: () => {
                            // $$linkMenuIsOpen.set(true);
                          },
                        },
                      ]}
                    />
                  </li>
                );
              },
            )}
          </ul>
        </CollapsibleListSection>

        {/* <CollapsibleListSection title={translate("workspace.admin.people.headings.inactive")}}>
        <ul class={styles.usersList}>
          {repeat(
            $$users,
            (u) => u.id,
            ($user) => {
              return (
                <li class={styles.listItem}>
                  <div class={styles.listItemContent}>
                    <UserAvatar src={$($user, (u) => u.avatar)} />
                    <span class={styles.userName}>{$($user, (u) => u.name)}</span>
                  </div>
                </li>
              );
            },
          )}
        </ul>
      </CollapsibleListSection> */}
      </div>
    </CardContent>
  );
}
