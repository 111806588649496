import { createRef, toSettableState, createState, derive, t, type ViewContext } from "@manyducks.co/dolla";
import { Button } from "@views/Button";
import { Dialog, DialogContent, DialogFooter, DialogHeader } from "@views/Dialog";
import { SegmentButton } from "@views/SegmentButton";
import { TextInput } from "@views/TextInput";
import { type Project } from "schemas";
import styles from "./InviteDialog.module.css";

import { DialogProps } from "@stores/dialog";

type Role = "viewer" | "collaborator" | "admin";

interface SubmissionData {
  email: string;
  role: Role;
}

interface InviteDialogProps extends DialogProps {
  project: Project;
  onSubmit: (data: SubmissionData) => Promise<void>;
  onCancel: () => void;
}

export function InviteDialog(props: InviteDialogProps, ctx: ViewContext) {
  const [$email, setEmail] = createState("");
  const [$role, setRole] = createState<Role>("collaborator");

  const [$isSubmitting, setIsSubmitting] = createState(false);

  const formElement = createRef<HTMLFormElement>();
  const emailInputElement = createRef<HTMLInputElement>();

  ctx.onMount(() => {
    const el = emailInputElement.node!;
    el.focus();
    // el.scrollIntoView({
    //   behavior: "smooth",
    //   block: "center",
    // });
  });

  return (
    <Dialog
      accentColor={props.project.color}
      onsubmit={() => {
        const email = $email.get();
        const role = $role.get();

        setIsSubmitting(true);
        props.onSubmit({ email, role }).finally(() => {
          setIsSubmitting(false);
          props.dialog.close();
        });
      }}
      transitionIn={props.dialog.transitionIn}
      transitionOut={props.dialog.transitionOut}
    >
      <DialogHeader dialog={props.dialog} $title={t("workspace.project.inviteDialog.dialogTitle")} />

      <DialogContent>
        <div class={styles.content}>
          <section class={[styles.section, styles.email]}>
            <label for="invite-email">{t("workspace.project.inviteDialog.emailHeading")}</label>
            <TextInput
              ref={emailInputElement}
              disabled={$isSubmitting}
              $$value={toSettableState($email, setEmail)}
              id="invite-email"
              placeholder="person@example.com"
              autocomplete={false}
              type="email"
            />
          </section>

          <section class={[styles.section, styles.role]}>
            <label for="invite-role">{t("workspace.project.inviteDialog.roleHeading")}</label>
            <SegmentButton<Role>
              id="invite-role"
              value={$role}
              disabled={$isSubmitting}
              onChange={setRole}
              segments={[
                {
                  value: "viewer",
                  content: <div>{t("workspace.project.roles.viewer.title")}</div>,
                },
                {
                  value: "collaborator",
                  content: <div>{t("workspace.project.roles.collaborator.title")}</div>,
                },
                {
                  value: "admin",
                  content: <div>{t("workspace.project.roles.admin.title")}</div>,
                },
              ]}
            />
            <p class={styles.roleDescription}>
              {derive([$role], (role) => {
                return t(`workspace.project.roles.${role}.description`);
              })}
            </p>
          </section>
        </div>
      </DialogContent>

      <DialogFooter>
        <Button
          type="button"
          disabled={$isSubmitting}
          onClick={() => {
            props.dialog.close();
            props.onCancel();
          }}
        >
          {t("common.cancel")}
        </Button>
        <Button
          type="submit"
          disabled={derive(
            [$email, $isSubmitting],
            (email, submitting) =>
              submitting || email.trim().length === 0 || !/^.+\@.+?\..+$/.test(email.trim()),
          )}
        >
          {t("workspace.project.inviteDialog.submitButtonText")}
        </Button>
      </DialogFooter>
    </Dialog>
  );
}
