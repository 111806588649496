export default {
  "taskListItem": "TaskListItem_taskListItem_2e1b0",
  "task": "TaskListItem_task_2e1b0",
  "focused": "TaskListItem_focused_2e1b0",
  "selected": "TaskListItem_selected_2e1b0",
  "highlighted": "TaskListItem_highlighted_2e1b0",
  "highlightTask": "TaskListItem_highlightTask_2e1b0",
  "taskLayout": "TaskListItem_taskLayout_2e1b0",
  "taskDot": "TaskListItem_taskDot_2e1b0",
  "filled": "TaskListItem_filled_2e1b0",
  "taskContent": "TaskListItem_taskContent_2e1b0",
  "taskTitle": "TaskListItem_taskTitle_2e1b0",
  "untitled": "TaskListItem_untitled_2e1b0",
  "taskTitleText": "TaskListItem_taskTitleText_2e1b0",
  "priorityBadge": "TaskListItem_priorityBadge_2e1b0",
  "taskDueDate": "TaskListItem_taskDueDate_2e1b0",
  "inPast": "TaskListItem_inPast_2e1b0",
  "metaLabel": "TaskListItem_metaLabel_2e1b0",
  "meta": "TaskListItem_meta_2e1b0",
  "metaIcon": "TaskListItem_metaIcon_2e1b0",
  "projectIcon": "TaskListItem_projectIcon_2e1b0",
  "projectLabel": "TaskListItem_projectLabel_2e1b0",
  "assignMenu": "TaskListItem_assignMenu_2e1b0",
  "assignedUser": "TaskListItem_assignedUser_2e1b0",
  "userDot": "TaskListItem_userDot_2e1b0",
  "editButtonBar": "TaskListItem_editButtonBar_2e1b0",
  "taskMenu": "TaskListItem_taskMenu_2e1b0"
};import "ni:sha-256;xVcVa7CEQ-5g04yeQk-F2WDvb9TFuj359cawCWu3bmw";