import Dolla, { derive, t, type ViewContext } from "@manyducks.co/dolla";
import scrollStyles from "@styles/ScrollBar.module.css";
import { Dialog, DialogContent, DialogHeader } from "@views/Dialog";
import styles from "./KeyboardShortcuts.module.css";
import { auth } from "@stores";
import type { DialogProps } from "@stores/dialog";
import { CollapsibleListSection } from "@views/CollapsibleListSection";
import { Icon } from "MaterialSymbols";

interface KeyboardShortcutsProps extends DialogProps {}

export function KeyboardShortcutsDialog(props: KeyboardShortcutsProps, ctx: ViewContext) {
  return (
    <Dialog
      transitionIn={props.dialog.transitionIn}
      transitionOut={props.dialog.transitionOut}
      onsubmit={() => {
        // props.dialog.$$open.set(false);
        // search();
      }}
      accentColor={derive([auth.$me], (m) => m?.color ?? "#888")}
    >
      <DialogHeader dialog={props.dialog} $title={t("workspace.keyboardShortcuts.dialogTitle")} />

      <DialogContent>
        <div class={[styles.content, scrollStyles.scrollable]}>
          <CollapsibleListSection $title={t("workspace.keyboardShortcuts.generalSectionTitle")}>
            <ul class={styles.shortcutList}>
              <li>
                <span class={styles.shortcutName}>Show Shortcuts</span>
                <span class={styles.shortcutKeys}>
                  <key>?</key>
                </span>
              </li>
              <li>
                <span class={styles.shortcutName}>Go to Home</span>
                <span class={styles.shortcutKeys}>
                  <key>h</key>
                </span>
              </li>
              <li>
                <span class={styles.shortcutName}>Open Search</span>
                <span class={styles.shortcutKeys}>
                  <key>f</key>
                </span>
              </li>
              <li>
                <span class={styles.shortcutName}>Open Activity</span>
                <span class={styles.shortcutKeys}>
                  <key>a</key>
                </span>
              </li>
              <li>
                <span class={styles.shortcutName}>Open Settings</span>
                <span class={styles.shortcutKeys}>
                  <key>s</key>
                </span>
              </li>
              <li>
                <span class={styles.shortcutName}>New Note</span>
                <span class={styles.shortcutKeys}>
                  <key>n</key>
                  <key>n</key>
                </span>
              </li>
              <li>
                <span class={styles.shortcutName}>New Task</span>
                <span class={styles.shortcutKeys}>
                  <key>n</key>
                  <key>t</key>
                </span>
              </li>
              <li>
                <span class={styles.shortcutName}>New Project</span>
                <span class={styles.shortcutKeys}>
                  <key>n</key>
                  <key>p</key>
                </span>
              </li>
              <li>
                <span class={styles.shortcutName}>Use Day theme</span>
                <span class={styles.shortcutKeys}>
                  <key>t</key>
                  <key>
                    <Icon name="Arrow Upward Alt" size={20} />
                  </key>
                </span>
              </li>
              <li>
                <span class={styles.shortcutName}>Use Night theme</span>
                <span class={styles.shortcutKeys}>
                  <key>t</key>
                  <key>
                    <Icon name="Arrow Downward Alt" size={20} />
                  </key>
                </span>
              </li>
              <li>
                <span class={styles.shortcutName}>Use System theme</span>
                <span class={styles.shortcutKeys}>
                  <key>t</key>
                  <key>
                    <Icon name="Arrow Left Alt" size={20} />
                  </key>
                  <span class={styles.separator}>/</span>
                  <key>t</key>
                  <key>
                    <Icon name="Arrow Right Alt" size={20} />
                  </key>
                </span>
              </li>
            </ul>
          </CollapsibleListSection>

          <CollapsibleListSection $title={Dolla.toState("Chat")}>
            <ul class={styles.shortcutList}>
              <li>
                <span class={styles.shortcutName}>Toggle Chat</span>
                <span class={styles.shortcutKeys}>
                  <key>c</key> <key>c</key>
                </span>
              </li>

              <li>
                <span class={styles.shortcutName}>Attach a file</span>
                <span class={styles.shortcutKeys}>
                  <key>c</key> <key>a</key>
                </span>
              </li>
            </ul>
          </CollapsibleListSection>

          <CollapsibleListSection $title={t("workspace.keyboardShortcuts.projectSectionTitle")}>
            <ul class={styles.shortcutList}>
              <li>
                <span class={styles.shortcutName}>Go to Overview tab</span>
                <span class={styles.shortcutKeys}>
                  <key>p</key> <key>o</key>
                </span>
              </li>
              <li>
                <span class={styles.shortcutName}>Go to Notes tab</span>
                <span class={styles.shortcutKeys}>
                  <key>p</key> <key>n</key>
                </span>
              </li>
              <li>
                <span class={styles.shortcutName}>Go to Calendar tab</span>
                <span class={styles.shortcutKeys}>
                  <key>p</key> <key>c</key>
                </span>
              </li>
              <li>
                <span class={styles.shortcutName}>Go to Files tab</span>
                <span class={styles.shortcutKeys}>
                  <key>p</key> <key>f</key>
                </span>
              </li>
              <li>
                <span class={styles.shortcutName}>Edit project info</span>
                <span class={styles.shortcutKeys}>
                  <key>p</key> <key>e</key>
                </span>
              </li>
              <li>
                <span class={styles.shortcutName}>Open invite menu</span>
                <span class={styles.shortcutKeys}>
                  <key>p</key> <key>p</key> <key>i</key>
                </span>
              </li>
            </ul>
          </CollapsibleListSection>
        </div>
      </DialogContent>
    </Dialog>
  );
}
