import Dolla, {
  t,
  cond,
  createSettableState,
  createState,
  derive,
  type ViewContext,
} from "@manyducks.co/dolla";
import { Button } from "@views/Button";
import { CollapsibleListSection } from "@views/CollapsibleListSection";
import { ColorInput } from "@views/ColorInput";
import { PixelEditor, PixelEditorControls } from "@views/PixelEditor";
import { SegmentButton } from "@views/SegmentButton";
import { Select } from "@views/Select";
import { Switch } from "@views/Switch";
import { TextInput } from "@views/TextInput";
import ToolBarGroup from "@views/ToolBar/ToolBarGroup";
import { UserAvatar } from "@views/UserAvatar";
import { auth, push, theme } from "@stores";
import { makeTogglePush } from "./makeTogglePush";
import styles from "./Settings.module.css";
import { Icon } from "MaterialSymbols";

const langLabels: Record<string, string> = {
  en: "English",
  ja: "日本語",
  tok: "toki pona",
};

interface SettingsProps {}

export function Settings(props: SettingsProps, ctx: ViewContext) {
  const togglePush = makeTogglePush(ctx);

  const $$pixelEditorRef = createSettableState<PixelEditorControls>();

  // const $$passwordEditIsOpen = $$(false);
  const $$passwordValue = createSettableState("");
  const $$passwordConfirmValue = createSettableState("");

  const $name = derive([auth.$me], (x) => x!.name);
  const $color = derive([auth.$me], (x) => x!.color);
  const $status = derive([auth.$me], (x) => x!.status);

  const $$inputColor = createSettableState($color.get());
  const $canSaveColor = derive([$color, $$inputColor], (color, inputColor) => color !== inputColor);

  // const $$userMenuIsOpen = $$(false);

  const [$isEditingAvatar, setIsEditingAvatar] = createState(false);
  const [$isEditingName, setIsEditingName] = createState(false);

  const $$profileSectionIsOpen = createSettableState(true);
  const $$appearanceSectionIsOpen = createSettableState(true);
  const $$languageSectionIsOpen = createSettableState(true);
  const $$credentialsSectionIsOpen = createSettableState(true);
  const $$notificationsSectionIsOpen = createSettableState(true);

  return (
    <div class={styles.layout}>
      <div class={styles.content}>
        <CollapsibleListSection
          $title={t("workspace.me.settings.profile.title")}
          $open={$$profileSectionIsOpen}
          onClose={() => {
            $$profileSectionIsOpen.set(false);
          }}
        >
          <ul class={styles.sectionList}>
            <li>
              <header class={styles.sectionHeader}>
                <label>{t("workspace.me.settings.profile.nameHeading")}</label>
              </header>

              {cond(
                $isEditingName,

                <form
                  class={styles.nameForm}
                  onSubmit={(e) => {
                    e.preventDefault();
                    const value = (e.currentTarget as HTMLFormElement).newName.value.trim();
                    if (value.length > 0) {
                      auth.updateMe({ name: value });
                    }
                    setIsEditingName(false);
                  }}
                >
                  <TextInput name="newName" placeholder={$name} />
                  <div class={styles.nameFormButtons}>
                    <Button>{t("common.cancel")}</Button>
                    <Button type="submit">{t("common.save")}</Button>
                  </div>
                </form>,

                <div class={styles.userName}>
                  <span>{$name}</span>
                  <Button
                    onClick={() => {
                      setIsEditingName(true);
                    }}
                  >
                    {t("common.edit")}
                  </Button>
                </div>,
              )}
            </li>
            <li>
              <header class={styles.sectionHeader}>
                <label>{t("workspace.me.settings.profile.avatarHeading")}</label>
              </header>

              {cond(
                $isEditingAvatar,
                <>
                  <div class={styles.editor}>
                    <PixelEditor
                      getInitialData={() => auth.$me.get()?.avatar ?? undefined}
                      $$controlsRef={$$pixelEditorRef}
                    />
                  </div>

                  <div class={styles.editorButtons}>
                    <Button
                      onClick={() => {
                        setIsEditingAvatar(false);
                      }}
                    >
                      {t("common.cancel")}
                    </Button>
                    <Button
                      onClick={() => {
                        const data = $$pixelEditorRef.get()!.getImageData();
                        auth.updateMe({ avatar: data });
                        setIsEditingAvatar(false);
                      }}
                    >
                      {t("common.save")}
                    </Button>
                  </div>
                </>,
                <div class={styles.userAvatar}>
                  <UserAvatar src={derive([auth.$me], (u) => u?.avatar ?? "")} />{" "}
                  <Button
                    onClick={() => {
                      setIsEditingAvatar(true);
                    }}
                  >
                    {t("common.edit")}
                  </Button>
                </div>,
              )}
            </li>

            <li>
              <header class={styles.sectionHeader}>
                <label>{t("workspace.me.settings.profile.colorHeading")}</label>
              </header>
              <div class={styles.userColorInput}>
                <ColorInput
                  $value={$$inputColor}
                  onChange={(color) => {
                    $$inputColor.set(color);
                  }}
                />

                <div class={styles.userColorButtons}>
                  <Button
                    disabled={derive([$canSaveColor], (x) => !x)}
                    onClick={() => {
                      $$inputColor.set($color.get());
                    }}
                  >
                    {t("common.cancel")}
                  </Button>
                  <Button
                    disabled={derive([$canSaveColor], (x) => !x)}
                    onClick={() => {
                      auth.updateMe({
                        color: $$inputColor.get(),
                      });
                    }}
                  >
                    {t("common.save")}
                  </Button>
                </div>
              </div>
            </li>

            {/* <li style={{ display: "flex" }}>
              <div style={{ flex: "1 1 auto" }}>
                <header class={styles.sectionHeader}>
                  <label>{translate("workspace.me.settings.profile.statusHeading")}</label>
                </header>
                <span class={styles.userStatus}>{$status}</span>
              </div>
              <div style={{ display: "flex", flexFlow: "row nowrap", alignItems: "flex-end" }}>
                <Button
                  onClick={() => {
                    dialog.open(UserStatusEdit, {
                      initialValue: $status.get() ?? "",
                      onSave: (value) => {
                        // Update status with API.
                        auth.updateMe({ status: value });
                      },
                    });
                    $$userMenuIsOpen.set(false);
                  }}
                >
                  {translate("common.edit")}
                </Button>
              </div>
            </li> */}
          </ul>
        </CollapsibleListSection>

        <CollapsibleListSection
          $title={t("workspace.me.settings.appearance.title")}
          $open={$$appearanceSectionIsOpen}
          onClose={() => {
            $$appearanceSectionIsOpen.set(false);
          }}
        >
          <ul class={styles.sectionList}>
            <li>
              <header class={styles.sectionHeader}>
                <label>{t("workspace.me.settings.appearance.themeHeading")}</label>
              </header>
              <SegmentButton
                value={theme.$selectedScheme}
                onChange={(scheme) => {
                  theme.setSelectedScheme(scheme);
                }}
                segments={[
                  {
                    value: theme.ColorSchemes.System,
                    content: (
                      <div class={styles.colorSchemeOption}>
                        <Icon name="Schedule" opticalSize={20} />{" "}
                        <span class={styles.colorSchemeLabel}>
                          {t("workspace.me.settings.appearance.colorScheme.system")}
                        </span>
                      </div>
                    ),
                  },
                  {
                    value: theme.ColorSchemes.Light,
                    content: (
                      <div class={styles.colorSchemeOption}>
                        <Icon name="Light Mode" opticalSize={20} />{" "}
                        <span class={styles.colorSchemeLabel}>
                          {t("workspace.me.settings.appearance.colorScheme.light")}
                        </span>
                      </div>
                    ),
                  },
                  {
                    value: theme.ColorSchemes.Dark,
                    content: (
                      <div class={styles.colorSchemeOption}>
                        <Icon name="Dark Mode" opticalSize={20} />{" "}
                        <span class={styles.colorSchemeLabel}>
                          {t("workspace.me.settings.appearance.colorScheme.dark")}
                        </span>
                      </div>
                    ),
                  },
                ]}
              />
            </li>
          </ul>
        </CollapsibleListSection>

        <CollapsibleListSection
          $title={t("workspace.me.settings.language.title")}
          $open={$$languageSectionIsOpen}
          onClose={() => {
            $$languageSectionIsOpen.set(false);
          }}
        >
          <ul class={styles.sectionList}>
            <li>
              <header class={styles.sectionHeader}>
                <h3 class={styles.headerTitle}>{t("workspace.me.settings.language.languageHeading")}</h3>
              </header>
              <Select
                value={Dolla.i18n.$locale}
                onChange={(value) => {
                  const language = value!;
                  Dolla.i18n.setLocale(language);
                  auth.updateMe({ language });
                }}
                options={Dolla.i18n.locales.map((language) => {
                  return {
                    value: language,
                    label: langLabels[language] || language,
                  };
                })}
              />
            </li>
          </ul>
        </CollapsibleListSection>

        {"Notification" in window && (
          <CollapsibleListSection
            $title={t("workspace.me.settings.notifications.title")}
            $open={$$notificationsSectionIsOpen}
            onClose={() => {
              $$notificationsSectionIsOpen.set(false);
            }}
          >
            <ul class={styles.sectionList}>
              <li class={styles.toggleGroup}>
                <label for="settings-push-enabled">
                  {t("workspace.me.settings.notifications.pushNotificationsLabel")}
                </label>
                <Switch
                  id="settings-push-enabled"
                  $value={push.$isPushEnabled}
                  onChange={() => {
                    // TODO: Display a loading state while this is happening.
                    if (!push.$isPushEnabled.get()) {
                      Notification.requestPermission().then(() => {
                        togglePush();
                      });
                    } else {
                      togglePush();
                    }
                  }}
                />
              </li>
            </ul>
          </CollapsibleListSection>
        )}

        <CollapsibleListSection
          $title={t("workspace.me.settings.credentials.title")}
          $open={$$credentialsSectionIsOpen}
          onClose={() => {
            $$credentialsSectionIsOpen.set(false);
          }}
        >
          <ul class={styles.sectionList}>
            <li>
              <header class={styles.sectionHeader}>
                <h3 class={styles.headerTitle}>
                  {t("workspace.me.settings.credentials.changePasswordHeading")}
                </h3>
              </header>
              <form
                class={styles.form}
                onSubmit={(e) => {
                  e.preventDefault();
                  auth.changePassword($$passwordValue.get());
                  // $$passwordEditIsOpen.set(false);
                  $$passwordValue.set("");
                  $$passwordConfirmValue.set("");
                }}
              >
                <div class={styles.formGroup}>
                  <label class={styles.formLabel} for="password-input">
                    {t("workspace.me.settings.credentials.newPasswordLabel")}
                  </label>
                  <TextInput
                    id="password-input"
                    type="password"
                    $$value={$$passwordValue}
                    placeholder="**********"
                  />
                </div>
                <div class={styles.formGroup}>
                  <label class={styles.formLabel} for="password-input">
                    {t("workspace.me.settings.credentials.confirmPasswordLabel")}
                  </label>
                  <TextInput
                    id="password-confirm-input"
                    type="password"
                    $$value={$$passwordConfirmValue}
                    placeholder="**********"
                  />
                </div>

                <span style={{ color: "var(--color-text-secondary)" }}>
                  {derive(
                    [
                      $$passwordValue,
                      $$passwordConfirmValue,
                      t("workspace.me.settings.credentials.passwordNotMatchedMessage"),
                      t("workspace.me.settings.credentials.passwordMatchedMessage"),
                    ],
                    (password, confirm, passwordNotMatchedMessage, passwordMatchedMessage) => {
                      if (password === "" && confirm === "") {
                        return "";
                      }
                      if (password !== confirm) {
                        return passwordNotMatchedMessage;
                      }
                      return passwordMatchedMessage;
                    },
                  )}
                </span>

                <div class={styles.formToolBar}>
                  <ToolBarGroup>
                    <Button
                      type="button"
                      disabled={derive(
                        [$$passwordValue, $$passwordConfirmValue],
                        (pw, pwc) => pw.length < 1 && pwc.length < 1,
                      )}
                      onClick={(e) => {
                        e.preventDefault();
                        $$passwordValue.set("");
                        $$passwordConfirmValue.set("");
                      }}
                    >
                      {t("common.cancel")}
                    </Button>
                  </ToolBarGroup>

                  <ToolBarGroup>
                    <Button
                      type="submit"
                      disabled={derive(
                        [$$passwordValue, $$passwordConfirmValue],
                        (pw, pwc) => pw.length < 1 || pwc.length < 1 || pw !== pwc,
                      )}
                    >
                      {t("common.save")}
                    </Button>
                  </ToolBarGroup>
                </div>
              </form>
            </li>
            <li>
              <Button
                onClick={() => {
                  auth.logOut().catch((err) => ctx.error(err));
                }}
              >
                {t("workspace.me.settings.logOutButtonText")}
              </Button>
            </li>
          </ul>
        </CollapsibleListSection>
      </div>
    </div>
  );
}
