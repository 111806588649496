import { derive, type MaybeState, type Renderable, type ViewContext } from "@manyducks.co/dolla";
import styles from "./MenuList.module.css";

interface MenuListProps {}

export function MenuList(props: MenuListProps, ctx: ViewContext) {
  return <ul class={styles.menuList}>{ctx.outlet()}</ul>;
}

interface MenuListItemProps {
  icon: Renderable;
  label: MaybeState<string>;
  variant?: MaybeState<"normal" | "destructive" | undefined>;
  disabled?: MaybeState<boolean>;
  onClick: (e: MouseEvent) => void;
}

export function MenuListItem(props: MenuListItemProps, ctx: ViewContext) {
  return (
    <li class={styles.menuListItem}>
      <button
        class={[
          styles.menuListItemButton,
          {
            // [styles.normal]: derive([props.variant], (v) => v === "normal"),
            [styles.destructive]: derive([props.variant], (v) => v === "destructive"),
          },
        ]}
        onClick={props.onClick}
        disabled={props.disabled}
      >
        <div class={styles.menuListItemIcon}>{props.icon}</div>
        <span class={styles.menuListItemLabel}>{props.label}</span>
      </button>
    </li>
  );
}
