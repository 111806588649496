export type Pattern<I = any> = PrimitivePattern | FunctionPattern<I>;
export type PrimitivePattern = string | number | symbol | boolean;
export type FunctionPattern<I> = (value: I) => boolean;

export type SwitchPattern<I, O> = SwitchPairPattern<I, O>[];
export type SwitchPairPattern<I, O> = [PrimitivePattern | FunctionPattern<I>, SwitchValue<I, O>];
export type SwitchValue<I, O> = ((value: I) => O) | O;

export type PatternMatcher<I, O> = (value: I) => O;

/**
 * Matches pattern(s) against a value. Called one of four ways:
 *
 * @example
 * 1. Pass (pattern) where pattern is a literal or a (value) => boolean function. Returns a function that takes a value and returns true if the value matches that pattern.
 *
 * const isFive = when(5);
 * const isFive = when(value => value === 5);
 *
 * isFive(2); // false
 * isFive(5); // true
 *
 * 2. Pass (pattern, value) where pattern is a literal or a (value) => boolean function. Returns true if the value matches the pattern.
 *
 * when(5, 2); // false
 * when(5, 5); // true
 *
 * 3. Pass (cases) where cases is an array of [pattern, result]. Returns the result of the first matching case, or the fallback if none match.
 *
 * const ordinal = when([
 *   [1, "once"],
 *   [2, "twice"],
 *   [3, "thrice"],
 *   (value) => `${value} times`
 * ]);
 *
 * ordinal(2); // "twice"
 * ordinal(5); // "5 times"
 *
 * 4. Pass (cases, value) where cases is an array of [pattern, result]. Returns the result of the first matching case or the fallback if none match.
 *
 * when([
 *   [1, "once"],
 *   [2, "twice"],
 *   [3, "thrice"],
 *   (value) => `${value} times`
 * ], 2); // "twice"
 */

export function when<I, O>(pattern: SwitchPattern<I, O>, value: I): O | null;
export function when<I, O>(pattern: SwitchPattern<I, O>): PatternMatcher<I, O | null>;

export function when<I>(pattern: Pattern<I>, value: I): boolean;
export function when<I>(pattern: Pattern<I>): PatternMatcher<I, boolean>;

export function when<I, O>(pattern: SwitchPattern<I, O> | Pattern<I>, value?: I) {
  const fn: PatternMatcher<I, O | null> = (value): O | null => {
    if (array(pattern)) {
      // Process the first case that matches, if any.
      for (const entry of pattern) {
        if (matches(entry[0], value)) {
          // Run value through result function if it is a function.
          if (func(entry[1])) {
            return entry[1](value) as O;
          }

          return entry[1] as O;
        }
      }

      return null;
    } else {
      return matches(pattern, value) as O;
    }
  };

  if (arguments.length > 1) {
    return fn(value!);
  } else {
    return fn;
  }
}

/**
 * Matches a value against a pattern, returning true if it matches or false if it doesn't.
 * Pattern can be a (value) => boolean function or a literal value.
 * Literal values will be compared based on deep equality.
 */
const matches = <I>(pattern: Pattern<I>, value: I) => {
  if (func(pattern)) {
    return truthy(pattern(value));
  } else {
    return deepEqual(pattern, value);
  }
};

/**
 * Compares an expected and actual value, returning true if they have equal values
 * or false otherwise.
 */
const deepEqual = (expected: unknown, value: unknown) => {
  if (expected === value) {
    return true;
  }

  if (typeof expected !== typeof value) {
    return false;
  }

  if (array(value)) {
    if ((expected as unknown[]).length !== value.length) {
      return false;
    }

    for (let i = 0; i < value.length; i++) {
      if (!deepEqual((expected as unknown[])[i], value[i])) {
        return false;
      }
    }

    return true;
  }

  if (object(value)) {
    const keys = Object.keys(value);

    if (Object.keys(expected as Record<any, any>).length !== keys.length) {
      return false;
    }

    for (const key of keys) {
      if (!deepEqual((expected as Record<any, any>)[key], value[key])) {
        return false;
      }
    }

    return true;
  }

  return false;
};

/*==========================*\
||         Utilities        ||
\*==========================*/

/**
 * Throws a TypeError if a value does not match a pattern. If called without a value,
 * returns a function that takes a value and throws a TypeError if value does not match the pattern.
 */
export const assert = <I>(pattern: Pattern<I>, message: string, value: I) => {
  const fn = (value: I) => {
    if (!matches(pattern, value)) {
      throw new TypeError(message);
    }
  };

  if (value) {
    return fn(value);
  } else {
    return fn;
  }
};

/**
 * Returns the same value that was passed in.
 * Useful for fallback cases with `when`.
 */
export function pass<T = unknown>(x: T): x is T {
  return true;
}

/**
 * Combines multiple patterns into one where value must match all.
 */
export const and = <I>(...patterns: Pattern<I>[]) => {
  assert(longerThan(1), "Must pass at least two patterns to combine.", patterns);

  return (x: I) => {
    for (const pattern of patterns) {
      if (!matches(pattern, x)) {
        return false;
      }
    }

    return true;
  };
};

/**
 * Combines multiple patterns into one where value must match at least one.
 */
export const or = <I>(...patterns: Pattern<I>[]) => {
  assert(longerThan(1), "Must pass at least two patterns to combine.", patterns);

  return (x: I) => {
    for (const pattern of patterns) {
      if (matches(pattern, x)) {
        return true;
      }
    }

    return false;
  };
};

/**
 * Combines multiple patterns into one where value must match exactly one.
 */
export const xor = <I>(...patterns: Pattern<I>[]) => {
  assert(longerThan(1), "Must pass at least two patterns to combine.", patterns);

  return (x: I) => {
    let matched = false;

    for (const pattern of patterns) {
      if (matches(pattern, x)) {
        if (matched) {
          return false;
        } else {
          matched = true;
        }
      }
    }

    return matched;
  };
};

/*==========================*\
||      Basic Patterns      ||
\*==========================*/

// Basic patterns take an unknown value and return a boolean.

export const string = (x: unknown): x is string => typeof x === "string";
export const object = <T extends Record<any, any>>(x: unknown): x is T =>
  x != null && typeof x === "object" && !array(x);
export const defined = (x: unknown) => x !== undefined;
export const number = (x: unknown): x is number => typeof x === "number" && !isNaN(x);
export const array = <T = unknown>(x: unknown): x is T[] => Array.isArray(x);
export const integer = (x: unknown): x is number => dividesBy(1)(x);
export const float = (x: unknown) => !dividesBy(1)(x);
export const iterable = (x: unknown) => object<any>(x) && typeof x[Symbol.iterator] === "function";
export const even = (x: unknown): x is number => dividesBy(2)(x);
export const odd = (x: unknown): x is number => and(number, not(even))(x);
export const truthy = (x: unknown): boolean => Boolean(x) === true;
export const falsy = (x: unknown): boolean => Boolean(x) === false;
export const func = (x: unknown): x is (...args: unknown[]) => unknown => typeof x === "function";

/*==========================*\
||    Parametric Patterns   ||
\*==========================*/

// Parametric patterns take parameters and return a pattern that matches
// based on the provided parameters.

// Meta conditions (takes another condition and returns a basic condition)
export const longerThan =
  <I>(n: number) =>
  (x: I) =>
    x && (x as any).length != null && (x as any).length > n;
export const shorterThan =
  <I>(n: number) =>
  (x: I) =>
    x && (x as any).length != null && (x as any).length < n;

export const has =
  (key: any) =>
  (x: unknown): boolean => {
    key = String(key);
    return (object<any>(x) && x[key]) || any(key) || (typeof x === "string" && x.includes(String(key)));
  };

export const dividesBy =
  (d: number) =>
  (x: unknown): x is number =>
    number(x) && x % d === 0;
export const lessThan = (d: number) => (x: unknown) => number(x) && x < d;
export const greaterThan = (d: number) => (x: unknown) => number(x) && x > d;
export const between = (l: number, h: number) => (x: unknown) => number(x) && x >= l && x <= h;

export const deepEquals =
  <T>(expected: T) =>
  (x: unknown): x is T =>
    deepEqual(expected, x);

export const not =
  <I = unknown>(pattern: Pattern<I>) =>
  (x: I) =>
    !matches(pattern, x);

export const first =
  <I>(pattern: Pattern<I>) =>
  (x: unknown) =>
    array<I>(x) && matches(pattern, x[0]);
export const last =
  <I>(pattern: Pattern<I>) =>
  (x: unknown) =>
    array<I>(x) && matches(pattern, x[x.length - 1]);

/**
 * Takes a pattern, returns a function that checks if that pattern matches all items in an array.
 */
export const all =
  <I>(pattern: Pattern<I>) =>
  (x: unknown) => {
    if (!array<I>(x)) return false;

    for (const item of x) {
      if (!matches(pattern, item)) {
        return false;
      }
    }

    return true;
  };

/**
 * Takes a pattern, returns a function that checks if that pattern matches any item in an array.
 */
export const any =
  <I>(pattern: Pattern<I>) =>
  (x: unknown) => {
    if (!array<I>(x)) return false;

    for (const item of x) {
      if (matches(pattern, item)) {
        return true;
      }
    }

    return false;
  };

/**
 * Takes a pattern, returns a function that checks if that pattern matches no items in an array.
 */
export const none = <I>(pattern: Pattern<I>) => not(any(pattern));
