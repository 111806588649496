import { sticky } from "@helpers/sticky";
import Dolla, { cond, derive, type ViewContext } from "@manyducks.co/dolla";

import { projects } from "@stores";

export function ProjectFiles(props: {}, ctx: ViewContext) {
  const $projectId = derive([Dolla.router.$params], (params) => Number(params.projectId));
  const $currentProject = derive([$projectId, projects.$cache], (id, projects) => projects.get(id));
  const $latestProject = derive([$currentProject], sticky());

  return cond($latestProject, <h1>FILES</h1>);
}
