import { Icon } from "MaterialSymbols";
import Dolla, {
  type MaybeState,
  type State,
  type ViewContext,
  createRef,
  toSettableState,
  createState,
  createSetter,
  derive,
} from "@manyducks.co/dolla";
import { CalendarBlockView } from "@views/CalendarInput";
import { HoverMenu } from "@views/HoverMenu";
import styles from "./DatePicker.module.css";

// Works with values in YYYY-MM-DD format.

interface DatePickerProps {
  $value: State<string | null> | State<string | undefined>;
  $min?: State<Date> | State<Date | undefined>;
  onChange: (newValue: string | null) => void;
  $disabled?: State<boolean>;
  placeholder?: MaybeState<string>;
  accentColor?: MaybeState<string>;
}

export function DatePicker(props: DatePickerProps, ctx: ViewContext) {
  const [$menuIsOpen, setMenuIsOpen] = createState(false);
  const menuAnchor = createRef<HTMLElement>();

  const $valueAsDate = derive([props.$value], (date) => {
    if (date) {
      const [y, m, d] = date.split("-").map((n) => Number(n));
      return new Date(y, m - 1, d);
    }
  });

  const setValue = createSetter($valueAsDate, (next) => {
    props.onChange(next ? next.toISOString().split("T")[0] : null);
  });

  return (
    <div class={styles.container}>
      <button
        type="button"
        ref={menuAnchor}
        class={[styles.button, { [styles.active]: $menuIsOpen }]}
        onClick={(e) => {
          setMenuIsOpen(true);
        }}
      >
        <div class={styles.icon}>
          <Icon name="Event" size={20} />
        </div>
        <div class={styles.label}>
          {derive([props.$value, props.placeholder], (value, placeholder) => {
            if (value) {
              const [y, m, d] = value.split("-").map((x) => Number(x));
              return Dolla.i18n.dateTime(new Date(y, m - 1, d), {
                year: "numeric",
                month: "short",
                day: "numeric",
                weekday: "short",
              });
            } else {
              return placeholder;
            }
          })}
        </div>
        <div class={styles.indicator}>
          <Icon name="Arrow Drop Down" />
        </div>
      </button>

      <HoverMenu
        $$open={toSettableState($menuIsOpen, setMenuIsOpen)}
        anchorRef={menuAnchor}
        color={props.accentColor}
        colorNotch
        distanceFromAnchor={14}
        preferHorizontalAlignment="center"
        preferVerticalAlignment="below"
      >
        <div class={styles.calendar}>
          <CalendarBlockView $$date={toSettableState($valueAsDate, setValue)} $min={props.$min} />
        </div>
      </HoverMenu>
    </div>
  );
}
