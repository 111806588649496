import { toState, type MaybeState, type ViewContext } from "@manyducks.co/dolla";
import { Button } from "@views/Button";
import { Dialog, DialogContent, DialogFooter, DialogHeader } from "@views/Dialog";
import styles from "./ChatInfoDialog.module.css";

import { DialogProps } from "@stores/dialog";

interface ChatInfoDialogProps extends DialogProps {
  color: MaybeState<string>;
}

/**
 * A dialog to confirm permanent deletion of some data.
 */
export function ChatInfoDialog(props: ChatInfoDialogProps, ctx: ViewContext) {
  return (
    <Dialog
      onsubmit={() => {
        props.dialog.close();
      }}
      transitionIn={props.dialog.transitionIn}
      transitionOut={props.dialog.transitionOut}
      accentColor={props.color}
    >
      <DialogHeader dialog={props.dialog} $title={toState("About Chat")} />

      <DialogContent>
        <div class={styles.content}>
          <p>Chat is a place for temporary discussions. Messages are deleted after 48 hours.</p>
          <p>If there is something you need to remember, create a note.</p>
          <p>If there is something you need to do, create a task.</p>
        </div>
      </DialogContent>

      <DialogFooter>
        <Button type="submit">OK</Button>
      </DialogFooter>
    </Dialog>
  );
}
