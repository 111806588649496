import { ConfirmDelete } from "@dialogs";
import { relativePastTime } from "@helpers/relativePastTime";
import { Icon } from "MaterialSymbols";
import Dolla, {
  t,
  type MaybeState,
  type State,
  type ViewContext,
  cond,
  toSettableState,
  createState,
  derive,
  repeat,
  toState,
  type Renderable,
} from "@manyducks.co/dolla";

import { MoreMenu } from "@views/MoreMenu";
import prettyBytes from "pretty-bytes";
import { type Note } from "schemas";
import { clock, dialog, notes, projects } from "@stores";
import styles from "./NoteListItem.module.css";

interface NoteListItemProps {
  $note: State<Note>;
  $orderBy?: State<string>;
  scrollToItem?: (noteId: number) => void;

  interactive?: MaybeState<boolean>;
  $charms?: State<Renderable>;
  $theme?: State<any>;

  onClick: () => void;
}

export function NoteListItem(props: NoteListItemProps, ctx: ViewContext) {
  const { $note, $orderBy, scrollToItem } = props;

  const $noteId = derive([$note], (n) => n.id);
  const $defaultTitle = t("views.noteListItem.unnamedFallbackTitle");
  const $title = derive([$note, $defaultTitle], (note, defaultTitle) => note.title || defaultTitle);
  const $isPinned = derive([$note], (n) => n.isPinned);
  const $isTitled = derive([$note], (note) => !!note.title);
  const $isUntitled = derive([$title, $defaultTitle], (title, defaultTitle) => title === defaultTitle);

  const $isSelected = derive(
    [Dolla.router.$pattern, Dolla.router.$params, $noteId],
    (pattern, params, id) => {
      return (pattern?.startsWith("/projects/{#projectId}/notes/{#noteId}") ?? false) && params.noteId === id;
    },
  );

  const $projectColor = derive(
    [$note, projects.$cache],
    (note, projects) => projects.get(note.projectId)?.color,
  );

  const $interactive = toState(props.interactive ?? true);

  const $date = derive([$note, $orderBy, Dolla.i18n.$locale, clock.$minute], (note, orderBy, lang) => {
    let timestamp!: string | null;

    switch (orderBy) {
      case "created-newest":
      case "created-oldest":
      case "alphabetical-newest":
      case "alphabetical-oldest":
        timestamp = relativePastTime(lang!, note.createdAt);
        break;
      case "updated-newest":
      case "updated-oldest":
      default:
        timestamp = relativePastTime(lang!, note.updatedAt);
        break;
    }

    if (timestamp == null) {
      return t("relativeTime.now").get();
    } else {
      return timestamp;
    }
  });

  const [$menuIsOpen, setMenuIsOpen] = createState(false);
  const [$highlight, setHighlight] = createState(false);

  // const $noteState = computed(notesCard.$cardState, (s) => ({
  //   justActiveNoteId: s.justActiveNoteId,
  //   noteState: s.noteState,
  // }));

  // // Trigger highlight animation when going back to list.
  // ctx.observe($noteState, (state) => {
  //   const note = $note.get();
  //   if (state.justActiveNoteId && state.noteState == undefined && note.id === state.justActiveNoteId) {
  //     $$highlight.set(true);
  //     scrollToItem(note.id);
  //     setTimeout(() => {
  //       $$highlight.set(false);
  //       notesCard.updateState({
  //         justActiveNoteId: undefined,
  //       });
  //     }, 1000);
  //   }
  // });

  return (
    <li class={styles.noteListItem} data-note-id={$noteId} style={props.$theme}>
      <button
        class={[
          styles.note,
          {
            [styles.highlight]: $highlight,
            [styles.focused]: $menuIsOpen,
            [styles.selected]: $isSelected,
          },
        ]}
        draggable
        onDragStart={(e) => {
          ctx.log("dragstart", e);

          e.dataTransfer!.setData("data/note-id", String($note.get().id));
          e.dataTransfer!.effectAllowed = "move";
        }}
        onClick={(e) => {
          e.preventDefault();
          props.onClick();
        }}
      >
        {cond(props.$charms, <div class={styles.charmsBar}>{props.$charms}</div>)}

        <div class={styles.noteLayout}>
          <div class={styles.noteText}>
            <span class={{ [styles.untitled]: $isUntitled, [styles.noteTitle]: $isTitled }} draggable={false}>
              {$title}
            </span>

            {cond(
              derive([$note], (n) => n.tags.length > 0),
              <ul class={styles.noteTagList}>
                {repeat(
                  derive([$note], (n) => [...n.tags].sort()),
                  (t) => t,
                  ($tag) => {
                    return <li class={styles.noteTag}>{$tag}</li>;
                  },
                )}
              </ul>,
            )}

            <ul class={styles.meta}>
              <li>
                <div class={styles.metaIcon}>
                  <Icon name="Stylus Note" size={20} />
                </div>
                <span class={styles.metaLabel}>
                  {derive([$note], (note) => {
                    return t("views.noteListItem.metaWordCountLabel", { count: note.wordCount });
                  })}
                </span>
              </li>

              <li>
                <div class={styles.metaIcon}>
                  <Icon name="Search Activity" size={20} />
                </div>
                <span class={styles.metaLabel}>{$date}</span>
              </li>

              {cond(
                derive([$note], (n) => n.isPinned),
                <li>
                  <div class={styles.metaIcon}>
                    <Icon name="Keep" size={20} />
                  </div>
                  <span class={styles.metaLabel}>{t("views.noteListItem.pinnedLabel")}</span>
                </li>,
              )}

              {cond(
                derive([$note], (n) => n.attachments.length > 0),
                <li>
                  <div class={styles.metaIcon}>
                    <Icon name="Attach File" size={20} />
                  </div>
                  <span class={styles.metaLabel}>
                    {derive([$note], (note) => {
                      const count = note.attachments.length;
                      const size = prettyBytes(note.attachments.reduce((sum, f) => sum + f.sizeInBytes, 0));
                      return t("views.taskListItem.meta.attachmentsLabel", { count, size });
                    })}
                  </span>
                </li>,
              )}
            </ul>
          </div>

          {cond(
            $interactive,
            <div class={styles.noteControls}>
              <MoreMenu
                $$open={toSettableState($menuIsOpen, setMenuIsOpen)}
                size="small"
                color={$projectColor}
                options={[
                  {
                    label: derive([$isPinned], (isPinned) =>
                      isPinned
                        ? t("views.noteListItem.actions.pin.unpinLabel")
                        : t("views.noteListItem.actions.pin.pinLabel"),
                    ),
                    icon: <Icon name="Keep" />,
                    callback: () => {
                      notes.setPinned($noteId.get(), !$isPinned.get());
                    },
                  },
                  {
                    label: t("common.delete"),
                    variant: "destructive",
                    icon: <Icon name="Delete Forever" />,
                    callback: () => {
                      dialog.show(ConfirmDelete, {
                        message: t("views.noteListItem.deleteDialog.message"),
                        itemName: $note.get().title,
                        onConfirm: () => {
                          const noteId = $noteId.get();
                          notes.deletePage(noteId).then(() => {
                            ctx.log("deleted note", noteId);
                          });
                        },
                      });
                    },
                  },
                ]}
              />
            </div>,
          )}
        </div>
      </button>
    </li>
  );
}
