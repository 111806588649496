export default {
  "container": "Card_container_fa6cb",
  "outOfViewport": "Card_outOfViewport_fa6cb",
  "lifted": "Card_lifted_fa6cb",
  "card": "Card_card_fa6cb",
  "scope": "Card_scope_fa6cb",
  "scopeIcon": "Card_scopeIcon_fa6cb",
  "scopeLabel": "Card_scopeLabel_fa6cb",
  "scopeRole": "Card_scopeRole_fa6cb",
  "roleIcon": "Card_roleIcon_fa6cb",
  "roleLabel": "Card_roleLabel_fa6cb",
  "header": "Card_header_fa6cb",
  "contacted": "Card_contacted_fa6cb",
  "headerContent": "Card_headerContent_fa6cb",
  "headerGroup": "Card_headerGroup_fa6cb",
  "titleGroup": "Card_titleGroup_fa6cb",
  "icon": "Card_icon_fa6cb",
  "title": "Card_title_fa6cb",
  "mainTitle": "Card_mainTitle_fa6cb",
  "subtitle": "Card_subtitle_fa6cb",
  "showSubtitle": "Card_showSubtitle_fa6cb",
  "outlet": "Card_outlet_fa6cb",
  "menuPixelIcon": "Card_menuPixelIcon_fa6cb",
  "cardContent": "Card_cardContent_fa6cb",
  "cardToolbar": "Card_cardToolbar_fa6cb",
  "cardWidget": "Card_cardWidget_fa6cb"
};import "ni:sha-256;BcDCLYlVh4ZhUA8azrnfiQT03hUYlFA_6CzrW7uOKcU";