import { createRef, type MaybeState, type ViewContext } from "@manyducks.co/dolla";
import Quill from "quill";
import markdownStyles from "@styles/Markdown.module.css";
import Delta, { type Op } from "quill-delta";
import styles from "./ChatMessageContent.module.css";
import { QuackLinkBlot } from "quill/QuackLinkBlot";
// import { QuackLinkBlot } from "@views/TextEditor/blots/QuackLinkBlot";

interface ChatMessageContentProps {
  delta: MaybeState<Op[]>;
}

export function ChatMessageContent(props: ChatMessageContentProps, ctx: ViewContext) {
  // const { translate } = ctx.getStore(LanguageStore);
  const editorElement = createRef<HTMLDivElement>();

  let editor: Quill;

  ctx.onMount(() => {
    editor = new Quill(editorElement.node!, {
      readOnly: true,
      formats: [
        "bold",
        "italic",
        "link",
        "list",
        "blockquote",
        "image",
        "indent",
        "code-block",
        QuackLinkBlot.blotName,
      ],
    });

    // Set link blot context object so it can display live link data and open cards.
    // (editor.scroll as any).emitter.on("quack-link-attached", (blot: QuackLinkBlot) => {
    //   blot.setContext(ctx, props.cardId);
    // });

    ctx.onUnmount(() => {
      editor.scroll.emitter.emit("parent-view-unmounted");
    });
  });

  ctx.watch([props.delta ?? []], (ops) => {
    const delta = new Delta(ops);
    editor.setContents(delta, "silent");
  });

  return (
    <div
      class={styles.container}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div class={[styles.editor, markdownStyles.markdown]} ref={editorElement}></div>
    </div>
  );
}
