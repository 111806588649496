import {
  type MaybeState,
  type Renderable,
  type ViewContext,
  derive,
  repeat,
  toState,
} from "@manyducks.co/dolla";
import styles from "./SegmentButton.module.css";

interface Segment<Id> {
  value: Id;
  content: Renderable;
  disabled?: MaybeState<boolean>;
  hidden?: MaybeState<boolean>;
}

interface SegmentButtonProps<Id> {
  id?: number | string;
  value: MaybeState<Id>;
  segments: MaybeState<Segment<Id>[]>;
  disabled?: MaybeState<boolean>;
  onChange: (newValue: Id, newSegment: Segment<Id>) => void;
}

export function SegmentButton<Id>(props: SegmentButtonProps<Id>, ctx: ViewContext) {
  const $value = toState(props.value);
  const $segments = derive([props.segments], (segments) => segments.filter((s) => !s.hidden));

  return (
    <div
      role="radiogroup"
      class={styles.container}
      style={{
        gridTemplateColumns: derive([$segments], (s) => `repeat(${s.length}, 1fr)`),
      }}
    >
      {repeat(
        $segments,
        (s) => String(s.value),
        ($segment) => {
          const $selected = derive([$value, $segment], (value, segment) => segment.value === value);
          const $segmentValue = derive([$segment], (s) => s.value);
          const $content = derive([$segment], (s) => s.content);

          return (
            <button
              class={styles.button}
              type="button"
              role="radio"
              tab-index={0}
              aria-checked={$selected}
              disabled={props.disabled}
              onClick={() => {
                props.onChange($segmentValue.get(), $segment.get());
              }}
            >
              {$content}
            </button>
          );
        },
      )}
    </div>
  );
}
