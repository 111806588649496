import Dolla, { t, derive, type ViewContext } from "@manyducks.co/dolla";
import { PlaceLayout } from "Workspace/PlaceLayout/PlaceLayout";
import Mousetrap from "mousetrap";

import { dialog, projects } from "@stores";
import { InviteDialog } from "./ProjectOverview/InviteDialog/InviteDialog";
import { EditProjectDialog } from "./ProjectOverview/EditProjectDialog/EditProjectDialog";

export function Project(props: {}, ctx: ViewContext) {
  const $projectId = derive([Dolla.router.$params], (params) =>
    params.projectId ? Number(params.projectId) : null,
  );
  const $project = derive([$projectId, projects.$cache], (id, projects) => (id ? projects.get(id) : null));

  ctx.watch([$projectId], (id) => {
    ctx.log("project id", id);
  });

  ctx.onMount(() => {
    ctx.watch([$projectId], async (projectId) => {
      if (projectId != null) {
        const { exists } = await projects.ensureProjectLoaded(projectId);
        if (!exists) {
          return Dolla.router.go("/");
        }
      }
    });

    // Project: Overview
    Mousetrap.bind("p o", (e) => {
      e.preventDefault();
      const projectId = $projectId.get();
      Dolla.router.go(`/projects/${projectId}/overview`);
    });

    // Project: Notes
    Mousetrap.bind("p n", (e) => {
      e.preventDefault();
      const projectId = $projectId.get();
      Dolla.router.go(`/projects/${projectId}/notes`);
    });

    // Project: Calendar
    Mousetrap.bind("p c", (e) => {
      e.preventDefault();
      const projectId = $projectId.get();
      Dolla.router.go(`/projects/${projectId}/calendar`);
    });

    // Project: Files
    Mousetrap.bind("p f", (e) => {
      e.preventDefault();
      const projectId = $projectId.get();
      Dolla.router.go(`/projects/${projectId}/files`);
    });

    // Project: Invite (project -> people -> invite)
    Mousetrap.bind("p p i", (e) => {
      e.preventDefault();

      const project = $project.get();

      if (!project) return;

      dialog.show(InviteDialog, {
        project: project,
        onSubmit: async (data) => {
          return projects.sendInvite(project.id, data);
        },
        onCancel: () => {},
      });
    });

    // Project: Edit
    Mousetrap.bind("p e", (e) => {
      e.preventDefault();

      const project = $project.get();

      if (!project) return;

      dialog.show(EditProjectDialog, {
        project,
        onConfirm: async (info) => {
          return projects.updateProject(project.id, info);
        },
      });
    });
  });

  ctx.onUnmount(() => {
    Mousetrap.unbind(["p o", "p n", "p c", "p f", "p p i", "p e"]);
  });

  return (
    <PlaceLayout
      color={derive([$project], (p) => p?.color)}
      title={derive([$project], (p) => p?.name ?? "???")}
      tabs={[
        {
          icon: <img src="/icons/todo.12.trans.png" alt="" />,
          href: derive([$projectId], (id) => `/projects/${id}`),
          active: (pattern) => pattern === "/projects/{#projectId}",
          text: t("workspace.project.overview.tabName"),
        },
        {
          icon: <img src="/icons/notes.12.trans.png" alt="" />,
          href: derive([$projectId], (id) => `/projects/${id}/notes`),
          active: (pattern) => pattern.startsWith("/projects/{#projectId}/notes"),
          text: t("workspace.project.notes.tabName"),
        },
        {
          icon: <img src="/icons/tasks.12.trans.png" alt="" />,
          href: derive([$projectId], (id) => `/projects/${id}/calendar`),
          active: (pattern) => pattern.startsWith("/projects/{#projectId}/calendar"),
          text: t("workspace.project.calendar.tabName"),
        },
        {
          icon: <img src="/icons/settings.12.trans.png" alt="" />,
          href: derive([$projectId], (id) => `/projects/${id}/files`),
          active: (pattern) => pattern === "/projects/{#projectId}/files",
          text: t("workspace.project.files.tabName"),
        },
      ]}
    >
      {ctx.outlet()}
    </PlaceLayout>
  );
}
