import Dolla, { t, cond, toSettableState, createState, type ViewContext } from "@manyducks.co/dolla";
import { TextInput } from "@views/TextInput";
import styles from "./Login.module.css";

import { auth, loader, theme } from "@stores";

export function Login(_: {}, ctx: ViewContext) {
  ctx.setName("Login");

  const [$email, setEmail] = createState("");
  const [$password, setPassword] = createState("");
  const [$error, setError] = createState<string>();

  function onSubmit(e: SubmitEvent) {
    e.preventDefault();

    setError(undefined);

    const email = $email.get();
    const password = $password.get();

    auth
      .logIn(email, password)
      .then(() => loader.showAppLoader())
      .then(() => auth.getUsers())
      .then(() => {
        Dolla.router.go("/");
      })
      .catch((err) => {
        setError(err.message);
        ctx.error(err);
      });
  }

  ctx.onMount(() => {
    loader.hideAppLoader();

    if (auth.$isLoggedIn.get()) {
      Dolla.router.go("/");
    }
  });

  return (
    <div class={styles.layout}>
      <div class={styles.container} style={theme.$cssVars}>
        {cond($error, <span class={styles.errorMessage}>{$error}</span>)}

        <form class={styles.form} onsubmit={onSubmit}>
          <TextInput
            autofocus
            $$value={toSettableState($email, setEmail)}
            placeholder={t("login.emailPlaceholder")}
          />
          <TextInput
            type="password"
            $$value={toSettableState($password, setPassword)}
            placeholder={t("login.passwordPlaceholder")}
          />

          <button class={styles.button}>{t("login.buttonPlaceholder")}</button>
        </form>
      </div>
    </div>
  );
}
