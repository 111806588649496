import Dolla from "@manyducks.co/dolla";
import { produce } from "immer";
import type { Project, ProjectInvite } from "schemas";

const debug = Dolla.createLogger("📦 stores/projects");

interface SendProjectInviteData {
  email: string;
  role: "admin" | "collaborator" | "viewer";
}

const [$cache, setCache] = Dolla.createState(new Map<number, Project>());
const [$invites, setInvites] = Dolla.createState<ProjectInvite[]>([]);

export { $cache, $invites };

export async function refreshList() {
  const [projects, invites] = await Promise.all([
    Dolla.http.get<Project[]>("/api/projects"),
    Dolla.http.get<ProjectInvite[]>("/api/projects/my-invites"),
  ]);

  setCache(() => {
    const map = new Map<number, Project>();
    for (const project of projects.body) {
      map.set(project.id, project);
    }
    return map;
  });
  setInvites(invites.body);

  return projects.body;
}

export async function ensureProjectLoaded(projectId: number) {
  const cached = $cache.get().get(projectId);
  if (!cached) {
    try {
      const res = await Dolla.http.get<Project>(`/api/projects/${projectId}`);
      setCache(
        produce((current) => {
          const existing = current.get(projectId);

          if (existing) {
            Object.assign(existing, res.body);
          } else {
            current.set(res.body.id, res.body);
          }
        }),
      );
      return { exists: res.body != null };
    } catch (err) {
      debug.error(err);
    }
  }
  return { exists: cached != null };
}

export async function sendInvite(projectId: number, data: SendProjectInviteData) {
  await Dolla.http.post(`/api/projects/${projectId}/invites`, { body: data });
}

export async function acceptInvite(inviteId: number): Promise<Project> {
  const res = await Dolla.http.post<Project>(`/api/projects/invites/${inviteId}/accept`);
  setInvites((invites) => invites.filter((i) => i.id !== inviteId));
  return res.body;
}

export async function declineInvite(inviteId: number) {
  setInvites((invites) => invites.filter((i) => i.id !== inviteId));
  await Dolla.http.post(`/api/projects/invites/${inviteId}/decline`);
}

export async function createProject(options: Pick<Project, "name" | "color">) {
  const res = await Dolla.http.post<Project>("/api/projects", {
    body: {
      name: options.name,
      color: options.color,
    },
  });

  setCache(
    produce((current) => {
      current.set(res.body.id, res.body);
    }),
  );

  return res.body;
}

export async function updateProject(projectId: number, options: Partial<Exclude<Project, "id" | "users">>) {
  await Dolla.http.put(`/api/projects/${projectId}`, {
    body: options,
  });

  setCache(
    produce((current) => {
      const existing = current.get(projectId);

      if (existing) {
        Object.assign(existing, options);
      } else {
        debug.warn("no matching project to be updated", current, options);
      }
    }),
  );
}

export async function deleteProject(id: number) {
  await Dolla.http.delete(`/api/projects/${id}`);

  setCache(
    produce((projects) => {
      projects.delete(id);
    }),
  );
}

export async function leaveProject(id: number) {
  await Dolla.http.post(`/api/projects/${id}/leave`);

  setCache(
    produce((projects) => {
      return projects.delete(id);
    }),
  );
}

export async function archiveProject(id: number) {
  setCache(
    produce((projects) => {
      const project = projects.get(id);
      if (project) {
        project.archivedAt = new Date().toISOString();
      }
    }),
  );

  await Dolla.http.put(`/api/projects/${id}/archived`);
}

export async function unarchiveProject(id: number) {
  setCache(
    produce((projects) => {
      const project = projects.get(id);
      if (project) {
        project.archivedAt = null;
      }
    }),
  );

  await Dolla.http.delete(`/api/projects/${id}/archived`);
}

export function projectUpdateReceived(project: Project) {
  setCache(
    produce((projects) => {
      projects.set(project.id, project);
    }),
  );
}

export function projectDeleteReceived(id: number) {
  setCache(
    produce((cache) => {
      cache.delete(id);
    }),
  );
}

export function projectInviteReceived(invite: ProjectInvite) {
  setInvites(
    produce((invites) => {
      const current = invites.find((x) => x.id === invite.id);

      if (current) {
        Object.assign(current, invite);
      } else {
        invites.push(invite);
      }
    }),
  );
}

export function updateCache(cache: Array<number>) {
  if (cache.length > 0) {
    // Remove all projects whose IDs are not in cache.
    setCache(
      produce((projects) => {
        for (const id of [...projects.keys()]) {
          if (!cache.includes(id)) {
            projects.delete(id);
          }
        }
      }),
    );
  }
}
