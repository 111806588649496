import {
  type InputType,
  type MaybeState,
  type Ref,
  type SettableState,
  type ViewContext,
  createSettableState,
  toState,
} from "@manyducks.co/dolla";
import styles from "./TextInput.module.css";

interface TextInputProps {
  ref?: Ref<HTMLInputElement>;
  $$value?: SettableState<string>;
  class?: MaybeState<any>;
  autofocus?: boolean;
  autocomplete?: boolean;
  disabled?: MaybeState<boolean>;
  id?: string;
  type?: InputType;
  name?: string;
  placeholder?: MaybeState<string>;
  minlength?: MaybeState<number>;
  onChange?: (value: Event) => void;
  onKeyDown?: (value: KeyboardEvent) => void;
}

export function TextInput(props: TextInputProps, ctx: ViewContext) {
  ctx.setName("TextInput");

  const $class = toState(props.class);
  const $placeholder = toState(props.placeholder ?? "");

  const { autofocus, autocomplete, onChange, onKeyDown, id, type, name } = props;

  const $$value = props.$$value ?? createSettableState("");

  return (
    <input
      ref={props.ref}
      className={[styles.input, $class]}
      id={id}
      type={type}
      name={name}
      placeholder={$placeholder}
      minLength={props.minlength}
      autofocus={autofocus}
      autocomplete={autocomplete}
      disabled={props.disabled}
      $$value={$$value}
      onKeyDown={(e) => {
        if (onKeyDown) {
          onKeyDown(e);
        }
      }}
      onChange={(e) => {
        if (onChange) {
          onChange(e);
        }
      }}
    />
  );
}
